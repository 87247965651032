/* eslint-disable linebreak-style */
import { createUseStyles } from 'react-jss';
import { device } from '../../../../../shared/components/ResponsiveDevice';
import theme from '../../../../../theme.module.scss';

const useClasses = createUseStyles({
  global: {
    '@global': {
      '.faqDiv div': {
        outline: 'none',

        borderBottom: `1px solid ${theme.wolfGrey}`,
        '&:last-child': {
          borderBottom: 'none',
        },
      },
    },
  },
  supportPage: {
    '& .section:nth-child(1)': {
      order: 1,
    },

    '& .section:nth-child(2)': {
      order: 2,
    },

    '& .section:nth-child(3)': {
      order: 3,
    },

    [`@media only screen and ${device.smartphone}`]: {
      '& .section:nth-child(1)': {
        order: 3,
      },

      '& .section:nth-child(2)': {
        order: 1,
      },

      '& .section:nth-child(3)': {
        order: 2,
      },
    },
  },
  lastModule: {
    paddingBottom: '0rem !important',
  },
  upperPad: {
    paddingTop: '1.5rem',
  },
  upperPadSmall: {
    paddingTop: '.5rem',
  },
  upperPadLarge: {
    paddingTop: '2.5rem',
  },
  deepBlue: {
    color: theme.deepBlue,
  },
  medBlue: {
    color: theme.medBlue,
  },
  lightBlue: {
    color: theme.lightBlue,
  },
  marleft1: {
    marginLeft: '1rem',
  },
  maxwidthMobileDoubleButton: {
    maxWidth: '300px',
    width: '300px',
    marginLeft: 'auto',
    marginRight: 'auto',

    [`@media only screen and ${device.smartphone}`]: {
      maxWidth: '300px',
      width: '300px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  maxwidthMobileSingleButton: {
    maxWidth: '200px',
    width: '200px',
    marginLeft: 'auto',
    marginRight: 'auto',

    [`@media only screen and ${device.smartphone}`]: {
      maxWidth: '200px',
      width: '200px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '450px',
    marginLeft: 'auto',
    marginRight: 'auto',

    [`@media only screen and ${device.smartphone}`]: {
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: '300px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  itemRow: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  rowFlex: {
    display: 'flex',
    justifyContent: 'space-between',
    [`@media only screen and ${device.mobile}`]: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexColumnPad: {
    paddingTop: '.5rem',
  },
  bottomBorder: {
    borderBottom: `1px solid ${theme.deepBlue}`,
  },
  bottomBorderGrey: {
    borderBottom: `1px solid ${theme.wolfGrey}`,
  },
  paddingBottom1: {
    paddingBottom: '1rem',
  },
  rightBarQuickLink: {
    color: theme.medBlue,
    display: 'flex',

    '&:hover': {
      color: theme.lightBlue,
      cursor: 'pointer',
    },
  },
  padRightHalf: {
    paddingRight: '.5rem',
  },
  faqRow: {
    paddingTop: '1rem',
    paddingBottom: '1rem',

    '&:hover': {
      cursor: 'pointer',
    },
    [`@media only screen and ${device.smartphone}`]: {
      maxWidth: '250px',
    },
  },
  faqQuestion: {
    maxWidth: '350px',

    [`@media only screen and ${device.smartphone}`]: {
      maxWidth: '250px',
    },

    '&:hover': {
      color: theme.lightBlue,
      cursor: 'pointer',
    },
  },
  faqAnswer: {
    padding: '1.5rem',
    paddingLeft: '3rem',
    paddingRight: '3rem',
    color: theme.medBlue,
    display: 'block',
    [`@media only screen and ${device.smartphone}`]: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
  },
  arrowIcon: {
    '&:hover': {
      color: theme.lightBlue,
      cursor: 'pointer',
    },
  },
  sendEmail: {
    position: 'relative',
    top: '-7px',
  },
  TermsAndConditionsBTNdiv: {
    width: '200px',
    maxWidth: '200px',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: '5rem',
    paddingTop: '0rem',
  },
  container: {
    border: '1px solid #d8dde6',
    backgroundColor: '#fff',
    transition: 'box-shadow 0.28s cubic-bezier(0.4,0,0.2,1)',
    willChange: 'box-shadow',
    padding: '1rem',
  },

  subSectionHeader: {
    color: theme.deepBlue,
    fontSize: '1.35rem',
    fontWeight: 600,
    paddingTop: '0rem',
    paddingBottom: '0.5rem',
    borderBottom: `1px solid ${theme.deepBlue}`,
  },
  moduleContent: {
    color: `${theme.deepBlue}`,
    fontSize: '0.95rem',
  },
  row: {
    color: `${theme.deepBlue}`,
  },
  paddingDiv0: {
    fontFamily: 'inherit',
    fontSize: '0.95rem',
    padding: '0rem',
    paddingTop: '1.5rem',
    paddingBottom: '1rem',
    color: `${theme.deepBlue}`,
  },
  sidebarUL: {
    color: `${theme.darkestBlue}`,
    listStyle: 'none',
    paddingLeft: '0rem',
    marginTop: '0rem',
  },

  sidebarActiveHeaderLI: {
    paddingTop: ' 0.5rem',
    paddingBottom: '0.5rem',
    fontWeight: '600',
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
    },
    '&:hover': {
      cursor: 'pointer',
      color: `${theme.lightBlue}`,
      textDecoration: 'underline',
    },
  },

  sidebarStaticTextUL: {
    color: `${theme.darkestBlue}`,
    listStyle: 'none',
    paddingLeft: '0rem',
    marginTop: '0rem',
  },

  sidebarStaticTextHeaderLI: {
    paddingTop: '0rem',
    paddingBottom: '0.5rem',
    fontWeight: '600',
  },

  sidebarStaticTextLI: {
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    '& a, a:visited': {
      textDecoration: 'none',
      color: 'inherit',
    },
    '& :firstChild': {
      paddingTop: '0rem',
    },
  },
  sidebarStaticTextLiveAssistLI: {
    fontSize: '14px',
    paddingTop: '0rem',
    paddingBottom: '0.5rem',
    fontWeight: '600',
  },

  buttonDiv: {
    display: 'flex',
    fontSize: '0.75rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: '0rem',
    paddingBottom: '0rem',
  },
  newUser: {
    padding: '0rem',
    paddingLeft: '1rem',
    paddingRight: '0rem',
    display: 'block',
  },
  flexUser: {
    display: 'flex',
    maxWidth: '450px',
    marginLeft: 'auto',
    marginRight: 'auto',

    [`@media only screen and ${device.smartphone}`]: {
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: '300px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  assistance: {
    paddingTop: '20px',
  },
  scrollbox: {
    padding: '2rem',
    background: `${theme.white}`,
    margin: '3rem',
    marginTop: '1rem',
    marginBottom: '1rem',
    maxHeight: '400px',
    overflowY: 'scroll',
  },
});

export default useClasses;
