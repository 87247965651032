/* eslint-disable linebreak-style */
import React, { useState } from 'react';

// Material UI components
import TextField from '@material-ui/core/TextField';

// Material UI Icons
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { makeStyles } from '@material-ui/core/styles';
import ToolTip from './Tooltip';

const useStyles = makeStyles(() => ({
  TextField: {
    borderColor: 'white',
    width: '100%',
    paddingTop: '1rem',
  },
  TextFieldLabel: {
    color: 'white',
    textTransform: 'capitalize',
    fontFamily: 'inherit',
  },
}));

/** Input Component With Label and Helpful information */
const InputWithLabel = (props) => {
  const [classes] = useState(useStyles());
  return (
    <>
      {props.hasHelpFulInfo ? (
        <ToolTip
          toolTipText={
            props.helpInfo
              ? props.helpInfo
              : 'Your username could be something custom that you created while registering.'
          }
        >
          <span className={`loginLabel ${classes.TextFieldLabel}`}>
            {props.fieldLabel}{' '}
            <span className={classes.iconButton}>
              <HelpOutlineIcon
                style={{ fontSize: '1.25rem' }}
                className={classes.helpIcon}
              />
            </span>
          </span>
        </ToolTip>
      ) : (
        <>
          {' '}
          {props.fieldLabel ? (
            <span className={`loginLabel ${classes.TextFieldLabel}`}>
              {' '}
              {props.fieldLabel}
            </span>
          ) : null}{' '}
        </>
      )}
      <TextField
        error={props.fieldError}
        helperText={props.fieldError ? props.fieldErrorMsg : ''}
        className={`loginLabel ${classes.TextField}`}
        variant="outlined"
        id={props.fieldId}
        type={props.fieldType}
        onChange={props.onChange}
        value={props.value}
        {...props}
      />
    </>
  );
};

export default InputWithLabel;
