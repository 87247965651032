/* eslint-disable linebreak-style */
import { createSelector } from 'reselect';

// selector
const userInfo = (state) => state.userReducer.userInfoDetails;

// reselect function
export const getUserName = createSelector([userInfo], (user) => user && user.userName);

// reselect function agency name
export const getAgencyName = createSelector(
  [userInfo],
  (user) => user && user.companyName
);
