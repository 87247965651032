/* eslint-disable linebreak-style */
import React, { useState, useEffect } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import { connect } from 'react-redux';
import * as MUI from '../styles/MUI_Components';
import useClasses from '../styles/Classes';
import * as Actions from '../../../../../redux/actionCreators/AdjustmentsActionCreators';
import { ComponentLoader } from '../../../../../shared/components/Loader';
import NumberFormatCustom from '../NumberFormatCustom';

const RefundPayment = ({
  launchRefundPaymentModal,
  cancel: _cancelrefundRequstCallback,
  transactionData,
  requestRefund,
  isConfirmationModalOpen,
  closeConfirmationModal,
  getRefundReasons,
  refundReasons,
  isRefundSubmissionFailed,
  isCreditAdjustmentSubmissionLoading,
  agencyName,
  isRowLevelRefund,
}) => {
  const [refundData, setRefundData] = useState({
    agency: agencyName || '',
    invoice: isRowLevelRefund ? transactionData.orderId : '',
    amount: null,
    paymentTransactionId: transactionData.id || '',
    adjustmentRequestReasonId: null,
    adjustmentRequestReason: {
      reason: null,
    },
  });

  useEffect(() => {
    const { id, amount, orderId } = transactionData;
    if (id && amount) {
      setRefundData({
        ...refundData,
        paymentTransactionId: parseInt(id, 10),
        invoice: isRowLevelRefund ? orderId : '',
        agency: agencyName,
        amount,
      });
    }
    if (!refundReasons.length > 0) {
      getRefundReasons();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionData]);
  const handleSubmit = () => {
    requestRefund(refundData);
  };

  const handleTextChange = (event) => {
    const { value, name } = event && event.target;
    if (name === 'adjustmentRequestReasonId') {
      const selectedReason = refundReasons.filter(
        (reason) => reason.active && reason.id === value
      );
      const reasonName = selectedReason && selectedReason[0] && selectedReason[0].reason;
      setRefundData({
        ...refundData,
        [name]: value,
        adjustmentRequestReason: {
          reason: reasonName,
        },
      });
      return;
    }
    setRefundData({ ...refundData, [name]: value });
  };

  const isEnabled =
    refundData.amount != null &&
    refundData.invoice != null &&
    refundData.adjustmentRequestReasonId != null;

  const closeRefundRequst = () => {
    setRefundData({
      ...refundData,
      invoice: '',
      amount: transactionData.amount,
      adjustmentRequestReasonId: null,
    });
    _cancelrefundRequstCallback();
    closeConfirmationModal();
  };
  const classes = useClasses();
  return (
    launchRefundPaymentModal && (
      <>
        <MUI.RefundRequestModal
          open={launchRefundPaymentModal}
          aria-labelledby="add-refundRequest-title"
          aria-describedby="add-refundRequest-description"
        >
          {isCreditAdjustmentSubmissionLoading ? (
            <ComponentLoader />
          ) : (
            <div className={`${classes.requestRefundModalBody} ${classes.global}`}>
              {!isConfirmationModalOpen ? (
                <div className={classes.modalTitle}>Refund</div>
              ) : (
                ''
              )}
              {isConfirmationModalOpen && (
                <>
                  <div className={classes.martop2}>
                    <div className={`${classes.requestSubmitted}`}>
                      Refund Request Submitted
                    </div>
                    <div
                      className={`${classes.deepBlue} ${classes.upperPad} ${classes.marleft1}`}
                    >
                      Your request has been sent. Please look for a response within 48
                      hours.
                    </div>

                    <div
                      className={`${classes.closeRefundModal} ${classes.martop4} ${classes.buttonDiv}`}
                    >
                      <MUI.ActionButton
                        className={`${classes.smallButtonText}`}
                        onClick={() => {
                          closeRefundRequst();
                        }}
                      >
                        Close
                      </MUI.ActionButton>
                    </div>
                  </div>
                </>
              )}

              {!isConfirmationModalOpen && (
                <>
                  <div className={classes.martop2}>
                    <div className={`${classes.deepBlue}`}>
                      Please fill out the form to request a payment adjustment
                    </div>
                    <div className={classes.martop2}>
                      <MUI.RefundRequestTextField
                        value={refundData.paymentTransactionId}
                        onChange={(e) => {
                          handleTextChange(e);
                        }}
                        className={classes.readOnly}
                        readOnly
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: 'center',
                            color: '#949494',
                          },
                          readOnly: true,
                        }}
                        label="Transaction ID"
                        variant="outlined"
                        name="paymentTransactionId"
                      />
                    </div>
                    <div className={classes.upperPadSmall}>
                      <MUI.RefundRequestTextField
                        value={refundData.agency}
                        onChange={(e) => {
                          handleTextChange(e);
                        }}
                        className={classes.readOnly}
                        readOnly
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: 'center',
                            color: '#949494',
                          },
                          readOnly: true,
                        }}
                        label="Advertiser/Agency Name"
                        variant="outlined"
                        name="agency"
                      />
                    </div>
                    <div className={classes.upperPadSmall}>
                      <span>
                        <MUI.FormControlCustom
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <InputLabel id="reason-label">Reason</InputLabel>
                          <MUI.RefundSelectCustom
                            labelId="reason-label"
                            name="adjustmentRequestReasonId"
                            value={refundData.adjustmentRequestReasonId}
                            className={classes.refundSelect}
                            onChange={handleTextChange}
                            label="Reason"
                          >
                            {refundReasons.length > 0 &&
                              refundReasons
                                .filter(
                                  (reason) =>
                                    reason.active &&
                                    reason.adjustmentRequestTypeId === 'R'
                                )
                                .map((item) => (
                                  <MUI.MenuItemCustom key={item.id} value={item.id}>
                                    {item.reason}
                                  </MUI.MenuItemCustom>
                                ))}
                          </MUI.RefundSelectCustom>
                        </MUI.FormControlCustom>
                      </span>
                    </div>

                    <div className={`${classes.upperPadSmall}`}>
                      <span>
                        <MUI.RefundRequestTextField
                          value={refundData.invoice}
                          onChange={(e) => {
                            handleTextChange(e);
                          }}
                          readOnly={isRowLevelRefund}
                          className={`${classes.refundTextFieldSmall} ${
                            isRowLevelRefund ? `${classes.readOnly}` : ''
                          }`}
                          inputProps={{
                            style: {
                              textAlign: 'left',
                            },
                            readOnly: isRowLevelRefund,
                          }}
                          label="Invoice #"
                          variant="outlined"
                          name="invoice"
                        />
                      </span>

                      <span>
                        <MUI.RefundRequestTextField
                          value={refundData.amount}
                          onChange={(e) => {
                            handleTextChange(e);
                          }}
                          className={`${classes.refundTextFieldSmall} ${classes.marleft1}`}
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                          // eslint-disable-next-line react/jsx-no-duplicate-props
                          inputProps={{
                            style: {
                              textAlign: 'left',
                            },
                          }}
                          label="Amount"
                          variant="outlined"
                          name="amount"
                        />
                      </span>
                    </div>
                  </div>
                  <div className={classes.martop2}>
                    {isRefundSubmissionFailed && (
                      <div>Failed to Submit Refund Request. Please contact support.</div>
                    )}
                  </div>
                  <div
                    className={`${classes.requestRefundModalButtons} ${classes.martop4} ${classes.buttonDiv}`}
                  >
                    <MUI.ActionButton
                      onClick={() => {
                        closeRefundRequst();
                      }}
                    >
                      Cancel
                    </MUI.ActionButton>
                    <span className={classes.marleft1}>
                      <MUI.ActionButton
                        variant="contained"
                        disabled={!isEnabled}
                        className={classes.marleft1}
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        Submit Request
                      </MUI.ActionButton>
                    </span>
                  </div>
                </>
              )}
            </div>
          )}
        </MUI.RefundRequestModal>
      </>
    )
  );
};

const mapStateToProps = (state) => ({
  isConfirmationModalOpen: state.adjustmentsReducer.isConfirmationModalOpen,
  refundReasons: state.adjustmentsReducer.refundReasons,
  isRefundSubmissionFailed: state.adjustmentsReducer.isRefundSubmissionFailed,
  isCreditAdjustmentSubmissionLoading:
    state.adjustmentsReducer.isCreditAdjustmentSubmissionLoading,
  isAdjustmentReasonsLoading: state.adjustmentsReducer.isAdjustmentReasonsLoading,
});
const mapDispatchToProps = (dispatch) => ({
  requestRefund: (data) => {
    Actions.requestRefund(dispatch, data);
  },
  closeConfirmationModal: () => {
    Actions.closeConfirmationModal(dispatch);
  },
  getRefundReasons: () => {
    Actions.getRefundReasons(dispatch);
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(RefundPayment);
