/* eslint-disable linebreak-style */
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import theme from '../../../../../theme.module.scss';

const modalTitleCustom = withStyles(() => ({
  root: {
    color: theme.medBlue,
    padding: '.5rem',
    '& .MuiTypography-root': {
      fontFamily: 'Reach-Text-Regular, sans-serif',
    },
  },
}))(DialogTitle);

const actionButton = withStyles(() => ({
  root: {
    color: theme.medBlue,
    border: `1px solid ${theme.medBlue}`,
    padding: '.5rem',
    minWidth: '9rem',
    textTransform: 'capitalize',
    borderRadius: '0px',
    '&:hover': {
      color: theme.white,
      backgroundColor: theme.deepBlue,
    },
  },
  disabled: {
    backgroundColor: '#e4e4e4',
    border: '1px solid #bdbdbd',
  },
}))(Button);
const actionButtonInverted = withStyles(() => ({
  root: {
    backgroundColor: theme.medBlue,
    color: theme.white,
    border: `1px solid ${theme.medBlue}`,
    padding: '.5rem',
    minWidth: '9rem',
    textTransform: 'capitalize',
    borderRadius: '0px',
    '&:hover': {
      color: theme.white,
      backgroundColor: theme.deepBlue,
    },
  },
  disabled: {
    backgroundColor: '#e4e4e4',
    border: '1px solid #bdbdbd',
  },
}))(Button);
const termsAndConditionsBTN = withStyles(() => ({
  root: {
    color: theme.medBlue,
    border: `1px solid ${theme.medBlue}`,
    padding: '.5rem',
    minWidth: '14rem',
    textTransform: 'capitalize',
    borderRadius: '0px',
    '&:hover': {
      color: theme.white,
      backgroundColor: theme.deepBlue,
    },
  },
  disabled: {
    backgroundColor: '#e4e4e4',
    border: '1px solid #bdbdbd',
  },
}))(Button);
const termsAndConditionsBTNInverted = withStyles(() => ({
  root: {
    backgroundColor: theme.medBlue,
    color: theme.white,
    border: `1px solid ${theme.medBlue}`,
    padding: '.5rem',
    minWidth: '12rem',
    textTransform: 'capitalize',
    borderRadius: '0px',
    '&:hover': {
      color: theme.white,
      backgroundColor: theme.deepBlue,
    },
  },
  disabled: {
    backgroundColor: '#e4e4e4',
    border: '1px solid #bdbdbd',
  },
}))(Button);

export const ActionButtonInverted = actionButtonInverted;
export const ModalTitleCustom = modalTitleCustom;
export const ActionButton = actionButton;
export const TermsAndConditionsBTN = termsAndConditionsBTN;
export const TermsAndConditionsBTNInverted = termsAndConditionsBTNInverted;
