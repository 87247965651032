/* eslint-disable linebreak-style */
import React from 'react';
import { Grid } from '@material-ui/core';
import useClasses from './ExtSupportClasses';
import SupportRightPanel from '../portal/support/components/panels/SupportRightPanel';

const ExternalSupportLayout = ({ title, children }) => {
  const classes = useClasses();
  return (
    <Grid className={`${classes.page}`}>
      <Grid className={`${classes.module}`}>
        <Grid
          className={`${classes.singleContent} ${classes.global} ${classes.supportExtPage}`}
        >
          <Grid className={`${classes.moduleContent}`}>
            <div className={`${classes.header}`}>
              <span>{title}</span>
            </div>
            <Grid className={`${classes.row}`}>
              <Grid className={`${classes.paddingDiv0}`}>{children}</Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid className={`${classes.rightBar}`}>
          <SupportRightPanel />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ExternalSupportLayout;
