/* eslint-disable linebreak-style */

import styled from 'styled-components';
import theme from '../../theme.module.scss';

const heroContainer = styled.div`
background: ${theme.ashGrey}
padding: 2rem;
min-height: calc(100vh - 263px);
`;

const formInstructionsLI = styled.li`
padding-top:.0rem;
padding-bottom: 1.5rem;
color: ${theme.slateGrey}

& :first-child{
    padding-top: 0rem;  
}

`;

export const HeroContainer = heroContainer;
export const FormInstructionsLI = formInstructionsLI;
