/* eslint-disable linebreak-style */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Link from '@material-ui/core/Link';
import { FormHelperText, Typography } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import * as MUI from '../styles/MUI_Components';
import * as Actions from '../../../../../redux/actionCreators/PaymentActionCreators';
import useClasses from '../styles/Classes';
import theme from '../../../../../theme.module.scss';
import NumberFormatCustom from '../NumberFormatCustom';
import { getDayOfWeekDateValidation } from './Helpers';
import { addDaysToDate, addMonthsToDate } from '../../../../../utils/DateUtils';

const MONTH = 'Month';

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersBasePicker: {
      pickerView: {},
    },
    MuiPickersDay: {
      today: {
        color: theme.medBlue,
      },
      daySelected: {
        backgroundColor: theme.medBlue,
      },
    },
  },
});

const OrderDetails = (props) => {
  const CHARACTER_LIMIT = 250;
  const {
    orderNumber,
    description,
    handleChange,
    amount,
    handleAutoPayStartDate,
    handleAutoPayEndDate,
    startDate,
    dayOfMonth,
    endDate,
    dayOfMonthError,
    recurringPaymentTypesData,
    weekDaysData,
    dayOfWeek,
    paymentMethods,
    paymentTokenId,
    paymentType,
    paymentStatus,
    amountError,
    recurringPaymentType,
    isEditFlow = false,
  } = props;

  const classes = useClasses();
  const dispatch = useDispatch();
  const today = new Date();
  const monthMinEndDate = addMonthsToDate(startDate, 1);
  const weekMinEndDate = addDaysToDate(startDate, 7);
  const [dayOfWeekError, setDayOfWeekError] = useState('');
  const [selectedRecurringType, setRecurringType] = useState('');
  const [selectedRecurringTypeValue, setRecurringTypeValue] = useState('');

  useEffect(() => {
    if (isEditFlow) {
      setRecurringType(paymentType);
      setRecurringTypeValue(paymentStatus);
    } else {
      const defaultPaymentStatus =
        (recurringPaymentType && recurringPaymentType.status) || 'EveryMonth';
      const defaultPaymentType =
        (recurringPaymentType && recurringPaymentType.type) || 'Month';
      setRecurringType(defaultPaymentType);
      setRecurringTypeValue(defaultPaymentStatus);
      handleChange({
        target: { name: 'recurringPaymentType', value: defaultPaymentStatus },
      });
      setDefaultEndDate(defaultPaymentType, startDate || today);
    }
  }, []);

  // Set default end based on type
  const setDefaultEndDate = (type, currentStartDate) => {
    let newEndDate = endDate;
    if (type === MONTH) {
      newEndDate = addMonthsToDate(currentStartDate, 1);
    } else {
      newEndDate = addDaysToDate(currentStartDate, 7);
    }
    handleAutoPayEndDate(new Date(newEndDate));
  };

  useEffect(() => {
    if (startDate && endDate && dayOfWeek) {
      setDayOfWeekError(getDayOfWeekDateValidation(startDate, endDate));
    } else {
      setDayOfWeekError();
    }
  }, [startDate, endDate, dayOfWeek]);

  const datesForRecurringPayments = () => Array.from({ length: 28 }, (_, i) => i + 1);

  const handleRecurringTypeValueChange = (event) => {
    const { value } = event.target;
    handleChange(event);
    setRecurringTypeValue(value);
    // setting type based on status
    const selectedType = (recurringPaymentTypesData || []).find(
      (type) => type.status === value
    );
    // if its not edit mode set default end date based on type
    if (!isEditFlow) {
      setDefaultEndDate(selectedType?.type, startDate || today);
    }
    setRecurringType(selectedType?.type);
  };

  const isRecurringMonthly = selectedRecurringType === MONTH;

  return (
    <>
      <div className={`${classes.FlexDiv} ${classes.martop1}`}>
        <span className={`${classes.autopayPaymentAmount}`}>Payment Type</span>
        <div className={classes.marginAuto}>
          <MUI.FormControlCustom variant="outlined">
            <MUI.SelectDateCustom
              aria-label="recurringPaymentTypes"
              name="recurringPaymentType"
              value={selectedRecurringTypeValue}
              onChange={handleRecurringTypeValueChange}
              inputProps={{
                style: {
                  textAlign: 'center',
                },
              }}
            >
              {recurringPaymentTypesData &&
                recurringPaymentTypesData.map((type) => (
                  <MUI.MenuItemCustom key={type.id} value={type?.status}>
                    {type.description}
                  </MUI.MenuItemCustom>
                ))}
            </MUI.SelectDateCustom>
          </MUI.FormControlCustom>
        </div>
      </div>
      <div className={`${classes.FlexDiv} ${classes.martop1}`}>
        <span className={`${classes.autopayPaymentAmount}`}>Payment Method</span>
        <div className={classes.marginAuto}>
          <MUI.FormControlCustom variant="outlined">
            <MUI.SelectDateCustom
              variant="outlined"
              labelId="account-label"
              onChange={handleChange}
              name="paymentTokenId"
              value={paymentTokenId || ''}
              inputProps={{
                style: {
                  textAlign: 'center',
                },
              }}
            >
              {paymentMethods &&
                paymentMethods.map((item) => (
                  <MUI.MenuItemCustom
                    key={item.paymentTokenId}
                    value={item.paymentTokenId}
                  >
                    {`${item.brand} ${item.paymentMethodSuffix}`}
                  </MUI.MenuItemCustom>
                ))}
            </MUI.SelectDateCustom>
            <Typography variant="" className={classes.addAccountLink}>
              Don&apos;t see your account ?
              <Link onClick={() => dispatch(Actions.toggleManagePaymentModal(true))}>
                <span role="link" className={`${classes.linkColor}`}>
                  Add an account
                </span>
              </Link>
            </Typography>
          </MUI.FormControlCustom>
        </div>
      </div>
      <div className={`${classes.FlexDiv} ${classes.martop1}`}>
        <span className={`${classes.autopayPaymentAmount}`}>Order Number</span>
        <MUI.OrderNumberBox
          value={orderNumber || ''}
          onChange={handleChange}
          className={classes.autopayPaymentAmountTextBox}
          variant="outlined"
          name="orderNumber"
        />
      </div>
      <div className={`${classes.FlexDiv} ${classes.martop1}`}>
        <span className={`${classes.autopayPaymentAmount}`}>Payment Amount</span>
        <form autoComplete="off">
          <MUI.OrderNumberBox
            value={amount || ''}
            error={amountError}
            onChange={handleChange}
            className={classes.autopayPaymentAmountTextBox}
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            variant="outlined"
            name="amount"
            id="amount"
          />
          {amountError && (
            <FormHelperText error className={classes.dayOfMonth}>
              {amountError}
            </FormHelperText>
          )}
        </form>
      </div>
      {isRecurringMonthly ? (
        <div className={`${classes.FlexDiv} ${classes.martop1}`}>
          <span className={`${classes.autopayPaymentAmount}`}>
            Date of recurring payment
          </span>
          <div>
            <MUI.FormControlCustom variant="outlined" error={!!dayOfMonthError}>
              <MUI.SelectDateCustom
                labelId="account-label"
                name="dayOfMonth"
                error={!!dayOfMonthError}
                value={dayOfMonth || null}
                onChange={handleChange}
                inputProps={{
                  style: {
                    textAlign: 'center',
                  },
                }}
              >
                {datesForRecurringPayments().map((date) => (
                  <MUI.MenuItemCustom key={date} value={date}>
                    {date}
                  </MUI.MenuItemCustom>
                ))}
              </MUI.SelectDateCustom>
            </MUI.FormControlCustom>
            {dayOfMonthError && (
              <FormHelperText error className={classes.dayOfMonth}>
                {dayOfMonthError}
              </FormHelperText>
            )}
          </div>
        </div>
      ) : (
        <div className={`${classes.FlexDiv} ${classes.martop1}`}>
          <span className={`${classes.autopayPaymentAmount}`}>
            Day of recurring payment
          </span>
          <div>
            <MUI.FormControlCustom variant="outlined">
              <MUI.SelectDateCustom
                labelId="account-label"
                name="dayOfWeek"
                value={dayOfWeek || null}
                onChange={handleChange}
                inputProps={{
                  style: {
                    textAlign: 'center',
                  },
                }}
              >
                {weekDaysData.map((week) => (
                  <MUI.MenuItemCustom key={week.id} value={week.description}>
                    {week.description}
                  </MUI.MenuItemCustom>
                ))}
              </MUI.SelectDateCustom>
            </MUI.FormControlCustom>
            {dayOfWeekError && (
              <FormHelperText error className={classes.dayOfMonth}>
                {dayOfWeekError}
              </FormHelperText>
            )}
          </div>
        </div>
      )}
      <div className={`${classes.noTopPad}`}>
        <div className={`${classes.FlexDiv} ${classes.martop1}`}>
          <span className={`${classes.autopayPaymentAmount} ${classes.martop1}`}>
            AutoPay Start Date
          </span>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ThemeProvider theme={materialTheme}>
              <MUI.DatePicker
                autoOk
                className={classes.marginRight10}
                disableToolbar
                variant="inline"
                format={isRecurringMonthly ? 'MM/yyyy' : 'MM/dd/yyyy'}
                margin="normal"
                id="payment-date-picker"
                minDate={today}
                views={isRecurringMonthly ? ['year', 'month'] : ['date']}
                value={startDate || today}
                onChange={handleAutoPayStartDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        </div>
        <div className={`${classes.FlexDiv} ${classes.martop1}`}>
          <span className={`${classes.autopayPaymentAmount} ${classes.martop1}`}>
            AutoPay End Date
          </span>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ThemeProvider theme={materialTheme}>
              <MUI.DatePicker
                autoOk
                className={classes.marginRight10}
                disableToolbar
                variant="inline"
                format={isRecurringMonthly ? 'MM/yyyy' : 'MM/dd/yyyy'}
                margin="normal"
                id="payment-date-picker"
                minDate={isRecurringMonthly ? monthMinEndDate : weekMinEndDate}
                value={endDate || today}
                views={isRecurringMonthly ? ['year', 'month'] : ['date']}
                onChange={handleAutoPayEndDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        </div>
      </div>
      <div className={`${classes.FlexDiv} ${classes.martop1}`}>
        <span className={`${classes.descriptionLabel}`}>Description</span>
        <MUI.TextAreaCustom
          placeholder="Maximum limit is 250 characters"
          value={description || ''}
          multiline
          rows={5}
          maxLength={25}
          variant="outlined"
          name="description"
          helperText={`${
            description && description.length > 0 ? description.length : 0
          }/${CHARACTER_LIMIT}`}
          onChange={handleChange}
          inputProps={{
            maxlength: CHARACTER_LIMIT,
          }}
        />
      </div>
    </>
  );
};

export default OrderDetails;
