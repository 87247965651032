/* eslint-disable linebreak-style */
import { createUseStyles } from 'react-jss';
import theme from '../../theme.module.scss';
import { device } from '../../shared/components/ResponsiveDevice';

const useClasses = createUseStyles({
  global: {
    '@global': {
      '.MuiRadio-colorSecondary.Mui-checked': {
        color: theme.medBlue,
      },
      '.MuiIconButton-colorSecondary:hover': {
        backgroundColor: 'rgba(0, 175, 255, 0.04)',
      },
      '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.slateGrey} !important`,
      },
    },
  },
  RegistrationKeyDivMessage: {
    color: theme.medBlue,
  },
  requiredLink: {
    color: theme.medBlue,
  },
  alignCenter: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 'fit-content',
  },

  dashBoardButton: {
    margin: '12px 0px 0px 8px',
    width: '100%',
  },
  buttonProgress: {
    color: '#003057',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: 62,
  },
  regKeyGuideLink: {
    textDecoration: 'underline',
    padding: '.25rem 0rem .25rem 1rem',
    color: theme.medBlue,
    display: 'block',

    '&:hover': {
      cursor: 'pointer',
      color: theme.lightBlue,
    },
  },
  modalImage: {
    maxWidth: '500px',
    padding: '1rem',

    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
  modalTitle: {
    padding: '1rem 0rem 0rem 1rem',
  },

  modalButtonDiv: {
    padding: '0rem 1rem 0rem 1rem',
    width: '140px',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    marginBottom: '1rem',
  },
  registrationKeyDiv: {
    width: '100%',
    paddingTop: '1rem',
  },

  infoTextError: {
    color: `${theme.darkRed}`,
    fontFamily: 'inherit',
    letterSpacing: '-0.03rem',
    textAlign: 'center',
    lineHeight: '1rem',
    fontSize: '1rem',
    [`@media only screen and ${device.mobile}`]: {
      paddingLeft: '0rem',
      paddingRight: '0rem',
    },
  },

  signupModule: {
    zIndex: '0',
    position: 'relative',
  },

  signupWrapper: {
    margin: '0 auto',
  },

  linkText: {
    color: `${theme.medBlue}`,
    fontFamily: 'Reach-Title-Bold, sans-serif',
    letterSpacing: '-0.08rem',
    textAlign: 'center',
    paddingTop: '0rem',
    fontSize: '1rem',

    '&:hover': {
      cursor: 'pointer',
      color: `${theme.deepBlue}`,
    },
  },

  headerText: {
    color: `${theme.deepBlue}`,
    fontFamily: 'Reach-Title-Bold, sans-serif',
    fontSize: '1.5rem',
    lineHeight: '2rem',
    letterSpacing: '-0.1rem',
    textAlign: 'center',
  },

  infoText: {
    color: ` ${theme.medBlue}`,
    fontFamily: 'inherit',
    letterSpacing: '0rem',
    textAlign: 'center',
    paddingTop: '0.5rem',
    paddingBottom: '1rem',
    paddingLeft: '2rem',
    paddingRight: '1.5rem',
    lineHeight: '1.3rem',
    fontSize: '1rem',
  },

  content: {
    display: 'flex',
    flexDirection: 'column',

    [`@media only screen and ${device.tablet}`]: {
      display: 'flex',
      flexDirection: 'column',
    },

    [`@media only screen and ${device.laptop}`]: {
      display: 'flex',
      flexDirection: 'row',
    },
  },

  flexColumn: {
    flexDirection: 'column !important',
  },

  formInstructions: {
    minWidth: '300px',
    maxWidth: '350px',
  },

  formInstructionsUL: {
    color: `${theme.darkestBlue}`,
    listStyle: 'none',
    paddingLeft: '0rem',
    marginTop: '0rem',
    paddingTop: '1rem',
    paddingRight: '2rem',
  },

  formInstructionsLI: {
    paddingTop: '.0rem',
    paddingBottom: '1.5rem',
    color: `${theme.slateGrey}`,
    '& :first-child': {
      paddingTop: '0rem',
    },
  },
  heroContainer: {
    background: `${theme.ashGrey}`,
    padding: '2rem',
    minHeight: 'calc(100vh - 263px)',
  },

  passwordError: {
    fontFamily: 'inherit',
    letterSpacing: '-0.03rem',
    textAlign: 'left',
    lineHeight: '1rem',
    [`@media only screen and ${device.mobile}`]: {
      paddingLeft: '0rem',
      paddingRight: '0rem',
    },
  },
  linkColor: {
    color: `${theme.medBlue}`,
  },
});

export default useClasses;
