/* eslint-disable linebreak-style */
import React from 'react';
import { makeStyles } from '@material-ui/core';
import Loading from '../../images/loading.gif';

const useStyles = makeStyles(() => ({
  pageLoader: {
    position: 'fixed',
    top: '83px',
    left: 0,
    height: '100vh' /* to make it responsive */,
    width: '100vw' /* to make it responsive */,
    overflow: 'hidden' /* to remove scrollbars */,
    zIndex: '99999' /* to make it appear on topmost part of the page */,
    backgroundColor: '#FFFFFF',
  },
  pageLoaderImg: {
    position: 'relative',
    top: 'calc(50% - 60px)',
    left: 'calc(50% - 60px)',
  },
  componentLoader: {
    position: 'relative',
    left: 0,
    overflow: 'hidden' /* to remove scrollbars */,
    zIndex: '99999' /* to make it appear on topmost part of the page */,
    backgroundColor: '#FFFFFF',
    minHeight: '7rem',
  },
  componentLoaderImg: {
    position: 'relative',
    left: 'calc(50% - 40px)',
    paddingTop: '5%',
  },
}));

export function PageLoader() {
  const classes = useStyles();
  return (
    <div id="loader" className={classes.pageLoader}>
      <img src={Loading} className={classes.pageLoaderImg} alt="Loading..." />
    </div>
  );
}

export function ComponentLoader() {
  const classes = useStyles();
  return (
    <div id="loader" className={classes.componentLoader}>
      <img src={Loading} className={classes.componentLoaderImg} alt="Loading..." />
    </div>
  );
}
