/* eslint-disable linebreak-style */
/* eslint-disable max-len */
export const NewUserContent = () => [
  {
    id: 1,
    value:
      'Register your new user account. You will not be able to access the new Client Portal using your previous payment site credentials.',
  },
  {
    id: 2,
    value: 'Add payment methods',
  },
  {
    id: 3,
    value: 'Make a payment!',
  },
];

export const Assistance =
  'You’ll find helpful hints and directions throughout the site by clicking the ? buttons. If you need assistance, please call us at 877-286-7117.';
