/* eslint-disable linebreak-style */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import * as MUI from '../styles/MUIComponents';
import useClasses from '../styles/ManageAccountClasses';
import ChangePasswordForm from './ChangePasswordForm';
import { ChangePasswordAction } from '../../../../../redux/actionCreators/authActionCreators';
import { isPasswordValid } from '../../../../../utils/Validations';
import { GetUserInfoAction } from '../../../../../redux/actionCreators/UserActionCreators';
import { ComponentLoader } from '../../../../../shared/components/Loader';

const Security = () => {
  const classes = useClasses();

  const dispatch = useDispatch();
  const {
    changePasswordDetails,
    isChangePasswordFailed,
    isChangePasswordSuccess,
    userInfoDetails,
    isFetchingUserLoading,
  } = useSelector((state) => ({
    changePasswordDetails: state.authReducer.changePasswordDetails,
    isChangePasswordFailed: state.authReducer.isChangePasswordFailed,
    isChangePasswordSuccess: state.authReducer.isChangePasswordSuccess,
    userInfoDetails: state.userReducer.userInfoDetails,
    isFetchingUserLoading: state.userReducer.isFetchingUserLoading,
  }));

  const [enableManagement, setEnableManagement] = useState(false);

  useEffect(() => {
    if (Object.keys(userInfoDetails).length === 0) {
      dispatch(GetUserInfoAction('Security'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { oldPassword, newPassword, confirmNewPassword } = changePasswordDetails;

  return (
    <Grid className={classes.container}>
      {isFetchingUserLoading ? (
        <ComponentLoader />
      ) : (
        <>
          <Grid className={classes.subSectionHeader}>Change Password</Grid>
          <Grid className={classes.moduleContent}>
            <Grid className={classes.row}>
              <Grid className={classes.paddingDiv0}>
                <div className={`${classes.rowFlex}`}>
                  <span>Email</span>
                  <span>{userInfoDetails && userInfoDetails.email}</span>
                </div>
                <div className={`${classes.rowFlex} ${classes.upperPad}`}>
                  <span>First Name</span>
                  <span>{userInfoDetails && userInfoDetails.firstName}</span>
                </div>
                <div className={`${classes.rowFlex} ${classes.upperPad}`}>
                  <span>Last Name</span>
                  <span>{userInfoDetails && userInfoDetails.lastName}</span>
                </div>
                {!enableManagement && (
                  <div className={`${classes.rowFlex} ${classes.upperPad}`}>
                    <span>Password</span>
                    <span>●●●●●●●●●</span>
                  </div>
                )}

                {!enableManagement && (
                  <div className={`${classes.changePasswordDiv}`}>
                    <Grid className={`${classes.buttonDivNoAlign}`}>
                      <MUI.ActionButton
                        onClick={() => {
                          setEnableManagement(true);
                        }}
                      >
                        Change Password
                      </MUI.ActionButton>
                    </Grid>
                  </div>
                )}

                {enableManagement && (
                  <div className={`${classes.upperPad}`}>
                    <div className={`${classes.passwordInstruction}`}>
                      To change your password, type in your new password and confirm it
                      below. Click &quot;Update&quot; to save the changes.
                    </div>
                    <div className={`${classes.upperPadLarge}`}>
                      <ChangePasswordForm />
                      <div className={`${classes.passwordHelperText}`}>
                        Password MUST be at least 8 characters and contain 3 character
                        sets: Uppercase, Lowercase, Numeric or Special.
                      </div>
                      {isChangePasswordFailed &&
                        isChangePasswordFailed.status === 500 && (
                          <Grid
                            className={`${classes.NoTopPad} ${classes.infoTextFailure}`}
                          >
                            {isChangePasswordFailed.error &&
                            Object.keys(isChangePasswordFailed.error).length > 0 &&
                            isChangePasswordFailed.error[23]
                              ? 'Passwords must not use any of the last four passwords.  Please create a different password.'
                              : 'Failed to change Password. Contact support for additional help.'}
                          </Grid>
                        )}
                      {isChangePasswordSuccess && (
                        <Grid
                          className={`${classes.NoTopPad} ${classes.infoTextSuccess}`}
                        >
                          Password successfully changed.
                        </Grid>
                      )}

                      <div className={`${classes.updatePasswordDiv}`}>
                        <Grid className={`${classes.buttonDivNoAlign}`}>
                          <MUI.ActionButton
                            onClick={() => {
                              dispatch(ChangePasswordAction());
                            }}
                            disabled={
                              !oldPassword ||
                              !newPassword ||
                              !confirmNewPassword ||
                              newPassword !== confirmNewPassword ||
                              !isPasswordValid(oldPassword) ||
                              !isPasswordValid(newPassword)
                            }
                          >
                            Update Password
                          </MUI.ActionButton>
                        </Grid>
                      </div>
                    </div>
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Security;
