/* eslint-disable linebreak-style */
/* eslint-disable no-return-assign */
import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { Transition } from 'react-transition-group';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import { Grid } from '@material-ui/core';
import PaymentFailed from './PaymentFailed';
import * as MUI from '../styles/MUI_Components';
import useClasses from '../styles/Classes';
import { ComponentLoader } from '../../../../../shared/components/Loader';
import * as Actions from '../../../../../redux/actionCreators/TransactionHistoryActionCreators';
import { toggleRequestRefundModal } from '../../../../../redux/actionCreators/AdjustmentsActionCreators';
import * as Selectors from '../../../../../redux/selectors/transactionHistorySelectors';
import { getAgencyName } from '../../../../../redux/selectors/UserSelectors';
import pagination from './Pagination';
import { formatDateMMDDYYYY } from '../../../../../utils/DateUtils';
import { MultipleInvoiceList } from '../make-a-payment/MultipleInvoiceList';
import RefundRequest from '../creditAdjustments/RefundRequest';
import RefundPayment from '../creditAdjustments/RefundPayment';
import { PrintComponent } from './PrintComponent';
import ValidationError from '../../../../../shared/components/ValidationError';

const viewDateOptons = [
  { name: '1 Month', value: 1 },
  { name: '3 Months', value: 3 },
  { name: '6 Months', value: 6 },
  { name: 'All', value: 0 },
];

const duration = 200;

const TransactionHistory = () => {
  const dispatch = useDispatch();
  const {
    transactionHistoryData,
    transactionHistoryInformation,
    isCancelTransactionFailed,
    isOpenRefundRequestModal,
    agencyName,
    userInfo,
  } = useSelector((state) => ({
    transactionHistoryData: Selectors.getTransactionHistory(state),
    transactionHistoryInformation:
      state.transactionHistoryReducer.transactionHistoryInformation,
    isCancelTransactionFailed: state.transactionHistoryReducer.isCancelTransactionFailed,
    isOpenRefundRequestModal: state.adjustmentsReducer.isOpenRefundRequestModal,
    agencyName: getAgencyName(state),
    userInfo: state.userReducer.userInfoDetails,
  }));

  const [searchInput, setSearchInput] = useState('');
  useEffect(() => {
    if (transactionHistoryData) {
      dispatch(Actions.getTransactionHistory());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    transactionHistoryData.page,
    transactionHistoryData.rowsPerPage,
    transactionHistoryData.search,
    transactionHistoryData.months,
  ]);

  useEffect(() => {
    dispatch(Actions.resetTransactionHistory());
    setSearchInput('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const classes = useClasses();
  const [showPaymentFailedModal, setShowPaymentFailedModal] = useState(false);
  const [startAnimation, setStartAnimation] = useState(false);
  const [showRefundPayment, setRefundPayment] = useState(false);
  const [transactionData, setTransactionData] = useState({});
  const [activeTransaction, setTransaction] = useState({ activeItem: null });

  const getValue = (id) => {
    const value = transactionHistoryData && transactionHistoryData[id];
    return value;
  };

  const toggleActiveItem = (val) => {
    dispatch(Actions.failedCancelTransaction(false));
    setStartAnimation(false);
    if (val === activeTransaction.activeItem) {
      setStartAnimation(false);
      setTransaction([]);
    } else {
      setTransaction({ activeItem: val });
      setTimeout(() => setStartAnimation(true), 300);
    }
  };

  const handleOnChange = (e) => {
    const { value, name } = e.target;
    dispatch(Actions.changeTransactionHistoryFieldValue({ value, id: name }));
    dispatch(Actions.changeTransactionHistoryFieldValue({ value: 0, id: 'page' }));
  };

  const handleChangePage = (event, newPage) => {
    dispatch(Actions.changeTransactionHistoryFieldValue({ value: newPage, id: 'page' }));
  };

  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value, 10);
    dispatch(Actions.changeTransactionHistoryFieldValue({ value, id: 'rowsPerPage' }));
    dispatch(Actions.changeTransactionHistoryFieldValue({ value: 0, id: 'page' }));
  };

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleEnterSearch = () => {
    dispatch(
      Actions.changeTransactionHistoryFieldValue({ value: searchInput, id: 'search' })
    );
    dispatch(Actions.changeTransactionHistoryFieldValue({ value: 0, id: 'page' }));
  };

  const handlePaymentFailed = () => {
    setShowPaymentFailedModal(true);
  };

  const closePaymentFailedModal = () => {
    setShowPaymentFailedModal(false);
  };

  // const handleRequestARefund = () => {
  //   toggleRequestRefundModal(dispatch, true);
  // };

  const closeRequestRefundModal = () => {
    toggleRequestRefundModal(dispatch, false);
  };

  // const handleRefundPayment = (row) => {
  //   setRefundPayment(true);
  //   setTransactionData(row);
  // };

  const closeRefundPaymentModal = () => {
    setRefundPayment(false);
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <Grid className={classes.container}>
      <PrintComponent
        ref={componentRef}
        transactionHistoryData={transactionData}
        userInfo={userInfo}
        classes={classes}
      />
      <Grid className={classes.subSectionHeader}>Transaction History</Grid>
      <Grid className={classes.moduleContent}>
        <Grid className={classes.row}>
          <div className={classes.transHistTableOptionsRow}>
            <form autoComplete="off">
              <MUI.TableSearch
                value={searchInput}
                name="search"
                onChange={handleSearchChange}
                inputProps={{
                  style: {
                    textAlign: 'left',
                  },
                }}
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    ev.preventDefault();
                    handleEnterSearch();
                  }
                }}
                label="Search"
                variant="outlined"
                id="transhistsearch"
              />
            </form>
            <div>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="dateoption-label">Period</InputLabel>
                <MUI.TransHistSelect
                  labelId="dateoption"
                  id="dateoption"
                  name="months"
                  value={getValue('months')}
                  className={classes.paymentMethodTextFieldMed}
                  onChange={handleOnChange}
                  label="Period"
                  defaultValue="3"
                >
                  {viewDateOptons.map((item) => (
                    <MUI.SelectItemCustom key={item.name} value={item.value}>
                      {item.name}
                    </MUI.SelectItemCustom>
                  ))}
                </MUI.TransHistSelect>
              </FormControl>
            </div>
          </div>
        </Grid>
        {getValue('failedLoadingTransactionHistory') ? (
          <ValidationError
            validationErrorData={getValue('failedLoadingTransactionHistory')}
          />
        ) : (
          <Grid className={classes.moduleContent}>
            {getValue('loadingTransactionHistory') ? (
              <ComponentLoader />
            ) : (
              <MUI.TableContainerCustom component={Paper} elevation={0}>
                <MUI.TableCustom
                  data-testid="transaction-history-table"
                  className={classes.table}
                  aria-label="customized table"
                >
                  {transactionHistoryInformation.length > 0 && (
                    <TableBody>
                      {transactionHistoryInformation.map((row) => (
                        <MUI.StyledTableRow
                          key={row.a}
                          className={classes.transHistTableRow}
                        >
                          <div className={classes.tableRowLabelRow}>
                            <span className={classes.tableInlineValueStatus}>&nbsp;</span>
                            <span className={classes.tableInlineLabel}>Date</span>
                            <span className={classes.tableInlineLabel}>Method</span>
                            <span className={classes.tableInlineLabel}>Amount</span>
                            <span
                              className={`${classes.tableInlineLabel} ${classes.statusCell}`}
                            >
                              Status
                            </span>
                            <span className={classes.tableInlineLabel}>&nbsp;</span>
                          </div>
                          <div className={classes.tableRowValueRow}>
                            <span className={classes.tableInlineValueStatus}>
                              <>
                                {row.status === 'Payment Failed' && (
                                  <ErrorIcon
                                    role="link"
                                    tabIndex={0}
                                    onKeyPress={() => ''}
                                    className={classes.recurringPaymentFailedIcon}
                                    data-testid="trans-history-failed-status"
                                  />
                                )}
                              </>
                              <>
                                {row.status === 'Payment Canceled' && (
                                  <WarningIcon
                                    role="link"
                                    tabIndex={0}
                                    onKeyPress={() => ''}
                                    className={classes.recurringPaymentWarningIcon}
                                    data-testid="trans-history-canceled-status"
                                  />
                                )}
                              </>
                              <>
                                {row.status === 'Processed' && (
                                  <CheckCircleIcon
                                    role="link"
                                    tabIndex={0}
                                    className={classes.recurringPaymentSuccessIcon}
                                    onKeyPress={() => ''}
                                    data-testid="recurring-history-success-status"
                                  />
                                )}
                              </>
                              <>
                                {row.status === 'Payment Received' && (
                                  <InfoIcon
                                    role="link"
                                    tabIndex={0}
                                    className={classes.recurringPaymentReceivedIcon}
                                    onKeyPress={() => ''}
                                    data-testid="trans-history-received-status"
                                  />
                                )}
                              </>
                            </span>
                            <span className={classes.tableInlineValue}>
                              {row.paymentDate && formatDateMMDDYYYY(row.paymentDate)}
                            </span>
                            <span className={classes.tableInlineValue}>{row.brand}</span>
                            <span className={classes.tableInlineValue}>
                              ${row.amount.toFixed(2)}
                            </span>
                            <span
                              className={`${classes.tableInlineValue} ${
                                classes.statusCell
                              } ${
                                row.status === 'Payment Failed'
                                  ? classes.failedStatusCell
                                  : ''
                              }`}
                              role="link"
                              tabIndex={0}
                              onKeyPress={() => ''}
                              onClick={() => {
                                if (row.status === 'Payment Failed') {
                                  handlePaymentFailed();
                                }
                              }}
                            >
                              {row.status}
                            </span>
                            <span className={classes.tableInlinePlus}>
                              {activeTransaction.activeItem === row.id ? (
                                <RemoveCircleOutlineIcon
                                  className={classes.tableMoreDetailsActive}
                                  role="link"
                                  tabIndex={0}
                                  onKeyPress={() => ''}
                                  onClick={() => {
                                    toggleActiveItem(row.id);
                                  }}
                                />
                              ) : (
                                <AddCircleOutlineIcon
                                  role="link"
                                  tabIndex={0}
                                  onKeyPress={() => ''}
                                  onClick={() => {
                                    toggleActiveItem(row.id);
                                    setTransactionData(row);
                                  }}
                                  data-testid="expand-history-row"
                                />
                              )}
                            </span>
                          </div>

                          {activeTransaction.activeItem === row.id ? (
                            <Transition in={startAnimation} timeout={duration}>
                              {() => (
                                <>
                                  <div
                                    className={
                                      classes.transHistoryMultipleInvoicesListContainer
                                    }
                                  >
                                    <div className={classes.transInvoiceWrapper}>
                                      <div className={classes.transIDdiv}>
                                        <div className={classes.invoiceTableInlineLabel}>
                                          Transaction ID
                                        </div>
                                        <div className={classes.tableInlineValue}>
                                          {row.id}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={`${
                                        row.transactionHistoryDetails.length > 0
                                          ? classes.martop2
                                          : ''
                                      } descBox`}
                                    >
                                      <div>
                                        {row.description && (
                                          <div className={classes.tableInlineLabel}>
                                            Description
                                          </div>
                                        )}
                                        <div>
                                          {row.description && (
                                            <div className={classes.tableRowLastRowValue}>
                                              <span className={classes.tableInlineValue}>
                                                <MUI.TransactionHistoryTextArea
                                                  value={row.description}
                                                  multiline
                                                  rows={5}
                                                  variant="outlined"
                                                  name="description"
                                                  className={`${classes.tableInlineValue} ${classes.textAreaStyle} 
                                                      `}
                                                />
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {row.description && (
                                    <div
                                      className={
                                        classes.transHistoryMultipleInvoicesListContainer
                                      }
                                    >
                                      <div className={classes.transInvoiceWrapper}>
                                        <div className={classes.transIDdiv}>
                                          <div
                                            className={classes.invoiceTableInlineLabel}
                                          >
                                            Description
                                          </div>
                                          <div className={classes.tableInlineValue}>
                                            {row.description}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  <div
                                    className={
                                      classes.transHistoryMultipleInvoicesListContainer
                                    }
                                  >
                                    <MultipleInvoiceList
                                      isTransactionHistory
                                      multipleInvoiceDetails={
                                        row.transactionHistoryDetails
                                      }
                                      transactionId={row.id}
                                      agencyName={agencyName}
                                    />
                                  </div>
                                  <div
                                    className={`${classes.flex} ${classes.marbot1} ${classes.historyActionsRow}`}
                                  >
                                    {/* <div
                                      className={`${classes.buttonDiv} ${classes.buttonDivTransHistoryBtns}`}
                                    >
                                      <MUI.ActionButton
                                        data-testid="refund-button"
                                        onClick={() => {
                                          handleRefundPayment(row);
                                        }}
                                        className={classes.invoiceListBtns}
                                        disabled={row.status !== 'Processed'}
                                      >
                                        Refund
                                      </MUI.ActionButton>
                                    </div> */}
                                    <div
                                      className={`${classes.buttonDiv} ${classes.buttonDivTransHistoryBtns}`}
                                    >
                                      <MUI.ActionButton
                                        data-testid="refund-button"
                                        className={classes.invoiceListBtns}
                                        onClick={() => {
                                          dispatch(
                                            Actions.cancelScheduledTransaction(row)
                                          );
                                        }}
                                        disabled={row.status !== 'Payment Received'}
                                      >
                                        Cancel
                                      </MUI.ActionButton>
                                    </div>
                                    <div
                                      className={`${classes.buttonDiv} ${classes.buttonDivTransHistoryBtns}`}
                                    >
                                      <MUI.ActionButton
                                        data-testid="print-transaction-button"
                                        className={classes.invoiceListBtns}
                                        onClick={handlePrint}
                                      >
                                        Print
                                      </MUI.ActionButton>
                                    </div>
                                  </div>
                                  {isCancelTransactionFailed && (
                                    <ValidationError
                                      validationErrorData={isCancelTransactionFailed}
                                    />
                                  )}
                                </>
                              )}
                            </Transition>
                          ) : (
                            <></>
                          )}
                        </MUI.StyledTableRow>
                      ))}
                    </TableBody>
                  )}

                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        colSpan={5}
                        count={getValue('totalRecords')}
                        rowsPerPage={getValue('rowsPerPage')}
                        page={getValue('page')}
                        SelectProps={{
                          inputProps: { 'aria-label': 'rows per page' },
                          native: true,
                        }}
                        style={{ overflow: 'hidden' }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={pagination}
                      />
                    </TableRow>
                  </TableFooter>
                </MUI.TableCustom>
              </MUI.TableContainerCustom>
            )}
            {/* <Link
              className={`${classes.rightQuickLinksLI} ${classes.requestRefundLink} ${classes.martop1}`}
              onClick={handleRequestARefund}
            >
              Need to request a refund for a transaction not shown here? Click here.
            </Link> */}
          </Grid>
        )}
        <PaymentFailed
          openModal={showPaymentFailedModal}
          cancel={closePaymentFailedModal}
        />
        {isOpenRefundRequestModal && (
          <RefundRequest
            launchRefundRequestModal={isOpenRefundRequestModal}
            cancel={closeRequestRefundModal}
          />
        )}
        {showRefundPayment && (
          <RefundPayment
            launchRefundPaymentModal={showRefundPayment}
            cancel={closeRefundPaymentModal}
            transactionData={transactionData}
            agencyName={agencyName}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default TransactionHistory;
