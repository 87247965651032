/* eslint-disable linebreak-style */
import React from 'react';
import { LeftPanel } from '@spectrum-reach/external-theme';
import PaneledPage from '../../../shared/package/layout/PaneledPage';
// import SupportLeftPanel from './components/panels/SupportLeftPanel';
import SupportCenterPanel from './components/panels/SupportCenterPanel';
import SupportRightPanel from './components/panels/SupportRightPanel';

const Support = () => {
  const leftPanelLinks = [
    {
      name: 'Frequently Asked Questions',
      smoothTo: '#FAQs',
    },
    {
      name: 'New User?',
      smoothTo: '#NewUser',
    },
    {
      name: 'Terms and Conditions',
      smoothTo: '#TermsAndConditions',
    },
  ];
  return (
    <PaneledPage
      title="Support"
      left={<LeftPanel leftPanelLinks={leftPanelLinks} />}
      center={<SupportCenterPanel />}
      right={<SupportRightPanel />}
    />
  );
};

export default Support;
