/* eslint-disable linebreak-style */
import React from 'react';
import { useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import { HashLink } from 'react-router-hash-link';
import * as MUI from '../styles/MUI_Components';
import customStyles from '../styles/CustomStyles';
import useClasses from '../styles/Classes';
import * as Actions from '../../../../../redux/actionCreators/PaymentActionCreators';

const PaymentType = () => {
  const classes = useClasses();
  const dispatch = useDispatch();

  return (
    <>
      {' '}
      <div style={customStyles.paymentModuleMaxWidth}>
        <Grid className={`${classes.rowPad}`}>
          <div
            className={`${classes.buttonDiv} ${classes.paymentTypeCenter}`}
            style={customStyles.nextButtonDiv}
          >
            <MUI.ActionButton
              onClick={() => {
                dispatch(Actions.makePaymentStep(2));
              }}
              className={classes.paymentTypeButton}
            >
              Make payment
            </MUI.ActionButton>
            <span className={classes.marleft1}>
              <MUI.ActionButton className={classes.paymentTypeButton}>
                <HashLink
                  smooth
                  to="#Autopay"
                  className={classes.recurringPaymentNoStyle}
                >
                  Schedule Recurring Payment
                </HashLink>
              </MUI.ActionButton>
            </span>
          </div>
        </Grid>
      </div>
    </>
  );
};

export default PaymentType;
