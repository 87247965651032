/* eslint-disable linebreak-style */
/* eslint-disable no-lonely-if */
import axios from 'axios';
import { push } from 'connected-react-router';
import * as types from '../actionTypes/UserActionTypes';
import * as authTypes from '../actionTypes/authActionTypes';
import { appApiEndPoints } from '../../constants/apiConstants';
import { UseAuthorization } from '../../utils/UseAuthorization';
import { handleUnAuthorizedUser } from './authActionCreators'; // eslint-disable-line import/no-cycle
import { removeTokens, isUserAuthenticated } from '../../utils/AuthUtils';
import { getErrorMessages } from '../../utils/ErrorHandler';

/** Request Action type for getting user information */
export const fetchUserInfoRequest = () => ({
  type: types.FETCH_USER_INFO_REQUEST,
});

/** Success Action type for getting user information */
export const fetchUserInfoSuccess = (data) => ({
  type: types.FETCH_USER_INFO_SUCCESS,
  data,
});

/** Failure Action type for getting user information */
export const fetchUserInfoFailure = () => ({
  type: types.FETCH_USER_INFO_FAILURE,
});

/** Request Action type for Terms and Conditions */
export const TermsandConditionsRequest = () => ({
  type: types.TERMS_AND_CONDITIONS_PENDING,
});

/** Success Action type for Terms and Conditions */
export const TermsandConditionsSuccess = (data) => ({
  type: types.TERMS_AND_CONDITIONS_ACCEPTED_STATUS,
  data,
});

/** Failure Action Type for Terms and Conditions  */
export const TermsandConditionsFailure = (data) => ({
  type: types.TERMS_AND_CONDITIONS_FAILURE,
  data,
});
export const loadingSignIn = (data) => ({
  type: authTypes.LOADING_SIGN_IN,
  data,
});

export const resetUserDetails = () => ({
  type: types.RESET_USER_DETAILS,
});

export const userDoesNotExist = (data) => ({
  type: types.USER_DOES_NOT_EXIST,
  data,
});

/** Fetch User Information Action */
export const GetUserInfoAction = (page) => async (dispatch) => {
  dispatch(loadingSignIn(true));
  dispatch(fetchUserInfoRequest());
  axios
    .get(`${appApiEndPoints.userInfo}`, await UseAuthorization())
    .then((response) => {
      if (response.data.status === 200) {
        dispatch(loadingSignIn(false));
        dispatch(fetchUserInfoSuccess(response.data.data));
        if (page === 'termsAndConditions') {
          localStorage.setItem(
            'termsAndConditionsStatus',
            response.data.data.acceptedTermsAndConditions
          );
          dispatch(push({ pathname: '/' }));
        }
      } else {
        dispatch(loadingSignIn(false));
        if (response.data.status === 500) {
          removeTokens();
          dispatch(fetchUserInfoFailure());
        }
      }
    })
    .catch((error) => {
      /** If user does not exist in Client portal DB */
      if (isUserAuthenticated() && error.response?.data.error === 'User does not exist') {
        dispatch(userDoesNotExist(false));
        dispatch(push({ pathname: '/user-profile' }));
      } else {
        dispatch(loadingSignIn(false));
        dispatch(fetchUserInfoFailure());
        if (error && error.response && error.response.status === 401) {
          handleUnAuthorizedUser(dispatch);
        } else {
          removeTokens();
        }
      }
      throw error;
    });
};

/** Update User Terms and Conditions */
export const UpdateTermsandConditionsAcceptedStatus = async (dispatch) => {
  dispatch(TermsandConditionsRequest());
  axios
    .post(`${appApiEndPoints.updateTermsAndConditions}`, {}, await UseAuthorization())
    .then((response) => {
      if (response.status === 200) {
        localStorage.setItem(
          'termsAndConditionsStatus',
          response.data.data.acceptedTermsAndConditions
        );
        dispatch(push({ pathname: '/' }));
        dispatch(TermsandConditionsSuccess(response.data.data));
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.status === 401) {
        handleUnAuthorizedUser(dispatch);
      } else if (error && error.response && error.response.status === 400) {
        const errorMsg = getErrorMessages(
          error,
          'Failed to save Terms & Conditions. Please contact support.'
        );
        dispatch(TermsandConditionsFailure(errorMsg));
      } else {
        dispatch(
          TermsandConditionsFailure(
            'Failed to save Terms & Conditions. Please contact support.'
          )
        );
      }
      throw error;
    });
};
