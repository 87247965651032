/* eslint-disable linebreak-style */
import React, { useState, useEffect } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import { connect } from 'react-redux';
import * as MUI from '../styles/MUI_Components';
import useClasses from '../styles/Classes';
import NumberFormatCustom from '../NumberFormatCustom';
import * as Actions from '../../../../../redux/actionCreators/AdjustmentsActionCreators';
import { ComponentLoader } from '../../../../../shared/components/Loader';

const CreditAdjustmentRequest = ({
  launchRefundRequestModal,
  cancel: _cancelrefundRequstCallback,
  refundReasons,
  getRefundReasons,
  requestRefund,
  isAdjustmentsConfirmationModalOpen,
  closeAdjustmentsModal,
  isRefundSubmissionFailed,
  isCreditAdjustmentSubmissionLoading,
}) => {
  const classes = useClasses();

  const [initialValues, setInitialValues] = useState({
    agency: '',
    invoice: '',
    amount: '',
    adjustmentRequestReasonId: null,
  });

  useEffect(() => {
    if (!refundReasons.length > 0) {
      getRefundReasons();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleTextChange = (event) => {
    const { value, name } = event && event.target;
    setInitialValues({ ...initialValues, [name]: value });
  };

  const resetIntitalValues = () => {
    setInitialValues({
      agency: '',
      invoice: '',
      amount: '',
      adjustmentRequestReasonId: null,
    });
  };

  const closeRefundRequst = () => {
    closeAdjustmentsModal();
    resetIntitalValues();
    _cancelrefundRequstCallback();
  };

  const handleSubmit = () => {
    requestRefund(initialValues);
  };

  const isEnabled =
    initialValues.agency.length > 0 &&
    initialValues.amount.length > 0 &&
    initialValues.invoice.length > 0 &&
    initialValues.adjustmentRequestReasonId != null;

  return (
    launchRefundRequestModal && (
      <>
        <MUI.RefundRequestModal
          open={launchRefundRequestModal}
          aria-labelledby="add-refundRequest-title"
          aria-describedby="add-refundRequest-description"
        >
          {isCreditAdjustmentSubmissionLoading ? (
            <ComponentLoader />
          ) : (
            <div className={`${classes.modalBody} ${classes.global}`}>
              {!isAdjustmentsConfirmationModalOpen ? (
                <div className={classes.modalTitle}>Credit Adjustment Form</div>
              ) : (
                ''
              )}
              {isAdjustmentsConfirmationModalOpen && (
                <>
                  <div className={classes.martop2}>
                    <div className={`${classes.requestSubmitted}`}>
                      Credit Adjustment Request Submitted
                    </div>
                    <div
                      className={`${classes.deepBlue} ${classes.upperPad} ${classes.marleft1}`}
                    >
                      Your request has been sent. Please look for a response within 48
                      hours.
                    </div>

                    <div
                      className={`${classes.requestRefundModalButtons} ${classes.martop4} ${classes.buttonDiv}`}
                    >
                      <MUI.ActionButton
                        className={`${classes.smallButtonText}`}
                        onClick={() => {
                          closeRefundRequst();
                        }}
                      >
                        Close
                      </MUI.ActionButton>
                      <span className={classes.marleft1}>
                        <MUI.ActionButton
                          className={`${classes.marleft1} ${classes.smallButtonText}`}
                          onClick={() => {
                            closeAdjustmentsModal();
                            resetIntitalValues();
                          }}
                        >
                          Submit Another Request
                        </MUI.ActionButton>
                      </span>
                    </div>
                  </div>
                </>
              )}

              {!isAdjustmentsConfirmationModalOpen && (
                <>
                  <form autoComplete="off">
                    <div className={classes.martop2}>
                      <div className={`${classes.deepBlue}`}>
                        Please fill out the form to request a payment adjustment
                      </div>
                      <div className={classes.martop2}>
                        <MUI.RefundRequestTextField
                          value={initialValues.agency}
                          onChange={(e) => {
                            handleTextChange(e);
                          }}
                          fullWidth
                          inputProps={{
                            style: {
                              textAlign: 'center',
                            },
                          }}
                          label="Advertiser/Agency Name"
                          variant="outlined"
                          name="agency"
                          id="agency"
                        />
                      </div>
                      <div className={classes.upperPadSmall}>
                        <span>
                          <MUI.FormControlCustom
                            variant="outlined"
                            className={classes.formControl}
                          >
                            <InputLabel id="reason-label">Reason</InputLabel>
                            <MUI.RefundSelectCustom
                              labelId="reason-label"
                              id="adjustmentRequestReasonId"
                              name="adjustmentRequestReasonId"
                              value={initialValues.reason}
                              className={classes.refundSelect}
                              onChange={handleTextChange}
                              label="Reason"
                            >
                              {refundReasons.length > 0 &&
                                refundReasons
                                  .filter(
                                    (reason) =>
                                      reason.active &&
                                      reason.adjustmentRequestTypeId === 'A'
                                  )
                                  .map((item) => (
                                    <MUI.MenuItemCustom key={item.id} value={item.id}>
                                      {item.reason}
                                    </MUI.MenuItemCustom>
                                  ))}
                            </MUI.RefundSelectCustom>
                          </MUI.FormControlCustom>
                        </span>
                      </div>
                      <div className={`${classes.upperPadSmall}`}>
                        <span>
                          <MUI.RefundRequestTextField
                            value={initialValues.invoice}
                            onChange={(e) => {
                              handleTextChange(e);
                            }}
                            className={classes.refundTextFieldSmall}
                            inputProps={{
                              style: {
                                textAlign: 'left',
                              },
                            }}
                            label="Invoice #"
                            variant="outlined"
                            name="invoice"
                            id="invoice"
                          />
                        </span>

                        <span>
                          <MUI.RefundRequestTextField
                            value={initialValues.amount}
                            onChange={(e) => {
                              handleTextChange(e);
                            }}
                            className={`${classes.refundTextFieldSmall} ${classes.marleft1}`}
                            label="Amount"
                            variant="outlined"
                            name="amount"
                            id="amount"
                            InputProps={{
                              inputComponent: NumberFormatCustom,
                              style: {
                                textAlign: 'left',
                              },
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  </form>
                  <div className={classes.martop2}>
                    {isRefundSubmissionFailed && (
                      <div className={classes.infoText}>
                        Failed to Submit Refund Request. Please contact support.
                      </div>
                    )}
                  </div>

                  <div
                    className={`${classes.requestRefundModalButtons} ${classes.martop4} ${classes.buttonDiv}`}
                  >
                    <MUI.ActionButton
                      onClick={() => {
                        closeRefundRequst();
                      }}
                    >
                      Cancel
                    </MUI.ActionButton>
                    <span className={classes.marleft1}>
                      <MUI.ActionButton
                        disabled={!isEnabled}
                        className={classes.marleft1}
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        Submit Request
                      </MUI.ActionButton>
                    </span>
                  </div>
                </>
              )}
            </div>
          )}
        </MUI.RefundRequestModal>
      </>
    )
  );
};

const mapStateToProps = (state) => ({
  refundReasons: state.adjustmentsReducer.refundReasons,
  isAdjustmentsConfirmationModalOpen:
    state.adjustmentsReducer.isAdjustmentsConfirmationModalOpen,
  isRefundSubmissionFailed: state.adjustmentsReducer.isRefundSubmissionFailed,
  isCreditAdjustmentSubmissionLoading:
    state.adjustmentsReducer.isCreditAdjustmentSubmissionLoading,
});
const mapDispatchToProps = (dispatch) => ({
  requestRefund: (data) => {
    Actions.requestRefund(dispatch, data);
  },
  closeAdjustmentsModal: () => {
    Actions.closeAdjustmentsModal(dispatch);
  },
  getRefundReasons: () => {
    Actions.getRefundReasons(dispatch);
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(CreditAdjustmentRequest);
