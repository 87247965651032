/* eslint-disable linebreak-style */
import React from 'react';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { Grid } from '@material-ui/core';
import useClasses from '../styles/SupportClasses';

// Graphics
import StyledPanel from '../../../../../shared/package/styles/StyledPanel';
import AutoHeightBox from '../../../../../shared/package/layout/AutoHeightBox';

const SupportRightPanel = () => {
  const classes = useClasses();
  return (
    <AutoHeightBox md={{ mb: 1 }}>
      <StyledPanel>
        <Grid className={`${classes.sidebarStaticTextUL}`}>
          <Grid className={`${classes.sidebarStaticTextHeaderLI}`}>Support</Grid>
          <Grid className={`${classes.sidebarStaticTextLI}`}>
            <span className={`${classes.rightBarQuickLink}`}>
              <a href="mailto:SRClientPortal@charter.com?subject=Spectrum Reach Client Portal - Email Support">
                <span className={`${classes.padRightHalf}`}>
                  <MailOutlineIcon />
                </span>
                <span className={`${classes.sendEmail}`}>send email</span>
              </a>
            </span>
          </Grid>
          <Grid className={`${classes.sidebarStaticTextLI}`}>
            <span className={`${classes.rightBarQuickLink}`}>
              <span className={`${classes.padRightHalf}`}>
                <PhoneIphoneIcon />
              </span>{' '}
              877-286-7117{' '}
            </span>
          </Grid>
          <Grid className={`${classes.sidebarStaticTextLiveAssistLI}`}>
            Live assistance available between 8am and 7pm EST
          </Grid>
        </Grid>
      </StyledPanel>
    </AutoHeightBox>
  );
};

export default SupportRightPanel;
