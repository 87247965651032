import React from 'react';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PageBackground from '../styles/PageBackground';
import PageContainer from './PageContainer';
import PageTitle from '../styles/PageTitle';

const useStyles = makeStyles((theme) => ({
  panel: {
    [theme.breakpoints.up('md')]: {
      minHeight: '55vh',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '94vw',
    },
  },
  adContainer: {
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(2),
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  adGroup: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  desktopAd: {
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobileAd: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));

const PaneledPage = ({ left, center, right, title }) => {
  const classes = useStyles();

  return (
    <PageBackground>
      <PageContainer>
        <Grid container direction="row" justify="center" alignItems="stretch">
          <Grid container item className={classes.adGroup}>
            <Grid item className={classes.titleContainer}>
              <PageTitle title={title} />
            </Grid>
          </Grid>
          <Grid container item justify="center" alignItems="stretch" spacing={3}>
            <Grid item xs={12} sm={12} md={3} lg={3} className={classes.panel}>
              {left}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className={classes.panel}>
              {center}
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} className={classes.panel}>
              {right}
            </Grid>
          </Grid>
        </Grid>
      </PageContainer>
    </PageBackground>
  );
};

export default PaneledPage;
