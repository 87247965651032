/* eslint-disable linebreak-style */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import * as MUI from '../styles/MUIComponents';
import useClasses from '../styles/ManageAccountClasses';
import * as Actions from '../../../../../redux/actionCreators/AccountUsersActionCreators';
import { ComponentLoader } from '../../../../../shared/components/Loader';
import ValidationError from '../../../../../shared/components/ValidationError';

const DisableUser = ({ launchDeleteUserMethod, selecteddata }) => {
  const classes = useClasses();
  const dispatch = useDispatch();

  const { isDisableUserLoading, userDisablingFailedError } = useSelector((state) => ({
    isDisableUserLoading: state.accountUsersReducer.isDisableUserLoading,
    userDisablingFailedError: state.accountUsersReducer.userDisablingFailedError,
  }));

  return (
    <MUI.DeleteUserMethodModal
      open={launchDeleteUserMethod}
      aria-labelledby="deleteUser-title"
      aria-describedby="deleteUser-description"
    >
      {isDisableUserLoading ? (
        <ComponentLoader />
      ) : (
        <>
          {' '}
          <div className={classes.modalBody}>
            <div className={classes.modalTitle}> Disable User </div>
            <div className={classes.upperPad}>
              <div className={`${classes.deepBlue}`}>
                Are you sure you want to disable this user? They will no longer be able to
                access your accounts, but they will still be able to login and make
                payments.
              </div>
              <div className={`${classes.deepBlue} ${classes.marginTopBottom}`}>
                {selecteddata.userName}
              </div>
              {userDisablingFailedError && (
                <ValidationError validationErrorData={userDisablingFailedError} />
              )}
              <span>
                <Grid
                  className={`${classes.modalButtonsLeftPad} ${classes.upperPad} ${classes.buttonDiv}`}
                >
                  <MUI.ActionButton
                    onClick={() => {
                      dispatch(
                        Actions.changeSecondaryUserFieldValue({
                          value: false,
                          id: 'isDisableModalOpen',
                        })
                      );
                    }}
                  >
                    Cancel
                  </MUI.ActionButton>
                  <span className={classes.marleft1}>
                    <MUI.ActionButtonDangerInverted
                      onClick={() => {
                        Actions.disableUser(dispatch, selecteddata && selecteddata.id);
                      }}
                    >
                      {' '}
                      Disable User
                    </MUI.ActionButtonDangerInverted>
                  </span>
                </Grid>
              </span>
            </div>
          </div>
        </>
      )}
    </MUI.DeleteUserMethodModal>
  );
};

export default DisableUser;
