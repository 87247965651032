/* eslint-disable linebreak-style */
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import React from 'react';
import { device } from './ResponsiveDevice';
import theme from '../../theme.module.scss';
import InputWithLabel from './InputWithLabel';

/** Shared Hero Container */
export const HeroContainer = styled.div`
background: ${theme.ashGrey}
padding: 2rem;
min-height: calc(100vh - 263px);


@media only screen and ${device.smartphone} { 
  max-height: 700px !important;
}

@media only screen and ${device.tablet} {
  max-height: 770px;
}

@media only screen and ${device.mobile} {
  max-height: 770px;
}

@media only screen and ${device.laptop} {
  height: 105vh;
}

@media only screen and ${device.desktop} {
  height: 105vh;
}
`;

/** TextBox styles */
export const TextBox = withStyles(() => ({
  root: {
    '& .MuiInputBase-root': {
      fontFamily: 'Reach-Text-Regular, sans-serif',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      marginLeft: '0px !important',
      marginRight: '0px !important',
      width: '280px',
      maxWidth: '280px',
    },
  },
}))(InputWithLabel);

export const TextBoxFirst = withStyles(() => ({
  root: {
    padding: '0rem',
    '& .MuiInputBase-root': {
      fontFamily: 'Reach-Text-Regular, sans-serif',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      marginLeft: '0px !important',
      marginRight: '0px !important',
      width: '280px',
      maxWidth: '280px',
    },
  },
}))(InputWithLabel);

/** Card Container styles */
export const CardContainer = withStyles(() => ({
  root: {
    fontFamily: 'Reach-Text-Regular, sans-serif',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '800px',

    '&.MuiPaper-root': {
      background: 'transparent',
    },
    '& .MuiCardContent-root:last-child': {
      paddingBottom: '64px',
    },
    '& .MuiCard-root': {
      background: '#fefeffc7',
      paddingLeft: '10%',
      paddingRight: '10%',

      '@media only screen and (max-width: 600px)': {
        background: '#fefeffc7',
        paddingLeft: '0%',
        paddingRight: '3%',
      },
    },
    '@media only screen and (max-width: 600px)': {
      width: 'auto',
      maxWidth: '85vw',
    },

    '&:focus': {
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.white,
      },
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.deepBlue,
      borderWidth: '2px',
    },
    '& .MuiFormHelperText-root': {
      fontFamily: 'Reach-Text-Regular, sans-serif',
    },
  },
}))(Card);

/** Section Component */
const Section = ({ className, children }) => (
  <section className={className}>{children}</section>
);

/** Section Wrapper styles */
export const SectionWrapper = styled(Section)`
  margin: 0 auto;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 0rem;
  position: relative;
  z-index: 0 !important;
`;
