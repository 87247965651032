/* eslint-disable linebreak-style */
/* eslint-disable no-console */
import moment from 'moment';

/** Date of recurring Payment Validation function */
export const getDayOfMonthValidationError = (startDate, endDate, dayOfMonth) => {
  let validationError = '';
  const dateCheck = (from, to, check) => {
    const fDate = Date.parse(from);
    const lDate = Date.parse(to);
    const cDate = Date.parse(check);
    if (cDate <= lDate && cDate >= fDate) {
      return true;
    }
    return false;
  };
  const start = moment(startDate);
  const startMonth = moment(startDate).format('MM/DD/YYYY');
  const end = moment(endDate);
  const endMonth = moment(endDate).format('MM/DD/YYYY');
  const daysCount = end.diff(start, 'days');
  const day = dayOfMonth;
  const currentStartMonth = moment(start).get('month') + 1;
  const currentStartYear = moment(start).get('year');
  const selectedStartDate = `${currentStartMonth}/${day}/${currentStartYear}`;
  const currentEndMonth = moment(end).get('month') + 1;
  const currentEndYear = moment(end).get('year');
  const selectedEndDate = `${currentEndMonth}/${day}/${currentEndYear}`;
  const isBetween =
    dateCheck(startMonth, endMonth, selectedStartDate) ||
    dateCheck(startMonth, endMonth, selectedEndDate);
  if (daysCount < 30 && !isBetween && day) {
    validationError = 'Day of month should be between the selected dates';
  } else {
    validationError = '';
  }
  return validationError;
};

/** Has date validation error */
export const hasDateSelectionError = (startDate, endDate) =>
  startDate && endDate && endDate.valueOf() <= startDate.valueOf();

/** Day of Week DateRange validation */
export const getDayOfWeekDateValidation = (startDate, endDate) => {
  // ignoring time to avoid fraction diff
  const start = moment(startDate).startOf('day');
  const end = moment(endDate).startOf('day');
  const daysCount = end.diff(start, 'days');
  if (daysCount < 7) {
    return 'Start date and End date range should be minimum of 7 days.';
  }
  return '';
};
