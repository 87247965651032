/* eslint-disable linebreak-style */
import { createUseStyles } from 'react-jss';
import { device } from '../../shared/components/ResponsiveDevice';
import theme from '../../theme.module.scss';

const useClasses = createUseStyles({
  global: {
    '@global': {
      '.faqDiv div': {
        outline: 'none',
        fontWeight: '600',

        borderBottom: `1px solid ${theme.wolfGrey}`,
        '&:last-child': {
          borderBottom: 'none',
        },
      },
    },
  },
  textDecoration: {
    textDecoration: 'none',
  },
  upperPad: {
    paddingTop: '1.5rem',
  },
  upperPadSmall: {
    paddingTop: '.5rem',
  },
  upperPadLarge: {
    paddingTop: '2.5rem',
  },
  deepBlue: {
    color: theme.deepBlue,
  },
  medBlue: {
    color: theme.medBlue,
  },
  lightBlue: {
    color: theme.lightBlue,
  },
  marleft1: {
    marginLeft: '1rem',
  },
  maxwidthMobileDoubleButton: {
    maxWidth: '300px',
    width: '300px',
    marginLeft: 'auto',
    marginRight: 'auto',

    [`@media only screen and ${device.smartphone}`]: {
      maxWidth: '300px',
      width: '300px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  maxwidthMobileSingleButton: {
    maxWidth: '200px',
    width: '200px',
    marginLeft: 'auto',
    marginRight: 'auto',

    [`@media only screen and ${device.smartphone}`]: {
      maxWidth: '200px',
      width: '200px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '450px',
    marginLeft: 'auto',
    marginRight: 'auto',

    [`@media only screen and ${device.smartphone}`]: {
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: '300px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },

  flexUser: {
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',

    [`@media only screen and ${device.smartphone}`]: {
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: '300px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  itemRow: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  rowFlex: {
    display: 'flex',
    justifyContent: 'space-between',
    [`@media only screen and ${device.mobile}`]: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexColumnPad: {
    paddingTop: '.5rem',
  },
  bottomBorder: {
    borderBottom: `1px solid ${theme.deepBlue}`,
  },
  bottomBorderGrey: {
    borderBottom: `1px solid ${theme.wolfGrey}`,
  },
  paddingBottom1: {
    paddingBottom: '1rem',
  },
  rightBarQuickLink: {
    color: theme.medBlue,
    display: 'flex',

    '&:hover': {
      color: theme.lightBlue,
      cursor: 'pointer',
    },
  },
  padRightHalf: {
    paddingRight: '.5rem',
  },
  faqRow: {
    paddingTop: '1rem',
    paddingBottom: '1rem',

    '&:hover': {
      cursor: 'pointer',
    },
  },
  faqQuestion: {
    maxWidth: '350px',

    [`@media only screen and ${device.smartphone}`]: {
      maxWidth: '250px',
    },

    '&:hover': {
      color: theme.lightBlue,
      cursor: 'pointer',
    },
  },
  assistance: {
    paddingTop: '20px',
    fontWeight: '600',
  },

  faqAnswer: {
    padding: '1.5rem',
    paddingLeft: '3rem',
    paddingRight: '3rem',
    color: theme.medBlue,
    display: 'block',
  },

  loginRegisterButton: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: '3rem',
    width: '150px',
  },

  newUser: {
    padding: '0rem',
    paddingLeft: '1rem',
    paddingRight: '0rem',
    display: 'block',
  },
  arrowIcon: {
    '&:hover': {
      color: theme.lightBlue,
      cursor: 'pointer',
    },
  },
  sendEmail: {
    position: 'relative',
    top: '-7px',
  },
  page: {
    background: `${theme.white}`,
  },
  module: {
    margin: '0 auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: '1rem',
    paddingBottom: '2.5rem',
    background: `${theme.ashGrey}`,
    position: 'relative',
    zIndex: '0 !important',
    justifyContent: 'center',
    minHeight: ' calc(100vh - 255px)',
    display: 'flex',

    [`@media only screen and ${device.smartphone}`]: {
      flexDirection: 'column',
      paddingTop: '0rem',
    },
  },

  singleContent: {
    border: '1px solid #d8dde6',
    transition: 'box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1)',
    willChange: 'box-shadow',
    backgroundColor: '#fff',
    borderRadius: '0px',
    outlineColor: 'transparent',
    outlineStyle: 'none',
    minHeight: '55vh',
    padding: '2rem',
    width: '50vw',
    maxWidth: '50vw',
    marginRight: '1rem',

    [`@media only screen and ${device.mobile}`]: {
      width: 'auto',
      maxWidth: '85vw',
      margin: '0.75rem',
      marginLeft: '1rem',
      marginRight: '1rem',
      marginTop: '2rem',
      marginBottom: '2rem',
    },
  },

  moduleContent: {
    color: `${theme.deepBlue}`,
    fontSize: '0.95rem',

    [`@media only screen and ${device.mobile}`]: {
      width: 'auto',
      maxWidth: '65vw',
      marginBottom: '1rem',
      margin: ' 0.75rem',
    },

    [`@media only screen and ${device.smartphone}`]: {
      width: 'auto',
      maxWidth: '85vw',
      marginBottom: '1rem',
      margin: '0.75rem',
    },
  },

  header: {
    fontSize: '1rem',
    fontWeight: '600',
    paddingBottom: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.deepBlue}`,
    color: `${theme.deepBlue}`,
  },

  row: {
    color: `${theme.deepBlue}`,
  },

  paddingDiv0: {
    fontFamily: 'inherit',
    fontSize: '0.95rem',
    padding: '0rem',
    paddingTop: '1.5rem',
    paddingBottom: '1rem',
    color: `${theme.deepBlue}`,
    maxWidth: '30vw',
    marginLeft: 'auto',
    marginRight: 'auto',

    [`@media only screen and ${device.mobile}`]: {
      maxWidth: 'fit-content',
    },
  },

  rightBar: {
    transition: 'box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1)',
    willChange: 'box-shadow',
    backgroundColor: '#fff',
    borderRadius: '0px',
    outlineColor: 'transparent',
    outlineStyle: 'none',
    minHeight: '55vh',
    width: '30vw',
    maxWidth: '250px',

    [`@media only screen and ${device.smartphone}`]: {
      width: 'auto',
      maxWidth: '85vw',
      marginTop: '2rem',
      marginLeft: '1rem',
      marginRight: '1rem',
      marginBottom: '2rem',
      padding: '2rem',
      minHeight: 'auto',
      border: '1px solid #d8dde6',
      '& >div>div': {
        border: 'none',
      },
    },
  },
});

export default useClasses;
