/* eslint-disable linebreak-style */
import axios from 'axios';
import * as types from '../actionTypes/TransactionHistoryActionTypes';
import { TransactionHistoryApiEndPoints } from '../../constants/apiConstants';
import { UseAuthorization } from '../../utils/UseAuthorization';
import { handleUnAuthorizedUser } from './authActionCreators';
import { getErrorMessages } from '../../utils/ErrorHandler';

export const changeTransactionHistoryFieldValue = (data) => ({
  type: types.CHANGE_TRANSACTION_HISTORY_FIELD_VALUE,
  data,
});
export const getTransactionHistorySuccess = (data) => ({
  type: types.TRANSACTION_HISTORY_SUCCESS,
  data,
});

export const loadingTransactionHistory = (data) => ({
  type: types.LOADING_TRANSACTION_HISTORY,
  data,
});
export const failedGetTransactionHistory = (data) => ({
  type: types.FAILED_LOADING_TRANSACTION_HISTORY,
  data,
});
export const resetTransactionHistory = () => ({
  type: types.RESET_TRANSACTION_HISTORY,
});
export const failedCancelTransaction = (data) => ({
  type: types.FAILED_CANCEL_TRANSACTION_HISTORY,
  data,
});

export const getTransactionHistory = () => async (dispatch, getState) => {
  dispatch(loadingTransactionHistory(true));
  const { transactionHistoryDetails } = getState().transactionHistoryReducer;
  const data = {
    start: transactionHistoryDetails.page * transactionHistoryDetails.rowsPerPage,
    length: transactionHistoryDetails.rowsPerPage,
    sortColumn: '',
    sortDirection: '',
    search: {
      value: transactionHistoryDetails.search,
      regex: '',
    },
    months: transactionHistoryDetails.months,
  };
  axios
    .post(
      `${TransactionHistoryApiEndPoints.getTransactionHistory}`,
      data,
      await UseAuthorization()
    )
    .then((response) => {
      dispatch(loadingTransactionHistory(false));
      if (response.data.status === 200) {
        dispatch(getTransactionHistorySuccess(response.data.data));
      } else if (response.status === 400) {
        const errorMsg =
          response.data.error ||
          'Failed to load transaction history. Please contact support.';
        dispatch(failedGetTransactionHistory(errorMsg));
      } else {
        dispatch(
          failedGetTransactionHistory(
            'Failed to load transaction history. Please contact support.'
          )
        );
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.status === 401) {
        handleUnAuthorizedUser(dispatch);
      }
      const errorMsg = getErrorMessages(
        error,
        'Failed to load transaction history. Please contact support.'
      );
      dispatch(failedGetTransactionHistory(errorMsg));
      dispatch(loadingTransactionHistory(false));
      throw error;
    });
};

export const cancelScheduledTransaction = (data) => async (dispatch) => {
  dispatch(loadingTransactionHistory(true));
  axios
    .post(
      `${TransactionHistoryApiEndPoints.cancelScheduledPayment}`,
      data,
      await UseAuthorization()
    )
    .then((response) => {
      dispatch(loadingTransactionHistory(false));
      if (response.data.status === 200) {
        dispatch(getTransactionHistory());
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.status === 401) {
        handleUnAuthorizedUser(dispatch);
      } else if (error && error.response && error.response.status === 400) {
        const errorMsg = getErrorMessages(
          error,
          'Failed to cancel transaction. Please contact support.'
        );
        dispatch(failedCancelTransaction(errorMsg));
      } else {
        dispatch(
          failedCancelTransaction('Failed to cancel transaction. Please contact support.')
        );
      }
      dispatch(loadingTransactionHistory(false));
      throw error;
    });
};
