/* eslint-disable max-len */
/* eslint-disable linebreak-style */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import * as MUI from '../styles/MUI_Components';
import useClasses from '../styles/Classes';
import customStyles from '../styles/CustomStyles';
import * as Actions from '../../../../../redux/actionCreators/PaymentActionCreators';
import * as Selectors from '../../../../../redux/selectors/PaymentSelectors';
import { ComponentLoader } from '../../../../../shared/components/Loader';
import { MultipleInvoiceList } from './MultipleInvoiceList';
import { getTotalPaymentValue } from './payment-helpers';

const PaymentComplete = () => {
  const classes = useClasses();
  const dispatch = useDispatch();
  const [methodOfPayment, setMethodOfPayment] = useState('');

  const {
    paymentDetails,
    paymentMethods,
    paymentSubmissionLoading,
    makePaymentFailed,
    paymentFailureReason,
    isSaveToWallet,
    oneTimePaymentTokenIds,
    oneTimePaymentDetails,
  } = useSelector((state) => ({
    paymentDetails: Selectors.getPaymentDetailsState(state),
    paymentMethods: state.paymentReducer.paymentMethods,
    paymentSubmissionLoading: state.paymentReducer.paymentSubmissionLoading,
    makePaymentFailed: state.paymentReducer.makePaymentFailed,
    paymentFailureReason: state.paymentReducer.paymentFailureReason,
    isSaveToWallet: state.paymentReducer.isSaveToWallet,
    oneTimePaymentTokenIds: state.paymentReducer.oneTimePaymentTokenIds,
    oneTimePaymentDetails: state.paymentReducer.oneTimePaymentDetails,
  }));

  useEffect(() => {
    if (paymentDetails && paymentDetails.paymentTokenId) {
      const hasOneTimePaymentDetails = oneTimePaymentDetails.length;

      const payFromPaymentMethods = hasOneTimePaymentDetails
        ? [...oneTimePaymentDetails, ...paymentMethods]
        : paymentMethods;
      const method = payFromPaymentMethods.find(
        (m) => m.paymentTokenId === parseInt(paymentDetails.paymentTokenId, 10)
      );
      setMethodOfPayment(`${method.brand} ${method.paymentMethodSuffix}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = () => {
    if (paymentDetails) {
      const updateDetails = paymentDetails.paymentDetails.map((data) => ({
        ...data,
        amount: parseFloat(data.amount),
      }));
      const getAmount = getTotalPaymentValue(
        paymentDetails && paymentDetails.paymentDetails
      );
      const data = {
        paymentDetails: updateDetails,
        paymentDate: moment(paymentDetails.paymentDate).format('YYYY-MM-DD HH:mm:ss'),
        paymentTokenId: isSaveToWallet
          ? parseInt(paymentDetails.paymentTokenId, 10)
          : oneTimePaymentTokenIds && parseInt(oneTimePaymentTokenIds, 10),
        paymentDateType: paymentDetails.paymentDateType,
        amount: parseFloat(getAmount.substring(1)),
      };
      Actions.completePayment(dispatch, data);
      makePaymentFailed && dispatch(Actions.makePaymentFailed(false));
    }
  };

  return (
    <>
      {paymentSubmissionLoading ? (
        <ComponentLoader />
      ) : (
        <div>
          <div className={`${classes.paymentReview} ${classes.upperPad}`}>
            <span className={classes.reviewPaymentDesc}>
              Please review your payment details
            </span>
            <div className={`${classes.upperPad} ${classes.multiInvoiceListTable}`}>
              <MultipleInvoiceList
                multipleInvoiceDetails={paymentDetails && paymentDetails.paymentDetails}
              />
            </div>
            <div className={`${classes.upperPad}`}>
              Payment Amount:
              <span className={classes.reviewPaymentItem}>
                {getTotalPaymentValue(paymentDetails && paymentDetails.paymentDetails)}
              </span>
            </div>
            <div className={`${classes.upperPad}`}>
              Payment Date:
              <span className={classes.reviewPaymentItem}>
                {moment(paymentDetails.paymentDate).format('MMM, Do YYYY')}
              </span>
            </div>
            <div className={`${classes.upperPad}`}>
              Payment Method:
              <span className={classes.reviewPaymentItem}>{methodOfPayment}</span>
            </div>
          </div>
          <div className={classes.martop2}>
            {makePaymentFailed && (
              <div className={classes.infoTextFailure}>{paymentFailureReason}</div>
            )}
          </div>

          <div className={classes.upperPad}>
            <div className={classes.buttonDiv} style={customStyles.nextButtonDiv}>
              <MUI.ActionButton
                onClick={() => {
                  dispatch(Actions.makePaymentStep(3));
                  makePaymentFailed && dispatch(Actions.makePaymentFailed(false));
                }}
              >
                Back
              </MUI.ActionButton>
              <MUI.ActionButtonInvertedDouble
                data-testid="submit-payment"
                onClick={handleSubmit}
              >
                Submit Payment
              </MUI.ActionButtonInvertedDouble>
            </div>
          </div>

          <div className={classes.paymentReviewDisclaimer}>
            Your payment would be proccesed on{' '}
            {moment(paymentDetails.paymentDate).format('MMM, Do YYYY')}, it may take up to
            24 hours to post to your account
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentComplete;
