/* eslint-disable linebreak-style */
import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import * as MUI from '../styles/MUI_Components';
import useClasses from '../styles/Classes';
import RefundPayment from '../creditAdjustments/RefundPayment';

/** Multiple Invoice List */
// TODO: Fix invoice table on small screen size.
export const MultipleInvoiceList = (props) => {
  const classes = useClasses();
  const [isRowLevelRefund, setRowLevelRefund] = useState(false);
  const [showRowLevelRefund, toggleRowLevelRefund] = useState(false);
  const [transactionData, setTransactionData] = useState({});
  const tableClasses = `${
    props.isTransactionHistory ? `${classes.multiInvoiceTable}` : ''
  }`;
  // const handleRowLevelRefund = (row) => {
  //   setTransactionData({ ...row, id: props.transactionId });
  //   setRowLevelRefund(true);
  //   toggleRowLevelRefund(true);
  // };

  const closeRefundPaymentModal = () => {
    setRowLevelRefund(false);
    setTransactionData({});
    toggleRowLevelRefund(false);
  };
  return (
    <>
      {!props.isTransactionHistory && (
        <div
          data-testid="payment-method-title"
          className={`${classes.upperPadSmall} ${classes.leftText}`}
        >
          Payment Items:
        </div>
      )}
      {props.multipleInvoiceDetails && props.multipleInvoiceDetails.length ? (
        <div className={classes.transHistInvoiceContainer}>
          <TableContainer>
            <Table className={tableClasses}>
              <TableHead
                className={`${classes.multiInvoiceTableHead} ${classes.tableHead}`}
              >
                <TableRow>
                  <TableCell
                    align="center"
                    className={`${classes.multipleInvoiceListHeadRow} ${classes.tableRow}`}
                  >
                    <span className={classes.multipleInvoiceListItem}>Items</span>
                  </TableCell>
                  <TableCell
                    align="center"
                    className={`${classes.multipleInvoiceListHeadRow} ${classes.tableRow}`}
                  >
                    <span className={classes.multipleInvoiceListItem}>Amount</span>
                  </TableCell>
                  {props.isTransactionHistory && (
                    <TableCell
                      align="center"
                      className={`${classes.multipleInvoiceListHeadRow} ${classes.borderLess}`}
                    >
                      <span className={classes.multipleInvoiceListItem}>Status</span>
                    </TableCell>
                  )}
                  {/* {props.isTransactionHistory && (
                    <TableCell
                      align="center"
                      className={`${classes.multipleInvoiceListHeadRow} ${classes.borderLess}`}
                    >
                      <span className={classes.multipleInvoiceListItem}>Actions</span>
                    </TableCell>
                  )} */}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.multipleInvoiceDetails.map((row) => (
                  <TableRow
                    key={row.orderId}
                    className={`${classes.multiInvoiceTableRow}`}
                  >
                    <TableCell
                      align="center"
                      className={`${classes.multipleInvoiceListHeadRow} ${classes.borderLess} ${classes.tableRow}`}
                    >
                      <span className={classes.multipleInvoiceListItemCell}>
                        {`${
                          row.orderTypeId === 1 || row.orderType === 'Order'
                            ? 'Order'
                            : 'Invoice'
                        } ${row.orderId}`}
                      </span>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={`${classes.multipleInvoiceListHeadRow} ${classes.borderLess}`}
                    >
                      <span className={classes.multipleInvoiceListItemCell}>
                        {`$  ${row.amount}`}
                      </span>
                    </TableCell>
                    {props.isTransactionHistory && (
                      <TableCell
                        align="center"
                        className={`${classes.multipleInvoiceListHeadRow} ${classes.borderLess}`}
                      >
                        <span className={classes.multipleInvoiceListItemCell}>
                          {row.status}
                        </span>
                      </TableCell>
                    )}
                    {/* {props.isTransactionHistory && (
                      <TableCell
                        align="center"
                        className={`${classes.multipleInvoiceListHeadRow} ${classes.borderLess}`}
                      >
                        <span className={classes.multipleInvoiceListItemCell}>
                          <MUI.ActionButton
                            data-testid="refund-button"
                            disabled={row.status !== 'Processed'}
                            className={classes.invoiceListBtns}
                            onClick={() => handleRowLevelRefund(row)}
                          >
                            Refund
                          </MUI.ActionButton>
                        </span>
                      </TableCell>
                    )} */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <></>
      )}
      {showRowLevelRefund && (
        <RefundPayment
          launchRefundPaymentModal={showRowLevelRefund}
          cancel={closeRefundPaymentModal}
          transactionData={transactionData}
          agencyName={props.agencyName}
          isRowLevelRefund={isRowLevelRefund}
        />
      )}
    </>
  );
};
