import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Container } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0 auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    position: 'relative',
    zIndex: '0 !important',
    // TODO: Make this setting dynamic, somehow.
    minHeight: 'calc(100vh - 198px)',
  },
}));

const PageContainer = ({ children, item, container, ...other }) => {
  const classes = useStyles();

  return (
    <Container maxWidth="lg" className={classes.root} {...other}>
      {children}
    </Container>
  );
};

export default PageContainer;
