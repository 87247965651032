/* eslint-disable linebreak-style */
import * as types from '../actionTypes/authActionTypes';

/** Initial State of Auth Reducer : Login, Logout, Signup */
const authInitialState = {
  isLoginFailed: false,
  openIdConfigData: {},
  isOpenIdConfigDataLoading: false,
  isAccessTokenLoading: false,
  loginFormData: {
    username: '',
    password: '',
  },
  isForgotPasswrdError: {},
  createUserErrors: [],
  isCreateUserPending: false,
  isLoginUserPending: false,
  changePasswordDetails: {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  },
  isChangePasswordFailed: {},
  isChangePasswordSuccess: false,
  isForgotPasswordPending: false,
  isForgotUsernamePending: false,
  isResetPasswordPending: false,
  isChangePasswordLoading: false,
  isEmailConfirmationPending: false,
  isEmailConfirmationTokenInvalid: false,
  isResendEmailConfirmationPending: false,
  isResendEmailConfirmationFailed: false,
  isUnAuthorizedUser: false,
  isLoadingSignIn: false,
  isAccountKeyValid: null,
  isInsertUserPending: false,
  isUserInsertFailed: false,
  linkAccountResponse: '',
};

/** Auth Reducer */
const authReducer = (state = authInitialState, action) => {
  switch (action.type) {
    case types.LINK_ACCOUNT_RESPONSE:
      return {
        ...state,
        linkAccountResponse: action.data,
      };
    case types.SIGN_UP:
      return {
        ...state,
        linkAccountResponse: '',
      };
    case types.INSERT_USER_FAILURE:
      return {
        ...state,
        isUserInsertFailed: true,
      };
    case types.INSERT_USER_LOADING:
      return {
        ...state,
        isInsertUserPending: action.data,
        isUserInsertFailed: false,
      };
    case types.INSERT_USER_SUCCESS:
      return {
        ...state,
        isUserInsertFailed: false,
      };
    case types.LOADING_SIGN_IN:
      return {
        ...state,
        isLoadingSignIn: action.data,
      };
    case types.FETCH_LOGIN_REQUEST:
      return {
        ...state,
        isLoginUserPending: true,
        isLoginFailed: false,
      };
    case types.FETCH_LOGIN_SUCCESS:
      return {
        ...state,
        isLoginFailed: false,
        loginFormData: authInitialState.loginFormData,
        isLoginUserPending: false,
        isUserInsertFailed: false,
      };
    case types.FETCH_LOGIN_FAILURE:
      return {
        ...state,
        isLoginFailed: true,
        loginFormData: {
          ...state.loginFormData,
          password: '',
        },
        isLoginUserPending: false,
      };
    case types.FETCH_ACCESS_TOKEN_REQUEST:
      return {
        ...state,
        isAccessTokenLoading: true,
      };
    case types.FETCH_ACCESS_TOKEN_SUCCESS:
      return {
        ...state,
        isAccessTokenLoading: false,
      };
    case types.FETCH_ACCESS_TOKEN_FAILURE:
      return {
        ...state,
        isAccessTokenLoading: false,
      };
    case types.FETCH_OPEN_ID_CONFIGURATION_DATA_REQUEST:
      return {
        ...state,
        isOpenIdConfigDataLoading: true,
      };
    case types.FETCH_OPEN_ID_CONFIGURATION_DATA_SUCCESS:
      return {
        ...state,
        isOpenIdConfigDataLoading: false,
        openIdConfigData: action.data,
      };
    case types.FETCH_OPEN_ID_CONFIGURATION_DATA_FAILURE:
      return {
        ...state,
        isOpenIdConfigDataLoading: false,
      };
    case types.SET_LOGIN_FORM_DATA:
      return {
        ...state,
        loginFormData: {
          ...state.loginFormData,
          [action.data.id]: action.data.value,
        },
      };
    case types.FETCH_FORGOT_USERNAME_REQUEST:
      return {
        ...state,
        isForgotUsernamePending: true,
      };
    case types.FETCH_FORGOT_USERNAME_SUCCESS:
    case types.FETCH_FORGOT_USERNAME_FAILURE:
      return {
        ...state,
        isForgotUsernamePending: false,
      };
    case types.FETCH_FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        isForgotPasswordPending: true,
      };
    case types.FETCH_FORGOT_PASSWORD_SUCCESS:
    case types.FETCH_FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        isForgotPasswordPending: false,
      };
    case types.FETCH_RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isResetPasswordPending: true,
        isForgotPasswrdError: {},
      };
    case types.FETCH_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isResetPasswordPending: false,
      };
    case types.FETCH_RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isForgotPasswrdError: {
          error: action.data.response.data.data,
          status: action.data.response.status,
        },
        isResetPasswordPending: false,
      };
    case types.CREATE_USER_REQUEST:
      return {
        ...state,
        isCreateUserPending: true,
      };
    case types.CREATE_USER_SUCCESS:
      return {
        ...state,
        createUserErrors: [],
        isCreateUserPending: false,
      };
    case types.CREATE_USER_FAILURE:
      const errors = action.data && Object.keys(action.data); // eslint-disable-line no-case-declarations
      return {
        ...state,
        createUserErrors: errors,
        isCreateUserPending: false,
      };
    case types.RESET_CREATE_USER_ERRORS:
      return {
        ...state,
        createUserErrors: [],
        isLoginFailed: false,
        isChangePasswordSuccess: authInitialState.isChangePasswordSuccess,
        isChangePasswordFailed: authInitialState.isChangePasswordFailed,
        isAccountKeyValid: authInitialState.isAccountKeyValid,
      };
    case types.CHANGE_PASSWORD_FIELD_VALUES:
      return {
        ...state,
        changePasswordDetails: {
          ...state.changePasswordDetails,
          [action.data.id]: action.data.value,
        },
      };
    case types.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        isChangePasswordLoading: true,
      };
    case types.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        changePasswordDetails: authInitialState.changePasswordDetails,
        isChangePasswordFailed: {
          error: action.data.response.data.data,
          status: action.data.response.status,
        },
        isChangePasswordSuccess: authInitialState.isChangePasswordSuccess,
        isChangePasswordLoading: false,
      };
    case types.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordDetails: authInitialState.changePasswordDetails,
        isChangePasswordFailed: authInitialState.isChangePasswordFailed,
        isChangePasswordSuccess: true,
        isChangePasswordLoading: false,
      };
    case types.FETCH_RESEND_EMAIL_CONFIRMATION_REQUEST:
      return {
        ...state,
        isResendEmailConfirmationPending: true,
      };
    case types.FETCH_RESEND_EMAIL_CONFIRMATION_STATUS:
      return {
        ...state,
        isResendEmailConfirmationSuccessful: action.data,
        isResendEmailConfirmationPending: false,
      };
    case types.FETCH_VERIFY_EMAIL_REQUEST:
      return {
        ...state,
        isEmailConfirmationTokenInvalid: false,
        isEmailConfirmationPending: true,
      };
    case types.FETCH_VERIFY_EMAIL_STATUS:
      return {
        ...state,
        isEmailConfirmationSuccessful: action.data,
        isEmailConfirmationPending: false,
      };
    case types.HANDLE_UNAUTHORIZED_USER:
      return {
        ...state,
        isUnAuthorizedUser: true,
      };
    default:
      return state;
  }
};

export { authInitialState, authReducer };
