/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import { useDispatch, useSelector } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import * as MUI from '../styles/MUI_Components';
import useClasses from '../styles/Classes';
import * as CheckboxComponents from '../../../../terms-and-conditions/MUI_Components';
import { stateCodes } from '../../../../../shared/constants/StateCodes';
import Tooltip from '../../../../../shared/components/Tooltip';
import { ComponentLoader } from '../../../../../shared/components/Loader';
import {
  AddCreditCardAction,
  changeFieldValue,
  AddACHAction,
  resetFieldValues,
} from '../../../../../redux/actionCreators/PaymentActionCreators';
import {
  loadEncriptionJS,
  loadKeyJS,
  doEncryption,
} from '../../../../../scripts/PaymentScripts';
import {
  isAccountNumberValid,
  isRoutingNumberValid,
} from '../../../../../utils/PaymentUtils';
import { isZipValid } from '../../../../../utils/Validations';
import * as Actions from '../../../../../redux/actionCreators/PaymentActionCreators';

const cardMonths = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
];

const year = new Date().getFullYear();
const cardYears = Array.from(new Array(15), (v, idx) => year + idx);
const accountTypes = ['Consumer Checking', 'Consumer Savings', 'Commercial Checking'];

const AddPaymentMethod = ({
  launchAddPaymentMethod,
  addpaymentmethodCallback: _addpaymentmethodCallback,
  isRecurringPaymentFlow = false,
}) => {
  const [loadedScripts, setLoaded] = useState(false);
  const [creditError, setCreditError] = useState('');
  const [resetErrorMsg, resetErrorMethod] = useState(false);
  const dispatch = useDispatch();

  const {
    paymentAccountDetails,
    isAddACHFailed,
    isAddCardFailed,
    addPaymentMethodStep,
    addingPaymentMethodFailedReason,
    addMethodType,
    isSaveToWallet,
    isAddACHLoading,
    isAddCardLoading,
  } = useSelector((state) => ({
    paymentAccountDetails: state.paymentReducer.paymentAccountDetails,
    isAddACHFailed: state.paymentReducer.isAddACHFailed,
    isAddCardFailed: state.paymentReducer.isAddCardFailed,
    addPaymentMethodStep: state.paymentReducer.addPaymentMethodStep,
    addingPaymentMethodFailedReason: state.paymentReducer.addingPaymentMethodFailedReason,
    addMethodType: state.paymentReducer.addMethodType,
    isSaveToWallet: state.paymentReducer.isSaveToWallet,
    isAddACHLoading: state.paymentReducer.isAddACHLoading,
    isAddCardLoading: state.paymentReducer.isAddCardLoading,
  }));
  useEffect(() => {
    dispatch(Actions.setSaveToWalletStatus(isRecurringPaymentFlow));
  }, []);
  useEffect(() => {
    if (launchAddPaymentMethod) {
      loadKeyJS(() => {
        setLoaded(true);
      });
      loadEncriptionJS();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [launchAddPaymentMethod]);

  const handleTextChange = (event) => {
    const { value, name } = event.target;
    dispatch(changeFieldValue({ value, id: name }));
    resetErrorMethod(false);
    setCreditError('');
  };

  const closePaymentMethodAdd = () => {
    _addpaymentmethodCallback();
    dispatch(resetFieldValues());
    resetErrorMethod(false);
  };

  const handleStateChange = (event) => {
    const { value, name } = event.target;
    dispatch(changeFieldValue({ value, id: name }));
    resetErrorMethod(false);
  };

  const sendAddPaymentMethodCallback = async () => {
    addMethodType === 'account'
      ? await dispatch(AddACHAction())
      : await dispatch(AddCreditCardAction());
    resetErrorMethod(true);
  };

  const handleChange = (event) => {
    dispatch(Actions.setSaveToWalletStatus(event.target.checked));
  };

  const getNextButtonDisableStatus = () => {
    const {
      routingNumber,
      accountNumber,
      confirmAccountNumber,
      confirmRoutingNumber,
      accountType,
      cardNumber,
      cvvNumber,
      expMonth,
      expYear,
    } = paymentAccountDetails;
    let isDisabled = false;
    if (addMethodType === 'account') {
      isDisabled =
        !confirmAccountNumber ||
        !isAccountNumberValid(accountNumber) ||
        !isRoutingNumberValid(routingNumber) ||
        !confirmRoutingNumber ||
        !accountType ||
        confirmAccountNumber !== accountNumber ||
        routingNumber !== confirmRoutingNumber;
    } else if (addMethodType === 'card') {
      isDisabled =
        !cardNumber ||
        !cvvNumber ||
        !expMonth ||
        !expYear ||
        creditError ||
        cvvNumber.length > 4 ||
        cvvNumber.length < 3;
    }
    return isDisabled;
  };

  const getAddButtonDisableStatus = () => {
    const { customerName, customerAddress1, customerCity, customerState, customerZIP } =
      paymentAccountDetails;
    return (
      !customerName ||
      !customerAddress1 ||
      !customerCity ||
      !customerState ||
      !isZipValid(customerZIP)
    );
  };

  const handleCredit = () => {
    const error = doEncryption(
      paymentAccountDetails.cardNumber,
      paymentAccountDetails.cvvNumber,
      () => dispatch(Actions.addPaymentMethodStepNumber(2)),
      (encryptedData) => {
        const encryptCredit = encryptedData[0];
        const encryptCVV = encryptedData[1];
        const encryptIntegrity = encryptedData[2];
        dispatch(
          changeFieldValue({ value: encryptCredit, id: 'encryptedCreditCardNumber' })
        );
        dispatch(
          changeFieldValue({ value: encryptCVV, id: 'encryptedCardSecurityValue' })
        );
        dispatch(
          changeFieldValue({ value: encryptIntegrity, id: 'encryptedIntegrityCheck' })
        );
      }
    );
    setCreditError(error);
  };
  const classes = useClasses();
  const getCheckboxLabel = () => (
    <Tooltip
      toolTipText={
        <>
          When you select the save to my wallet checkbox, we’ll remember your card/account
          details so that when you come back, you can see them under Manage payment
          methods.
        </>
      }
    >
      <span className={`${classes.rememberUsernameTooltip}`}>
        Save to my wallet
        <span className={classes.iconButton}>
          <HelpOutlineIcon style={{ fontSize: '1.25rem' }} className={classes.helpIcon} />
        </span>
      </span>
    </Tooltip>
  );
  return (
    <MUI.AddPaymentMethodModal
      open={launchAddPaymentMethod}
      aria-labelledby="add-paymentMethod-title"
      aria-describedby="add-paymentMethod-description"
    >
      <div className={`${classes.paymentMethodModalBody} ${classes.global}`}>
        <div className={classes.paymentMethodModalTitle}>
          {' '}
          Add
          {addMethodType === 'account' ? ' New Bank Account' : ' New Card'}
        </div>

        {addPaymentMethodStep === 1 && loadedScripts ? (
          <>
            {addMethodType === 'card' && (
              <div className={classes.martop2}>
                <div className={`${classes.upperPad} ${classes.deepBlue}`}>
                  Please enter your card details below and then click next to proceed.{' '}
                </div>

                <div className={classes.martop2}>
                  <MUI.PaymentMethodTextField
                    onChange={(e) => {
                      handleTextChange(e);
                    }}
                    fullWidth
                    inputProps={{
                      style: {
                        textAlign: 'center',
                      },
                      'data-testid': 'add-method-card-number',
                    }}
                    label="Card Number"
                    variant="outlined"
                    name="cardNumber"
                    id="cardNumber"
                    value={paymentAccountDetails.cardNumber}
                    error={!!creditError}
                    helperText={!!creditError && creditError}
                  />
                </div>
                <div className={classes.upperPadSmall}>
                  <span>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel id="expMonth-label">Exp Month</InputLabel>
                      <MUI.PaymentEditSelect
                        labelId="expMonth-label"
                        id="expMonth"
                        name="expMonth"
                        value={paymentAccountDetails.expMonth}
                        className={classes.selectInputLarge}
                        onChange={handleTextChange}
                        label="Exp Month"
                        inputProps={{ 'data-testid': 'exp-month' }}
                      >
                        {cardMonths.map((item) => (
                          <MUI.PaymentMenuItem key={item} value={item}>
                            {item}
                          </MUI.PaymentMenuItem>
                        ))}
                      </MUI.PaymentEditSelect>
                    </FormControl>
                  </span>

                  <span className={classes.marLeftExpYear}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel id="expYear-label">Exp Year</InputLabel>
                      <MUI.PaymentEditSelect
                        labelId="expYear-label"
                        id="expYear"
                        name="expYear"
                        value={paymentAccountDetails.expYear}
                        className={classes.selectInputLarge}
                        onChange={handleTextChange}
                        label="Exp Year"
                        inputProps={{ 'data-testid': 'exp-year' }}
                      >
                        {cardYears.map((item) => (
                          <MUI.PaymentMenuItem key={item} value={item}>
                            {item}
                          </MUI.PaymentMenuItem>
                        ))}
                      </MUI.PaymentEditSelect>
                    </FormControl>
                  </span>
                </div>
                <div className={`${classes.upperPadSmall}`}>
                  <span>
                    <MUI.PaymentMethodTextField
                      onChange={(e) => {
                        handleTextChange(e);
                      }}
                      className={classes.paymentMethodTextFieldSmall}
                      inputProps={{
                        style: {
                          textAlign: 'center',
                        },
                        'data-testid': 'add-method-cvv-number',
                      }}
                      label="CVV"
                      variant="outlined"
                      name="cvvNumber"
                      id="cvvNumber"
                      value={paymentAccountDetails.cvvNumber}
                    />
                  </span>
                </div>
              </div>
            )}

            {addMethodType === 'account' && (
              <>
                <div className={`${classes.upperPad} ${classes.deepBlue}`}>
                  Please enter your account details below and then click next to proceed{' '}
                </div>
                <div className={classes.upperPad}>
                  <MUI.PaymentMethodTextField
                    value={paymentAccountDetails.accountNumber}
                    onChange={(e) => {
                      handleTextChange(e);
                    }}
                    fullWidth
                    inputProps={{
                      style: {
                        textAlign: 'left',
                      },
                      'data-testid': 'add-method-account-number',
                    }}
                    label="Account Number"
                    variant="outlined"
                    name="accountNumber"
                    id="accountNumber"
                    error={
                      paymentAccountDetails.accountNumber &&
                      !isAccountNumberValid(paymentAccountDetails.accountNumber)
                    }
                    helperText={
                      paymentAccountDetails.accountNumber &&
                      !isAccountNumberValid(paymentAccountDetails.accountNumber) &&
                      'Please enter valid Account Number'
                    }
                  />
                </div>
                <div className={classes.upperPadSmall}>
                  <MUI.PaymentMethodTextField
                    value={paymentAccountDetails.confirmAccountNumber}
                    onChange={(e) => {
                      handleTextChange(e);
                    }}
                    fullWidth
                    inputProps={{
                      style: {
                        textAlign: 'left',
                      },
                      'data-testid': 'add-method-c-account-number',
                    }}
                    label="Confirm Account Number"
                    variant="outlined"
                    name="confirmAccountNumber"
                    id="confirmAccountNumber"
                    error={
                      paymentAccountDetails.confirmAccountNumber &&
                      paymentAccountDetails.accountNumber !==
                        paymentAccountDetails.confirmAccountNumber
                    }
                    helperText={
                      paymentAccountDetails.confirmAccountNumber &&
                      paymentAccountDetails.accountNumber !==
                        paymentAccountDetails.confirmAccountNumber &&
                      'Account Number and Confirm Account Number do not match!!'
                    }
                  />
                </div>
                <div className={classes.upperPadSmall}>
                  <MUI.PaymentMethodTextField
                    value={paymentAccountDetails.routingNumber}
                    onChange={(e) => {
                      handleTextChange(e);
                    }}
                    fullWidth
                    inputProps={{
                      style: {
                        textAlign: 'left',
                      },
                      'data-testid': 'add-method-route-number',
                    }}
                    label="Routing Number"
                    variant="outlined"
                    name="routingNumber"
                    id="routingNumber"
                    error={
                      paymentAccountDetails.routingNumber &&
                      !isRoutingNumberValid(paymentAccountDetails.routingNumber)
                    }
                    helperText={
                      paymentAccountDetails.routingNumber &&
                      !isRoutingNumberValid(paymentAccountDetails.routingNumber) &&
                      'Please enter valid Routing Number'
                    }
                  />
                </div>
                <div className={classes.upperPadSmall}>
                  <MUI.PaymentMethodTextField
                    value={paymentAccountDetails.confirmRoutingNumber}
                    onChange={(e) => {
                      handleTextChange(e);
                    }}
                    fullWidth
                    inputProps={{
                      style: {
                        textAlign: 'left',
                      },
                      'data-testid': 'add-method-c-route-number',
                    }}
                    label="Confirm Routing Number"
                    variant="outlined"
                    name="confirmRoutingNumber"
                    id="confirmRoutingNumber"
                    error={
                      paymentAccountDetails.confirmRoutingNumber &&
                      paymentAccountDetails.routingNumber !==
                        paymentAccountDetails.confirmRoutingNumber
                    }
                    helperText={
                      paymentAccountDetails.confirmRoutingNumber &&
                      paymentAccountDetails.routingNumber !==
                        paymentAccountDetails.confirmRoutingNumber &&
                      'Routing Number and Confirm Routing Number do not match!!'
                    }
                  />
                </div>
                <div className={classes.upperPadSmall}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="state-label">Account Type</InputLabel>
                    <MUI.PaymentEditSelect
                      labelId="account-type"
                      id="accountType"
                      name="accountType"
                      value={paymentAccountDetails.accountType}
                      className={classes.paymentMethodTextFieldMed}
                      onChange={handleTextChange}
                      label="Account Type"
                      inputProps={{
                        'data-testid': 'add-method-acc-type',
                      }}
                    >
                      {accountTypes.map((item) => (
                        <MUI.PaymentMenuItem key={item} value={item}>
                          {item}
                        </MUI.PaymentMenuItem>
                      ))}
                    </MUI.PaymentEditSelect>
                  </FormControl>
                </div>
              </>
            )}
            <div className={`${classes.upperPadSmall}`}>
              <CheckboxComponents.FormControlLabelCustom
                control={
                  <CheckboxComponents.AgreeCheckbox
                    checked={isSaveToWallet}
                    onChange={handleChange}
                    name="saveToWallet"
                  />
                }
                label={getCheckboxLabel()}
              />
            </div>
            {!isSaveToWallet && (
              <div className={`${classes.upperPad} ${classes.yellow}`}>
                You can only do immediate payment if you don&apos;t save card/account to
                wallet.
              </div>
            )}
            <div
              className={`${classes.updatePaymentMethodModalButtons} ${classes.martop4} ${classes.buttonDiv}`}
            >
              <MUI.ActionButton
                onClick={() => {
                  closePaymentMethodAdd();
                  dispatch(Actions.setSaveToWalletStatus(true));
                }}
                data-testid="add-payment-cancel"
              >
                Cancel
              </MUI.ActionButton>
              <span className={classes.marleft1}>
                <MUI.ActionButton
                  className={classes.marleft1}
                  data-testid="next-save-card"
                  onClick={() => {
                    addMethodType === 'card' && handleCredit();
                    addMethodType === 'account' &&
                      dispatch(Actions.addPaymentMethodStepNumber(2));
                  }}
                  disabled={getNextButtonDisableStatus()}
                >
                  Next
                </MUI.ActionButton>
              </span>
            </div>
          </>
        ) : addPaymentMethodStep === 1 ? (
          <div className={classes.loaderStyles}>
            <ComponentLoader />
          </div>
        ) : null}

        {addPaymentMethodStep === 2 && (
          <>
            {isAddCardLoading || isAddACHLoading ? (
              <div className={classes.loaderStyles}>
                <ComponentLoader />
              </div>
            ) : (
              <>
                {addMethodType === 'card' && (
                  <div
                    data-testid="add-card-name"
                    className={`${classes.upperPadSmall} ${classes.paymentMethodEditText}`}
                  >
                    <span>
                      Please enter your name as it appears on your credit or debit card
                    </span>
                  </div>
                )}
                <div className={classes.martop2}>
                  <MUI.PaymentMethodTextField
                    value={paymentAccountDetails.customerName}
                    onChange={(e) => {
                      handleTextChange(e);
                    }}
                    fullWidth
                    inputProps={{
                      style: {
                        textAlign: 'left',
                      },
                      maxLength: 30,
                      'data-testid': 'add-method-cust-name',
                    }}
                    label={
                      addMethodType === 'account'
                        ? 'Account Owner Name'
                        : 'Cardholder Name'
                    }
                    variant="outlined"
                    name="customerName"
                    id="customerName"
                  />
                </div>

                <div className={classes.upperPadSmall}>
                  <MUI.PaymentMethodTextField
                    value={paymentAccountDetails.companyName}
                    onChange={(e) => {
                      handleTextChange(e);
                    }}
                    fullWidth
                    inputProps={{
                      style: {
                        textAlign: 'left',
                      },
                      'data-testid': 'add-method-comp-name',
                    }}
                    label="Company Name"
                    variant="outlined"
                    name="companyName"
                    id="companyName"
                  />
                </div>

                <div className={classes.upperPadSmall}>
                  <MUI.PaymentMethodTextField
                    value={paymentAccountDetails.customerAddress1}
                    onChange={(e) => {
                      handleTextChange(e);
                    }}
                    fullWidth
                    inputProps={{
                      style: {
                        textAlign: 'left',
                      },
                      maxLength: 30,
                      'data-testid': 'add-method-cust-addr1',
                    }}
                    label="Address"
                    variant="outlined"
                    name="customerAddress1"
                    id="customerAddress1"
                  />
                </div>

                <div className={classes.upperPadSmall}>
                  <MUI.PaymentMethodTextField
                    value={paymentAccountDetails.customerAddress2}
                    onChange={(e) => {
                      handleTextChange(e);
                    }}
                    fullWidth
                    className={classes.paymentMethodTextField}
                    inputProps={{
                      style: {
                        textAlign: 'left',
                      },
                      maxLength: 30,
                      'data-testid': 'add-method-cust-addr2',
                    }}
                    label="Address 2"
                    variant="outlined"
                    name="customerAddress2"
                    id="customerAddress2"
                  />
                </div>

                <div className={classes.upperPadSmall}>
                  <MUI.PaymentMethodTextField
                    value={paymentAccountDetails.customerCity}
                    onChange={(e) => {
                      handleTextChange(e);
                    }}
                    className={classes.paymentMethodTextField}
                    fullWidth
                    inputProps={{
                      style: {
                        textAlign: 'left',
                      },
                      maxLength: 20,
                      'data-testid': 'add-method-cust-city',
                    }}
                    label="City"
                    variant="outlined"
                    name="customerCity"
                    id="customerCity"
                  />
                </div>

                <div className={classes.upperPadSmall}>
                  <span>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel id="state-label">State</InputLabel>
                      <MUI.PaymentEditSelect
                        labelId="state-label"
                        id="customerState"
                        name="customerState"
                        value={paymentAccountDetails.customerState}
                        className={classes.paymentMethodTextFieldMed}
                        onChange={handleStateChange}
                        label="State"
                        inputProps={{ 'data-testid': 'add-method-state' }}
                      >
                        {stateCodes.map((item) => (
                          <MUI.PaymentMenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MUI.PaymentMenuItem>
                        ))}
                      </MUI.PaymentEditSelect>
                    </FormControl>
                  </span>
                  <span className={classes.marLeftZip}>
                    <MUI.PaymentMethodTextField
                      value={paymentAccountDetails.customerZIP}
                      className={classes.paymentMethodTextFieldZip}
                      onChange={(e) => {
                        handleTextChange(e);
                      }}
                      inputProps={{
                        style: {
                          textAlign: 'left',
                        },
                        'data-testid': 'add-method-zip',
                      }}
                      error={
                        paymentAccountDetails.customerZIP &&
                        !isZipValid(paymentAccountDetails.customerZIP)
                      }
                      helperText={
                        paymentAccountDetails.customerZIP &&
                        !isZipValid(paymentAccountDetails.customerZIP) &&
                        'Please enter valid zipcode'
                      }
                      label="Zip"
                      variant="outlined"
                      name="customerZIP"
                      id="customerZIP"
                    />
                  </span>
                </div>
                <div style={{ marginTop: '20px' }}>
                  {(isAddCardFailed || isAddACHFailed) && resetErrorMsg && (
                    <div className={`${classes.NoTopPad} ${classes.infoTextFailure} `}>
                      {addingPaymentMethodFailedReason}
                    </div>
                  )}
                </div>
                <div
                  className={`${classes.updatePaymentMethodModalButtons} ${classes.martop2} ${classes.buttonDiv}`}
                >
                  <MUI.ActionButton
                    onClick={() => {
                      dispatch(Actions.addPaymentMethodStepNumber(1));
                    }}
                  >
                    Back
                  </MUI.ActionButton>
                  <span className={classes.marleft1}>
                    <MUI.ActionButton
                      data-testid="add-payment-button"
                      className={classes.marleft1}
                      disabled={getAddButtonDisableStatus()}
                      onClick={() => {
                        sendAddPaymentMethodCallback();
                      }}
                    >
                      Add{addMethodType === 'account' ? ' Account' : ' Card'}
                    </MUI.ActionButton>
                  </span>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </MUI.AddPaymentMethodModal>
  );
};

export default AddPaymentMethod;
