/* eslint-disable linebreak-style */
import moment from 'moment';

/** Format Date to MM/DD/YYYY */
export const formatDateMMDDYYYY = (date) => moment(date).format('MM/DD/YYYY');

/** add number of months to date */
export const addMonthsToDate = (date, numberOfMonths = 0) => {
  const dateCopy = new Date(date);
  return dateCopy.setMonth(dateCopy.getMonth() + numberOfMonths);
};
/** add number of days to date */
export const addDaysToDate = (date, numberOfDays = 0) => {
  const dateCopy = new Date(date);
  return dateCopy.setDate(dateCopy.getDate() + numberOfDays);
};
