/* eslint-disable linebreak-style */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import * as Actions from '../../redux/actionCreators/authActionCreators';
import theme from '../../theme.module.scss';
import { isPasswordValid } from '../../utils/Validations';
import { getTokenUrl } from '../../redux/selectors/authSelectors';
import { TextBox } from '../../shared/components/SharedComponentStyles';
import { ComponentLoader } from '../../shared/components/Loader';
import useClasses from './ResetPasswordClasses';

const useStyles = makeStyles(() => ({
  ResetPasswordSubmit: {
    color: 'white',
    marginTop: '1rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    padding: '1.25rem',
    width: '100%',
    backgroundColor: theme.lightBlue,
    '&:hover': {
      background: '#34c0e8',
    },
  },
}));

/** Reset Password Form Component */
const ResetPasswordForm = () => {
  const classes = useClasses();
  const [resetPasswordclasses] = useState(useStyles());
  const dispatch = useDispatch();

  const {
    isForgotPasswrdError,
    loginTokenUrl,
    isResetPasswordPending,
    isLoginUserPending,
    isFetchingUserFailed,
  } = useSelector((state) => ({
    isForgotPasswrdError: state.authReducer.isForgotPasswrdError,
    loginTokenUrl: getTokenUrl(state),
    isResetPasswordPending: state.authReducer.isResetPasswordPending,
    isLoginUserPending: state.authReducer.isLoginUserPending,
    isFetchingUserFailed: state.userReducer.isFetchingUserFailed,
  }));
  const [resetPasswordData, setResetPassword] = useState({
    password: '',
    confirmPassword: '',
  });
  const [errorResponse, resetErrorResponse] = useState(false);

  const handleChangeField = (event) => {
    const { value, id } = event && event.target;
    setResetPassword({ ...resetPasswordData, [id]: value });
    resetErrorResponse(false);
  };

  const { password, confirmPassword } = resetPasswordData;

  const saveNewPassword = async () => {
    const parsed = queryString.parse(window.location.search);
    const data = {
      username: parsed.username,
      token: parsed.token,
      newPassword: password,
    };
    await Actions.ResetPasswordAction(dispatch, data, loginTokenUrl);
    resetErrorResponse(true);
    setResetPassword({ password: '', confirmPassword: '' });
  };

  const getPasswordError = () => (
    <>
      <div className={`${classes.passwordError}`}>
        * Password must be 8 characters or more.
      </div>
      <div className={`${classes.passwordError}`}>
        * Password must contain 3 character sets: Uppercase, Lowercase, Numeric & Special.
      </div>
    </>
  );
  return (
    <>
      {isResetPasswordPending || isLoginUserPending ? (
        <ComponentLoader />
      ) : (
        <>
          {errorResponse &&
          isForgotPasswrdError &&
          isForgotPasswrdError.status === 500 ? (
            isForgotPasswrdError.error &&
            Object.keys(isForgotPasswrdError.error).length > 0 &&
            isForgotPasswrdError.error[23] ? (
              <div className={`${classes.infoTextFailure}`}>
                Passwords must not use any of the last four passwords. Please create a
                different password.
              </div>
            ) : (
              <div className={`${classes.infoTextFailure}`}>
                An error occurred while resetting your password. Please try again later.
              </div>
            )
          ) : (
            <></>
          )}
          {isFetchingUserFailed && errorResponse && (
            <div className={`${classes.infoTextFailure}`}>
              An error occurred while resetting your password. Please try again later.
            </div>
          )}
          <form noValidate autoComplete="off">
            <TextBox
              fieldId="password"
              style={{ margin: '2px 0px' }}
              placeholder="Password"
              type="password"
              fieldError={password && !isPasswordValid(password)}
              fieldErrorMsg={getPasswordError()}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              onChange={handleChangeField}
              value={password}
            />
            <TextBox
              fieldId="confirmPassword"
              style={{ margin: '2px 0px' }}
              type="password"
              placeholder="Confirm Password"
              fieldError={password !== confirmPassword}
              fieldErrorMsg="Passwords do not match."
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              onChange={handleChangeField}
              value={confirmPassword}
            />
          </form>
          <Button
            className={resetPasswordclasses.ResetPasswordSubmit}
            variant="contained"
            color="primary"
            disableElevation
            disabled={
              !password ||
              !confirmPassword ||
              !isPasswordValid(password) ||
              password !== confirmPassword
            }
            onClick={saveNewPassword}
          >
            Save New Password
          </Button>
        </>
      )}
    </>
  );
};

export default ResetPasswordForm;
