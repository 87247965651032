/** Calculate Total payment */

export const getTotalPaymentValue = (invoiceDetails) => {
  const totalPayment = invoiceDetails.reduce(
    (accum, currentValue) => Number(accum) + Number(currentValue.amount),
    ''
  );

  return `$${totalPayment ? totalPayment.toFixed(2) : 0.0}`;
};
