/* eslint-disable max-len */
/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { Login } from '@spectrum-reach/external-theme';
import { isUserAuthenticated } from '../../utils/AuthUtils';
import {
  signUp,
  setLoginFormData,
  login,
} from '../../redux/actionCreators/authActionCreators';
import { resetUserDetails } from '../../redux/actionCreators/UserActionCreators';

/** Login Container */
const UserLogin = () => {
  const dispatch = useDispatch();
  const [rememberMe, setRememberMe] = useState(false);
  useEffect(() => {
    if (isUserAuthenticated()) {
      dispatch(push({ pathname: '/' }));
    }
    setRememberMe(localStorage.getItem('rememberMe'));
    dispatch(setLoginFormData({ id: 'username', value: localStorage.getItem('user') }));
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        document.getElementById('SubmitLogin').click();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createAccount = () => {
    dispatch(signUp());
    dispatch(resetUserDetails());
    dispatch(push({ pathname: '/signup' }));
  };

  const onForgetPasswordClick = () => {
    dispatch(push({ pathname: '/forgot-password' }));
  };
  const onForgetUsernameClick = () => {
    dispatch(push({ pathname: '/forgot-username' }));
  };
  const websiteDetails =
    'Welcome to the new Spectrum Reach payment portal. We have transitioned our payment processing to a new vendor through this new portal. If you have made credit card or ACH payments through our former payment site, you will need to create a new user account as a one-time step. Your credentials from the previous site have not been imported. Please reach out to our team with questions!';

  const handleFieldOnChange = (event) => {
    const fieldInfo = event && event.target;
    const { id } = fieldInfo;
    const { value } = fieldInfo;

    dispatch(setLoginFormData({ id, value }));
  };

  const {
    isLoginFailed,
    formData,
    isFetchingUserFailed,
    isLoadingSignIn,
    isOpenIdConfigDataLoading,
  } = useSelector((state) => ({
    isLoginFailed: state.authReducer.isLoginFailed,
    formData: state.authReducer.loginFormData,
    isFetchingUserFailed: state.userReducer.isFetchingUserFailed,
    isLoadingSignIn: state.authReducer.isLoadingSignIn,
    isOpenIdConfigDataLoading: state.authReducer.isOpenIdConfigDataLoading,
  }));

  const handleSignIn = () => {
    dispatch(login(formData));
    localStorage.setItem('rememberMe', rememberMe);
    localStorage.setItem('user', rememberMe ? formData.username : '');
  };

  const handleRememberMe = (event) => {
    const input = event.target;
    const value = input.type === 'checkbox' ? input.checked : input.value;

    if (!value) {
      localStorage.removeItem('rememberMe', rememberMe);
      localStorage.removeItem('user', '');
    }
    setRememberMe(value);
  };

  return (
    <>
      <Login
        websiteDetails={websiteDetails}
        onClickCreateAccount={createAccount}
        onClickForgetPassword={onForgetPasswordClick}
        onClickForgetUsername={onForgetUsernameClick}
        handleUserName={handleFieldOnChange}
        handlePassword={handleFieldOnChange}
        isFetchingUserFailed={isFetchingUserFailed}
        isLoadingLoginSection={isOpenIdConfigDataLoading}
        userName={formData.username}
        password={formData.password}
        handleSignIn={handleSignIn}
        isLoadingSignIn={isLoadingSignIn}
        handleRememberMe={handleRememberMe}
        isLoginFailed={isLoginFailed}
        rememberMe={rememberMe}
      />
    </>
  );
};

export default UserLogin;
