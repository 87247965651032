/* eslint-disable linebreak-style */
export const REFUND_SUBMITTED = 'REFUND_SUBMITTED';
export const CREDIT_ADJUSTMENTS_SUBMITTED = 'CREDIT_ADJUSTMENTS_SUBMITTED';
export const CLOSE_CONFIRMATION = 'CLOSE_CONFIRMATION';
export const CLOSE_ADJUSTMENTS_CONFIRMATION = 'CLOSE_ADJUSTMENTS_CONFIRMATION';
export const REFUND_REASONS = 'REFUND_REASONS';
export const REFUND_SUBMISSION_FAILED = 'REFUND_SUBMISSION_FAILED';
export const CREDIT_ADJUSTMENT_SUBMITTED_CONFIRMATION =
  'CREDIT_ADJUSTMENT_SUBMITTED_CONFIRMATION';
export const LOADING_ADJUSTMENT_REASONS = 'LOADING_ADJUSTMENT_REASONS';
export const TOGGLE_REQUEST_REFUND_MODAL = 'TOGGLE_REQUEST_REFUND_MODAL';
