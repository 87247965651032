/* eslint-disable linebreak-style */
import * as types from '../actionTypes/UserActionTypes';

/** Initial State of User Information Reducer */
const userInitialState = {
  userInfoDetails: {},
  isFetchingUserFailed: false,
  isTermsandConditionsAccepted: false,
  isTermsandConditionsUpdateFailed: false,
  isFetchingUserLoading: false,
  isTermsAndConditionsLoading: false,
  isUserExist: true,
};

/** User Reducer */
const userReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case types.FETCH_USER_INFO_REQUEST:
      return {
        ...state,
        isFetchingUserLoading: true,
      };
    case types.FETCH_USER_INFO_SUCCESS:
      return {
        ...state,
        userInfoDetails: action.data,
        isFetchingUserLoading: false,
        isFetchingUserFailed: false,
      };
    case types.FETCH_USER_INFO_FAILURE:
      return {
        ...state,
        isFetchingUserFailed: true,
        isFetchingUserLoading: false,
      };
    case types.TERMS_AND_CONDITIONS_PENDING:
      return {
        ...state,
        isTermsAndConditionsLoading: true,
      };
    case types.TERMS_AND_CONDITIONS_ACCEPTED_STATUS:
      return {
        ...state,
        userInfoDetails: action.data,
        isTermsandConditionsUpdateFailed: false,
        isTermsAndConditionsLoading: false,
      };
    case types.TERMS_AND_CONDITIONS_FAILURE:
      return {
        ...state,
        isTermsandConditionsUpdateFailed: action.data,
        isTermsAndConditionsLoading: false,
      };
    case types.USER_DOES_NOT_EXIST:
      return {
        ...state,
        isUserExist: action.data,
      };
    case types.RESET_USER_DETAILS:
      return {
        ...state,
        userInfoDetails: {},
        isFetchingUserFailed: false,
        isTermsandConditionsAccepted: false,
        isTermsandConditionsUpdateFailed: false,
        isFetchingUserLoading: false,
        isTermsAndConditionsLoading: false,
      };
    default:
      return state;
  }
};

export { userInitialState, userReducer };
