/* eslint-disable linebreak-style */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useClasses from '../styles/ManageAccountClasses';
import * as MUI from '../styles/MUIComponents';
import {
  changePasswordFieldValues,
  resetCreateUserErrors,
} from '../../../../../redux/actionCreators/authActionCreators';
import { isPasswordValid } from '../../../../../utils/Validations';

/** Change Password Form */
const ChangePasswordForm = () => {
  const dispatch = useDispatch();
  const { changePasswordDetails } = useSelector((state) => ({
    changePasswordDetails: state.authReducer.changePasswordDetails,
  }));

  const handleChangePassword = (event) => {
    const { value, id } = event && event.target;
    dispatch(changePasswordFieldValues({ id, value }));
    dispatch(resetCreateUserErrors());
  };
  const { oldPassword, newPassword, confirmNewPassword } = changePasswordDetails;
  const classes = useClasses();
  return (
    <>
      <div
        className={`${classes.rowFlex} ${classes.bottomBorderGrey} ${classes.paddingBottom1}`}
      >
        <span className={`${classes.lineHeight3}`}>Old Password</span>
        <span>
          <MUI.TextFieldCustom
            type="password"
            inputProps={{
              style: {
                padding: '.85rem',
                textAlign: 'center',
              },
            }}
            variant="outlined"
            name="old-password"
            onChange={handleChangePassword}
            value={oldPassword}
            id="oldPassword"
            error={oldPassword && !isPasswordValid(oldPassword)}
            helperText={
              !isPasswordValid(oldPassword) && oldPassword
                ? 'Password requirements are not met.'
                : ''
            }
          />
        </span>
      </div>
      <div
        className={`${classes.rowFlex} ${classes.bottomBorderGrey} ${classes.paddingBottom1} ${classes.upperPad}`}
      >
        <span className={`${classes.lineHeight3}`}>New Password</span>
        <span>
          <MUI.TextFieldCustom
            type="password"
            inputProps={{
              style: {
                padding: '.85rem',
                textAlign: 'center',
              },
            }}
            variant="outlined"
            name="new-password"
            onChange={handleChangePassword}
            value={newPassword}
            id="newPassword"
            error={newPassword && !isPasswordValid(newPassword)}
            helperText={
              !isPasswordValid(newPassword) && newPassword
                ? 'Password requirements are not met.'
                : ''
            }
          />
        </span>
      </div>
      <div className={`${classes.rowFlex} ${classes.paddingBottom1} ${classes.upperPad}`}>
        <span className={`${classes.lineHeight3}`}>Confirm New Password</span>
        <span>
          <MUI.TextFieldCustom
            type="password"
            inputProps={{
              style: {
                padding: '.85rem',
                textAlign: 'center',
              },
            }}
            variant="outlined"
            name="confirm-new-password"
            onChange={handleChangePassword}
            value={confirmNewPassword}
            id="confirmNewPassword"
            error={newPassword !== confirmNewPassword}
            helperText={
              newPassword !== confirmNewPassword ? 'Passwords do not match.' : ''
            }
          />
        </span>
      </div>
    </>
  );
};

export default ChangePasswordForm;
