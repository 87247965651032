/* eslint-disable linebreak-style */
import { createUseStyles } from 'react-jss';
import { device } from '../../shared/components/ResponsiveDevice';
import theme from '../../theme.module.scss';

const useClasses = createUseStyles({
  padding2: {
    padding: '2rem',
  },
  checkboxDiv: {
    paddingLeft: '2rem',
    minWidth: '300px',
    textAlign: 'center',
  },
  label: {
    fontSize: '.85rem',
  },
  continueDiv: {
    padding: '2rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '350px',
    textAlign: 'center',
  },

  module: {
    zIndex: '0',
    position: 'relative',
  },

  container: {
    background: `${theme.ashGrey}`,
    padding: '2rem',

    [`@media only screen and ${device.smartphone}`]: {
      maxHeight: '700px !important',
      height: '700px',
    },

    [`@media only screen and ${device.tablet}`]: {
      maxHeight: '770px',
      height: '770px',
    },

    [`@media only screen and ${device.mobile}`]: {
      maxHeight: '770px',
      height: '770px',
    },

    [`@media only screen and ${device.laptop}`]: {
      height: '95vh',
    },

    [`@media only screen and ${device.desktop}`]: {
      height: '95vh',
    },
  },

  linkText: {
    color: `${theme.medBlue}`,
    fontFamily: 'Reach-Title-Bold, sans-serif',
    letterSpacing: '-0.08rem',
    textAlign: 'center',
    paddingTop: '1rem',
    fontSize: '1rem',

    '&:hover': {
      cursor: 'pointer',
      color: `${theme.deepBlue}`,
    },
  },

  headerText: {
    color: ` ${theme.deepBlue}`,
    fontFamily: 'Reach-Title-Bold, sans-serif',
    fontSize: '1.5rem',
    lineHeight: '2rem',
    letterSpacing: '-0.1rem',
    textAlign: 'center',
    paddingTop: '1rem',
  },

  scrollbox: {
    padding: '2rem',
    paddingTop: '0rem',
    background: `${theme.white}`,
    margin: '3rem',
    marginTop: '0rem',
    marginBottom: '1rem',
    maxHeight: '400px',
    overflowY: 'scroll',

    [`@media only screen and ${device.smartphone}`]: {},

    [`@media only screen and ${device.tablet}`]: {},

    [`@media only screen and ${device.mobile}`]: {},

    [`@media only screen and ${device.laptop}`]: {},

    [`@media only screen and ${device.desktop}`]: {},
  },

  infoText: {
    fontFamily: 'inherit',
    letterSpacing: '-0.03rem',
    textAlign: 'center',
    paddingTop: '1rem',
    paddingLeft: '2rem',
    paddingRight: '1.5rem',
    lineHeight: '1rem',
    color: `${theme.red}`,
    [`@media only screen and ${device.mobile}`]: {
      paddingLeft: '0rem',
      paddingRight: '0rem',
    },
  },
});

export default useClasses;
