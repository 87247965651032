/* eslint-disable linebreak-style */
import {
  Button,
  TextField,
  Select,
  Table,
  FormControl,
  TableContainer,
  Chip,
  Dialog,
  TableRow,
  MenuItem,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';
import { device } from '../../../../../shared/components/ResponsiveDevice';
import theme from '../../../../../theme.module.scss';

const actionButton = withStyles(() => ({
  root: {
    color: theme.medBlue,
    border: `1px solid ${theme.medBlue}`,
    padding: '.5rem',
    minWidth: '9rem',
    textTransform: 'capitalize',
    borderRadius: '0px',
    '&:hover': {
      color: theme.white,
      backgroundColor: theme.deepBlue,
    },
    [`@media only screen and ${device.mobile}`]: {
      marginRight: '0.5rem',
      marginBottom: '0.4rem',
      padding: '0.4rem',
      minWidth: '8rem',
    },
  },
  disabled: {
    backgroundColor: '#e4e4e4',
    border: '1px solid #bdbdbd',
  },
}))(Button);

const actionButtonEmailConfirm = withStyles(() => ({
  root: {
    color: theme.medBlue,
    border: `1px solid ${theme.medBlue}`,
    padding: '.5rem',
    minWidth: '200px',
    textTransform: 'capitalize',
    borderRadius: '0px',
    '&:hover': {
      color: theme.white,
      backgroundColor: theme.deepBlue,
    },
  },
  disabled: {
    backgroundColor: '#e4e4e4',
    border: '1px solid #bdbdbd',
  },
}))(Button);

const editPaymentMethodModal = withStyles(() => ({
  root: {
    '& .MuiDialog-paperScrollPaper': {
      [`@media only screen and ${device.laptop}`]: {
        minWidth: '500px',
        minHeight: '300px',
        border: `2px solid ${theme.lightBlue}`,
      },
      [`@media only screen and ${device.smartphone}`]: {
        minWidth: '340px',
        border: `2px solid ${theme.lightBlue}`,
      },
    },

    '& .MuiDialog-paper': {
      margin: 0,
    },
  },
}))(Dialog);

const deletePaymentMethodModal = withStyles(() => ({
  root: {
    '& .MuiDialog-paperScrollPaper': {
      [`@media only screen and ${device.laptop}`]: {
        minHeight: '200px',
        minWidth: '500px',
        border: `2px solid ${theme.lightBlue}`,
      },
      [`@media only screen and ${device.smartphone}`]: {
        minHeight: '200px',
        minWidth: '340px',
        border: `2px solid ${theme.lightBlue}`,
      },
    },

    '& .MuiDialog-paper': {
      margin: 0,
    },
  },
}))(Dialog);

const refundRequestMethodModal = withStyles(() => ({
  root: {
    '& .MuiDialog-paperScrollPaper': {
      [`@media only screen and ${device.laptop}`]: {
        minHeight: '250px',
        minWidth: '500px',
        border: `2px solid ${theme.lightBlue}`,
      },
      [`@media only screen and ${device.smartphone}`]: {
        minHeight: '500px',
        minWidth: '340px',
        border: `2px solid ${theme.lightBlue}`,
      },
    },

    '& .MuiDialog-paper': {
      margin: 0,
    },
  },
}))(Dialog);

const paymentFailedModal = withStyles(() => ({
  root: {
    '& .MuiDialog-paperScrollPaper': {
      [`@media only screen and ${device.laptop}`]: {
        minHeight: '250px',
        minWidth: '500px',
        border: `2px solid ${theme.lightBlue}`,
      },
      [`@media only screen and ${device.smartphone}`]: {
        minHeight: '500px',
        minWidth: '340px',
        border: `2px solid ${theme.lightBlue}`,
      },
    },

    '& .MuiDialog-paper': {
      margin: 0,
    },
  },
}))(Dialog);

const actionButtonPayNow = withStyles(() => ({
  root: {
    color: theme.medBlue,
    border: `1px solid ${theme.medBlue}`,
    padding: '.5rem',
    minWidth: '9rem',
    textTransform: 'capitalize',
    borderRadius: '0px',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '175px',

    '&:hover': {
      color: theme.white,
      backgroundColor: theme.deepBlue,
    },
  },
}))(Button);

const actionButtonInverted = withStyles(() => ({
  root: {
    backgroundColor: theme.medBlue,
    color: theme.white,
    border: `1px solid ${theme.medBlue}`,
    padding: '.5rem',
    minWidth: '9rem',
    textTransform: 'capitalize',
    borderRadius: '0px',
    '&:hover': {
      color: theme.white,
      backgroundColor: theme.deepBlue,
    },
    [`@media only screen and ${device.mobile}`]: {
      marginRight: '0.5rem',
      marginBottom: '0.4rem',
      padding: '0.4rem',
      minWidth: '8rem',
    },
  },
  disabled: {
    backgroundColor: '#e4e4e4',
    border: '1px solid #bdbdbd',
  },
}))(Button);

const actionButtonInvertedDouble = withStyles(() => ({
  root: {
    backgroundColor: theme.medBlue,
    color: theme.white,
    border: `1px solid ${theme.medBlue}`,
    padding: '.5rem',
    minWidth: '9rem',
    marginLeft: '.5rem',
    textTransform: 'capitalize',
    borderRadius: '0px',
    '&:hover': {
      color: theme.white,
      backgroundColor: theme.deepBlue,
    },
  },
  disabled: {
    backgroundColor: '#e4e4e4',
    border: '1px solid #bdbdbd',
  },
}))(Button);

const actionButtonDangerInverted = withStyles(() => ({
  root: {
    backgroundColor: theme.red,
    color: theme.white,
    border: `1px solid ${theme.red}`,
    padding: '.5rem',
    minWidth: '9rem',
    textTransform: 'capitalize',
    borderRadius: '0px',
    '&:hover': {
      color: theme.white,
      backgroundColor: theme.darkRed,
    },
  },
}))(Button);

const tableCustom = withStyles(() => ({
  root: {
    overflowX: 'hidden',
    [`@media only screen and ${device.smartphone}`]: {
      overflowX: 'auto !important',
    },
  },
}))(Table);

const tableContainerCustom = withStyles(() => ({
  root: {
    overflowX: 'hidden',
    border: '1px solid #b7b5b5',

    [`@media only screen and ${device.smartphone}`]: {
      overflowX: 'auto !important',
    },

    '& .MuiTablePagination-toolbar': {
      minHeight: '52px',
      paddingRight: '25px',
    },
  },
}))(TableContainer);

const datePicker = withStyles(() => ({
  root: {
    '& .MuiInputBase-input': {
      color: theme.deepBlue,
    },
    '& .MuiFormLabel-root': {
      maxWidth: '250px',
      minWidth: '250px',
    },
    '& .MuiPickersDay-daySelected': {
      backgroundColor: theme.deepBlue,
    },

    '@media only screen and (max-width: 600px)': {
      width: 'auto',
      maxWidth: '85vw',
    },
  },
}))(KeyboardDatePicker);

const paymentdatePicker = withStyles(() => ({
  root: {
    '& .MuiFormControl-root': {
      width: '290px',
    },
    '& .MuiInputBase-input': {
      color: theme.deepBlue,
      borderRadius: '0px',
    },
    '& .MuiFormLabel-root': {
      maxWidth: '270px',
      minWidth: '270px',
    },
    '& .MuiPickersDay-daySelected': {
      backgroundColor: theme.deepBlue,
    },

    '@media only screen and (max-width: 600px)': {
      width: 'auto',
      maxWidth: '85vw',
    },
  },
}))(KeyboardDatePicker);

const paymentMenuItem = withStyles(() => ({
  root: {
    color: theme.deepBlue,

    '& .MuiInputBase-root': {
      minWidth: '150px',
    },
    '& .MuiFormControl-root': {
      minWidth: '150px',
    },
  },
}))(MenuItem);

const selectItemCustom = withStyles(() => ({
  root: {
    color: theme.deepBlue,

    '& .MuiInputBase-root': {
      minWidth: '150px',
    },
    '& .MuiFormControl-root': {
      minWidth: '150px',
    },
  },
}))(MenuItem);

const paymentEditSelect = withStyles(() => ({
  root: {
    '& .MuiInputBase-input': {
      color: theme.deepBlue,
    },
    '& .MuiInputBase-root': {
      color: theme.deepBlue,
    },
  },
}))(Select);

const transHistSelect = withStyles(() => ({
  root: {
    height: '1rem',

    [`@media only screen and ${device.smartphone}`]: {
      height: '1.75rem',
    },
  },
}))(Select);

const refundselectCustom = withStyles(() => ({
  root: {
    padding: '1rem',
  },
  selectMenu: {
    width: '277px',
    maxWidth: '277px',
    minWidth: '277px',
    color: theme.deepBlue,
    textAlign: 'left',
    fontWeight: 600,
    fontFamily: 'Reach-Text-Regular, sans-serif',

    [`@media only screen and ${device.mobile}`]: {
      width: '268px',
      maxWidth: '268px',
      minWidth: '268px',
      color: theme.deepBlue,
      textAlign: 'center',
      fontWeight: 600,
      fontFamily: 'Reach-Text-Regular, sans-serif',
    },
  },
}))(Select);

const styledTableRow = withStyles(() => ({
  root: {
    fontFamily: 'Reach-Text-Regular, sans-serif',

    '& .MuiTableCell-root': {
      borderBottom: `1px solid ${theme.iceGrey}`,
    },
    '& .MuiTableRow-root:last-child': {
      '& .MuiTableCell-root': {
        borderBottom: 'none',
      },
    },
    '&:nth-of-type(odd)': {
      backgroundColor: theme.ashGrey,
    },
  },
}))(TableRow);

const paymentMethodTextField = withStyles({
  root: {
    maxWidth: '325px',
    marginLeft: '0rem',

    '& .MuiInputBase-root': {
      fontFamily: 'Reach-Text-Regular, sans-serif',
      fontSize: '1.0rem',
      color: theme.deepBlue,
      fontWeight: '600',
    },

    '& label.Mui-focused': {
      color: theme.lightBlue,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#0000003b',
        borderRadius: '3px',
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.lightBlue,
      },
    },
  },
})(TextField);

const tableSearch = withStyles({
  root: {
    maxWidth: '325px',
    marginLeft: '0rem',

    '& .MuiInputBase-root': {
      fontFamily: 'Reach-Text-Regular, sans-serif',
      fontSize: '1.0rem',
      color: theme.deepBlue,
      fontWeight: '600',
    },

    '& label.Mui-focused': {
      color: theme.lightBlue,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#0000003b',
        borderRadius: '3px',
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.lightBlue,
      },
    },
  },
})(TextField);

const refundRequestTextField = withStyles({
  root: {
    maxWidth: '325px',
    marginLeft: '0rem',
    marginRight: '5px',

    [`@media only screen and ${device.mobile}`]: {
      maxWidth: '316px',
      marginLeft: '0rem',
      marginRight: '5px',
    },

    '& .MuiInputBase-root': {
      fontFamily: 'Reach-Text-Regular, sans-serif',
      fontSize: '1.0rem',
      color: theme.deepBlue,
      fontWeight: '600',
    },

    '& label.Mui-focused': {
      color: theme.lightBlue,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#0000003b',
        borderRadius: '3px',
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.lightBlue,
      },
    },
  },
})(TextField);

const textFieldCustom = withStyles({
  root: {
    maxWidth: '125px',
    marginLeft: '0rem',

    '& .MuiInputBase-root': {
      fontFamily: 'Reach-Text-Regular, sans-serif',
      fontSize: '1.0rem',
      color: theme.deepBlue,
      fontWeight: '600',
      height: '2rem',
    },

    '& label.Mui-focused': {
      color: theme.slateGrey,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.deepBlue,
        borderRadius: '0px',
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.slateGrey,
      },
    },
  },
})(TextField);

const orderNumberBox = withStyles({
  root: {
    width: '250px',
    height: '1.5rem',
    marginLeft: '0rem',
    marginBottom: '1rem',

    '& .MuiInputBase-root': {
      fontFamily: 'Reach-Text-Regular, sans-serif',
      fontSize: '1.0rem',
      color: theme.deepBlue,
      fontWeight: '600',
      height: '2rem',
    },

    '& label.Mui-focused': {
      color: theme.slateGrey,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.deepBlue,
        borderRadius: '0px',
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.slateGrey,
      },
    },
  },
})(TextField);

const textAreaCustom = withStyles({
  root: {
    minWidth: '250px',
    marginLeft: '0rem',

    '& .MuiInputBase-root': {
      fontFamily: 'Reach-Text-Regular, sans-serif',
      fontSize: '1.0rem',
      color: theme.deepBlue,
      fontWeight: '600',
    },

    '& label.Mui-focused': {
      color: theme.slateGrey,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.deepBlue,
        borderRadius: '0px',
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.slateGrey,
      },
    },
  },
})(TextField);

const transactionHistoryTextArea = withStyles({
  root: {
    minWidth: '200px',
    marginLeft: '0rem',
    paddingLeft: '1rem',
    '& .MuiInputBase-root': {
      fontFamily: 'Reach-Text-Regular, sans-serif',
      fontSize: '0.8rem',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '5px',
        border: `1px solid ${theme.iceGrey} !important`,
      },
    },
  },
})(TextField);

const selectDateCustom = withStyles(() => ({
  root: {
    padding: '0.5rem',
  },
  selectMenu: {
    width: '210px',
    maxWidth: '210px',
    minWidth: '202px',
    color: theme.medBlue,
    textAlign: 'center',
    fontWeight: 600,
    fontFamily: 'Reach-Text-Regular, sans-serif',

    [`@media only screen and ${device.mobile}`]: {
      color: theme.deepBlue,
      textAlign: 'center',
      fontWeight: 600,
      fontFamily: 'Reach-Text-Regular, sans-serif',
    },
  },
}))(Select);

const formControlCustom = withStyles({
  root: {},
})(FormControl);

const enrolledTag = withStyles(() => ({
  root: {
    color: theme.medBlue,
    border: `1px solid ${theme.medBlue}`,
    backgroundColor: 'white',
    padding: '.5rem',
    minWidth: '40px',
    textTransform: 'capitalize',
    borderRadius: '3px',
    fontFamily: 'Reach-Text-Regular, sans-serif',
  },
  disabled: {
    backgroundColor: '#e4e4e4',
    border: '1px solid #bdbdbd',
  },
}))(Chip);

const selectPayFromCustom = withStyles(() => ({
  root: {
    padding: '1rem',
  },
  selectMenu: {
    width: '240px',
    maxWidth: '240px',
    minWidth: '240px',
    color: theme.medBlue,
    textAlign: 'center',
    fontWeight: 600,
    fontFamily: 'Reach-Text-Regular, sans-serif',

    [`@media only screen and ${device.mobile}`]: {
      color: theme.deepBlue,
      textAlign: 'center',
      fontWeight: 600,
      fontFamily: 'Reach-Text-Regular, sans-serif',
      width: '220px',
      maxWidth: '220px',
      minWidth: '220px',
    },
  },
}))(Select);

export const SelectPayFromCustom = selectPayFromCustom;
export const StyledTableRow = styledTableRow;
export const DatePicker = datePicker;
export const ActionButtonInverted = actionButtonInverted;
export const ActionButtonInvertedDouble = actionButtonInvertedDouble;
export const ActionButtonDangerInverted = actionButtonDangerInverted;
export const ActionButtonPayNow = actionButtonPayNow;
export const EditPaymentMethodModal = editPaymentMethodModal;
export const ActionButton = actionButton;
export const TextFieldCustom = textFieldCustom;
export const FormControlCustom = formControlCustom;
export const PaymentMethodTextField = paymentMethodTextField;
export const PaymentMenuItem = paymentMenuItem;
export const PaymentEditSelect = paymentEditSelect;
export const DeletePaymentMethodModal = deletePaymentMethodModal;
export const AddPaymentMethodModal = EditPaymentMethodModal;
export const RefundRequestModal = refundRequestMethodModal;
export const PaymentFailedModal = paymentFailedModal;
export const RefundSelectCustom = refundselectCustom;
export const MenuItemCustom = paymentMenuItem;
export const RefundRequestTextField = refundRequestTextField;
export const TableCustom = tableCustom;
export const TableContainerCustom = tableContainerCustom;
export const SelectItemCustom = selectItemCustom;
export const TableSearch = tableSearch;
export const TransHistSelect = transHistSelect;
export const ActionButtonEmailConfirm = actionButtonEmailConfirm;
export const EnrolledTag = enrolledTag;
export const TextAreaCustom = textAreaCustom;
export const SelectDateCustom = selectDateCustom;
export const OrderNumberBox = orderNumberBox;
export const TransactionHistoryTextArea = transactionHistoryTextArea;
export const PaymentdatePicker = paymentdatePicker;
