/* eslint-disable linebreak-style */
import { createSelector } from 'reselect';
import { sortByNumber } from '../../utils/PaymentUtils';

// selector
const getPaymentDetails = (state) => state.paymentReducer.paymentDetails;

const getPaymentMethods = (state) =>
  sortByNumber(state.paymentReducer.paymentMethods, 'paymentTokenId');

// reselect function
export const getPaymentDetailsState = createSelector(
  [getPaymentDetails],
  (paymentDetails) => paymentDetails
);

/** Get Selected Autopay payment method */
export const getSelectedAutoPayMethodId = createSelector(
  [getPaymentMethods],
  (paymentMethods) => {
    const filteredPaymentMethod =
      paymentMethods && paymentMethods.filter((payment) => payment.isAutoPayEnabled);
    return filteredPaymentMethod && filteredPaymentMethod.length > 0
      ? filteredPaymentMethod[0].paymentTokenId
      : '';
  }
);
