/* eslint-disable linebreak-style */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useClasses from './ExtSupportClasses';
import ExternalLayout from './ExternalLayout';
import {
  NewUserContent,
  Assistance,
} from '../portal/support/components/NewUser/NewUserContent';
import * as MUI from '../portal/support/components/styles/MUIComponents';

const NewUser = () => {
  const [steps] = useState(NewUserContent);
  const [assistance] = useState(Assistance);
  const classes = useClasses();
  return (
    <ExternalLayout title="Welcome to the new Spectrum Reach Client Portal!">
      <div className="faqDiv">
        {steps &&
          steps.map((item) => (
            <div key={item.id}>
              <div className={`${classes.flexUser} ${classes.faqRow}`}>
                <span>{`Step${item.id}:`}</span>
                <span className={`${classes.newUser}`}>{item.value}</span>
              </div>
            </div>
          ))}
      </div>
      <div className={`${classes.assistance}`}>{assistance}</div>
      <div className={`${classes.loginRegisterButton}`}>
        <Link className={classes.textDecoration} to="/" target="_blank">
          <MUI.ActionButton>Register/Login</MUI.ActionButton>
        </Link>
      </div>
    </ExternalLayout>
  );
};
export default NewUser;
