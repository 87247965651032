/* eslint-disable linebreak-style */
import React from 'react';
import * as MUI from '../styles/MUI_Components';
import useClasses from '../styles/Classes';
import PaymentMethods from '../managePaymentMethods/PaymentMethods';

const ManagePaymentMethod = ({
  launchManagePaymentMethod,
  isRecurringPaymentFlow,
  showCloseBtn,
}) => {
  const classes = useClasses();
  return (
    <MUI.AddPaymentMethodModal
      open={launchManagePaymentMethod}
      aria-labelledby="manage-paymentMethod-title"
      aria-describedby="manage-paymentMethod-description"
    >
      <div className={`${classes.managePaymentMethodModalBody} ${classes.global}`}>
        <PaymentMethods
          showCloseBtn={showCloseBtn}
          isRecurringPaymentFlow={isRecurringPaymentFlow}
        />
      </div>
    </MUI.AddPaymentMethodModal>
  );
};

export default ManagePaymentMethod;
