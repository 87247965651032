/* eslint-disable linebreak-style */
import { createUseStyles } from 'react-jss';
import theme from '../../../../../theme.module.scss';
import { device } from '../../../../../shared/components/ResponsiveDevice';

const useClasses = createUseStyles({
  global: {
    '@global': {
      '.quicklinks li': {
        outline: 'none',
      },
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: theme.deepBlue,
        borderRadius: '0px',
      },
      '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.deepBlue,
      },
      '.MuiRadio-colorSecondary.Mui-checked': {
        color: theme.medBlue,
      },
      '.MuiIconButton-colorSecondary:hover': {
        backgroundColor: 'rgba(0, 175, 255, 0.04)',
      },
    },
  },
  accountPage: {
    '& .mobile:nth-child(1)': {
      order: 1,
    },

    '& .mobile:nth-child(2)': {
      order: 2,
    },

    '& .mobile:nth-child(3)': {
      order: 3,
    },

    [`@media only screen and ${device.smartphone}`]: {
      '& .mobile:nth-child(1)': {
        order: 2,
      },

      '& .mobile:nth-child(2)': {
        order: 1,
      },

      '& .mobile:nth-child(3)': {
        order: 3,
      },
    },
  },
  lastModule: {
    paddingBottom: '0px',
  },
  flex: {
    display: 'flex',
  },
  lowerPad: {
    paddingBottom: '1rem',
  },
  padding2: {
    padding: '2rem',
  },
  marright1: {
    marginRight: '1rem !important',
  },
  martopHalf: {
    marginTop: '.5rem',
  },
  martop1: {
    marginTop: '1rem !important',
  },
  martop2: {
    marginTop: '2rem',
  },
  martop4: {
    marginTop: '4rem',
  },
  upperPad: {
    paddingTop: '1.5rem !important',
  },
  upperPadMed: {
    paddingTop: '1.0rem',
  },
  upperPadSmall: {
    paddingTop: '.5rem',
  },
  marbot1: {
    marginBottom: '1rem',
  },
  noTopPad: {
    paddingTop: '0px !important',
  },
  noBottomPad: {
    paddingBottom: '0px !important',
  },
  smallText: {
    fontSize: '.85rem',
  },
  centerText: {
    textAlign: 'center',
  },
  smallSpace: {
    paddingLeft: '.25rem',
  },
  deepBlue: {
    color: theme.deepBlue,
  },
  yellow: {
    color: theme.trophyGold,
  },
  TextField: {
    borderColor: theme.deepBlue,
  },
  table: {
    minWidth: 350,
  },
  divider: {
    borderTop: `1px solid ${theme.deepBlue}`,
    paddingTop: '2rem !important',
  },
  transTableButtons: {
    paddingBottom: '.5rem',
  },
  activePeriodBTN: {
    background: theme.deepBlue,
    color: `${theme.white} !important`,
  },
  paymentReview: {
    minWidth: '300px',
    color: theme.deepBlue,
  },
  padleft4: {
    paddingLeft: '4rem',
  },
  paragraph: {
    lineHeight: '1.6',
  },
  paymentTextBoxLabel: {
    paddingBottom: '.2rem',
  },
  paymentDivider: {
    width: '90px',
    padding: '.65rem',
    textAlign: 'center',
    color: theme.slateGrey,
  },
  paymentRowPad: {
    marginRight: '10px',
    [`@media only screen and ${device.smartphone}`]: {
      marginRight: '5px',
    },
  },
  paymentMethodPayByTextField: {
    height: '32px !important',
    width: '130px !important',
    [`@media only screen and ${device.smartphone}`]: {
      width: '80px !important',
    },
  },
  addNewRow: {
    display: 'flex',
    justifyContent: 'space-around',
    paddingTop: '10px',
  },
  paymentStep1Left: {
    width: '310px',
    [`@media only screen and ${device.smartphone}`]: {
      maxWidth: '160px',
      minWidth: '160px',
    },
  },
  paymentStep1Button: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: '1rem',
    width: '150px',
  },
  paymentStepLabel: {
    color: '#919194',
    fontFamily: "'Reach-Title-Bold', sans-serif",
    fontSize: '.95rem !important',
  },
  activepaymentStep: {
    color: 'black !important',
  },
  textboxDivider: {
    borderBottom: '1px solid #8eacb9',
  },
  marleft1: {
    marginLeft: '1rem',
  },
  marLeftZip: {
    marginLeft: '1rem',
    [`@media only screen and ${device.mobile}`]: {
      marginLeft: '0rem',
    },
  },
  marLeftExpYear: {
    marginLeft: '0.5rem',
    [`@media only screen and ${device.mobile}`]: {
      marginLeft: '0rem',
    },
  },
  reviewPaymentModalTitle: {
    fontFamily: 'inherit !important',
    color: theme.deepBlue,
    textAlign: 'center',
  },
  reviewPaymentItem: {
    paddingLeft: '1rem',
    color: theme.medBlue,
  },
  multipleInvoiceListItem: {
    fontWeight: '400',
    textAlign: 'center',
    color: '#a9a7a7',
  },
  multipleInvoiceListItemCell: {
    fontWeight: '400',
    textAlign: 'center',
    color: '#383737',
  },
  invoiceListBtns: {
    padding: '.2rem .4rem !important',
    minWidth: '6rem !important',
  },
  reviewPaymentDesc: {
    fontWeight: '600',
  },
  cancelBTN: {
    paddingLeft: '2rem',
  },
  paymentReviewDisclaimer: {
    width: '300px',
    paddingTop: '2rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    color: theme.deepBlue,
    marginTop: '0',
    fontSize: '.85rem',
  },
  paymentMethodListWrapperActive: {
    fontSize: '.95rem',
    padding: '1rem',
    paddingTop: '1.5rem',
    paddingBottom: '1rem',
    color: theme.deepBlue,
    borderBottom: `1px solid ${theme.wolfGrey}`,
  },
  paymentMethodListWrapperInactive: {
    fontSize: '.95rem',
    padding: '4rem',
    paddingTop: '1.5rem',
    paddingBottom: '1rem',
    color: theme.deepBlue,
  },
  paymentMethodListDiv: {
    borderBottom: 'none',
    display: 'flex',
  },
  paymentMethodListItem: {
    marginRight: 'auto',
    width: '90px',
  },
  editMethod: {
    marginLeft: '1rem',
    color: theme.lightBlue,
    fontSize: '.85rem',
    '&:hover': {
      cursor: 'pointer',
      borderBottom: `1px solid ${theme.lightBlue}`,
    },
  },
  deleteMethod: {
    marginLeft: '1rem',
    color: theme.darkRed,
    fontSize: '.85rem',
    '&:hover': {
      cursor: 'pointer',
      borderBottom: `1px solid ${theme.darkRed}`,
    },
  },
  paymentMethodModalBody: {
    width: '350px',
    marginTop: '.5rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '1rem',
    paddingLeft: '1rem',

    [`@media only screen and ${device.smartphone}`]: {
      maxWidth: '320px',
      minWidth: '320px',
      marginLeft: '1rem',
      marginRight: '1rem',
      marginTop: '1rem',
      marginBottom: '1rem',
      paddingLeft: '0rem',
    },
  },
  managePaymentMethodModalBody: {
    marginTop: '.5rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '1rem',

    [`@media only screen and ${device.smartphone}`]: {
      maxWidth: '320px',
      minWidth: '320px',
      marginLeft: '1rem',
      marginRight: '1rem',
      marginTop: '1rem',
      marginBottom: '1rem',
      paddingLeft: '0rem',
    },
  },
  paymentMethodModalTitle: {
    color: theme.deepBlue,
    textAlign: 'center',
    fontSize: '1.4rem',
  },
  modalBody: {
    width: '350px',
    marginTop: '.5rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '1rem',
    paddingLeft: '1rem',

    [`@media only screen and ${device.smartphone}`]: {
      maxWidth: '320px',
      minWidth: '320px',
      marginLeft: '.5rem',
      marginTop: '1rem',
      marginBottom: '1rem',
      paddingLeft: '0rem',
    },
  },
  modalTitle: {
    color: theme.deepBlue,
    textAlign: 'center',
    fontSize: '1.4rem',
  },
  requestRefundModalBody: {
    width: '350px',
    marginTop: '.5rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '1rem',
    paddingLeft: '2rem',
    overflow: 'hidden',

    [`@media only screen and ${device.smartphone}`]: {
      maxWidth: '320px',
      minWidth: '320px',
      marginLeft: '.5rem',
      marginTop: '1rem',
      marginBottom: '1rem',
      paddingLeft: '0rem',
    },
  },
  selectInput: {
    minWidth: '97px',
    color: theme.deepBlue,

    [`@media only screen and ${device.smartphone}`]: {
      minWidth: '94px',
      color: theme.deepBlue,
    },

    '& .MuiOutlinedInput-notchedOutline': {
      color: theme.deepBlue,
    },
  },
  selectInputLarge: {
    minWidth: '154px',
    color: theme.deepBlue,

    [`@media only screen and ${device.smartphone}`]: {
      minWidth: '152px',
      color: theme.deepBlue,
    },

    '& .MuiOutlinedInput-notchedOutline': {
      color: theme.deepBlue,
    },
  },
  paymentMethodTextFieldSmall: {
    width: '100px',
  },
  paymentMethodTextFieldMed: {
    width: '160px',
  },
  paymentMethodEditText: {
    color: theme.deepBlue,
  },
  updatePaymentMethodModalButtons: {
    width: '300px',
    marginRight: 'auto',
    marginLeft: 'auto',

    [`@media only screen and ${device.smartphone}`]: {
      padding: '.2rem',
      paddingTop: '3rem',
    },
  },
  paymentMethodTypesButtonGroup: {
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    [`@media only screen and ${device.mobile}`]: {
      paddingLeft: '0rem',
      flexDirection: 'column',
      width: 'auto',
    },
    [`@media only screen and ${device.smartphone}`]: {
      paddingLeft: '0rem',
      width: 'auto',
    },
  },
  secondButton: {
    paddingTop: '0rem',
    marginLeft: '1rem !important',
    [`@media only screen and ${device.mobile}`]: {
      marginLeft: '0rem !important',
    },
  },
  paymentMethodTextFieldZip: {
    width: '150px',

    [`@media only screen and ${device.smartphone}`]: {
      width: '143px',
    },
  },
  deleteMethodItemType: {
    fontSize: '1.5rem',
    color: theme.deepBlue,
  },
  autopayPaymentAmount: {
    verticalAlign: 'bottom',
    marginRight: '.75rem',
    display: 'inline-block',
  },
  autopayPaymentAmountTextBox: {
    padding: '.85rem',
    textAlign: 'center',
    width: '85px',
  },
  smallCC: {
    color: theme.darkGrey,
  },
  refundSelect: {
    textAlign: 'left',
  },
  refundTextFieldSmall: {
    width: '160px',

    [`@media only screen and ${device.smartphone}`]: {
      width: '155px',
    },
  },
  requestRefundModalButtons: {
    width: '325px',
    marginRight: 'auto',
    marginLeft: 'auto',

    [`@media only screen and ${device.smartphone}`]: {
      padding: '.2rem',
      paddingTop: '0rem',
      width: '300px',
      marginRight: 'auto',
      marginLeft: 'auto',
    },
  },
  requestSubmitted: {
    fontSize: '1.7rem',
    color: theme.medBlue,
    textAlign: 'center',

    [`@media only screen and ${device.smartphone}`]: {
      fontSize: '1.5rem',
      textAlign: 'center',
      color: theme.medBlue,
    },
  },
  smallButtonText: {
    fontSize: '.75rem !important',
  },
  closeRefundModal: {
    width: '180px',
    [`@media only screen and ${device.smartphone}`]: {
      width: '130px',
    },
  },
  readOnly: {
    background: '#f1f1f1',
  },
  transHistoryMultipleInvoicesListContainer: {
    display: 'flex',
    margin: '10px',
  },
  invoiceTableInlineLabel: {
    display: 'block',
    fontWeight: '400',
    color: '#a9a7a7',
  },
  invoiceTableInlineValue: {
    display: 'block',
    fontWeight: '400',
    color: '#383737',
  },
  tableInlineLabel: {
    display: 'block',
    minWidth: '7rem',
    fontWeight: '400',
    textAlign: 'center',
    color: '#a9a7a7',
  },
  descriptionContainer: {
    margin: '0px 0px 0px 0px',
  },
  textAreaStyle: {
    minWidth: '125px !important',
  },
  tableInlineValue: {
    display: 'block',
    minWidth: '7rem',
    fontWeight: '400',
    textAlign: 'center',
    color: '#383737',
  },
  tableInlineValueStatus: {
    display: 'block',
    minWidth: '2rem',
    fontWeight: '400',
    textAlign: 'center',
  },
  recurringPaymentSuccessIcon: {
    width: '18px !important',
    height: '18px !important',
    color: theme.trendingUp,
  },
  recurringPaymentWarningIcon: {
    width: '18px !important',
    height: '18px !important',
    color: theme.trophyGold,
  },
  recurringPaymentReceivedIcon: {
    width: '18px !important',
    height: '18px !important',
    color: theme.medBlue,
  },
  recurringPaymentFailedIcon: {
    width: '18px !important',
    height: '18px !important',
    color: theme.red,
  },
  tableInlineButton: {
    display: 'block',
    minWidth: '7rem',
    fontWeight: '400',
    textAlign: 'center',
    color: '#383737',
    position: 'relative',
    top: '-20px',
  },
  tableInlinePlus: {
    textAlign: 'left',
    color: theme.deepBlue,
    '& :hover': {
      color: theme.lightBlue,
      cursor: 'pointer',
    },
    '& :focus': {
      outline: 'none',
      border: 'none',
    },
  },
  tableInlineRefundLabel: {
    display: 'block',
    minWidth: '7rem',
    fontWeight: '400',
    textAlign: 'center',
    paddingLeft: '.8rem',
    color: '#a9a7a7',
  },
  tableRowLabelRow: {
    display: 'flex',
    paddingTop: '1.5rem',
    paddingBottom: '.5rem',
    maxWidth: '300px',
    [`@media only screen and ${device.smartphone}`]: {
      maxWidth: '100%',
    },
  },
  tableRowValueRow: {
    display: 'flex',
    maxWidth: '300px',
    paddingBottom: '.5rem',
  },
  tableRowLastRowLabel: {
    display: 'flex',
    maxWidth: '300px',
    paddingTop: '1rem',

    [`@media only screen and ${device.smartphone}`]: {
      maxWidth: '100%',
    },
  },
  tableRowLastRowValue: {
    display: 'flex',
    paddingBottom: '1.5rem',
    maxWidth: '300px',
    [`@media only screen and ${device.smartphone}`]: {
      maxWidth: '100%',
    },
  },
  transHistTableRow: {
    minHeight: '3rem',
  },
  tableMoreDetailsActive: {
    color: `${theme.lightBlue} !important`,
  },
  transHistTableOptionsRow: {
    paddingTop: '1.5rem',
    paddingBottom: '1rem',
    display: 'flex',
    justifyContent: 'space-between',

    [`@media only screen and ${device.smartphone}`]: {
      paddingTop: '0rem',
      flexDirection: 'column',
      '& div': {
        paddingTop: '.5rem',
        width: '100%',
      },
    },
  },
  statusCell: {
    minWidth: '100px !important',
    display: 'block',
  },
  failedStatusCell: {
    color: theme.red,

    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  singleButtonModalDiv: {
    width: '143px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  paymentfailedReasons: {
    width: '100%',

    '& li': {
      fontSize: '1rem',
      fontWeight: '600',
      color: theme.medBlue,
    },
  },
  resendEmailButton: {
    minWidth: '200px',
  },
  confirmationColor: {
    color: '#0099d8',
    width: '300px',
    margin: 'auto',
    display: 'block',
    paddingTop: '1.75rem',
    paddingBottom: '1rem',
  },
  engageAutoPay: {
    padding: '1rem',
    paddingLeft: '1rem',
    marginLeft: '3rem',
    marginRight: '7rem',

    [`@media only screen and ${device.mobile}`]: {
      marginLeft: '0rem',
      marginRight: '0rem',
    },
  },
  descriptionLabel: {
    display: 'block',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  multipleInvoiceListHeadRow: {
    lineHeight: '0.5rem !important',
  },
  multiInvoiceListTable: {
    maxWidth: '350px !important',
  },
  borderLess: {
    borderBottom: 'none !important',
  },
  multiInvoiceTable: {
    minWidth: '430px !important',
  },
  multiInvoiceTableHead: {
    borderBottom: `0.5px solid ${theme.iceGrey} !important`,
  },
  multiInvoiceTableRow: {
    borderBottom: `0.5px solid ${theme.iceGrey} !important`,
    '&:last-child': {
      borderBottom: 'none !important',
    },
  },
  marginTop: {
    marginTop: '25px',
  },
  cancelBtnMargin: {
    margin: '5px',
    display: 'flex',
    alignItems: 'center',
  },
  transInvoiceWrapper: {
    display: 'flex',
    flexWrap: 'wrap',

    '& .descBox>*': {
      flex: '1 1 160px',
      marginTop: '2rem',
    },
  },
  transIDdiv: {
    display: 'block',
    minHeight: '3.05rem',
  },
  moduleWrapper: {
    border: '1px solid #d8dde6',
    backgroundColor: '#fff',
    transition: 'box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1)',
    willChange: 'box-shadow',
    padding: '1rem',
  },
  header: {
    fontSize: '1rem',
    fontWeight: '600',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.deepBlue}`,
    color: `${theme.deepBlue}`,
  },
  moduleContent: {
    color: `${theme.deepBlue}`,
    fontSize: '0.95rem',
  },
  paddingText: {
    fontSize: '0.95rem',
    padding: '4rem',
    paddingTop: '1.5rem',
    paddingBottom: '1rem',
    color: `${theme.deepBlue}`,

    [`@media only screen and ${device.mobile}`]: {
      padding: '1rem',
    },
  },
  paddingTextRecurringPayment: {
    fontSize: '0.95rem',
    padding: '1.5rem 2rem 1rem 2rem',
    paddingTop: '1.5rem',
    paddingBottom: '1rem',
    color: `${theme.deepBlue}`,

    [`@media only screen and ${device.mobile}`]: {
      padding: '1rem',
    },
  },
  blueRow: {
    color: `${theme.deepBlue}`,
  },
  noPaddingText: {
    fontSize: '0.95rem',
    padding: '0rem',
    paddingTop: '0rem',
    paddingBottom: '0rem',
    color: `${theme.deepBlue}`,
  },
  buttonDiv: {
    display: 'flex',
    fontSize: '0.75rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: '0rem',
    paddingBottom: '0rem',
  },
  buttonDivTransHistoryBtns: {
    marginLeft: '10px',
    marginRight: '0px',
  },
  historyActionsRow: {
    marginLeft: '15px',
  },
  container: {
    border: '1px solid #d8dde6',
    backgroundColor: '#fff',
    transition: 'box-shadow 0.28s cubic-bezier(0.4,0,0.2,1)',
    willChange: 'box-shadow',
    padding: '1rem 2rem',
  },
  containerManagePayMethods: {
    backgroundColor: '#fff',
    transition: 'box-shadow 0.28s cubic-bezier(0.4,0,0.2,1)',
    willChange: 'box-shadow',
    padding: '1rem',
  },
  paymentTypeCenter: {
    justifyContent: 'center',
  },
  paymentTypeButton: {
    height: '40px',
    minWidth: '13rem !important',
    [`@media only screen and ${device.mobile}`]: {
      minWidth: '9rem !important',
    },
  },
  iconButton: {
    paddingLeft: '0.4rem',
  },
  rememberUsernameTooltip: {
    alignSelf: 'flex-end',
    display: 'flex',
    paddingTop: '.4rem',
  },
  paymentDatePickerStyles: {
    width: '288px',
    marginTop: '0px !important',
    marginBottom: '0px !important',
  },
  dateOfPaymentStyles: {
    width: '325px',
    marginTop: '0px !important',
  },
  helpIcon: {
    '&:hover': {
      color: `${theme.medBlue}`,
    },
  },
  row: {
    color: `${theme.deepBlue}`,
  },
  recurringPaymentNoStyle: {
    color: `${theme.medBlue}`,
    textDecoration: 'none',
    '&:hover': {
      color: 'white',
    },
  },
  subSectionHeader: {
    color: theme.deepBlue,
    fontSize: '1.35rem',
    fontWeight: 600,
    paddingTop: '0rem',
    paddingBottom: '0.5rem',
    borderBottom: `1px solid ${theme.deepBlue}`,
  },
  subSectionHeaderScheduled: {
    color: theme.deepBlue,
    fontSize: '1.35rem',
    fontWeight: 600,
    paddingTop: '0rem',
    paddingBottom: '0.5rem',
  },

  infoTextFailure: {
    fontFamily: 'inherit',
    letterSpacing: '-0.03rem',
    textAlign: 'center',
    paddingTop: '1rem',
    paddingLeft: '2rem',
    paddingRight: '1.5rem',
    lineHeight: '1rem',
    color: `${theme.red}`,
    [`@media only screen and ${device.mobile}`]: {
      paddingLeft: '0rem',
      paddingRight: '0rem',
    },
  },
  paddingTop: {
    marginTop: '1.5rem',
  },
  makeAPaymentModule: {
    fontSize: '1.4rem',
    color: `${theme.deepBlue}`,
  },
  paymentRow: {
    fontSize: '0.85rem',
    color: `${theme.deepBlue}`,
    borderBottom: `1px solid ${theme.deepBlue}`,
    padding: '1rem',
    [`@media only screen and ${device.mobile}`]: {
      padding: '1rem 0.5rem',
    },
  },
  paymentRowLast: {
    fontSize: '0.85rem',
    color: `${theme.deepBlue}`,
    padding: '1rem',
  },
  rowPad: {
    paddingTop: '1rem',
  },
  blue: {
    color: `${theme.lightBlue}`,
  },
  infoText: {
    fontFamily: 'inherit',
    letterSpacing: '-0.03rem',
    textAlign: 'center',
    paddingTop: '1rem',
    paddingLeft: '2rem',
    paddingRight: '1.5rem',
    lineHeight: '1rem',
    color: `${theme.red}`,
    [`@media only screen and ${device.mobile}`]: {
      paddingLeft: '0rem',
      paddingRight: '0rem',
    },
  },
  transHistInvoiceContainer: {
    paddingLeft: '1rem',
    overflow: 'visible',
  },
  makeAPaymentStep1: {
    display: 'flex',
    [`@media only screen and ${device.smartphone}`]: {
      maxWidth: '300px',
    },
  },
  linkText: {
    color: `${theme.deepBlue}`,
    textAlign: 'center',
    paddingTop: '0rem',
    fontSize: '0.9rem',
    display: 'block',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },

  leftOtherServicesUL: {
    color: ` ${theme.darkestBlue}`,
    listStyle: 'none',
    paddingTop: '3rem',
    paddingLeft: '0rem',
    marginTop: '0rem',
  },

  pageTitle: {
    maxWidth: '1200px',
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize: '2rem',

    '&:h5': {
      padding: '1rem',
      paddingLeft: '1rem',
      display: 'block',
      margin: '0',
      paddingBottom: '0',
      maxWidth: '1140px',
      marginLeft: 'auto',
      marginRight: 'auto',
      color: ` ${theme.deepBlue}`,

      '@media screen and (maxWidth: 1340px) and (min-width: 1090px)': {
        paddingLeft: '2.5rem',
      },
    },
  },

  leftOtherServicesLI: {
    marginTop: '0.5rem',
    height: '130px',
    maxWidth: '250px',
    marginRight: '0.5rem',
    marginBottom: '1rem',

    '&:hover': {
      cursor: 'pointer',
    },

    [`@media only screen and ${device.smartphone}`]: {
      height: '160px',
      maxWidth: '450px',
    },

    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
      border: '1px solid #ccc',
      padding: '3px',
      backgroundColor: ' #fff',
    },

    '&:first-child': {
      paddingTop: '0rem',
    },
  },

  rightQuickLinksUL: {
    color: `${theme.darkestBlue}`,
    listStyle: 'none',
    paddingLeft: '0rem',
    marginTop: '0rem',
    paddingTop: '1rem',
  },

  rightQuickLinksHeaderLI: {
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    fontWeight: '600',
    fontSize: '1.35rem',
  },

  rightQuickLinksLI: {
    paddingTop: '0rem',
    paddingBottom: '0.5rem',
    color: `${theme.lightBlue}`,
    '& a, a:visited': {
      textDecoration: 'none',
      color: 'inherit',
    },

    '&:first-child': {
      paddingTop: '0rem',
    },

    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },

  nopaddingText: {
    fontSize: '0.95rem',
    padding: '0rem',
    paddingTop: '0rem',
    paddingBottom: '0rem',
    color: `${theme.deepBlue}`,
  },

  paymentAmountDiv: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },

  MultiInvoiceRowInfo: {
    display: 'flex',
    paddingBottom: '5px',
    [`@media only screen and ${device.smartphone}`]: {
      paddingLeft: '0rem',
      marginLeft: '-0.2rem',
    },
  },
  buttonSingleDiv2: {
    maxWidth: '175px',
    display: 'flex',
    fontSize: '0.75rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: '0rem',
    paddingBottom: '0rem',
  },

  buttonSingleDiv: {
    display: 'flex',
    fontSize: '0.75rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: '0rem',
    paddingBottom: '0rem',
  },
  buttonSingleDiv1: {
    size: '200px',
    display: 'flex',
    fontSize: '0.75rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: '0rem',
    paddingBottom: '0rem',
    justifyContent: 'center',
  },

  expand: {
    fontSize: '0.75rem',
    '&:hover': {
      cursor: 'pointer',
      color: `${theme.medBlue}`,
    },
  },

  refundRequestInfoText: {
    fontFamily: 'inherit',
    letterSpacing: '-0.03rem',
    textAlign: 'center',
    paddingTop: '1rem',
    paddingLeft: '2rem',
    paddingRight: '1.5rem',
    lineHeight: '1rem',
    color: `${theme.red}`,
    [`@media only screen and ${device.mobile}`]: {
      paddingLeft: '0rem',
      paddingRight: '0rem',
    },
  },

  verificationMessage: {
    marginBottom: '2rem',
    fontSize: '1rem',
  },

  tableHead: {
    background: 'transparent !important',
  },
  tableRow: {
    padding: '16px !important',
  },
  linkColor: {
    color: `${theme.medBlue}`,
    cursor: 'pointer',
    paddingLeft: '5px',
  },
  paddingDontSeeAcct: {
    padding: '1rem 0rem',
  },
  printPageTitle: {
    backgroundColor: theme.ashGrey,
    width: '100%',
    height: '30px',
    textAlign: 'center',
  },
  printLogo: {
    padding: '20px 0px',
    textAlign: 'left',
  },
  printLogoImg: {
    backgroundColor: theme.deepBlue,
    width: '125px',
    padding: '5px',
  },
  printInfoContainer: {
    padding: '20px 0px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  printContainer: {
    margin: '20mm 20mm 0 20mm',
  },
  printFooter: {
    width: '100%',
    marginTop: '50px',
  },
  requestRefundLink: {
    marginBottom: '10px',
    display: 'flex',
  },
  loaderStyles: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    bottom: '30%',
    left: '40%',
  },
  autoPayPayments: {
    display: 'flex',
    justifyContent: 'center',
  },
  recurringRadioDiv: {
    flexDirection: 'row !important',
  },
  typesDropDownDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  recurringPaymentHistoryContainer: {
    backgroundColor: '#fff',
    transition: 'box-shadow 0.28s cubic-bezier(0.4,0,0.2,1)',
    willChange: 'box-shadow',
    padding: '1rem 2rem',
  },
  recurringPaymentHistorySubSectionHeader: {
    color: theme.deepBlue,
    fontSize: '1rem',
    fontWeight: 600,
    paddingTop: '0rem',
    paddingBottom: '0.5rem',
  },
  addRecurringPaymentLink: {
    fontSize: '1rem',
    cursor: 'pointer',
  },
  addRecurringPayContainer: {
    display: 'flex',
    padding: '0 32px',
    alignItems: 'center',
  },
  FlexDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  payFromField: {
    width: '200px',
    maxWidth: '200px',
    minWidth: '200px !important',
  },
  marginRight10: {
    marginRight: '10px !important',
  },
  addAccountLink: {
    fontSize: '12px',
  },
  marginAuto: {
    margin: '0 auto !important',
    marginRight: '0px !important',
  },
  dayOfMonth: {
    width: '250px',
  },
  recurringPaymentModalBody: {
    margin: '2rem',

    [`@media only screen and ${device.smartphone}`]: {
      maxWidth: '320px',
      minWidth: '320px',
      marginLeft: '.5rem',
      marginTop: '1rem',
      marginBottom: '1rem',
      paddingLeft: '0rem',
    },
  },
  marLeft5: {
    marginLeft: '5px !important',
  },
  recurringRequestSubmitted: {
    fontSize: '1.2rem',
    color: theme.medBlue,
    textAlign: 'center',

    [`@media only screen and ${device.smartphone}`]: {
      fontSize: '1rem',
      textAlign: 'center',
      color: theme.medBlue,
    },
  },
  makeToPaymentLink: {
    cursor: 'pointer',
    color: '#0099d8',
  },
});

export default useClasses;
