/* eslint-disable linebreak-style */
import * as types from '../actionTypes/TransactionHistoryActionTypes';

const transactionHistoryReducerInitialState = {
  transactionHistoryDetails: {
    months: 1,
    search: '',
    rowsPerPage: 5,
    start: 0,
    length: 5,
    totalRecords: 0,
    page: 0,
    loadingTransactionHistory: false,
    failedLoadingTransactionHistory: false,
  },
  isCancelTransactionFailed: false,
  transactionHistoryInformation: [],
};

const transactionHistoryReducer = (
  state = transactionHistoryReducerInitialState,
  action
) => {
  switch (action.type) {
    case types.TRANSACTION_HISTORY_SUCCESS:
      return {
        ...state,
        transactionHistoryInformation: action.data.data,
        transactionHistoryDetails: {
          ...state.transactionHistoryDetails,
          totalRecords: action.data.totalRecords,
        },
      };
    case types.CHANGE_TRANSACTION_HISTORY_FIELD_VALUE: {
      const { value, id } = action.data && action.data;
      return {
        ...state,
        transactionHistoryDetails: {
          ...state.transactionHistoryDetails,
          [id]: value,
        },
      };
    }
    case types.LOADING_TRANSACTION_HISTORY:
      return {
        ...state,
        transactionHistoryDetails: {
          ...state.transactionHistoryDetails,
          loadingTransactionHistory: action.data,
          failedLoadingTransactionHistory: false,
        },
      };
    case types.FAILED_LOADING_TRANSACTION_HISTORY:
      return {
        ...state,
        transactionHistoryDetails: {
          ...state.transactionHistoryDetails,
          failedLoadingTransactionHistory: action.data,
        },
      };
    case types.RESET_TRANSACTION_HISTORY: {
      return {
        ...state,
        transactionHistoryDetails:
          transactionHistoryReducerInitialState.transactionHistoryDetails,
      };
    }
    case types.FAILED_CANCEL_TRANSACTION_HISTORY: {
      return {
        ...state,
        isCancelTransactionFailed: action.data,
      };
    }
    default:
      return state;
  }
};

export { transactionHistoryReducerInitialState, transactionHistoryReducer };
