/* eslint-disable linebreak-style */
// Get key JS Script
export const loadKeyJS = (callback) => {
  const existingScript = document.getElementById('loadGetKey');
  if (!existingScript) {
    const script = document.createElement('script');
    // XX=Format ID
    // YYYYYYYYYYYY=Subscriber ID (unique value assigned by Merchant Services)
    script.src = `${process.env.REACT_APP_PAYMENT_TECH_URL}/pie/v1/64100000000207/getkey.js`;
    script.id = 'loadGetKey';
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};

// Encription JS Script
export const loadEncriptionJS = (callback) => {
  const existingScript = document.getElementById('loadEncription');
  if (!existingScript) {
    const script = document.createElement('script');
    // Returns Functions to validate Credit card number and encript card data
    script.src = `${process.env.REACT_APP_PAYMENT_TECH_URL}/pie/v1/encryption.js`;
    script.id = 'loadEncription';
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};
const PIE = {};
window.PIE = PIE;
// Functions From Encription JS
// This function checks whether getkey.js is loaded.
const isPieKeyDownloadError = () => {
  if (
    typeof PIE === 'undefined' ||
    typeof PIE === 'undefined' ||
    typeof PIE === 'undefined' ||
    typeof PIE === 'undefined' ||
    typeof PIE === 'undefined' ||
    typeof PIE === 'undefined'
  ) {
    return true;
  }
  return false;
};
// This function checks whether encryption.js is loaded.
const isPieEncryptionDownloadError = () => {
  if (
    typeof ValidatePANChecksum !== 'function' ||
    typeof ProtectPANandCVV !== 'function'
  ) {
    return true;
  }
  return false;
};

// This funtion will encrypt credit card number and cvv
export const doEncryption = (cardNumber, cvvNumber, callback1, callback2) => {
  if (isPieEncryptionDownloadError()) {
    console.log('Failed to load encryption.js file'); // eslint-disable-line no-console
    return;
  }
  if (isPieKeyDownloadError()) {
    console.log('Failed to load getkey.js file'); // eslint-disable-line no-console
    return;
  }

  const embed = true;
  // Check MOD 10 digit, since PIE embedded encryption
  // requires that the MOD 10 checksum is valid.
  if (!window.ValidatePANChecksum(cardNumber)) {
    return 'Card Number is Invalid';
  }

  const result = window.ProtectPANandCVV(cardNumber, cvvNumber, !embed);
  if (result != null) {
    callback1 && callback1();
    callback2 && callback2(result);
    return '';
  }
  if (result.length > 2) {
    callback1 && callback1();
    callback2 && callback2(result);
    return '';
  }
  return 'Card Number is Invalid';
};
