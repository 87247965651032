/* eslint-disable linebreak-style */
import React from 'react';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Grid, Link } from '@material-ui/core';
import {
  HeroContainer,
  CardContainer,
  SectionWrapper,
} from '../../shared/components/SharedComponentStyles';
import useClasses from './LogoutClasses';

const Logout = () => {
  const classes = useClasses();
  const dispatch = useDispatch();

  const { isUnAuthorizedUser } = useSelector((state) => ({
    isUnAuthorizedUser: state.authReducer.isUnAuthorizedUser,
  }));
  return (
    <SectionWrapper>
      <Grid className={`${classes.signoutModule}`}>
        <Grid className={`${classes.signoutWrapper}`}>
          <HeroContainer>
            <CardContainer>
              <Card>
                <CardContent>
                  <div className={`${classes.headerText}`}>
                    You have successfully logged off
                  </div>
                  {isUnAuthorizedUser && (
                    <div className={`${classes.headerText}`}>
                      You have been logged out due to inactivity.
                    </div>
                  )}
                  <div className={`${classes.linkText}`}>
                    <Link onClick={() => dispatch(push({ pathname: '/login' }))}>
                      <span className={`${classes.linkText}`}>Return to Login</span>
                    </Link>
                  </div>
                </CardContent>
              </Card>
            </CardContainer>
          </HeroContainer>
        </Grid>
      </Grid>
    </SectionWrapper>
  );
};

export default Logout;
