/* eslint-disable linebreak-style */
import React, { useState, useEffect } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { connect } from 'react-redux';
import * as MUI from '../styles/MUI_Components';
import useClasses from '../styles/Classes';
import theme from '../../../../../theme.module.scss';
import * as Actions from '../../../../../redux/actionCreators/AdjustmentsActionCreators';
import { ComponentLoader } from '../../../../../shared/components/Loader';
import NumberFormatCustom from '../NumberFormatCustom';

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersBasePicker: {
      pickerView: {},
    },
    MuiPickersDay: {
      today: {
        color: theme.medBlue,
      },
      daySelected: {
        backgroundColor: theme.medBlue,
      },
    },
  },
});

const RefundRequest = ({
  launchRefundRequestModal,
  cancel: _cancelrefundRequstCallback,
  requestRefund,
  isConfirmationModalOpen,
  closeConfirmationModal,
  getRefundReasons,
  refundReasons,
  isRefundSubmissionFailed,
  isCreditAdjustmentSubmissionLoading,
}) => {
  const [refundData, setRefundData] = useState({
    agency: '',
    paymentDate: null,
    amount: null,
    adjustmentRequestReasonId: null,
    adjustmentRequestReason: {
      reason: null,
    },
  });
  useEffect(() => {
    if (!refundReasons.length > 0) {
      getRefundReasons();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = () => {
    requestRefund(refundData);
  };

  const handleTextChange = (event) => {
    const { value, name } = event && event.target;
    if (name === 'adjustmentRequestReasonId') {
      const selectedReason = refundReasons.filter(
        (reason) => reason.active && reason.id === value
      );
      const reasonName = selectedReason && selectedReason[0] && selectedReason[0].reason;
      setRefundData({
        ...refundData,
        [name]: value,
        adjustmentRequestReason: {
          reason: reasonName,
        },
      });
      return;
    }
    setRefundData({ ...refundData, [name]: value });
  };
  const handleDate = (e) => {
    setRefundData({
      ...refundData,
      paymentDate: e,
    });
  };

  const isEnabled =
    refundData.agency !== null &&
    refundData.paymentDate != null &&
    refundData.amount != null &&
    refundData.adjustmentRequestReasonId != null;

  const closeRefundRequst = () => {
    setRefundData({
      agency: '',
      paymentDate: null,
      amount: null,
      adjustmentRequestReasonId: null,
    });
    _cancelrefundRequstCallback();
    closeConfirmationModal();
  };
  const classes = useClasses();
  return (
    launchRefundRequestModal && (
      <>
        <MUI.RefundRequestModal
          open={launchRefundRequestModal}
          aria-labelledby="add-refundRequest-title"
          aria-describedby="add-refundRequest-description"
        >
          {isCreditAdjustmentSubmissionLoading ? (
            <ComponentLoader />
          ) : (
            <div className={`${classes.requestRefundModalBody} ${classes.global}`}>
              {!isConfirmationModalOpen ? (
                <div className={classes.modalTitle}>Refund Request</div>
              ) : (
                ''
              )}
              {isConfirmationModalOpen && (
                <>
                  <div className={classes.martop2}>
                    <div className={`${classes.requestSubmitted}`}>
                      Refund Request Submitted
                    </div>
                    <div
                      className={`${classes.deepBlue} ${classes.upperPad} ${classes.marleft1}`}
                    >
                      Your request has been sent. Please look for a response within 48
                      hours.
                    </div>

                    <div
                      className={`${classes.closeRefundModal} ${classes.martop4} ${classes.buttonDiv}`}
                    >
                      <MUI.ActionButton
                        className={`${classes.smallButtonText}`}
                        onClick={() => {
                          closeRefundRequst();
                        }}
                      >
                        Close
                      </MUI.ActionButton>
                    </div>
                  </div>
                </>
              )}

              {!isConfirmationModalOpen && (
                <>
                  <div className={classes.martop2}>
                    <div className={`${classes.deepBlue}`}>
                      Please fill out the form to request a payment adjustment
                    </div>
                    <div className={classes.martop2}>
                      <MUI.RefundRequestTextField
                        value={refundData.agency}
                        onChange={(e) => {
                          handleTextChange(e);
                        }}
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: 'center',
                          },
                        }}
                        label="Advertiser/Agency Name"
                        variant="outlined"
                        name="agency"
                      />
                    </div>
                    <div className={classes.upperPadSmall}>
                      <span>
                        <MUI.FormControlCustom
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <InputLabel id="reason-label">Reason</InputLabel>
                          <MUI.RefundSelectCustom
                            labelId="reason-label"
                            name="adjustmentRequestReasonId"
                            value={refundData.adjustmentRequestReasonId}
                            className={classes.refundSelect}
                            onChange={handleTextChange}
                            label="Reason"
                          >
                            {refundReasons.length > 0 &&
                              refundReasons
                                .filter(
                                  (reason) =>
                                    reason.active &&
                                    reason.adjustmentRequestTypeId === 'R'
                                )
                                .map((item) => (
                                  <MUI.MenuItemCustom key={item.id} value={item.id}>
                                    {item.reason}
                                  </MUI.MenuItemCustom>
                                ))}
                          </MUI.RefundSelectCustom>
                        </MUI.FormControlCustom>
                      </span>
                    </div>

                    <div className={`${classes.upperPadSmall}`}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <ThemeProvider theme={materialTheme}>
                          <MUI.PaymentdatePicker
                            disableToolbar
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            format="MM/dd/yyyy"
                            margin="normal"
                            name="paymentDate"
                            label="Date of Original Payment"
                            id="request-refund-date-picker"
                            data-testid="date-picker"
                            value={refundData.paymentDate}
                            onChange={handleDate}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                            className={classes.dateOfPaymentStyles}
                          />
                        </ThemeProvider>
                      </MuiPickersUtilsProvider>
                    </div>
                    <div className={`${classes.upperPadSmall}`}>
                      <MUI.RefundRequestTextField
                        value={refundData.amount}
                        onChange={(e) => {
                          handleTextChange(e);
                        }}
                        fullWidth
                        className={`${classes.refundTextFieldSmall} ${classes.marleft1}`}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        inputProps={{
                          style: {
                            textAlign: 'left',
                          },
                        }}
                        label="Amount"
                        variant="outlined"
                        name="amount"
                      />
                    </div>
                  </div>
                  <div className={classes.martop2}>
                    {isRefundSubmissionFailed && (
                      <div>Failed to Submit Refund Request. Please contact support.</div>
                    )}
                  </div>
                  <div
                    className={`${classes.requestRefundModalButtons} ${classes.martop4} ${classes.buttonDiv}`}
                  >
                    <MUI.ActionButton
                      onClick={() => {
                        closeRefundRequst();
                      }}
                    >
                      Cancel
                    </MUI.ActionButton>
                    <span className={classes.marleft1}>
                      <MUI.ActionButton
                        variant="contained"
                        disabled={!isEnabled}
                        className={classes.marleft1}
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        Submit Request
                      </MUI.ActionButton>
                    </span>
                  </div>
                </>
              )}
            </div>
          )}
        </MUI.RefundRequestModal>
      </>
    )
  );
};

const mapStateToProps = (state) => ({
  isConfirmationModalOpen: state.adjustmentsReducer.isConfirmationModalOpen,
  refundReasons: state.adjustmentsReducer.refundReasons,
  isRefundSubmissionFailed: state.adjustmentsReducer.isRefundSubmissionFailed,
  isCreditAdjustmentSubmissionLoading:
    state.adjustmentsReducer.isCreditAdjustmentSubmissionLoading,
  isAdjustmentReasonsLoading: state.adjustmentsReducer.isAdjustmentReasonsLoading,
});
const mapDispatchToProps = (dispatch) => ({
  requestRefund: (data) => {
    Actions.requestRefund(dispatch, data);
  },
  closeConfirmationModal: () => {
    Actions.closeConfirmationModal(dispatch);
  },
  getRefundReasons: () => {
    Actions.getRefundReasons(dispatch);
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(RefundRequest);
