/* eslint-disable linebreak-style */
import { validate, res } from 'react-email-validator';

/** zip code regex */
export const zipcodeRegex = /^\d{5}(?:[-]?\d{4})?$/;

/** Card Expire Valid Regex */
export const cardExpireValidRegex = /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/;

/** Email Validations */
export const isValidEmailField = (value, allowEmpty) => {
  if (allowEmpty && (value === undefined || value === '')) {
    return true;
  }
  validate(value);
  if (res) {
    return true;
  }
  return false;
};

/** Verify GUID */
export const isGuidValid = (value) => {
  const regex = new RegExp('[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}');
  return regex.test(value);
};

/** Verify Password */
export const isPasswordValid = (value) => {
  const regex = new RegExp(
    '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'
  );
  return regex.test(value);
};

/** Is Zipcode valid */
export const isZipValid = (zip) => {
  if (zip && zip.match(zipcodeRegex)) {
    return true;
  }
  return false;
};

/** Is Phone Number Valid */
export const isPhoneNumberValid = (inputtxt) => {
  const phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return !!inputtxt.match(phoneno);
};
