/* eslint-disable linebreak-style */
import { createUseStyles } from 'react-jss';
import { device } from '../../../../../shared/components/ResponsiveDevice';
import theme from '../../../../../theme.module.scss';

const useClasses = createUseStyles({
  global: {
    '@global': {
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: theme.deepBlue,
        borderRadius: '0px',
      },
      '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.deepBlue,
      },
      '.userlist': {
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',

        [`@media only screen and ${device.smartphone}`]: {
          width: '300px',
          marginLeft: 'auto',
          marginRight: 'auto',
        },
      },
      '.userlist div': {
        borderBottom: `1px solid ${theme.wolfGrey}`,
        '&:last-child': {
          borderBottom: 'none',
        },
      },
    },
  },
  userPage: {
    '& .section:nth-child(1)': {
      order: 1,
    },

    '& .section:nth-child(2)': {
      order: 2,
    },

    '& .section:nth-child(3)': {
      order: 3,
    },

    [`@media only screen and ${device.smartphone}`]: {
      '& .section:nth-child(1)': {
        order: 2,
      },

      '& .section:nth-child(2)': {
        order: 1,
      },

      '& .section:nth-child(3)': {
        order: 3,
      },
    },
  },
  lastModule: {
    paddingBottom: '0px',
  },
  upperPad: {
    paddingTop: '1.5rem',
  },
  upperPadSmall: {
    paddingTop: '.5rem',
  },
  upperPadLarge: {
    paddingTop: '2.5rem',
  },
  lowerPad: {
    paddingBottom: '1.5rem',
  },
  deepBlue: {
    color: theme.deepBlue,
  },
  medBlue: {
    color: theme.medBlue,
  },
  lightBlue: {
    color: theme.lightBlue,
  },
  marleft1: {
    marginLeft: '1rem',
  },
  maxwidthMobile: {
    maxWidth: '300px',
    width: '300px',
    marginLeft: 'auto',
    marginRight: 'auto',

    [`@media only screen and ${device.smartphone}`]: {
      maxWidth: '300px',
      width: '300px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  boldFont: {
    fontFamily: 'Reach-Title-Bold, san-sarif !important',
    letterSpacing: '-.5px',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '450px',
    marginLeft: 'auto',
    marginRight: 'auto',

    [`@media only screen and ${device.smartphone}`]: {
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: '300px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },

  subSectionHeader: {
    color: theme.deepBlue,
    fontSize: '1.35rem',
    fontWeight: 600,
    paddingTop: '0rem',
    paddingBottom: '0.5rem',
    borderBottom: `1px solid ${theme.deepBlue}`,
  },
  itemRow: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  rowFlex: {
    display: 'flex',
    justifyContent: 'space-between',
    [`@media only screen and ${device.mobile}`]: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexColumnPad: {
    paddingTop: '.5rem',
  },
  lineHeight3: {
    lineHeight: '3',
  },
  bottomBorder: {
    borderBottom: `1px solid ${theme.deepBlue}`,
  },
  bottomBorderGrey: {
    borderBottom: `1px solid ${theme.wolfGrey}`,
    [`@media only screen and ${device.smartphone}`]: {
      borderBottom: 'none',
    },
  },
  paddingBottom1: {
    paddingBottom: '1rem',
  },
  passwordHelperText: {
    color: theme.mutedBlack,
    fontSize: '.75rem',
    marginLeft: 'auto',
    width: '225px',
    paddingRight: '.3rem',
    paddingLeft: '2rem',

    [`@media only screen and ${device.smartphone}`]: {
      marginLeft: '0',
      paddingLeft: '0rem',
    },
  },
  updateContactBTNDiv: {
    marginLeft: 'auto',
    paddingTop: '1.5rem',
    width: '100%',
    display: 'flex',
  },
  changePasswordDiv: {
    maxWidth: '140px',
    marginLeft: 'auto',
    paddingTop: '1.5rem',

    [`@media only screen and ${device.smartphone}`]: {
      maxWidth: '140px',
      paddingTop: '1.5rem',
    },
  },
  updatePasswordDiv: {
    maxWidth: '200px',
    marginLeft: 'auto',
    paddingTop: '1.5rem',

    [`@media only screen and ${device.smartphone}`]: {
      maxWidth: '140px',
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingTop: '1.5rem',
    },
  },
  passwordInstruction: {
    color: theme.mutedBlack,
    maxWidth: '400px',
    minWidth: '400px',
    paddingTop: '4rem',
    margin: 'auto',

    [`@media only screen and ${device.mobile}`]: {
      maxWidth: '300px',
      minWidth: '300px',
    },
  },
  contactValue: {
    color: theme.mutedBlack,
    textAlign: 'right',
  },
  adduserForm: {
    maxWidth: '330px',
    width: '330px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  addusermodalActionButtonDiv: {
    paddingTop: '2.5rem',
    paddingLeft: '.8rem',

    [`@media only screen and ${device.mobile}`]: {
      width: '310px',
      paddingTop: '0rem',
      paddingLeft: '0rem',
    },
  },
  deleteMethodRow: {
    paddingBottom: '1rem',
    paddingTop: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
  modalButtonsLeftPad: {
    paddingLeft: '.8rem',
  },
  enableMethod: {
    marginLeft: '1rem',
    color: theme.medBlue,
    fontSize: '.85rem',
    '&:hover': {
      cursor: 'pointer',
      borderBottom: `1px solid ${theme.medBlue}`,
    },
  },
  disableMethod: {
    marginLeft: '1rem',
    color: theme.darkRed,
    fontSize: '.85rem',
    '&:hover': {
      cursor: 'pointer',
      borderBottom: `1px solid ${theme.darkRed}`,
    },
  },
  deleteUserHelperText: {
    paddingBottom: '1rem',
    display: 'block',
  },
  modalBody: {
    width: '350px',
    minWidth: '350px',
    marginTop: '.5rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '1rem',
    paddingLeft: '1rem',

    [`@media only screen and ${device.smartphone}`]: {
      maxWidth: '320px',
      minWidth: '320px',
      marginLeft: '.5rem',
      marginTop: '1rem',
      marginBottom: '1rem',
      paddingLeft: '0rem',
    },
  },
  modalTitle: {
    color: theme.deepBlue,
    textAlign: 'center',
    fontSize: '1.4rem',
  },
  addUserConfirmation: {
    display: 'block',
    padding: '2rem',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    marginRight: '1rem',
    background: 'aliceblue',
    borderRadius: '.5rem',
  },
  selectLabelLineHeight: {
    lineHeight: '4.5',
    [`@media only screen and ${device.smartphone}`]: {
      lineHeight: '1',
      marginTop: '20px !important',
    },
  },
  formTextLabel: {
    lineHeight: '1',
    [`@media only screen and ${device.mobile}`]: {
      lineHeight: '2.5',
    },
  },
  adduserInfoText: {
    paddingLeft: '.3rem',
  },
  accountKeyInstruction: {
    paddingLeft: '1rem',
    paddingRight: '2rem',
    color: theme.deepBlue,
    textAlign: 'center',
  },
  accountKeyGuide: {
    fontSize: '.75rem',
    color: theme.medBlue,
    textDecoration: 'underline',
    paddingTop: '.5rem',
    '&:hover': {
      cursor: 'pointer',
      color: theme.lightBlue,
    },
  },
  linkAccountKeySubmit: {
    maxWidth: '140px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  moduleContent: {
    color: `${theme.deepBlue}`,
    fontSize: '0.95rem',
  },
  row: {
    color: `${theme.deepBlue}`,
  },
  paddingDiv0: {
    fontFamily: 'inherit',
    fontSize: '0.95rem',
    padding: '0rem',
    paddingTop: '1.5rem',
    paddingBottom: '1rem',
    color: `${theme.deepBlue}`,
  },
  container: {
    border: '1px solid #d8dde6',
    backgroundColor: '#fff',
    transition: 'box-shadow 0.28s cubic-bezier(0.4,0,0.2,1)',
    willChange: 'box-shadow',
    padding: '1rem',
  },
  infoTextSuccess: {
    color: `${theme.trendingUp}`,
  },
  infoTextFailure: {
    color: `${theme.red}`,
  },
  buttonDivNoAlign: {
    display: 'flex',
    fontSize: '0.75rem',
    maxWidth: '175px',
    paddingTop: '0rem',
    paddingBottom: '0rem',
  },
  infoText: {
    fontFamily: 'inherit',
    letterSpacing: '-0.03rem',
    textAlign: 'center',
    paddingTop: '1rem',
    paddingLeft: '2rem',
    paddingRight: '1.5rem',
    lineHeight: '1rem',
    [`@media only screen and ${device.mobile}`]: {
      paddingLeft: '0rem',
      paddingRight: '0rem',
    },
  },

  buttonDiv: {
    display: 'flex',
    fontSize: '0.75rem',
    marginLeft: 'auto',
    paddingTop: '0rem',
    paddingBottom: '0rem',
  },
  marginTopBottom: {
    marginBottom: '2rem',
    marginTop: '2rem',
  },
  sidebarUL: {
    color: `${theme.darkestBlue}`,
    listStyle: 'none',
    paddingLeft: '0rem',
    marginTop: '0rem',
  },

  sidebarLI: {
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
    color: `${theme.slateGrey}`,
    fontWeight: '600',
    '& a, a:visited': {
      textDecoration: 'none',
      color: 'inherit',
    },
    '& :firstChild': {
      paddingTop: '0rem',
    },
    '& :hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
});

export default useClasses;
