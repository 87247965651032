/* eslint-disable linebreak-style */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { Button, Link } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import * as Actions from '../../redux/actionCreators/authActionCreators';
import InputWithLabel from '../../shared/components/InputWithLabel';
import theme from '../../theme.module.scss';
import { PageLoader } from '../../shared/components/Loader';
import useClasses from './ForgetPasswordClasses';
import { isValidEmailField } from '../../utils/Validations';

const useStyles = makeStyles(() => ({
  center: {
    textAlign: 'center',
  },
  NoTopPad: {
    paddingTop: '0 !important',
  },
  ForgotPasswordSubmit: {
    color: 'white',
    marginTop: '1rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    padding: '1.25rem',
    display: 'block',
    width: '75%',
    backgroundColor: theme.lightBlue,
    '&:hover': {
      background: '#34c0e8',
    },
  },
  fogotPasswordText: {
    fontSize: '.75rem',
    width: '75%',
    display: 'block',
    margin: 'auto',
  },
  ForgotLoginInfo: {
    color: theme.lightBlue,
  },
}));

const TextBox = withStyles(() => ({
  root: {
    '& .MuiInputBase-root': {
      fontFamily: 'Reach-Text-Regular, sans-serif',
    },
  },
}))(InputWithLabel);

/** Forgot Password Form Component */
const ForgotPasswordForm = () => {
  const [classes] = useState(useStyles());
  const forgetPasswordClasses = useClasses();
  const [username, setUserName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const handleChangeUserName = (event) => {
    const usernameValue = event && event.target.value;
    setUserName(usernameValue);
  };
  const handleChangeEmail = (event) => {
    const emailValue = event && event.target.value;
    setEmailAddress(emailValue);
  };
  const dispatch = useDispatch();

  const { isForgotPasswordPending } = useSelector((state) => ({
    isForgotPasswordPending: state.authReducer.isForgotPasswordPending,
  }));
  return (
    <>
      {isForgotPasswordPending ? (
        <PageLoader />
      ) : (
        <>
          <div
            className={`${classes.NoTopPad} ${classes.fogotPasswordText} ${forgetPasswordClasses.infoText}`}
          >
            If you have submitted payments to Spectrum Reach before through our previous
            payment portal version, your existing user credentials will not be valid. You
            will need to create a new user account for the new Client Portal by clicking
            Return to Login.
            <br />
            <br />
            If you have created a user account for the new Client Portal and forgot your
            password, enter the Username associated with your account below. If it matches
            our records, an email will be sent with instructions on how to reset your
            password.
            <br />
            <br />
            If you do not receive the email within 1 hour, be sure to check your spam
            folder. If you continue to experience issues, please contact us at
            1-877-286-7117 or email us at reachme@charter.com.
          </div>
          <div className={`${forgetPasswordClasses.linkText} ${classes.ForgotLoginInfo}`}>
            {' '}
            <Link onClick={() => dispatch(push({ pathname: '/ext-support' }))}>
              <span className={`${forgetPasswordClasses.linkColor}`}>
                {' '}
                Previous Payment Portal User?
              </span>
            </Link>
          </div>
          <div className={classes.center}>
            <TextBox
              fieldId="username"
              onChange={handleChangeUserName}
              value={username}
              disabled={emailAddress}
              inputProps={{ 'data-testid': 'user-name' }}
              style={{ margin: 4, width: '75%' }}
              placeholder="Username associated with your account"
              margin="normal"
            />
            <div>OR</div>
            <TextBox
              fieldId="emailAddress"
              onChange={handleChangeEmail}
              disabled={username}
              value={emailAddress}
              inputProps={{ 'data-testid': 'email-address' }}
              style={{ margin: 4, width: '75%' }}
              placeholder="Email associated with your account"
              margin="normal"
              fieldError={emailAddress && !isValidEmailField(emailAddress)}
              fieldErrorMsg="Please enter a valid email address."
            />
          </div>

          <Button
            className={classes.ForgotPasswordSubmit}
            variant="contained"
            color="primary"
            data-testid="password-reset"
            disableElevation
            disabled={!username && !emailAddress}
            onClick={() => {
              Actions.ForgotPasswordAction(dispatch, username, emailAddress);
              dispatch(push({ pathname: '/confirmation' }));
            }}
          >
            Email Reset Instructions
          </Button>
        </>
      )}
    </>
  );
};

export default ForgotPasswordForm;
