/* eslint-disable linebreak-style */
import React from 'react';
import * as MUI from '../styles/MUI_Components';
import useClasses from '../styles/Classes';

const PaymentFailed = ({ cancel: _cancelModalCallback, openModal }) => {
  const closeModal = () => {
    _cancelModalCallback();
  };
  const classes = useClasses();
  return (
    <>
      <MUI.PaymentFailedModal
        open={openModal}
        aria-labelledby="add-paymentFailed-title"
        aria-describedby="add-paymentFailed-description"
      >
        <div className={`${classes.requestRefundModalBody} ${classes.global}`}>
          <div className={classes.modalTitle}>Payment Failed</div>

          <div className={classes.martop2}>
            <div>
              This payment was not successful, there are several reasons why a payment may
              fail, please review the list below for troubleshooting tips:
            </div>
            <div className={classes.paymentfailedReasons}>
              <ul>
                <li>Incorrect card number</li>
                <li>Incorrect account number</li>
                <li>Incorrect routing number</li>
                <li>Billing address mismatch</li>
                <li>Card expiration date has elapsed</li>
                <li>
                  The charge exceeded the maximum amount allowed for a single charge
                </li>
              </ul>
            </div>

            <div
              className={`${classes.singleButtonModalDiv} ${classes.martop4} ${classes.buttonDiv}`}
            >
              <MUI.ActionButton
                onClick={() => {
                  closeModal();
                }}
              >
                Close
              </MUI.ActionButton>
            </div>
          </div>
        </div>
      </MUI.PaymentFailedModal>
    </>
  );
};

export default PaymentFailed;
