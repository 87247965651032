/* eslint-disable linebreak-style */
import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import Card from '@material-ui/core/Card';
import Alert from '@material-ui/lab/Alert';
import CardContent from '@material-ui/core/CardContent';
import { Grid, Link } from '@material-ui/core';
import SignUpForm from './SignUpForm';
import * as EL from './StyledComponents';
import { SectionWrapper } from '../../shared/components/SharedComponentStyles';
import useClasses from './Classes';
import * as MUI from './MUI_Components';

const Signup = () => {
  const classes = useClasses();
  const dispatch = useDispatch();
  return (
    <SectionWrapper className={`${classes.global}`}>
      <Grid className={`${classes.signupModule}`}>
        <Grid className={`${classes.signupWrapper}`}>
          <EL.HeroContainer>
            <MUI.CardContainer>
              <Card>
                <CardContent>
                  <div className={`${classes.headerText}`}>Sign Up to Get Started</div>
                  <div className={`${classes.linkText}`}>
                    {' '}
                    <Link onClick={() => dispatch(push({ pathname: '/login' }))}>
                      <span className={`${classes.linkColor}`}>Return to Login</span>
                    </Link>
                  </div>
                  <div className={`${classes.infoText}`}>
                    Complete the form then click the &quot;Sign Up&quot; button below
                  </div>
                  <Alert severity="info">
                    Already a One Spot user? Use your One Spot account to login!
                  </Alert>
                  <Grid className={`${classes.content}`}>
                    <Grid className={`${classes.formInstructions}`}>
                      <Grid className={`${classes.formInstructionsUL}`}>
                        <EL.FormInstructionsLI>
                          Get started by providing some basic information about your
                          account.
                        </EL.FormInstructionsLI>
                        <EL.FormInstructionsLI>
                          Once you’re set up, you’ll be able to access various information
                          regarding your account. You can schedule a payment, update your
                          payment method.
                        </EL.FormInstructionsLI>
                        <EL.FormInstructionsLI>
                          We’re committed to providing your business with a simplified
                          approach to advertising across TV, streaming apps, digital,
                          social and search.
                        </EL.FormInstructionsLI>
                        <EL.FormInstructionsLI>
                          Whatever your needs, we can provide the smartest marketing
                          products and the right ideas to help your business be seen by
                          any consumer, anywhere.
                        </EL.FormInstructionsLI>
                      </Grid>
                    </Grid>
                    <SignUpForm />
                  </Grid>
                </CardContent>
              </Card>
            </MUI.CardContainer>
          </EL.HeroContainer>
        </Grid>
      </Grid>
    </SectionWrapper>
  );
};

export default Signup;
