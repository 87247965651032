/* eslint-disable import/no-cycle */
/* eslint-disable linebreak-style */
/* eslint-disable no-lonely-if */
import axios from 'axios';
import queryString from 'query-string';
import { push } from 'connected-react-router';

import * as types from '../actionTypes/authActionTypes';
import {
  removeTokens,
  saveToken,
  constants as tokenConstants,
} from '../../utils/AuthUtils';
import { authApiEndPoints, appApiEndPoints } from '../../constants/apiConstants';
import { getTokenUrl } from '../selectors/authSelectors';
import { getUserName } from '../selectors/UserSelectors';
import {
  GetUserInfoAction,
  resetUserDetails,
  userDoesNotExist,
} from './UserActionCreators';
import { UseAuthorization } from '../../utils/UseAuthorization';

/** Request Action type for fetching Login data */
export const fetchLoginRequest = () => ({
  type: types.FETCH_LOGIN_REQUEST,
});

/** Success Action type for fetching Login data */
export const fetchLoginSuccess = (data) => ({
  type: types.FETCH_LOGIN_SUCCESS,
  data,
});

/** Failure Action type for fetching Login data */
export const fetchLoginFailure = (data) => ({
  type: types.FETCH_LOGIN_FAILURE,
  data,
});

/** Status Action type for fetching Open ID Configuration data */
export const fetchOpenIDConfigDataRequest = (data) => ({
  type: types.FETCH_OPEN_ID_CONFIGURATION_DATA_REQUEST,
  data,
});

/** Success Action type for fetching Open ID Configuration data */
export const fetchOpenIDConfigDataSuccess = (data) => ({
  type: types.FETCH_OPEN_ID_CONFIGURATION_DATA_SUCCESS,
  data,
});

/** Failure Action type for fetching Open ID Configuration data */
export const fetchOpenIDConfigDataFailure = (data) => ({
  type: types.FETCH_OPEN_ID_CONFIGURATION_DATA_FAILURE,
  data,
});

/** Set Login Field Values */
export const setLoginFormData = (data) => ({
  type: types.SET_LOGIN_FORM_DATA,
  data,
});

export const loadingSignIn = (data) => ({
  type: types.LOADING_SIGN_IN,
  data,
});

/** Request Action type for fetching access token data */
export const fetchAccessTokenRequest = () => ({
  type: types.FETCH_ACCESS_TOKEN_REQUEST,
});

/** Success Action type for fetching access token data */
export const fetchAccessTokenSuccess = (data) => ({
  type: types.FETCH_ACCESS_TOKEN_SUCCESS,
  data,
});

/** Failure Action type for fetching access token data */
export const fetchAccessTokenFailure = (data) => ({
  type: types.FETCH_ACCESS_TOKEN_FAILURE,
  data,
});

/** Get Access Action */
export const getAccessToken = () => async (dispatch, getState) => {
  const loginTokenUrl = getTokenUrl(getState());
  if (!loginTokenUrl) return;
  const refreshToken = window.sessionStorage.getItem(tokenConstants.REFRESH_TOKEN);
  const data = {
    refresh_token: refreshToken,
    grant_type: 'refresh_token',
    client_id: `${process.env.REACT_APP_IDENTITY_CLIENT_ID}`,
  };

  const formData = queryString.stringify(data);
  await dispatch(fetchAccessTokenRequest());
  axios
    .post(loginTokenUrl, formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then(async (response) => {
      if (response.status === 200) {
        await dispatch(fetchAccessTokenSuccess(response.data));
        saveToken(response.data);
        if (Object.keys(getState().userReducer.userInfoDetails).length === 0) {
          await dispatch(GetUserInfoAction());
        }
      } else {
        await dispatch(fetchAccessTokenFailure());
      }
    })
    .catch(async (error) => {
      await dispatch(fetchAccessTokenFailure(error));
      dispatch(push({ pathname: '/logout' }));
      removeTokens();
      localStorage.removeItem('termsAndConditionsStatus');
      dispatch(resetUserDetails());
      throw error;
    });
};

/** Get OpenID Configuration data */
export const fetchOpenIDConfiguration = async (dispatch) => {
  dispatch(fetchOpenIDConfigDataRequest());
  axios
    .get(`${process.env.REACT_APP_IDENTITY_URL}/.well-known/openid-configuration`)
    .then((response) => {
      if (response.status === 200) {
        dispatch(fetchOpenIDConfigDataSuccess(response.data));

        // If user is not fully authenticated.
        if (
          window.sessionStorage.getItem(tokenConstants.REFRESH_TOKEN) &&
          !window.sessionStorage.getItem(tokenConstants.ACCESS_TOKEN)
        ) {
          dispatch(getAccessToken());
        }
      } else {
        // Else case handled here
      }
    })
    .catch((error) => {
      dispatch(fetchOpenIDConfigDataFailure());
      throw error;
    });
};

/** Success Action type for save invoice token */
export const insertUserSuccess = () => ({
  type: types.INSERT_USER_SUCCESS,
});

export const insertUserLoading = (data) => ({
  type: types.INSERT_USER_LOADING,
  data,
});

export const insertUserFailure = () => ({
  type: types.INSERT_USER_FAILURE,
});

export const signUp = () => ({
  type: types.SIGN_UP,
});

/** Insert User Information in Client Portal DB */
export const saveUser =
  (data, isExistingUser = false) =>
  async (dispatch) => {
    const userData = {
      firstName: data.firstName,
      lastName: data.lastName,
      accountExecutive: data.accountExecutive,
      companyName: data.companyName,
      agencyId: data.agencyId,
      PhoneNumber: data.PhoneNumber.replace(/\D/g, ''),
    };
    dispatch(insertUserLoading(true));
    axios
      .post(appApiEndPoints.createUser, userData, await UseAuthorization())
      .then((response) => {
        dispatch(insertUserLoading(false));
        if (response.data.status === 200) {
          if (isExistingUser) {
            dispatch(GetUserInfoAction('termsAndConditions'));
            dispatch(userDoesNotExist(false));
          } else {
            dispatch(GetUserInfoAction('termsAndConditions'));
            dispatch(insertUserSuccess());
          }
        } else {
          dispatch(insertUserFailure());
        }
      })
      .catch((error) => {
        dispatch(insertUserLoading(false));
        dispatch(insertUserFailure());
        throw error;
      });
  };

/** Login Action */
export const login = (data, loginFlow) => async (dispatch, getState) => {
  dispatch(loadingSignIn(true));
  const loginTokenUrl = getTokenUrl(getState());
  const loginData = {
    username: data.username,
    password: data.password,
    grant_type: 'password',
    client_id: `${process.env.REACT_APP_IDENTITY_CLIENT_ID}`,
  };
  const formData = queryString.stringify(loginData);
  dispatch(fetchLoginRequest());
  axios
    .post(loginTokenUrl, formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then((response) => {
      dispatch(loadingSignIn(false));
      if (response.status === 200) {
        dispatch(fetchLoginSuccess(response.data));
        saveToken(response.data);
        if (loginFlow === 'createUserFlow') {
          dispatch(saveUser(data));
        } else {
          dispatch(GetUserInfoAction('termsAndConditions'));
        }
      }
    })
    .catch((error) => {
      dispatch(loadingSignIn(false));
      dispatch(fetchLoginFailure(error));
    });
};

/** Request Action type for forgot password */
export const fetchForgotPasswordRequest = () => ({
  type: types.FETCH_FORGOT_PASSWORD_REQUEST,
});

/** Success Action type for forgot password */
export const fetchForgotPasswordSuccess = (data) => ({
  type: types.FETCH_FORGOT_PASSWORD_SUCCESS,
  data,
});

/** Failure Action type for forgot password */
export const fetchForgotPasswordFailure = (data) => ({
  type: types.FETCH_FORGOT_PASSWORD_FAILURE,
  data,
});

/** Request Action type for forgot username */
export const fetchForgotUsernameRequest = () => ({
  type: types.FETCH_FORGOT_USERNAME_REQUEST,
});

/** Success Action type for forgot username */
export const fetchForgotUsernameSuccess = (data) => ({
  type: types.FETCH_FORGOT_USERNAME_SUCCESS,
  data,
});

/** Failure Action type for forgot username */
export const fetchForgotUsernameFailure = (data) => ({
  type: types.FETCH_FORGOT_USERNAME_FAILURE,
  data,
});

/** Request Action type for reset password */
export const fetchResetPasswordRequest = () => ({
  type: types.FETCH_RESET_PASSWORD_REQUEST,
});

/** Success Action type for reset password */
export const fetchResetPasswordSuccess = (data) => ({
  type: types.FETCH_RESET_PASSWORD_SUCCESS,
  data,
});

/** Failure Action type for reset password */
export const fetchResetPasswordFailure = (data) => ({
  type: types.FETCH_RESET_PASSWORD_FAILURE,
  data,
});

/** Forgot Password Action */
export const ForgotPasswordAction = async (dispatch, username, emailAddress) => {
  const data = {
    username,
    emailAddress,
    validationUrl: `${process.env.REACT_APP_DEV_DOMAIN_URL}reset-password`,
    applicationName: `${process.env.REACT_APP_APPLICATION_NAME}`,
  };
  dispatch(fetchForgotPasswordRequest());
  axios
    .post(`${authApiEndPoints.postForgotPassword}`, data)
    .then((response) => {
      if (response.data.status === 200) {
        dispatch(fetchForgotPasswordSuccess(response.data));
      } else {
        // Else case handled here
      }
    })
    .catch((error) => {
      dispatch(fetchForgotPasswordFailure(error));
      throw error;
    });
};

/** Forgot Username Action */
export const ForgotUserNameAction = async (dispatch, email) => {
  dispatch(fetchForgotUsernameRequest());
  // eslint-disable-next-line max-len
  const URL = `${authApiEndPoints.forgotUsername}?email=${email}&loginUrl=${process.env.REACT_APP_DEV_DOMAIN_URL}login&applicationName=${process.env.REACT_APP_APPLICATION_NAME}`;
  axios
    .get(URL)
    .then((response) => {
      if (response.data.status === 200) {
        dispatch(fetchForgotUsernameSuccess(response.data));
      } else {
        // Else case handled here
      }
    })
    .catch((error) => {
      dispatch(fetchForgotUsernameFailure(error));
      throw error;
    });
};

/** Reset Password Action */
export const ResetPasswordAction = async (dispatch, data) => {
  dispatch(fetchResetPasswordRequest());
  const loginData = {
    username: data.username,
    password: data.newPassword,
  };
  axios
    .post(`${authApiEndPoints.resetPassword}`, data)
    .then((response) => {
      if (response.data.status === 200) {
        dispatch(fetchResetPasswordSuccess(response.data));
        dispatch(login(loginData));
      } else {
        if (response.data.status === 400) {
          dispatch(fetchResetPasswordFailure());
        }
        dispatch(fetchResetPasswordFailure());
      }
    })
    .catch((error) => {
      dispatch(fetchResetPasswordFailure(error));
      throw error;
    });
};

/** Request Action type for Create user */
export const createUserRequest = () => ({
  type: types.CREATE_USER_REQUEST,
});

/** Success Action type for create user */
export const createUserSuccess = (data) => ({
  type: types.CREATE_USER_SUCCESS,
  data,
});

/** Failure Action type for create user */
export const createUserFailure = (data) => ({
  type: types.CREATE_USER_FAILURE,
  data,
});

/** Create User Action */
export const CreateUserAction = (data) => async (dispatch) => {
  const userData = {
    username: data.username,
    password: data.password,
    emailAddress: data.emailAddress,
    validationUrl: `${process.env.REACT_APP_DEV_DOMAIN_URL}email-confirmation`,
    applicationName: `${process.env.REACT_APP_APPLICATION_NAME}`,
  };
  dispatch(createUserRequest());
  axios
    .post(`${authApiEndPoints.createUser}`, userData)
    .then((response) => {
      if (response.data.status === 200) {
        dispatch(createUserSuccess(response.data));
        dispatch(login(data, 'createUserFlow'));
      } else {
        if (response.data.status === 500) {
          dispatch(createUserFailure());
        }
      }
    })
    .catch((error) => {
      const errorResponse =
        error && error.response && error.response.data && error.response.data.data;
      dispatch(createUserFailure(errorResponse));
      throw error;
    });
};

export const linkAccountResponse = (data) => ({
  type: types.LINK_ACCOUNT_RESPONSE,
  data,
});

/** Reset Create user errors */
export const resetCreateUserErrors = () => ({
  type: types.RESET_CREATE_USER_ERRORS,
});

/** Action type for change password field values */
export const changePasswordFieldValues = (data) => ({
  type: types.CHANGE_PASSWORD_FIELD_VALUES,
  data,
});

/** Request Action type for change password */
export const changePasswordRequest = (data) => ({
  type: types.CHANGE_PASSWORD_REQUEST,
  data,
});

/** Success Action type for change password */
export const changePasswordSuccess = (data) => ({
  type: types.CHANGE_PASSWORD_SUCCESS,
  data,
});

/** Failure Action type for change password */
export const changePasswordFailure = (data) => ({
  type: types.CHANGE_PASSWORD_FAILURE,
  data,
});

/** Change Password Action */
export const ChangePasswordAction = () => async (dispatch, getState) => {
  const data = getState().authReducer.changePasswordDetails;
  const userName = getUserName(getState());
  const changePasswordPayload = {
    username: userName,
    currentPassword: data.oldPassword,
    newPassword: data.newPassword,
  };
  dispatch(changePasswordRequest());
  axios
    .post(`${authApiEndPoints.changePassword}`, changePasswordPayload)
    .then((response) => {
      if (response.data.status === 200) {
        dispatch(changePasswordSuccess(response.data));
      } else {
        dispatch(changePasswordFailure());
      }
    })
    .catch((error) => {
      dispatch(changePasswordFailure(error));
      throw error;
    });
};

/** Request Action type for verify email */
export const fetchVerifyEmailRequest = () => ({
  type: types.FETCH_VERIFY_EMAIL_REQUEST,
});

/** Status Action type for verify email */
export const fetchVerifyEmailStatus = (data) => ({
  type: types.FETCH_VERIFY_EMAIL_STATUS,
  data,
});

/** Confirm Email Action */
export const VerifyEmailAction = async (dispatch, data) => {
  await dispatch(fetchVerifyEmailRequest());
  axios
    .post(`${authApiEndPoints.verifyEmail}`, data)
    .then(async (response) => {
      if (response.data.status === 200) {
        await dispatch(fetchVerifyEmailStatus(true));
        await dispatch(GetUserInfoAction());
        dispatch(push({ pathname: '/' }));
      } else {
        await dispatch(fetchVerifyEmailStatus(false));
      }
    })
    .catch((error) => {
      dispatch(fetchVerifyEmailStatus(false));
      throw error;
    });
};

/** Request Action type for resending email confirmation */
export const fetchResendEmailConfirmationRequest = () => ({
  type: types.FETCH_RESEND_EMAIL_CONFIRMATION_REQUEST,
});

/** Status Action type for resending email confirmation */
export const fetchResendEmailConfirmationStatus = (data) => ({
  type: types.FETCH_RESEND_EMAIL_CONFIRMATION_STATUS,
  data,
});

/** Resend Email Confirmation Action */
export const ResendEmailConfirmationAction = () => async (dispatch, getState) => {
  const username = getUserName(getState());
  const data = {
    username,
    validationUrl: `${process.env.REACT_APP_DEV_DOMAIN_URL}email-confirmation`,
    applicationName: `${process.env.REACT_APP_APPLICATION_NAME}`,
  };

  dispatch(fetchResendEmailConfirmationRequest());
  axios
    .post(`${authApiEndPoints.resendEmailConfirmation}`, data)
    .then((response) => {
      if (response.data.status === 200) {
        dispatch(fetchResendEmailConfirmationStatus(true));
      } else {
        dispatch(fetchResendEmailConfirmationStatus(false));
      }
    })
    .catch(() => {
      dispatch(fetchResendEmailConfirmationStatus(false));
    });
};

/** API UnAuthorized Action */
export const unAuthorizedUser = () => ({
  type: types.HANDLE_UNAUTHORIZED_USER,
});

// Handle 401 Unauthorized Case
export const handleUnAuthorizedUser = async (dispatch) => {
  if (window.sessionStorage.getItem(tokenConstants.REFRESH_TOKEN)) {
    await dispatch(getAccessToken());
  } else {
    dispatch(push({ pathname: '/logout' }));
    dispatch(unAuthorizedUser());
    removeTokens();
  }
};

export const logoutUser = async (dispatch) => {
  dispatch(push({ pathname: '/logout' }));
  dispatch(unAuthorizedUser());
  removeTokens();
};
