/* eslint-disable linebreak-style */
import { createUseStyles } from 'react-jss';
import theme from '../../theme.module.scss';

const useClasses = createUseStyles({
  confirmationModule: {
    zIndex: '0',
    position: 'relative',
  },

  confirmationWrapper: {
    margin: ' 0 auto',
  },
  linkText: {
    color: `${theme.medBlue}`,
    fontFamily: 'Reach-Title-Bold, sans-serif',
    letterSpacing: '-0.08rem',
    textAlign: 'center',
    paddingTop: '5rem',
    fontSize: '1rem',

    '&:hover': {
      cursor: 'pointer',
      color: `${theme.deepBlue}`,
    },
  },
  headerText: {
    color: `${theme.deepBlue}`,
    fontFamily: 'Reach-Title-Bold, sans-serif',
    fontSize: '1.5rem',
    lineHeight: '2rem',
    letterSpacing: '-0.1rem',
    textAlign: 'center',
    paddingTop: '1rem',
  },
  linkColor: {
    color: `${theme.medBlue}`,
  },
});

export default useClasses;
