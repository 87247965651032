/* eslint-disable linebreak-style */
import React from 'react';
import AudienceTrakIMG from '../../../../../images/account-management/CTA_AudienceTrak.jpg';
import SpectrumReachIMG from '../../../../../images/account-management/CTA_SpectrumReach.jpg';
import BannerAdGif from '../../../../../images/marketing/SB_2151_Spectrum-Reach_Billing_287x150_ANIMATED.gif';
import useClasses from '../styles/Classes';

const ManagePaymentsLeftPanel = () => {
  const classes = useClasses();

  const items = [
    {
      href: 'https://spectrumreach.brandcdn.com',
      src: AudienceTrakIMG,
      alt: 'Audience Trak',
    },
    {
      href: 'https://www.spectrumreach.com/spectrum-reach-advertising',
      src: SpectrumReachIMG,
      alt: 'Spectrum Reach',
    },
    {
      href: 'https://business.spectrum.com/lp/bundles?p1=8552200833',
      src: BannerAdGif,
      alt: 'Spectrum Business',
    },
  ];

  return (
    <ul className={`${classes.leftOtherServicesUL}`}>
      <li className={classes.rightQuickLinksHeaderLI}>Other Services</li>
      {items &&
        items.map((item) => (
          <li className={classes.leftOtherServicesLI} key={item.href}>
            <a href={item.href} target="_blank">
              <img alt={item.alt} src={item.src} />
            </a>
          </li>
        ))}
    </ul>
  );
};

export default ManagePaymentsLeftPanel;
