/* eslint-disable linebreak-style */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CreateUserAction } from '../../redux/actionCreators/authActionCreators';
import { getCreateUserErrorCode } from '../../redux/selectors/authSelectors';
import {
  isPasswordValid,
  isValidEmailField,
  isPhoneNumberValid,
} from '../../utils/Validations';
import { TextBox, TextBoxFirst } from '../../shared/components/SharedComponentStyles';
// import * as EL from './StyledComponents';
import useClasses from './Classes';
import * as MUI from './MUI_Components';
import { phoneNumberFormatter } from '../../utils/PhoneNumberFormatter';

const SignUpForm = () => {
  const classes = useClasses();

  const dispatch = useDispatch();

  const {
    createUserErrors,
    isLoginFailed,
    isCreateUserPending,
    isLoginUserPending,
    isInsertUserPending,
    isUserInsertFailed,
  } = useSelector((state) => ({
    createUserErrors: state.authReducer.createUserErrors,
    isLoginFailed: state.authReducer.isLoginFailed,
    isCreateUserPending: state.authReducer.isCreateUserPending,
    isLoginUserPending: state.authReducer.isLoginUserPending,
    isInsertUserPending: state.authReducer.isInsertUserPending,
    isUserInsertFailed: state.authReducer.isUserInsertFailed,
  }));

  const [createUserData, setCreateUser] = useState({
    password: '',
    confirmPassword: '',
    emailAddress: '',
    confirmEmailAddress: '',
    username: '',
    firstName: '',
    lastName: '',
    accountExecutive: '',
    companyName: '',
    agencyId: '',
    PhoneNumber: '',
  });

  const handleChangeField = (event) => {
    const { value, id } = event && event.target;
    if (id === 'PhoneNumber') {
      setCreateUser({ ...createUserData, [id]: phoneNumberFormatter(value) });
    } else {
      setCreateUser({ ...createUserData, [id]: value });
    }
  };

  const {
    password,
    confirmPassword,
    emailAddress,
    confirmEmailAddress,
    username,
    firstName,
    lastName,
    accountExecutive,
    companyName,
    agencyId,
    PhoneNumber,
  } = createUserData;

  const saveNewUser = async () => {
    const data = {
      username,
      password,
      emailAddress,
      firstName,
      lastName,
      accountExecutive,
      companyName,
      agencyId,
      PhoneNumber,
    };
    await dispatch(CreateUserAction(data));
    setCreateUser({
      ...createUserData,
      password: '',
      confirmPassword: '',
    });
  };

  const getPasswordError = () => (
    <>
      <div className={`${classes.passwordError}`}>
        * Password must be 8 characters or more.
      </div>
      <div className={`${classes.passwordError}`}>
        {' '}
        * Password must contain 3 character sets: Uppercase, Lowercase, Numeric &
        Special..
      </div>
    </>
  );
  const isDisableSignUp =
    isCreateUserPending || isLoginUserPending || isInsertUserPending;

  return (
    <div>
      <>
        {createUserErrors &&
          createUserErrors.length > 0 &&
          createUserErrors.map((errors) => (
            <div
              className={`${classes.infoTextError}`}
              data-testid="error-message"
              key={`create_${errors}`}
            >
              {getCreateUserErrorCode(parseFloat(errors))}
            </div>
          ))}
        {(isLoginFailed || isUserInsertFailed) && (
          <div className={`${classes.infoTextError}`}>
            An error occurred while creating your account. Please try again later.
          </div>
        )}

        <form noValidate autoComplete="off">
          <small className={classes.requiredLink}>* denotes required field</small>
          <TextBoxFirst
            fieldId="firstName"
            style={{ margin: '2px 8px', paddingTop: '0rem !important' }}
            placeholder="First Name*"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            value={firstName}
            onChange={handleChangeField}
            disabled={isDisableSignUp}
          />
          <TextBox
            fieldId="lastName"
            style={{ margin: '2px 8px' }}
            placeholder="Last Name*"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            value={lastName}
            onChange={handleChangeField}
            disabled={isDisableSignUp}
          />
          <TextBox
            fieldId="emailAddress"
            style={{ margin: '2px 8px' }}
            placeholder="Email Address*"
            fullWidth
            margin="normal"
            onPaste={(e) => {
              e.preventDefault();
            }}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ 'data-testid': 'emailAddress' }}
            fieldError={emailAddress && !isValidEmailField(emailAddress)}
            fieldErrorMsg="Please enter a valid email address."
            variant="outlined"
            value={emailAddress}
            onChange={handleChangeField}
            disabled={isDisableSignUp}
          />
          <TextBox
            fieldId="confirmEmailAddress"
            style={{ margin: '2px 8px' }}
            placeholder="Confirm Email Address*"
            fullWidth
            margin="normal"
            onPaste={(e) => {
              e.preventDefault();
            }}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            fieldError={emailAddress !== confirmEmailAddress}
            fieldErrorMsg="Email Address does not match."
            value={confirmEmailAddress}
            onChange={handleChangeField}
            disabled={isDisableSignUp}
          />
          <TextBox
            fieldId="username"
            style={{ margin: '2px 8px' }}
            placeholder="Username*"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            autoComplete="new-username"
            onChange={handleChangeField}
            value={username}
            disabled={isDisableSignUp}
          />
          <TextBox
            fieldId="accountExecutive"
            style={{ margin: '2px 8px' }}
            placeholder="Account Executive"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            value={accountExecutive}
            onChange={handleChangeField}
            disabled={isDisableSignUp}
          />
          <TextBox
            fieldId="companyName"
            style={{ margin: '2px 8px' }}
            placeholder="Company name*"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            value={companyName}
            onChange={handleChangeField}
            disabled={isDisableSignUp}
          />
          <TextBox
            fieldId="agencyId"
            style={{ margin: '2px 8px' }}
            placeholder="Client or agency ID"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            value={agencyId}
            onChange={handleChangeField}
            disabled={isDisableSignUp}
          />
          <TextBox
            fieldId="PhoneNumber"
            style={{ margin: '2px 8px' }}
            placeholder="Phone Number"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            value={PhoneNumber}
            onChange={handleChangeField}
            disabled={isDisableSignUp}
          />
          <TextBox
            fieldId="password"
            style={{ margin: '2px 8px' }}
            placeholder="Password"
            type="password"
            fieldError={password && !isPasswordValid(password)}
            fieldErrorMsg={getPasswordError()}
            fullWidth
            inputProps={{ 'data-testid': 'password' }}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            onChange={handleChangeField}
            value={password}
            disabled={isDisableSignUp}
          />
          <TextBox
            fieldId="confirmPassword"
            style={{ margin: '2px 8px' }}
            type="password"
            placeholder="Confirm Password"
            fieldError={password !== confirmPassword}
            fieldErrorMsg="Passwords do not match."
            fullWidth
            inputProps={{ 'data-testid': 'confirm-password' }}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            onChange={handleChangeField}
            value={confirmPassword}
            disabled={isDisableSignUp}
          />
        </form>
        <MUI.Submit
          style={{ margin: '12px 8px 0px 8px' }}
          variant="contained"
          color="primary"
          disableElevation
          disabled={
            !password ||
            !confirmPassword ||
            !isPasswordValid(password) ||
            !isValidEmailField(emailAddress) ||
            password !== confirmPassword ||
            emailAddress !== confirmEmailAddress ||
            !firstName ||
            !lastName ||
            !companyName ||
            (PhoneNumber && !isPhoneNumberValid(PhoneNumber))
          }
          onClick={saveNewUser}
        >
          {isDisableSignUp ? 'Creating User...' : 'Sign Up'}
          {isDisableSignUp && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </MUI.Submit>
      </>
    </div>
  );
};

export default SignUpForm;
