/* eslint-disable linebreak-style */
import creditCardType from 'credit-card-type';

// Get Card Brand
export const getCardBrand = (cardNum) => {
  const cardBrandDetails = creditCardType(cardNum);
  const cardBrandType =
    cardBrandDetails && cardBrandDetails[0] && cardBrandDetails[0].niceType;
  let cardBrandCode = '';
  switch (cardBrandType) {
    case 'Visa':
      cardBrandCode = 'VI';
      break;
    case 'Mastercard':
      cardBrandCode = 'MC';
      break;
    case 'American Express':
      cardBrandCode = 'AX';
      break;
    case 'ACH':
      cardBrandCode = 'AC';
      break;
    case 'Diners Club':
      cardBrandCode = 'DC';
      break;
    case 'Discover':
      cardBrandCode = 'DI';
      break;
    case 'Debit':
      cardBrandCode = 'DP';
      break;
    default:
      cardBrandCode = 'VI';
      break;
  }
  return cardBrandCode;
};

// Determine if account number is valid
export const isAccountNumberValid = (accountNum) => {
  const accountNumberValidation = /^[0-9]{2,17}$/;
  if (accountNum && accountNum.match(accountNumberValidation)) {
    return true;
  }
  return false;
};

// Determine if routing number is valid
export const isRoutingNumberValid = (routingNum) => {
  if (routingNum.length !== 9) {
    return false;
  }

  // http://en.wikipedia.org/wiki/Routing_transit_number#MICR_Routing_number_format
  const checksumTotal =
    7 *
      (parseInt(routingNum.charAt(0), 10) +
        parseInt(routingNum.charAt(3), 10) +
        parseInt(routingNum.charAt(6), 10)) +
    3 *
      (parseInt(routingNum.charAt(1), 10) +
        parseInt(routingNum.charAt(4), 10) +
        parseInt(routingNum.charAt(7), 10)) +
    9 *
      (parseInt(routingNum.charAt(2), 10) +
        parseInt(routingNum.charAt(5), 10) +
        parseInt(routingNum.charAt(8), 10));

  const checksumMod = checksumTotal % 10;
  if (checksumMod !== 0) {
    return false;
  }
  return true;
};

// Get Full credit card brand name
export const getFullBrandName = (cardNum) => {
  const cardBrandDetails = creditCardType(cardNum);
  const cardBrandType =
    cardBrandDetails && cardBrandDetails[0] && cardBrandDetails[0].niceType;
  return cardBrandType;
};

/** Sort by number */
export const sortByNumber = (numArray, key) => {
  const sortedArray = numArray.sort((a, b) => parseFloat(b[key]) - parseFloat(a[key]));
  return sortedArray;
};
