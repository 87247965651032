/* eslint-disable linebreak-style */
// Define Authorized Route Functions here
import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';

import { push } from 'connected-react-router';
import { constants as tokenConstants, removeTokens } from '../utils/AuthUtils';
import { getAccessToken } from '../redux/actionCreators/authActionCreators';
import { PageLoader } from '../shared/components/Loader';

/** Determine If Route is Authenticated */
const AuthorizeRoute = (props) => {
  const {
    component: Component,
    isOpenIdConfigDataLoading,
    isAccessTokenLoading,
    isUserExist,
    ...rest
  } = props;
  const isAuthenticated = window.sessionStorage.getItem(tokenConstants.ACCESS_TOKEN);
  const isAbleToAuthenticate = window.sessionStorage.getItem(
    tokenConstants.REFRESH_TOKEN
  );
  const termsAndConditionStatus = localStorage.getItem('termsAndConditionsStatus');
  const checkUser = () => props.dispatch(getAccessToken());

  return (
    <Route
      {...rest}
      render={() => {
        // User is refreshing & logging in.
        if (isOpenIdConfigDataLoading || isAccessTokenLoading) {
          return <PageLoader />;
        }

        // User is a logged in, valid user.
        if (
          isAuthenticated &&
          termsAndConditionStatus !== null &&
          termsAndConditionStatus === 'true'
        ) {
          return <Component {...props} />;
        }
        // User is logged in, but not fully validated.
        if (
          isAuthenticated &&
          termsAndConditionStatus !== null &&
          termsAndConditionStatus === 'false'
        ) {
          props.dispatch(push({ pathname: '/terms-and-conditions' }));
          return null;
        }

        // User is not logged in, but has a refresh token.
        if (
          !isAuthenticated &&
          isAbleToAuthenticate &&
          !isOpenIdConfigDataLoading &&
          !isAccessTokenLoading
        ) {
          checkUser();
          return <Component {...props} />;
        }

        // User details exist in Identity server but not in CLient portal DB
        if (isAuthenticated && termsAndConditionStatus == null && !isUserExist) {
          return <Component {...props} />;
        }

        // User is not logged in.
        removeTokens();
        props.dispatch(push({ pathname: '/login' }));
        return null;
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  isOpenIdConfigDataLoading: state.userReducer.isOpenIdConfigDataLoading,
  isAccessTokenLoading: state.userReducer.isAccessTokenLoading,
  userInfoDetails: state.userReducer.userInfoDetails,
  isUserExist: state.userReducer.isUserExist,
});
export default connect(mapStateToProps, null)(AuthorizeRoute);
