/* eslint-disable linebreak-style */
import React, { useEffect } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import PaymentAmount from './PaymentAmount';
import useClasses from '../styles/Classes';
import PaymentType from './PaymentType';
import PaymentMethod from './PaymentMethod';
import PaymentComplete from './PaymentComplete';
import PaymentConfirmation from './PaymentConfirmation';
import * as Actions from '../../../../../redux/actionCreators/PaymentActionCreators';

const Payment = () => {
  const classes = useClasses();
  const dispatch = useDispatch();

  const PaymentTypes = [
    {
      name: 'Payment Type',
      component: <PaymentType />,
    },
    {
      name: 'Payment Amount',
      component: <PaymentAmount />,
    },
    {
      name: 'Payment Method',
      component: <PaymentMethod />,
    },
    {
      name: 'Review and Complete Payment',
      component: <PaymentComplete />,
    },
    {
      name: 'Payment Confirmation',
      component: <PaymentConfirmation />,
    },
  ];

  const { makePaymentStepValue } = useSelector((state) => ({
    makePaymentStepValue: state.paymentReducer.makePaymentStep,
  }));

  useEffect(
    () => () => {
      dispatch(Actions.resetPaymentDetails());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Grid className={classes.container}>
      <Grid className={classes.subSectionHeader}>Make A Payment</Grid>
      <Grid className={classes.moduleContent}>
        <Grid className={classes.makeAPaymentModule}>
          {PaymentTypes.map((type, index) => (
            <Grid key={type.name} className={classes.paymentRow}>
              <span
                className={`${classes.paymentStepLabel} ${
                  makePaymentStepValue === index + 1 ? classes.activepaymentStep : ''
                }`}
              >
                {`${index + 1}. ${type.name}`}
                {index === 1 ? (
                  <Tooltip
                    disableFocusListener
                    title="Press the tab key to move to the next box and create a new row"
                  >
                    <HelpOutlineIcon
                      style={{
                        fontSize: '.75rem',
                        color: '#20699f',
                        position: 'relative',
                        top: '2px',
                        left: '3px',
                      }}
                    />
                  </Tooltip>
                ) : (
                  <></>
                )}
              </span>
              {makePaymentStepValue === index + 1 && type.component}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Payment;
