/* eslint-disable linebreak-style */
/* eslint-disable max-len */
/* eslint-disable react/display-name */
import React from 'react';
import moment from 'moment';
import { SpectrumReachLogoAlt } from '@spectrum-reach/mui-theme';
import { Footer } from '@spectrum-reach/external-theme';
import { Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

export const PrintComponent = React.forwardRef((props, ref) => {
  const { classes, userInfo, transactionHistoryData } = props;
  return userInfo && transactionHistoryData ? (
    <div style={{ display: 'none' }}>
      <div className={classes.printContainer} ref={ref}>
        <Typography variant="subtitle1" component="h2" className={classes.printPageTitle}>
          Spectrum Reach Payment Status
        </Typography>
        <div className={classes.printLogo}>
          <div className={classes.printLogoImg}>
            <SpectrumReachLogoAlt width="125" height="85" v1="1200" v2="250" />
          </div>
        </div>
        <div>
          <div>Spectrum Reach</div>
          <div>877-286-7117</div>
          <div>reachbusops@charter.com</div>
        </div>
        <div className={classes.printInfoContainer}>
          <div>
            <div className={classes.paddingDontSeeAcct}>
              <b>Billing Information</b>
            </div>
            <div>
              <div>
                <b>Agency : </b>
                {userInfo.companyName}
              </div>
              <div>
                <b>Agency ID : </b>
                {userInfo.agencyId}
              </div>
              <div>
                <b>Name : </b>
                {userInfo.firstName} {userInfo.lastName}
              </div>
              <div>
                <b>Email : </b>
                {userInfo.email}
              </div>
              <div>
                <b>Phone : </b>
                {userInfo.phoneNumber}
              </div>
            </div>
          </div>
          <div>
            <div className={classes.paddingDontSeeAcct}>
              <b>Payment Information</b>
            </div>
            <div>
              <div>
                <b>Date : </b>
                {moment(transactionHistoryData.paymentDate).format('MMM, Do YYYY')}
              </div>
              <div>
                <b>Transaction ID : </b>
                {transactionHistoryData.id}
              </div>
              <div>
                <b>Payment Method : </b>
                {transactionHistoryData.type}
              </div>
              <div>
                <b>Payment Status : </b>
                {transactionHistoryData.status}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.upperPad}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Type</TableCell>
                  <TableCell align="center">ID</TableCell>
                  <TableCell align="center">Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactionHistoryData.transactionHistoryDetails &&
                  transactionHistoryData.transactionHistoryDetails.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell align="center">
                        {row.orderType === 'Order' ? 'Order' : 'Invoice'}
                      </TableCell>
                      <TableCell align="center">{row.orderId}</TableCell>
                      <TableCell align="center"> {`$ ${row.amount}`}</TableCell>
                    </TableRow>
                  ))}
                <TableRow>
                  <TableCell colSpan={2} align="right">
                    Total
                  </TableCell>
                  <TableCell align="center"> {transactionHistoryData.amount}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className={classes.printFooter}>
          <Footer />
        </div>
      </div>
    </div>
  ) : null;
});
