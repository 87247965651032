/* eslint-disable linebreak-style */
/* eslint-disable no-return-assign */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Transition } from 'react-transition-group';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Grid } from '@material-ui/core';
import * as MUI from '../styles/MUI_Components';
import useClasses from '../styles/Classes';
import * as Actions from '../../../../../redux/actionCreators/PaymentActionCreators';
import { ComponentLoader } from '../../../../../shared/components/Loader';
import { formatDateMMDDYYYY } from '../../../../../utils/DateUtils';
import ValidationError from '../../../../../shared/components/ValidationError';

const duration = 200;

const RecurringPaymentsHistory = () => {
  const {
    enrollmentData,
    isAutoPayFailed,
    autoPayLoading,
    isDeleteRecurringPaymentFailed,
    editedEnrollmentData,
  } = useSelector((state) => ({
    enrollmentData: state && state.paymentReducer.enrollmentData,
    isAutoPayFailed: state.paymentReducer.isAutoPayFailed,
    autoPayLoading: state.paymentReducer.autoPayLoading,
    isDeleteRecurringPaymentFailed: state.paymentReducer.isDeleteRecurringPaymentFailed,
    editedEnrollmentData: state.paymentReducer.editedEnrollmentData,
  }));

  const classes = useClasses();
  const dispatch = useDispatch();
  const [startAnimation, setStartAnimation] = useState(false);

  const toggleActiveItem = (val) => {
    setStartAnimation(false);
    if (val === editedEnrollmentData.activeItem) {
      setStartAnimation(false);
      dispatch(Actions.updateEnrollment({}));
    } else {
      dispatch(Actions.updateEnrollment({ activeItem: val }));
      setTimeout(() => setStartAnimation(true), 300);
    }
  };

  const handleDeleteRecurringPayment = (id) => {
    dispatch(Actions.EndEnrollmentAction(id));
  };

  return (
    <Grid className={classes.recurringPaymentHistoryContainer}>
      <Grid className={classes.recurringPaymentHistorySubSectionHeader}>
        Scheduled Recurring Payments
      </Grid>
      <Grid className={classes.moduleContent}>
        {isAutoPayFailed ? (
          <div className={classes.infoTextFailure}>
            Failed to Load Scheduled Recurring Payments. Please contact support.
          </div>
        ) : (
          <Grid className={classes.moduleContent}>
            {autoPayLoading ? (
              <ComponentLoader />
            ) : (
              <MUI.TableContainerCustom component={Paper} elevation={0}>
                <MUI.TableCustom
                  data-testid="recurring-payment-history-table"
                  className={classes.table}
                  aria-label="customized table"
                >
                  {enrollmentData.length > 0 && (
                    <TableBody>
                      {enrollmentData.map((row) => (
                        <MUI.StyledTableRow
                          key={row.a}
                          className={classes.transHistTableRow}
                        >
                          <div className={classes.tableRowLabelRow}>
                            <span className={classes.tableInlineValueStatus}>&nbsp;</span>
                            <span className={classes.tableInlineLabel}>Start Date</span>
                            <span className={classes.tableInlineLabel}>End Date</span>
                            <span className={classes.tableInlineLabel}>Amount</span>
                            <span
                              className={`${classes.tableInlineLabel} ${classes.statusCell}`}
                            >
                              Frequency
                            </span>
                            <span className={classes.tableInlineLabel}>&nbsp;</span>
                          </div>
                          <div className={classes.tableRowValueRow}>
                            <span className={classes.tableInlineValueStatus}>
                              {row.nextChargeDate > row.endDate ? (
                                <WarningIcon
                                  role="link"
                                  tabIndex={0}
                                  onKeyPress={() => ''}
                                  className={classes.recurringPaymentWarningIcon}
                                  data-testid="recurring-history-warning-status"
                                />
                              ) : (
                                <CheckCircleIcon
                                  role="link"
                                  tabIndex={0}
                                  className={classes.recurringPaymentSuccessIcon}
                                  onKeyPress={() => ''}
                                  data-testid="recurring-history-success-status"
                                />
                              )}
                            </span>
                            <span className={classes.tableInlineValue}>
                              {row.startDate && formatDateMMDDYYYY(row.startDate)}
                            </span>
                            <span className={classes.tableInlineValue}>
                              {row.endDate && formatDateMMDDYYYY(row.endDate)}
                            </span>
                            <span className={classes.tableInlineValue}>
                              ${row.amount.toFixed(2)}
                            </span>
                            <span
                              className={`${classes.tableInlineValue} ${classes.statusCell}`}
                              role="link"
                              tabIndex={0}
                              onKeyPress={() => ''}
                            >
                              {row.paymentStatus
                                .match(/([A-Z]?[^A-Z]*)/g)
                                .slice(0, -1)
                                .join(' ')}
                            </span>
                            <span className={classes.tableInlinePlus}>
                              {editedEnrollmentData.recurringPaymentId ===
                              row.recurringPaymentId ? (
                                <RemoveCircleOutlineIcon
                                  className={classes.tableMoreDetailsActive}
                                  role="link"
                                  tabIndex={0}
                                  onKeyPress={() => ''}
                                  onClick={() => {
                                    toggleActiveItem(row.recurringPaymentId);
                                  }}
                                />
                              ) : (
                                <AddCircleOutlineIcon
                                  role="link"
                                  tabIndex={0}
                                  onKeyPress={() => ''}
                                  onClick={() => {
                                    toggleActiveItem(row.recurringPaymentId);
                                    dispatch(
                                      Actions.updateEnrollment({
                                        ...row,
                                        activeItem: row.recurringPaymentId,
                                      })
                                    );
                                  }}
                                  data-testid="expand-history-row"
                                />
                              )}
                            </span>
                          </div>

                          {editedEnrollmentData.activeItem === row.recurringPaymentId ? (
                            <Transition in={startAnimation} timeout={duration}>
                              {() => (
                                <>
                                  <div
                                    className={
                                      classes.transHistoryMultipleInvoicesListContainer
                                    }
                                  >
                                    <div className={classes.transInvoiceWrapper}>
                                      <div className={classes.transIDdiv}>
                                        <div className={classes.invoiceTableInlineLabel}>
                                          Next Payment Date
                                        </div>
                                        <div className={classes.tableInlineValue}>
                                          {row.nextChargeDate > row.endDate
                                            ? 'N/A'
                                            : row.nextChargeDate &&
                                              formatDateMMDDYYYY(row.nextChargeDate)}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={`${classes.transInvoiceWrapper} ${classes.marleft1}`}
                                    >
                                      <div className={classes.transIDdiv}>
                                        <div className={classes.invoiceTableInlineLabel}>
                                          Payment Method
                                        </div>
                                        <div className={classes.tableInlineValue}>
                                          {row.paymentCard}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={`${classes.transInvoiceWrapper} ${classes.marleft1}`}
                                    >
                                      <div className={classes.transIDdiv}>
                                        <div className={classes.invoiceTableInlineLabel}>
                                          Day of {row.dayOfMonth ? 'Month' : 'Week'}
                                        </div>
                                        <div className={classes.tableInlineValue}>
                                          {row.dayOfMonth || row.dayOfWeek}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      classes.transHistoryMultipleInvoicesListContainer
                                    }
                                  >
                                    <div className={classes.transInvoiceWrapper}>
                                      <div className={classes.transIDdiv}>
                                        <div className={classes.invoiceTableInlineLabel}>
                                          Order Number
                                        </div>
                                        <div className={classes.tableInlineValue}>
                                          {row.orderNumber}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {row.description && (
                                    <div
                                      className={
                                        classes.transHistoryMultipleInvoicesListContainer
                                      }
                                    >
                                      <div className={classes.transInvoiceWrapper}>
                                        <div className={classes.transIDdiv}>
                                          <div
                                            className={classes.invoiceTableInlineLabel}
                                          >
                                            Description
                                          </div>
                                          <div className={classes.tableInlineValue}>
                                            {row.description}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  <div
                                    className={`${classes.flex} ${classes.marbot1} ${classes.historyActionsRow} ${classes.yellow}`}
                                  >
                                    {row.nextChargeDate > row.endDate
                                      ? `Recurring Payment is expired, please edit this
                                      recurring payment to continue processing for invoices.`
                                      : ''}
                                  </div>
                                  <div
                                    className={`${classes.flex} ${classes.marbot1} ${classes.historyActionsRow}`}
                                  >
                                    <div
                                      className={`${classes.buttonDiv} ${classes.buttonDivTransHistoryBtns}`}
                                    >
                                      <MUI.ActionButtonInverted
                                        data-testid="delete-recurring-button"
                                        className={`${classes.invoiceListBtns}`}
                                        onClick={() =>
                                          handleDeleteRecurringPayment(
                                            row.recurringPaymentId
                                          )
                                        }
                                      >
                                        Delete
                                      </MUI.ActionButtonInverted>
                                    </div>
                                  </div>
                                  {isDeleteRecurringPaymentFailed && (
                                    <ValidationError
                                      validationErrorData={isDeleteRecurringPaymentFailed}
                                    />
                                  )}
                                </>
                              )}
                            </Transition>
                          ) : (
                            <></>
                          )}
                        </MUI.StyledTableRow>
                      ))}
                    </TableBody>
                  )}
                </MUI.TableCustom>
              </MUI.TableContainerCustom>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default RecurringPaymentsHistory;
