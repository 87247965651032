/* eslint-disable linebreak-style */
import React from 'react';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Grid } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import queryString from 'query-string';
import {
  HeroContainer,
  CardContainer,
  SectionWrapper,
} from '../../shared/components/SharedComponentStyles';
import useClasses from './ConfirmationClasses';

const Confirmation = () => {
  const classes = useClasses();
  const dispatch = useDispatch();
  const parsed = queryString.parse(window.location.search);
  return (
    <SectionWrapper>
      <Grid className={`${classes.confirmationModule}`}>
        <Grid className={`${classes.confirmationWrapper}`}>
          <HeroContainer>
            <CardContainer>
              <Card>
                <CardContent>
                  <div className={`${classes.headerText}`}>
                    {parsed.isForgotUsername
                      ? 'If the email address is registered, you will receive an email with Username information.'
                      : 'If your username/email exists, a link will be sent to your registered email address to reset your password.'}
                  </div>
                  <div className={`${classes.linkText}`}>
                    {' '}
                    <Link onClick={() => dispatch(push({ pathname: '/login' }))}>
                      <span className={`${classes.linkColor}`}>Return to Login</span>
                    </Link>
                  </div>
                </CardContent>
              </Card>
            </CardContainer>
          </HeroContainer>
        </Grid>
      </Grid>
    </SectionWrapper>
  );
};

export default Confirmation;
