/* eslint-disable linebreak-style */
/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import theme from '../../../../../theme.module.scss';
import * as MUI from '../styles/MUI_Components';
import useClasses from '../styles/Classes';
import customStyles from '../styles/CustomStyles';
import * as Actions from '../../../../../redux/actionCreators/PaymentActionCreators';
import * as Selectors from '../../../../../redux/selectors/PaymentSelectors';
import { ComponentLoader } from '../../../../../shared/components/Loader';
import ManagePaymentMethod from './ManagePaymentsModel';
import { sortByNumber } from '../../../../../utils/PaymentUtils';
import { formatDateMMDDYYYY } from '../../../../../utils/DateUtils';

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersBasePicker: {
      pickerView: {},
    },
    MuiPickersDay: {
      today: {
        color: theme.medBlue,
      },
      daySelected: {
        backgroundColor: theme.medBlue,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '0px',
      },
      input: {
        padding: '16px 14px',
      },
      notchedOutline: {
        borderColor: theme.deepBlue,
      },
    },
    MuiInputBase: {
      root: {
        color: theme.medBlue,
      },
    },
    MuiSvgIcon: {
      root: {
        color: theme.medBlue,
      },
    },
  },
});

const PaymentMethod = () => {
  const paymentDate = 'paymentDate';
  const dispatch = useDispatch();
  const classes = useClasses();
  useEffect(() => {
    Actions.getPaymentMethods(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const {
    paymentMethods,
    paymentDetails,
    paymentMethodLoading,
    isAddCardLoading,
    isAddACHLoading,
    showManagePaymentModal,
    oneTimePaymentDetails,
  } = useSelector((state) => ({
    paymentTokenId: state.paymentReducer.paymentTokenId,
    makePaymentStepValue: state.paymentReducer.makePaymentStep,
    paymentMethods: state.paymentReducer.paymentMethods,
    paymentDetails: Selectors.getPaymentDetailsState(state),
    paymentMethodLoading: state.paymentReducer.paymentMethodLoading,
    isAddCardLoading: state.paymentReducer.isAddCardLoading,
    isAddACHLoading: state.paymentReducer.isAddACHLoading,
    showManagePaymentModal: state.paymentReducer.showManagePaymentModal,
    oneTimePaymentDetails: state.paymentReducer.oneTimePaymentDetails,
  }));

  const hasOneTimePaymentDetails = oneTimePaymentDetails.length;

  const payFromPaymentMethods = hasOneTimePaymentDetails
    ? [...oneTimePaymentDetails, ...paymentMethods]
    : paymentMethods;

  const sortedPayMethods = sortByNumber(payFromPaymentMethods, 'paymentTokenId');
  const getValue = (id) => {
    const value =
      paymentDetails && paymentDetails[id]
        ? paymentDetails[id]
        : sortedPayMethods?.[0]
        ? sortedPayMethods[0].paymentTokenId
        : '';
    return value;
  };

  const handleOnChange = (e) => {
    const { value, name } = e.target;
    dispatch(Actions.changeConfigurationFieldValue({ value, id: name }));
  };
  const handleDate = (e) => {
    dispatch(Actions.changeConfigurationFieldValue({ value: e, id: paymentDate }));
    const today = new Date();
    const isTodaysDate =
      (e && formatDateMMDDYYYY(e)) === (today && formatDateMMDDYYYY(today));
    const dateTypeValue =
      e === getValue(paymentDate) || isTodaysDate ? 'PayNow' : 'CustomDate';
    dispatch(
      Actions.changeConfigurationFieldValue({
        value: dateTypeValue,
        id: 'paymentDateType',
      })
    );
  };

  const handleNextAction = () => {
    const value = getValue('paymentTokenId');
    paymentDetails.paymentTokenId === null &&
      dispatch(Actions.changeConfigurationFieldValue({ value, id: 'paymentTokenId' }));
  };

  const getDisabledStatusForPaymentDate = () => {
    const isIdMatch = oneTimePaymentDetails.some(
      (data) => data.paymentTokenId === paymentDetails.paymentTokenId
    );
    return isIdMatch;
  };
  return (
    <>
      {paymentMethodLoading || isAddACHLoading || isAddCardLoading ? (
        <ComponentLoader />
      ) : (
        <>
          <Grid lg={12} justify="space-between" alignItems="center" container>
            <Typography
              variant=""
              className={`${classes.selectLabelLineHeight} ${classes.medBlue} ${classes.boldFont} ${classes.paddingDontSeeAcct}`}
            >
              Pay from
            </Typography>
            <Grid className={`${classes.flexColumnPad}`}>
              <MUI.SelectPayFromCustom
                variant="outlined"
                className={classes.formControl}
                labelId="account-label"
                name="paymentTokenId"
                value={getValue('paymentTokenId')}
                onChange={handleOnChange}
                inputProps={{
                  style: {
                    textAlign: 'center',
                  },
                }}
              >
                {payFromPaymentMethods &&
                  payFromPaymentMethods.map((item) => (
                    <MUI.MenuItemCustom
                      key={item.paymentTokenId}
                      value={item.paymentTokenId}
                    >
                      {`${item.brand} ${item.paymentMethodSuffix}`}
                    </MUI.MenuItemCustom>
                  ))}
              </MUI.SelectPayFromCustom>
            </Grid>
          </Grid>
          <Grid lg={12} justify="flex-end" alignItems="center" container>
            <Typography variant="" className={classes.paddingDontSeeAcct}>
              Don&apos;t see your account ?
              <Link onClick={() => dispatch(Actions.toggleManagePaymentModal(true))}>
                <span className={`${classes.linkColor}`}>Add an account</span>
              </Link>
            </Typography>
          </Grid>

          <Grid lg={12} justify="space-between" alignItems="center" container>
            <Typography
              variant=""
              className={`${classes.selectLabelLineHeight} ${classes.medBlue} ${classes.boldFont} ${classes.paddingDontSeeAcct}`}
            >
              Payment Date
            </Typography>
            <Grid className={`${classes.flexColumnPad}`}>
              <div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <ThemeProvider theme={materialTheme}>
                    <MUI.PaymentdatePicker
                      disableToolbar
                      autoOk
                      disabled={getDisabledStatusForPaymentDate()}
                      variant="inline"
                      inputVariant="outlined"
                      format="MM/dd/yyyy"
                      margin="normal"
                      name="paymentDate"
                      id="payment-date-picker"
                      data-testid="date-picker"
                      value={getValue(paymentDate)}
                      onChange={handleDate}
                      minDate={new Date()}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      className={classes.paymentDatePickerStyles}
                    />
                  </ThemeProvider>
                </MuiPickersUtilsProvider>
              </div>
            </Grid>
          </Grid>
          <Grid lg={12} justify="flex-end" alignItems="center" container>
            {getDisabledStatusForPaymentDate() && (
              <div className={`${classes.upperPad} ${classes.yellow}`}>
                As card is not added to wallet, you can only make immediate payment.
              </div>
            )}
          </Grid>
          <Grid />
          <div style={customStyles.paymentModuleMaxWidth}>
            <div
              className={`${classes.upperPad} ${classes.buttonDiv}`}
              style={customStyles.nextButtonDiv}
            >
              <MUI.ActionButton
                onClick={() => {
                  dispatch(Actions.makePaymentStep(2));
                }}
              >
                Back
              </MUI.ActionButton>
              <span className={classes.marleft1}>
                <MUI.ActionButton
                  disabled={!getValue('paymentTokenId') || !getValue(paymentDate)}
                  onClick={() => {
                    handleNextAction();
                    dispatch(Actions.makePaymentStep(4));
                  }}
                >
                  Next
                </MUI.ActionButton>
              </span>
            </div>
          </div>
        </>
      )}
      <ManagePaymentMethod
        launchManagePaymentMethod={showManagePaymentModal}
        showCloseBtn
      />
    </>
  );
};

export default PaymentMethod;
