/* eslint-disable linebreak-style */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  TextBox,
  TextBoxFirst,
  SectionWrapper,
} from '../../shared/components/SharedComponentStyles';
import * as EL from './StyledComponents';
import { phoneNumberFormatter } from '../../utils/PhoneNumberFormatter';
import useClasses from './Classes';
import * as MUI from './MUI_Components';
import { saveUser } from '../../redux/actionCreators/authActionCreators';
import { isPhoneNumberValid } from '../../utils/Validations';
import { userDoesNotExist } from '../../redux/actionCreators/UserActionCreators';

const UserProfile = () => {
  const classes = useClasses();
  const dispatch = useDispatch();

  useEffect(
    () => () => {
      dispatch(userDoesNotExist(true));
    },
    []
  );

  const { isInsertUserPending, isUserInsertFailed } = useSelector((state) => ({
    isInsertUserPending: state.authReducer.isInsertUserPending,
    isUserInsertFailed: state.authReducer.isUserInsertFailed,
  }));

  const [createUserData, setCreateUser] = useState({
    firstName: '',
    lastName: '',
    accountExecutive: '',
    companyName: '',
    agencyId: '',
    PhoneNumber: '',
  });

  const handleChangeField = (event) => {
    const { value, id } = event && event.target;
    if (id === 'PhoneNumber') {
      setCreateUser({ ...createUserData, [id]: phoneNumberFormatter(value) });
    } else {
      setCreateUser({ ...createUserData, [id]: value });
    }
  };

  const { firstName, lastName, accountExecutive, companyName, agencyId, PhoneNumber } =
    createUserData;

  const saveNewUser = async () => {
    const data = {
      firstName,
      lastName,
      accountExecutive,
      companyName,
      agencyId,
      PhoneNumber,
    };
    await dispatch(saveUser(data));
  };
  return (
    <div>
      <>
        <SectionWrapper className={`${classes.global}`}>
          <Grid className={`${classes.signupModule}`}>
            <Grid className={`${classes.signupWrapper}`}>
              <EL.HeroContainer>
                <MUI.CardContainer>
                  <Card>
                    <CardContent>
                      <div className={`${classes.headerText}`}>User Profile</div>
                      {isUserInsertFailed && (
                        <div className={`${classes.infoTextError}`}>
                          An error occurred while creating user profile. Please try again
                          later.
                        </div>
                      )}
                      <Grid className={`${classes.content} ${classes.flexColumn}`}>
                        <form noValidate autoComplete="off">
                          <small className={classes.requiredLink}>
                            * denotes required field
                          </small>
                          <TextBoxFirst
                            fieldId="firstName"
                            style={{ margin: '2px 8px', paddingTop: '0rem !important' }}
                            placeholder="First Name*"
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            value={firstName}
                            onChange={handleChangeField}
                            disabled={isInsertUserPending}
                          />
                          <TextBox
                            fieldId="lastName"
                            style={{ margin: '2px 8px' }}
                            placeholder="Last Name*"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            value={lastName}
                            onChange={handleChangeField}
                            disabled={isInsertUserPending}
                          />
                          <TextBox
                            fieldId="accountExecutive"
                            style={{ margin: '2px 8px' }}
                            placeholder="Account Executive"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            value={accountExecutive}
                            onChange={handleChangeField}
                            disabled={isInsertUserPending}
                          />
                          <TextBox
                            fieldId="companyName"
                            style={{ margin: '2px 8px' }}
                            placeholder="Company name*"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            value={companyName}
                            onChange={handleChangeField}
                            disabled={isInsertUserPending}
                          />
                          <TextBox
                            fieldId="agencyId"
                            style={{ margin: '2px 8px' }}
                            placeholder="Client or agency ID"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            value={agencyId}
                            onChange={handleChangeField}
                            disabled={isInsertUserPending}
                          />
                          <TextBox
                            fieldId="PhoneNumber"
                            style={{ margin: '2px 8px' }}
                            placeholder="Phone Number"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            value={PhoneNumber}
                            onChange={handleChangeField}
                            disabled={isInsertUserPending}
                          />
                        </form>
                        <MUI.Submit
                          style={{ margin: '12px 8px 0px 8px' }}
                          variant="contained"
                          color="primary"
                          disableElevation
                          disabled={
                            !firstName ||
                            !lastName ||
                            !companyName ||
                            (PhoneNumber && !isPhoneNumberValid(PhoneNumber)) ||
                            isInsertUserPending
                          }
                          onClick={saveNewUser}
                        >
                          {' '}
                          {isInsertUserPending ? 'Creating Profile...' : 'Save Profile'}
                          {isInsertUserPending && (
                            <CircularProgress
                              size={24}
                              className={classes.buttonProgress}
                            />
                          )}
                        </MUI.Submit>
                      </Grid>
                    </CardContent>
                  </Card>
                </MUI.CardContainer>
              </EL.HeroContainer>
            </Grid>
          </Grid>
        </SectionWrapper>
      </>
    </div>
  );
};

export default UserProfile;
