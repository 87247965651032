/* eslint-disable linebreak-style */
import * as types from '../actionTypes/PaymentActionTypes';

const paymentReducerInitialState = {
  makePaymentStep: 1,
  addPaymentMethodStep: 1,
  paymentDetails: {
    paymentTokenId: null,
    paymentDetails: null,
    paymentDate: new Date(),
    paymentDateType: 'PayNow',
  },
  invoicesInfo: [{ orderTypeId: 0, orderId: '', amount: null }],
  paymentMethods: [],
  paymentMethodLoading: false,
  paymentSubmissionLoading: false,
  getPaymetMethodsFailed: false,
  makePaymentFailed: false,
  paymentFailureReason: '',
  paymentAccountDetails: {
    customerName: '',
    customerAddress1: '',
    customerAddress2: '',
    customerCity: '',
    customerState: '',
    customerZIP: '',
    customerPhone: '',
    customerCountryCode: '',
    paymentName: '',
    routingNumber: '',
    accountNumber: '',
    confirmAccountNumber: '',
    confirmRoutingNumber: '',
    accountType: '',
    cardNumber: '',
    cvvNumber: '',
    expMonth: '',
    expYear: '',
    cardBrand: '',
    encryptedCreditCardNumber: '',
    companyName: '',
    encryptedIntegrityCheck: '',
    encryptedCardSecurityValue: '',
  },
  isDeletePaymentMethodLoading: false,
  isAddACHFailed: false,
  isAddCardFailed: false,
  isAddACHLoading: false,
  isAddCardLoading: false,
  paymentFieldType: '',
  showAddPaymentModal: false,
  enrollmentData: [],
  isAutoPayFailed: false,
  autoPayLoading: false,
  isDeleteRecurringPaymentFailed: false,
  addingPaymentMethodFailedReason: '',
  paymentMethodOption: 1,
  addMethodType: '',
  showManagePaymentModal: false,
  oneTimePaymentTokenIds: [],
  oneTimePaymentDetails: [],
  isSaveToWallet: true,
  completedPaymentData: {},
  weekDaysData: [],
  recurringPaymentTypesData: [],
  recurringPaymentError: '',
  isEnableManagement: false,
  newEnrollmentData: {},
  showRecurringPayment: false,
  editedEnrollmentData: {},
  showRecurringPaymentConfirmationModal: false,
  nextPaymentMessage: '',
};

const paymentReducer = (state = paymentReducerInitialState, action) => {
  switch (action.type) {
    case types.ADD_NEW_ROW:
      return {
        ...state,
        invoicesInfo: [
          ...state.invoicesInfo,
          {
            ...action.data,
            orderTypeId: action.data ? action.data : 0,
            orderId: '',
            amount: null,
          },
        ],
      };
    case types.SET_INVOICE_INFO:
      return {
        ...state,
        invoicesInfo: [...action.data],
      };
    case types.MAKE_PAYMENT_STEP:
      return {
        ...state,
        makePaymentStep: action.data,
      };
    case types.ADD_METHOD_TYPE:
      return {
        ...state,
        addMethodType: action.data,
      };
    case types.PAYMENT_METHOD_OPTION:
      return {
        ...state,
        paymentMethodOption: action.data,
      };
    case types.RESET_ERROR:
      return {
        ...state,
        isAddCardFailed: false,
        isAddACHLoading: false,
      };
    case types.ADD_PAYMENT_METHOD_STEP:
      return {
        ...state,
        addPaymentMethodStep: action.data,
      };
    case types.CHANGE_PAYMENT_FIELD_VALUE: {
      const { value, id } = action.data && action.data;
      return {
        ...state,
        paymentDetails: {
          ...state.paymentDetails,
          [id]: value,
        },
      };
    }
    case types.PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: action.data,
        getPaymetMethodsFailed: false,
      };
    case types.LOADING_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethodLoading: action.data,
      };
    case types.LOADING_PAYMENT_SUBMITTED:
      return {
        ...state,
        paymentSubmissionLoading: action.data,
      };
    case types.GET_PAYMENT_METHODS_FAILED:
      return {
        ...state,
        getPaymetMethodsFailed: action.data,
      };
    case types.MAKE_PAYMENT_FAILED:
      return {
        ...state,
        makePaymentFailed: action.data.isPaymentFailed,
        paymentFailureReason: action.data.message,
      };
    case types.RESET_PAYMENT_DETAILS: {
      return {
        ...state,
        paymentDetails: paymentReducerInitialState.paymentDetails,
        invoicesInfo: paymentReducerInitialState.invoicesInfo,
        paymentAccountDetails: paymentReducerInitialState.paymentAccountDetails,
        makePaymentStep: 1,
        paymentMethodLoading: false,
        paymentSubmissionLoading: false,
        getPaymetMethodsFailed: false,
        makePaymentFailed: false,
        paymentFieldType: '',
        addingPaymentMethodFailedReason: '',
        paymentMethodOption: 1,
        addMethodType: '',
        showManagePaymentModal: false,
        showAddPaymentModal: false,
        oneTimePaymentDetails: [],
        oneTimePaymentTokenIds: [],
        isSaveToWallet: true,
        completedPaymentData: {},
      };
    }
    case types.ADD_CREDIT_CARD_REQUEST:
      return {
        ...state,
        isAddCardFailed: false,
        isAddCardLoading: true,
      };
    case types.ADD_CREDIT_CARD_SUCCESS:
      return {
        ...state,
        isAddCardFailed: false,
        isAddCardLoading: false,
      };
    case types.ADD_CREDIT_CARD_FAILURE:
      return {
        ...state,
        isAddCardFailed: action.data.isAddingCreditCardFailed,
        addingPaymentMethodFailedReason: action.data.message,
        isAddCardLoading: false,
      };
    case types.ADD_ACH_REQUEST:
      return {
        ...state,
        isAddACHFailed: false,
        isAddACHLoading: true,
      };
    case types.ADD_ACH_SUCCESS:
      return {
        ...state,
        isAddACHFailed: false,
        isAddACHLoading: false,
      };
    case types.ADD_ACH_FAILURE:
      return {
        ...state,
        isAddACHFailed: action.data.isAddingAccountFailed,
        addingPaymentMethodFailedReason: action.data.message,
        isAddACHLoading: false,
      };
    case types.DELETE_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        isDeletePaymentMethodLoading: true,
      };
    case types.DELETE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        paymentMethods: state.paymentMethods.filter(
          (item) => item.paymentTokenId !== action.paymentToken
        ),
        isDeletePaymentMethodLoading: false,
      };
    case types.DELETE_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        isDeletePaymentMethodLoading: false,
      };
    case types.CHANGE_FIELD_VALUE: {
      const { value, id } = action.data && action.data;
      return {
        ...state,
        paymentAccountDetails: {
          ...state.paymentAccountDetails,
          [id]: value,
        },
      };
    }
    case types.RESET_FIELD_VALUES:
      return {
        ...state,
        paymentAccountDetails: paymentReducerInitialState.paymentAccountDetails,
        addingPaymentMethodFailedReason:
          paymentReducerInitialState.addingPaymentMethodFailedReason,
      };
    case types.TOGGLE_ADD_PAYMENT_MODAL: {
      return {
        ...state,
        showAddPaymentModal: action.isOpen,
      };
    }
    case types.TOGGLE_MANAGE_PAYMENT_MODAL: {
      return {
        ...state,
        showManagePaymentModal: action.isOpen,
      };
    }
    case types.SAVE_PAYMENT_TOKEN_ID: {
      return {
        ...state,
        oneTimePaymentTokenIds: [...state.oneTimePaymentTokenIds, action.id],
      };
    }
    case types.IS_USER_ENROLLED_IN_AUTOPAY_REQUEST: {
      return {
        ...state,
        autoPayLoading: true,
        isAutoPayFailed: false,
      };
    }
    case types.IS_USER_ENROLLED_IN_AUTOPAY_FAILURE: {
      return {
        ...state,
        enrollmentData: [],
        autoPayLoading: false,
        isAutoPayFailed: true,
      };
    }
    case types.IS_USER_ENROLLED_IN_AUTOPAY_SUCCESS: {
      const today = new Date();

      return {
        ...state,
        isAutoPayFailed: false,
        enrollmentData:
          action.data &&
          action.data.map((item) => ({
            ...item,
            startDate: item.startDate ? item.startDate : today,
            endDate: item.endDate ? item.endDate : today,
          })),
        autoPayLoading: false,
      };
    }
    case types.END_ENROLLMENT_REQUEST: {
      return {
        ...state,
        autoPayLoading: true,
      };
    }
    case types.END_ENROLLMENT_FAILURE: {
      return {
        ...state,
        autoPayLoading: false,
        isDeleteRecurringPaymentFailed: action.data,
      };
    }
    case types.END_ENROLLMENT_SUCCESS: {
      return {
        ...state,
        autoPayLoading: false,
        isDeleteRecurringPaymentFailed: false,
      };
    }
    case types.SET_AUTO_PAY_ENROLLMENT_DATA: {
      const { value, id } = action.data && action.data;
      return {
        ...state,
        newEnrollmentData: {
          ...state.newEnrollmentData,
          [id]: value,
        },
      };
    }
    case types.RESET_AUTO_PAY_ENROLLMENT_DATA: {
      const today = new Date();
      return {
        ...state,
        newEnrollmentData: {
          startDate: today,
          endDate: today,
        },
      };
    }
    case types.TOGGLE_RECURRING_PAYMENT_CONFIRMATION_MODAL: {
      return {
        ...state,
        showRecurringPaymentConfirmationModal: action.isOpen,
      };
    }
    case types.SET_ENABLE_MANAGEMENT: {
      return {
        ...state,
        isEnableManagement: action.data,
        recurringPaymentError: '',
      };
    }
    case types.START_ENROLLMENT_REQUEST: {
      return {
        ...state,
        autoPayLoading: true,
        recurringPaymentError: '',
        nextPaymentMessage: {},
      };
    }
    case types.START_ENROLLMENT_FAILURE: {
      return {
        ...state,
        autoPayLoading: false,
        recurringPaymentError: action.data,
      };
    }
    case types.START_ENROLLMENT_SUCCESS: {
      return {
        ...state,
        autoPayLoading: false,
        newEnrollmentData: {},
        editedEnrollmentData: {},
        nextPaymentMessage: action.data,
      };
    }
    case types.UPDATE_ENROLLMENT: {
      return {
        ...state,
        editedEnrollmentData: action.data && action.data,
        recurringPaymentError: '',
      };
    }
    case types.SAVE_ONE_TIME_PAYMENT_DETAILS: {
      return {
        ...state,
        oneTimePaymentDetails: [...state.oneTimePaymentDetails, action.data],
      };
    }
    case types.SET_SAVE_TO_WALLET_STATUS: {
      return {
        ...state,
        isSaveToWallet: action.isSave,
      };
    }
    case types.COMPLETE_PAYMENT_SUCCESS: {
      return {
        ...state,
        completedPaymentData: action.data,
      };
    }
    case types.FETCH_RECURRING_PAYMENTS_REQUEST: {
      return {
        ...state,
        autoPayLoading: true,
      };
    }
    case types.FETCH_RECURRING_PAYMENTS_SUCCESS: {
      return {
        ...state,
        autoPayLoading: false,
        recurringPaymentTypesData: action.data,
      };
    }
    case types.FETCH_RECURRING_PAYMENTS_FAILURE: {
      return {
        ...state,
        autoPayLoading: false,
      };
    }
    case types.FETCH_WEEK_DAYS_REQUEST: {
      return {
        ...state,
        autoPayLoading: true,
      };
    }
    case types.FETCH_WEEK_DAYS_SUCCESS: {
      return {
        ...state,
        autoPayLoading: false,
        weekDaysData:
          action.data &&
          action.data.map((item) => ({
            ...item,
            value: item.day,
            description: item.day,
          })),
      };
    }
    case types.FETCH_WEEK_DAYS_FAILURE: {
      return {
        ...state,
        autoPayLoading: false,
      };
    }
    case '@@router/LOCATION_CHANGE':
    case '@@INIT': {
      return {
        ...state,
        showManagePaymentModal: false,
        showAddPaymentModal: false,
        oneTimePaymentDetails: [],
        oneTimePaymentTokenIds: [],
        isSaveToWallet: true,
        recurringPaymentError: '',
        newEnrollmentData: {},
        editedEnrollmentData: {},
        isDeleteRecurringPaymentFailed: false,
        nextPaymentMessage: '',
      };
    }
    default:
      return state;
  }
};

export { paymentReducerInitialState, paymentReducer };
