/* eslint-disable linebreak-style */

import { constants as tokenConstants } from './AuthUtils';

export async function UseAuthorization(
  auth = tokenConstants.ACCESS_TOKEN,
  config = null
) {
  let authToken;
  if (auth === tokenConstants.ACCESS_TOKEN) {
    authToken = window.sessionStorage.getItem(tokenConstants.ACCESS_TOKEN) || '';
  } else if (auth === tokenConstants.REFRESH_TOKEN) {
    authToken = window.sessionStorage.getItem(tokenConstants.REFRESH_TOKEN) || '';
  }
  if (config && authToken) {
    config.headers.Authorization = `Bearer ${authToken}`; // eslint-disable-line no-param-reassign
  }
  return {
    headers: !authToken ? {} : { Authorization: `Bearer ${authToken}` },
  };
}
