/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable linebreak-style */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';
import * as MUI from '../styles/MUI_Components';
import useClasses from '../styles/Classes';
import * as Actions from '../../../../../redux/actionCreators/PaymentActionCreators';
import NumberFormatCustom from '../NumberFormatCustom';
import { getTotalPaymentValue } from './payment-helpers';
import customStyles from '../styles/CustomStyles';

const payByOptions = [
  { name: 'Invoice', value: 0 },
  { name: 'Pre-pay', value: 1 },
];

const PaymentAmount = () => {
  const classes = useClasses();

  const dispatch = useDispatch();

  const { invoicesInfo } = useSelector((state) => ({
    invoicesInfo: state.paymentReducer.invoicesInfo,
  }));

  const [idFieldAutoFocus, setIdFieldAutoFocus] = useState(false);

  const handleOnChange = (e, index) => {
    let enteredValue = '';
    const { value, name } = e.target;
    enteredValue = value;
    const newInvoicesInfo =
      invoicesInfo &&
      invoicesInfo.map((data, i) => {
        const filterValue =
          name !== 'orderTypeId' ? enteredValue.replace(/\s+/g, '') : enteredValue;
        return i === index
          ? {
              ...data,
              [name]: filterValue,
            }
          : data;
      });
    dispatch(Actions.setInvoicesInfo(newInvoicesInfo));
  };

  const handleOnKeyDown = (e) => {
    if (!e.shiftKey && e.key === 'Tab') {
      e.preventDefault();
      const prevPayBySelection = invoicesInfo[invoicesInfo.length - 1].orderTypeId;
      setIdFieldAutoFocus(true);
      dispatch(Actions.addNewRowFunction(prevPayBySelection));
    }
  };

  const deleteInvoiceMethod = (deleteIndex) => {
    const filteredInvoices = invoicesInfo.filter((value, index) => index !== deleteIndex);
    if (filteredInvoices.length > 0) {
      dispatch(Actions.setInvoicesInfo(filteredInvoices));
    } else {
      dispatch(
        Actions.setInvoicesInfo([
          ...filteredInvoices,
          { orderTypeId: 0, orderId: '', amount: '' },
        ])
      );
    }
  };

  return (
    <>
      <div className={classes.makeAPaymentStep1}>
        <div className={classes.rowPad}>
          {invoicesInfo.map((invoice, index) => (
            <div className={classes.MultiInvoiceRowInfo} key={index}>
              <div className={`${classes.paymentRowPad}`}>
                <div className={classes.paymentTextBoxLabel}>Pay by</div>
                <MUI.TransHistSelect
                  labelId="orderTypeId"
                  id="orderTypeId"
                  name="orderTypeId"
                  value={invoice.orderTypeId}
                  className={classes.paymentMethodPayByTextField}
                  onChange={(e) => handleOnChange(e, index)}
                  defaultValue="0"
                  variant="outlined"
                  data-testId="pay-my-invoice"
                >
                  {payByOptions.map((item) => (
                    <MUI.SelectItemCustom key={item.name} value={item.value}>
                      {item.name}
                    </MUI.SelectItemCustom>
                  ))}
                </MUI.TransHistSelect>
              </div>
              <div className={`${classes.paymentRowPad}`}>
                <>
                  <div className={classes.paymentTextBoxLabel}>
                    {invoice.orderTypeId === 0 ? 'Invoice #' : 'Order #'}
                    <Tooltip
                      disableFocusListener
                      title="If you do not know your invoice or order number, you can enter a description of what you’re paying.
                      (example: “December invoices”, “oldest open invoices”, “Payment Plan”)"
                    >
                      <HelpOutlineIcon
                        style={{
                          fontSize: '.75rem',
                          color: '#20699f',
                          position: 'relative',
                          top: '2px',
                          left: '3px',
                        }}
                      />
                    </Tooltip>
                  </div>
                  <MUI.TextFieldCustom
                    value={invoice.orderId}
                    onChange={(e) => handleOnChange(e, index)}
                    autoFocus={idFieldAutoFocus}
                    inputProps={{
                      'data-testId': 'inv-number',
                      maxLength: 50,
                      style: {
                        padding: '.85rem',
                        textAlign: 'center',
                        height: '.1rem',
                      },
                    }}
                    variant="outlined"
                    name="orderId"
                    id="orderId"
                  />
                </>
              </div>

              <div className={classes.paymentRowPad}>
                <div className={classes.paymentTextBoxLabel}>Amount</div>
                <MUI.TextFieldCustom
                  value={invoice.amount}
                  onChange={(e) => handleOnChange(e, index)}
                  onKeyDown={(e) => handleOnKeyDown(e, index)}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  variant="outlined"
                  name="amount"
                  id="formatted-amount"
                />
              </div>
              <div className={classes.paymentRowPad}>
                <div
                  className={`${classes.deleteMethod} ${classes.marginTop}`}
                  role="link"
                  tabIndex={0}
                  onKeyPress={() => ''}
                  onClick={() => {
                    deleteInvoiceMethod(index);
                  }}
                >
                  delete
                </div>
              </div>
            </div>
          ))}
          <div className={classes.addNewRow}>
            <div
              onKeyPress={() => {}}
              className={`${classes.paymentTextBoxLabel} ${classes.linkText}`}
              onClick={() => dispatch(Actions.addNewRowFunction())}
            >
              Add Row
            </div>
            <div className={classes.paymentTextBoxLabel}>Total Payment</div>
            <div className={classes.paymentTextBoxLabel}>
              {getTotalPaymentValue(invoicesInfo)}
            </div>
          </div>
          <hr />
          <div style={customStyles.paymentModuleMaxWidth}>
            <div className={classes.upperPad}>
              <div className={`${classes.buttonDiv}`} style={customStyles.nextButtonDiv}>
                <MUI.ActionButton
                  data-testid="back-to-step1"
                  onClick={() => {
                    dispatch(Actions.makePaymentStep(1));
                  }}
                >
                  Back
                </MUI.ActionButton>
                <span className={classes.marleft1}>
                  <MUI.ActionButton
                    data-testid="goTo-Step3"
                    disabled={
                      !invoicesInfo.every((entry) => entry.orderId && entry.amount)
                    }
                    onClick={() => {
                      dispatch(Actions.makePaymentStep(3));
                      dispatch(
                        Actions.changeConfigurationFieldValue({
                          value: invoicesInfo,
                          id: 'paymentDetails',
                        })
                      );
                    }}
                  >
                    {' '}
                    Next{' '}
                  </MUI.ActionButton>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentAmount;
