/* eslint-disable linebreak-style */
import { createUseStyles } from 'react-jss';
import theme from '../../theme.module.scss';

const useClasses = createUseStyles({
  pageNotFoundModule: {
    zIndex: '0',
    position: 'relative',
  },

  pageNotFoundWrapper: {
    margin: '0 auto',
  },

  linkText: {
    color: `${theme.medBlue}`,
    fontFamily: 'Reach-Title-Bold, sans-serif',
    letterSpacing: '-0.01rem',
    textAlign: 'center',
    paddingTop: '5rem',
    fontSize: '1rem',
    '&:hover': {
      cursor: 'pointer',
      color: ` ${theme.deepBlue}`,
    },
  },

  headerText: {
    color: `${theme.deepBlue}`,
    fontFamily: 'Reach-Title-Bold, sans-serif',
    fontSize: '1.5rem',
    lineHeight: '2rem',
    letterSpacing: '-0.1rem',
    textAlign: 'center',
    paddingTop: '1rem',
  },

  infoText: {
    color: `${theme.medBlue}`,
    fontFamily: 'inherit',
    letterSpacing: '-0.01rem',
    textAlign: 'center',
    paddingTop: '2rem',
    paddingBottom: '1rem',
    paddingLeft: '2rem',
    paddingRight: '1.5rem',
    lineHeight: '1rem',
    fontSize: '1rem',
  },
});

export default useClasses;
