/* eslint-disable linebreak-style */

/** Action Type to login request */
export const FETCH_LOGIN_REQUEST = 'FETCH_LOGIN_REQUEST';

/** Action Type to fetch login data */
export const FETCH_LOGIN_SUCCESS = 'FETCH_LOGIN_SUCCESS';

/** Action Type to fetch login data failure */
export const FETCH_LOGIN_FAILURE = 'FETCH_LOGIN_FAILURE';

/** Action Type to get open ID Configuration data */
export const FETCH_OPEN_ID_CONFIGURATION_DATA_REQUEST =
  'FETCH_OPEN_ID_CONFIGURATION_DATA_REQUEST';

/** Action Type to get open ID Configuration data status */
export const FETCH_OPEN_ID_CONFIGURATION_DATA_SUCCESS =
  'FETCH_OPEN_ID_CONFIGURATION_DATA_SUCCESS';

/** Action Type to get open ID Configuration data status */
export const FETCH_OPEN_ID_CONFIGURATION_DATA_FAILURE =
  'FETCH_OPEN_ID_CONFIGURATION_DATA_FAILURE';

/** Action Type to get login form data */
export const SET_LOGIN_FORM_DATA = 'SET_LOGIN_FORM_DATA';

/** Action Type for forgot password request */
export const FETCH_FORGOT_PASSWORD_REQUEST = 'FETCH_FORGOT_PASSWORD_REQUEST';

/** Action Type for forgot password success */
export const FETCH_FORGOT_PASSWORD_SUCCESS = 'FETCH_FORGOT_PASSWORD_SUCCESS';

/** Action Type for forgot password failure */
export const FETCH_FORGOT_PASSWORD_FAILURE = 'FETCH_FORGOT_PASSWORD_FAILURE';

/** Action Type for reset password request */
export const FETCH_RESET_PASSWORD_REQUEST = 'FETCH_RESET_PASSWORD_REQUEST';

/** Action Type for reset password success */
export const FETCH_RESET_PASSWORD_SUCCESS = 'FETCH_RESET_PASSWORD_SUCCESS';

/** Action Type for reset password failure */
export const FETCH_RESET_PASSWORD_FAILURE = 'FETCH_RESET_PASSWORD_FAILURE';

/** Action Type for Create user REQUEST */
export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';

/** Action Type for Create user success */
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';

/** Action Type for Create user success */
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

/** Action Type for resetting create user */
export const RESET_CREATE_USER_ERRORS = 'RESET_CREATE_USER_ERRORS';

/** Action Type for Change password request */
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';

/** Success Action Type for change password */
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';

/** Failure Action Type for change password */
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

/** Change Change Password Field Values */
export const CHANGE_PASSWORD_FIELD_VALUES = 'CHANGE_PASSWORD_FIELD_VALUES';

/** Action Type for verify email request */
export const FETCH_VERIFY_EMAIL_REQUEST = 'FETCH_VERIFY_EMAIL_REQUEST';

/** Status Action Type for verify email request */
export const FETCH_VERIFY_EMAIL_STATUS = 'FETCH_VERIFY_EMAIL_STATUS';

/** Action Type for resend email confirmation request */
export const FETCH_RESEND_EMAIL_CONFIRMATION_REQUEST =
  'FETCH_RESEND_EMAIL_CONFIRMATION_REQUEST';

/** Status Action Type for resent email confirmation request */
export const FETCH_RESEND_EMAIL_CONFIRMATION_STATUS =
  'FETCH_RESEND_EMAIL_CONFIRMATION_STATUS';

/** Handle unauthorized user */
export const HANDLE_UNAUTHORIZED_USER = 'HANDLE_UNAUTHORIZED_USER';

export const LOADING_SIGN_IN = 'LOADING_SIGN_IN';

/** Action Type to get access token request */
export const FETCH_ACCESS_TOKEN_REQUEST = 'FETCH_ACCESS_TOKEN_REQUEST';

/** Action Type to get access token success */
export const FETCH_ACCESS_TOKEN_SUCCESS = 'FETCH_ACCESS_TOKEN_SUCCESS';

/** Action Type to get access token failure */
export const FETCH_ACCESS_TOKEN_FAILURE = 'FETCH_ACCESS_TOKEN_FAILURE';

/** Insert User in client portal DB  Success */
export const INSERT_USER_SUCCESS = 'INSERT_USER_SUCCESS';

/** Insert User in client portal DB  Failure */
export const INSERT_USER_FAILURE = 'INSERT_USER_FAILURE';

/** Insert User in client portal DB */
export const INSERT_USER_LOADING = 'INSERT_USER_LOADING';

/** Sign Up */
export const SIGN_UP = 'SIGN_UP';

/** Link Account Response */
export const LINK_ACCOUNT_RESPONSE = 'LINK_ACCOUNT_RESPONSE';

/** Action Type for forgot username request */
export const FETCH_FORGOT_USERNAME_REQUEST = 'FETCH_FORGOT_USERNAME_REQUEST';

/** Action Type for forgot username success */
export const FETCH_FORGOT_USERNAME_SUCCESS = 'FETCH_FORGOT_USERNAME_SUCCESS';

/** Action Type for forgot username failure */
export const FETCH_FORGOT_USERNAME_FAILURE = 'FETCH_FORGOT_USERNAME_FAILURE';
