import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import * as MUI from '../styles/MUIComponents';
import useClasses from '../styles/ManageAccountClasses';
import InputText from '../../../../../shared/components/InputText';

import { updateUser } from '../../../../../redux/actionCreators/AccountUsersActionCreators';

import {
  phoneNumberFormatter,
  PhoneNumberFormatToNumber,
} from '../../../../../utils/PhoneNumberFormatter';
import { ComponentLoader } from '../../../../../shared/components/Loader';
import ValidationError from '../../../../../shared/components/ValidationError';

const useStyles = makeStyles((theme) => ({
  helpIcon: {
    fontSize: '1.25rem',
  },
  submitBtnWrapper: {
    display: 'flex',
    marginTop: '2rem',
  },
  submitBtn: {
    color: theme.palette.common.white,
    width: '60%',
    margin: 'auto',
    padding: '0.5rem',
    fontSize: '1rem',
    backgroundColor: `${theme.palette.blue.light}`,
    '&:hover': {
      background: `${theme.palette.blue.lighter}`,
    },
  },
  errorMsg: {
    color: theme.palette.error.main,
    fontSize: '1rem',
    textAlign: 'center',
  },
  alertMsg: {
    marginBottom: '1rem',
  },
  required: {
    color: theme.palette.error.main,
  },
  optional: {
    fontSize: '0.8rem',
    marginLeft: '0.3rem',
  },
}));

export const UserProfile = () => {
  const regClasses = useClasses();
  const styleClasses = useStyles();
  const classes = { ...regClasses, ...styleClasses };

  const dispatch = useDispatch();
  const { isUpdatingUserLoading, userInfoDetails, userUpdateFailedError } = useSelector(
    (state) => ({
      userInfoDetails: state.userReducer.userInfoDetails,
      isUpdatingUserLoading: state.accountUsersReducer.isUpdatingUserLoading,
      userUpdateFailedError: state.accountUsersReducer.userUpdateFailedError,
    })
  );

  const [profileData, setProfileData] = useState({
    firstName: userInfoDetails?.firstName,
    lastName: userInfoDetails?.lastName,
    email: userInfoDetails?.email,
    companyName: userInfoDetails?.companyName,
    accountExecutive: userInfoDetails?.accountExecutive,
    agencyId: userInfoDetails?.agencyId,
    phoneNumber: userInfoDetails?.phoneNumber,
  });

  useEffect(() => {
    if (userInfoDetails) {
      setProfileData({
        firstName: userInfoDetails?.firstName,
        lastName: userInfoDetails?.lastName,
        email: userInfoDetails?.email,
        companyName: userInfoDetails?.companyName,
        accountExecutive: userInfoDetails?.accountExecutive,
        agencyId: userInfoDetails?.agencyId,
        phoneNumber: userInfoDetails?.phoneNumber,
      });
    }
  }, [userInfoDetails]);
  const [errorData, setErrorData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    accountExecutive: '',
    companyName: '',
    agencyId: '',
    phoneNumber: '',
  });

  const fieldList = [
    { name: 'First Name', key: 'firstName', required: true, disabled: false },
    { name: 'Last Name', key: 'lastName', required: true, disabled: false },
    {
      name: 'Account Executive',
      key: 'accountExecutive',
      required: false,
      disabled: false,
    },
    { name: 'Company Name', key: 'companyName', required: true, disabled: false },
    { name: 'Customer Id', key: 'agencyId', required: false, disabled: false },
    { name: 'Email Address', key: 'email', required: true, disabled: true },
    { name: 'Phone Number', key: 'phoneNumber', required: false, disabled: true },
  ];

  const validateForm = (data, key = '', errorMsg = '') => {
    let isValid = true;
    const error = errorData;
    if (key && !data[key]) {
      isValid = false;
      error[key] = errorMsg;
      return { isValid, error };
    }
    fieldList.forEach((field) => {
      if (!data[field.key] && field.required) {
        error[field.key] = `Please enter ${field.name}`;
        isValid = false;
      }
    });
    return { isValid, error };
  };

  const handleChange = (name) => (event) => {
    const { value } = event.target;
    if (name === 'phoneNumber') {
      setProfileData({ ...profileData, [name]: phoneNumberFormatter(value) });
    } else {
      setProfileData({ ...profileData, [name]: value });
    }
    setErrorData({ ...errorData, [name]: '' });
  };

  const handleSubmit = () => {
    const { isValid, error } = validateForm(profileData);
    if (isValid) {
      dispatch(
        updateUser(
          {
            ...profileData,
            phoneNumber: PhoneNumberFormatToNumber(profileData.phoneNumber || ''),
          },
          userInfoDetails?.userName
        )
      );
    } else {
      setErrorData({ ...error });
    }
  };

  const FieldLabel = ({ field }) => (
    <div>
      <span>{field?.name}</span>
      {field?.required ? (
        <span className={classes.required}>*</span>
      ) : (
        <span className={classes.optional}>(Optional)</span>
      )}
    </div>
  );

  return (
    <Grid className={classes.container}>
      {isUpdatingUserLoading ? (
        <ComponentLoader />
      ) : (
        <>
          <Grid className={classes.subSectionHeader}>User Profile</Grid>
          <Grid className={classes.moduleContent}>
            <Grid className={classes.row}>
              <Grid className={classes.paddingDiv0}>
                {userUpdateFailedError && (
                  <ValidationError validationErrorData={userUpdateFailedError} />
                )}
                <div>
                  <>
                    {fieldList.map((field) => (
                      <InputText
                        key={field.key}
                        disabled={field.disabled}
                        inputProps={{ 'data-testid': field.key, id: field.key }}
                        fieldId={field.key}
                        fieldLabel={<FieldLabel field={field} />}
                        size="small"
                        value={profileData[field.key] || ''}
                        fieldError={errorData[field.key]}
                        onChange={handleChange(field.key)}
                      />
                    ))}
                    <div className={`${classes.upperPad} ${classes.updateContactBTNDiv}`}>
                      <Grid className={`${classes.buttonDiv}`}>
                        <span>
                          <MUI.ActionButton
                            onClick={() => {
                              handleSubmit();
                            }}
                            disabled={
                              !profileData.firstName ||
                              !profileData.lastName ||
                              !profileData.companyName
                            }
                          >
                            Update Profile
                          </MUI.ActionButton>
                        </span>
                      </Grid>
                    </div>
                  </>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};
