/* eslint-disable linebreak-style */
import { createSelector } from 'reselect';

// selector
const transactionHistoryData = (state) =>
  state.transactionHistoryReducer.transactionHistoryDetails;

// reselect function
export const getTransactionHistory = createSelector(
  [transactionHistoryData],
  (transactionHistory) => transactionHistory
);
