/* eslint-disable linebreak-style */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import * as Actions from '../../redux/actionCreators/authActionCreators';
import theme from '../../theme.module.scss';
import { ComponentLoader } from '../../shared/components/Loader';
import useClasses from './EmailConfirmationClasses';

const useStyles = makeStyles(() => ({
  ResetPasswordSubmit: {
    color: 'white',
    marginTop: '1rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    padding: '1.25rem',
    width: '100%',
    backgroundColor: theme.lightBlue,
    '&:hover': {
      background: '#34c0e8',
    },
  },
}));

/** Reset Password Form Component */
const EmailConfirmationForm = () => {
  const [emailclasses] = useState(useStyles());
  const classes = useClasses();
  const { isEmailConfirmationSuccessful, isEmailConfirmationPending } = useSelector(
    (state) => ({
      isEmailConfirmationSuccessful: state.authReducer.isEmailConfirmationSuccessful,
      isEmailConfirmationPending: state.authReducer.isEmailConfirmationPending,
    })
  );

  const dispatch = useDispatch();

  const [errorResponse, resetErrorResponse] = useState(false);
  const confirmEmailToken = async () => {
    const parsed = queryString.parse(window.location.search);
    const data = {
      username: parsed.username,
      token: parsed.token,
    };
    await Actions.VerifyEmailAction(dispatch, data);
    resetErrorResponse(true);
  };

  return (
    <>
      {isEmailConfirmationPending ? (
        <ComponentLoader />
      ) : (
        <>
          {!isEmailConfirmationSuccessful && errorResponse && (
            <div className={classes.infoTextFailure}>
              An error occurred while confirming your email. Please try again later.
            </div>
          )}
          <Button
            className={emailclasses.ResetPasswordSubmit}
            variant="contained"
            color="primary"
            disableElevation
            onClick={confirmEmailToken}
          >
            Confirm Email
          </Button>
        </>
      )}
    </>
  );
};

export default EmailConfirmationForm;
