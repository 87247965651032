/* eslint-disable linebreak-style */
import React from 'react';
import { Panels, LeftPanel } from '@spectrum-reach/external-theme';

import ManagePaymentsLeftPanel from './components/panels/ManagePaymentsLeftPanel';
import ManagePaymentsRightPanel from './components/panels/ManagePaymentsRightPanel';
import ManagePaymentsCenterPanel from './components/panels/ManagePaymentsCenterPanel';

const Account = () => {
  const leftPanelLinks = [
    {
      name: 'Manage Payment Methods',
      smoothTo: '#ManagePaymentMthods',
    },
    {
      name: 'Make A Payment',
      smoothTo: '#MakePayment',
    },
    {
      name: 'Recurring Payments',
      smoothTo: '#Autopay',
    },
    {
      name: 'Transaction History',
      smoothTo: '#TransactionHistory',
    },
  ];
  return (
    <Panels
      title="Manage Payments"
      left={
        <LeftPanel leftPanelLinks={leftPanelLinks}>
          <ManagePaymentsLeftPanel />
        </LeftPanel>
      }
      center={<ManagePaymentsCenterPanel />}
      right={<ManagePaymentsRightPanel />}
    />
  );
};

export default Account;
