/* eslint-disable linebreak-style */
import { createSelector } from 'reselect';

// selector
const getOpenIdConfigData = (state) => state.authReducer.openIdConfigData;

// reselect function
export const getTokenUrl = createSelector(
  [getOpenIdConfigData],
  (openIdConfigData) => openIdConfigData && openIdConfigData.token_endpoint
);

/** Get Signup Error code */
export const getCreateUserErrorCode = (code) => {
  let errorMessage = '';
  switch (code) {
    case 0:
    case 1:
    case 2:
    case 3:
    case 4:
      errorMessage = 'An Error Occured while creating a user, Please try again later.';
      break;
    case 5:
      errorMessage = 'Username is not valid.';
      break;
    case 6:
      errorMessage = 'Email address is not valid.';
      break;
    case 7:
      errorMessage = 'Username is already taken.';
      break;
    case 8:
      errorMessage = 'Email address is already taken.';
      break;
    case 9:
      errorMessage = 'Role is not valid.';
      break;
    case 10:
      errorMessage = 'Role is already taken.';
      break;
    case 11:
    case 12:
    case 13:
    case 14:
      errorMessage = 'An Error Occured while creating a user, Please try again later.';
      break;
    case 15:
      errorMessage = 'Password is too short.';
      break;
    case 16:
      errorMessage = 'Password requires non alphanumeric';
      break;
    case 17:
      errorMessage = 'Password requires a digit.';
      break;
    case 18:
      errorMessage = 'Password requires lowercase';
      break;
    case 19:
      errorMessage = 'Password requires uppercase';
      break;
    case 20:
    case 21:
    case 22:
      errorMessage = 'An Error Occured while creating a user, Please try again later.';
      break;
    default:
      errorMessage = 'An Error Occured while creating a user, Please try again later.';
      break;
  }
  return errorMessage;
};
