/* eslint-disable linebreak-style */
import 'core-js/es/map';
import 'core-js/es/set';
import 'babel-types';
import 'raf/polyfill';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { useEffect } from 'react';
import './App.scss';
import { connect, useSelector } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { MuiThemeProvider } from '@material-ui/core';
import { InternalTheme } from '@spectrum-reach/mui-theme';
import Routes from './routes/index';
import { history } from './store/createStore';
import * as Actions from './redux/actionCreators/authActionCreators';
import Layout from './shared/layout/Layout';
import LogoutUserOnInactivity from './modules/logout/LogoutUserOnInactivity';
import GetNewAccessToken from './modules/AccessToken/GetNewAccessToken';
import { isInternetExplorer } from './utils/IEUtils';
import NotCompatiblePage from './shared/components/NotCompatiblePage';

const App = (props) => {
  const { openIdConfigData } = useSelector((state) => ({
    openIdConfigData: state.authReducer.openIdConfigData,
  }));
  useEffect(() => {
    props.getOpenIDConfiguration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (openIdConfigData && Object.keys(openIdConfigData).length === 0) {
      props.getOpenIDConfiguration();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openIdConfigData]);

  return (
    <>
      <MuiThemeProvider theme={InternalTheme}>
        <GetNewAccessToken />
        <LogoutUserOnInactivity />
        <ConnectedRouter history={history}>
          <Layout isIE={isInternetExplorer()}>
            {isInternetExplorer() ? <NotCompatiblePage /> : <Routes />}
          </Layout>
        </ConnectedRouter>
      </MuiThemeProvider>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getOpenIDConfiguration: () => {
    Actions.fetchOpenIDConfiguration(dispatch);
  },
});

export default connect(null, mapDispatchToProps)(App);
