/* eslint-disable linebreak-style */
import React from 'react';
import * as MUI from '../styles/MUI_Components';
import useClasses from '../styles/Classes';

const DeletePaymentMethod = ({
  launchDeletePaymentMethod,
  selecteddata,
  deletepaymentmethodCallback: _DeletePaymentMethodCallback,
}) => {
  const senddeletepaymentMethodCallbackCancel = () => {
    _DeletePaymentMethodCallback('cancel');
  };

  const senddeletepaymentMethodCallback = () => {
    _DeletePaymentMethodCallback(selecteddata.paymentTokenId);
  };

  const classes = useClasses();

  return (
    <MUI.DeletePaymentMethodModal
      open={launchDeletePaymentMethod}
      aria-labelledby="paymentMethod-title"
      aria-describedby="paymentMethod-description"
    >
      <div className={classes.paymentMethodModalBody}>
        <div className={classes.paymentMethodModalTitle}> Delete Payment Method </div>
        <div className={classes.upperPad}>
          <div className={`${classes.deepBlue}`}>
            Are you sure you want to delete this payment method?
          </div>
          {selecteddata && selecteddata.isAutoPayEnabled && (
            <div className={`${classes.upperPad} ${classes.yellow}`}>
              Deleting this payment method will disable your autopay schedule.
            </div>
          )}
          <div className={`${classes.upperPad} ${classes.deleteMethodItemType}`}>
            {selecteddata.brand && selecteddata.brand.startsWith('ACH')
              ? 'Checking'
              : selecteddata.brand}
          </div>
          <div>
            <small className={classes.smallCC}>Last 4 </small>
            <span className={`${classes.smallSpace} ${classes.deepBlue}`}>
              {selecteddata.paymentMethodSuffix}
            </span>
          </div>
          <span>
            <div
              className={`${classes.updatePaymentMethodModalButtons} ${classes.upperPad} ${classes.buttonDiv} ${classes.marginTop}`}
            >
              <MUI.ActionButton onClick={senddeletepaymentMethodCallbackCancel}>
                Cancel
              </MUI.ActionButton>
              <span className={classes.marleft1}>
                <MUI.ActionButtonDangerInverted
                  onClick={() => {
                    senddeletepaymentMethodCallback();
                  }}
                >
                  {' '}
                  Delete Method
                </MUI.ActionButtonDangerInverted>
              </span>
            </div>
          </span>
        </div>
      </div>
    </MUI.DeletePaymentMethodModal>
  );
};
export default DeletePaymentMethod;
