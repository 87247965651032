/* eslint-disable linebreak-style */
import React, { useState } from 'react';

// Material UI components
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

// Material UI Icons
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { makeStyles } from '@material-ui/core/styles';
import { InputAdornment } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    display: 'inline-flex',
    marginBottom: '0.3rem',
  },
  inputBaseRoot: {
    backgroundColor: theme.palette.common.white,
  },
  iconButton: {
    paddingLeft: '0.2rem',
  },
  helpIcon: {
    fontSize: '1.25rem',
  },
}));

/** Input Component With Label and Helpful information */
const InputText = ({
  tooltipText,
  fieldLabel,
  fieldError,
  fieldId,
  fieldType = '',
  onChange,
  onBlur,
  onKeyDown,
  value,
  inputProps,
  appearanceType,
  variant = 'outlined',
  placeholder = '',
  fullWidth = true,
  size = 'medium',
  ...rest
}) => {
  const [classes] = useState(useStyles());
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
      <div className={classes.inputLabel}>
        {fieldLabel}
        {tooltipText && (
          <Tooltip title={tooltipText || ''}>
            <span className={classes.iconButton}>
              <HelpOutlineIcon className={classes.helpIcon} />
            </span>
          </Tooltip>
        )}
      </div>
      <TextField
        fullWidth={fullWidth}
        InputProps={{
          classes: {
            root: `${appearanceType} ${classes.inputBaseRoot}`,
            focused: `${appearanceType} ${classes.notchedOutline}`,
          },
          inputProps,
          endAdornment:
            fieldType.toLowerCase() === 'password' ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ) : (
              <span />
            ),
        }}
        size={size}
        placeholder={placeholder}
        error={!!fieldError}
        helperText={fieldError || ''}
        variant={variant}
        id={fieldId}
        type={showPassword ? 'text' : fieldType}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        value={value}
        {...rest}
      />
    </>
  );
};

export default InputText;
