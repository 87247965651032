/* eslint-disable linebreak-style */
import Card from '@material-ui/core/Card';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { device } from '../../shared/components/ResponsiveDevice';
import theme from '../../theme.module.scss';

const tacCard = withStyles(() => ({
  root: {
    fontFamily: 'Reach-Text-Regular, sans-serif',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '800px',

    '&.MuiPaper-root': {
      background: 'transparent',
    },
    '& .MuiCardContent-root:last-child': {
      paddingBottom: '64px',
    },
    '& .MuiCard-root': {
      background: '#fefeffc7',
      paddingLeft: '10%',
      paddingRight: '10%',

      '@media only screen and (max-width: 600px)': {
        background: '#fefeffc7',
        paddingLeft: '0%',
        paddingRight: '3%',
      },
    },
    '@media only screen and (max-width: 600px)': {
      width: 'auto',
      maxWidth: '85vw',
    },

    '&:focus': {
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.white,
      },
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.deepBlue,
      borderWidth: '2px',
    },
    '& .MuiFormHelperText-root': {
      fontFamily: 'Reach-Text-Regular, sans-serif',
    },
  },
}))(Card);

const agreeCheckbox = withStyles(() => ({
  root: {
    '&$checked': {
      color: theme.medBlue,
    },
  },
  checked: {
    '& .Mui-checked': {
      fill: theme.lightBlue,
    },
  },
}))(Checkbox);

const formControlLabel = withStyles(() => ({
  root: {},
  label: {
    fontSize: '.85rem',
  },
}))(FormControlLabel);

const actionButtonInverted = withStyles(() => ({
  root: {
    backgroundColor: theme.medBlue,
    color: theme.white,
    border: `1px solid ${theme.medBlue}`,
    padding: '.5rem',
    minWidth: '9rem',
    textTransform: 'capitalize',
    borderRadius: '0px',
    '&:hover': {
      color: theme.white,
      backgroundColor: theme.deepBlue,
    },
  },
  disabled: {
    backgroundColor: '#e4e4e4',
    border: '1px solid #bdbdbd',
  },
}))(Button);

const actionButton = withStyles(() => ({
  root: {
    color: theme.medBlue,
    border: `1px solid ${theme.medBlue}`,
    padding: '.5rem',
    minWidth: '9rem',
    textTransform: 'capitalize',
    borderRadius: '0px',
    '&:hover': {
      color: theme.white,
      backgroundColor: theme.deepBlue,
    },
    [`@media only screen and ${device.mobile}`]: {
      marginRight: '0.5rem',
      marginBottom: '0.4rem',
      padding: '0.4rem',
      minWidth: '8rem',
    },
  },
  disabled: {
    backgroundColor: '#e4e4e4',
    border: '1px solid #bdbdbd',
  },
}))(Button);

export const TACCard = tacCard;
export const AgreeCheckbox = agreeCheckbox;
export const FormControlLabelCustom = formControlLabel;
export const ActionButtonInverted = actionButtonInverted;
export const ActionButton = actionButton;
