/* eslint-disable linebreak-style */
import React from 'react';
import { Box } from '@material-ui/core';
import SecondaryUsers from '../accountUsers/SecondaryUsers';
import Security from '../changePassword/Security';
import { UserProfile } from '../userProfile/UserProfile';

const UserSettingsCenterPanel = () => (
  <Box md={{ mb: 1 }}>
    <Box mb={3} id="SecondaryUsers">
      <SecondaryUsers />
    </Box>
    <Box mb={3} id="UserProfile">
      <UserProfile />
    </Box>
    <Box id="ChangePassword">
      <Security />
    </Box>
  </Box>
);

export default UserSettingsCenterPanel;
