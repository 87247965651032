/* eslint-disable linebreak-style */
import React from 'react';
import useClasses from '../styles/Classes';

export default function RecurringPaymentEnrollmentText({ data }) {
  const classes = useClasses();
  function getEnrolledText() {
    const enrollmentText = `You are currently enrolled in AutoPay.
    Below are the recurring payments scheduled.`;

    return (
      <>
        <div className={classes.blueRow}>
          <div className={classes.paddingTextRecurringPayment}>{enrollmentText}</div>
        </div>
      </>
    );
  }

  function getDisabledText() {
    return (
      <div className={classes.blueRow}>
        <div className={classes.paddingTextRecurringPayment}>
          You are not enrolled in AutoPay.
        </div>
      </div>
    );
  }

  return data && data.length ? getEnrolledText() : getDisabledText();
}
