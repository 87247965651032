/* eslint-disable linebreak-style */
import {
  Button,
  TextField,
  DialogTitle,
  Dialog,
  MenuItem,
  Select,
  FormControl,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import InputWithLabel from '../../../../../shared/components/InputWithLabel';
import { device } from '../../../../../shared/components/ResponsiveDevice';
import theme from '../../../../../theme.module.scss';

const actionButton = withStyles(() => ({
  root: {
    color: theme.medBlue,
    border: `1px solid ${theme.medBlue}`,
    padding: '.5rem',
    minWidth: '9rem',
    textTransform: 'capitalize',
    borderRadius: '0px',
    '&:hover': {
      color: theme.white,
      backgroundColor: theme.deepBlue,
    },
  },
  disabled: {
    backgroundColor: '#e4e4e4',
    border: '1px solid #bdbdbd',
  },
}))(Button);

const deleteUserMethodModal = withStyles(() => ({
  root: {
    '& .MuiDialog-paperScrollPaper': {
      [`@media only screen and ${device.laptop}`]: {
        minHeight: '200px',
        minWidth: '200px',
        border: `2px solid ${theme.lightBlue}`,
      },
      [`@media only screen and ${device.smartphone}`]: {
        minHeight: '300px',
        minWidth: '340px',
        border: `2px solid ${theme.lightBlue}`,
      },
    },

    '& .MuiDialog-paper': {
      margin: 0,
    },
  },
}))(Dialog);

const addUserMethodModal = withStyles(() => ({
  root: {
    '& .MuiDialog-paperScrollPaper': {
      [`@media only screen and ${device.laptop}`]: {
        minHeight: '500px',
        minWidth: '200px',
        border: `2px solid ${theme.lightBlue}`,
      },
      [`@media only screen and ${device.smartphone}`]: {
        minHeight: '500px',
        minWidth: '340px',
        border: `2px solid ${theme.lightBlue}`,
      },
    },

    '& .MuiDialog-paper': {
      margin: 0,
    },
  },
}))(Dialog);

const modalTitleCustom = withStyles(() => ({
  root: {
    color: theme.medBlue,
    padding: '.5rem',
    '& .MuiTypography-root': {
      fontFamily: 'Reach-Text-Regular, sans-serif',
    },
  },
}))(DialogTitle);

const actionButtonInverted = withStyles(() => ({
  root: {
    backgroundColor: theme.medBlue,
    color: theme.white,
    border: `1px solid ${theme.medBlue}`,
    padding: '.5rem',
    minWidth: '9rem',
    textTransform: 'capitalize',
    borderRadius: '0px',
    '&:hover': {
      color: theme.white,
      backgroundColor: theme.deepBlue,
    },
  },
  disabled: {
    backgroundColor: '#e4e4e4',
    border: '1px solid #bdbdbd',
  },
}))(Button);

const actionButtonDangerInverted = withStyles(() => ({
  root: {
    backgroundColor: theme.red,
    color: theme.white,
    border: `1px solid ${theme.red}`,
    padding: '.5rem',
    minWidth: '9rem',
    textTransform: 'capitalize',
    borderRadius: '0px',
    '&:hover': {
      color: theme.white,
      backgroundColor: theme.darkRed,
    },
  },
  disabled: {
    backgroundColor: '#e4e4e4',
    border: '1px solid #bdbdbd',
  },
}))(Button);

const menuItemCustom = withStyles(() => ({
  root: {
    color: theme.deepBlue,

    '& .MuiInputBase-root': {
      minWidth: '150px',
    },
    '& .MuiFormControl-root': {
      minWidth: '150px',
    },
  },
}))(MenuItem);

const selectStateCustom = withStyles(() => ({
  root: {
    padding: '1rem',
  },
  selectMenu: {
    width: '202px',
    maxWidth: '202px',
    minWidth: '202px',
    color: theme.deepBlue,
    textAlign: 'center',
    fontWeight: 600,
    fontFamily: 'Reach-Text-Regular, sans-serif',

    [`@media only screen and ${device.mobile}`]: {
      width: '262px',
      color: theme.deepBlue,
      textAlign: 'center',
      fontWeight: 600,
      fontFamily: 'Reach-Text-Regular, sans-serif',
    },
  },
}))(Select);

const selectAccountCustom = withStyles(() => ({
  root: {
    padding: '1rem',
  },
  selectMenu: {
    width: '202px',
    maxWidth: '202px',
    minWidth: '202px',
    color: theme.medBlue,
    textAlign: 'center',
    fontWeight: 600,
    fontFamily: 'Reach-Text-Regular, sans-serif',

    [`@media only screen and ${device.mobile}`]: {
      color: theme.deepBlue,
      textAlign: 'center',
      fontWeight: 600,
      fontFamily: 'Reach-Text-Regular, sans-serif',
    },
  },
}))(Select);

const textFieldCustom = withStyles({
  root: {
    maxWidth: '250px',
    width: '250px',
    marginLeft: '0rem',

    [`@media only screen and ${device.mobile}`]: {
      maxWidth: '250px',
      width: '250px',
      marginLeft: '0rem',
    },

    '& .MuiInputBase-root': {
      fontFamily: 'Reach-Text-Regular, sans-serif',
      fontSize: '1.0rem',
      color: theme.deepBlue,
      fontWeight: '600',
    },

    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.deepBlue,
        borderRadius: '0px',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'green',
      },
    },
  },
})(TextField);

const addUserTextFieldCustom = withStyles({
  root: {
    maxWidth: '305px',
    width: '305px',
    marginLeft: '0rem',

    '& .MuiInputBase-root': {
      fontFamily: 'Reach-Text-Regular, sans-serif',
      fontSize: '1.0rem',
      color: theme.deepBlue,
      fontWeight: '600',
    },

    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.deepBlue,
        borderRadius: '0px',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'green',
      },
    },
  },
})(TextField);

const formControlCustom = withStyles({
  root: {},
})(FormControl);

const accountKey = withStyles(() => ({
  root: {
    marginLeft: 'auto !important',
    marginRight: 'auto !important',

    '& .MuiInputBase-root': {
      fontFamily: 'Reach-Text-Regular, sans-serif',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      marginLeft: 'auto !important',
      marginRight: 'auto !important',
    },
  },
}))(InputWithLabel);

export const SelectStateCustom = selectStateCustom;
export const SelectAccountCustom = selectAccountCustom;
export const ActionButtonInverted = actionButtonInverted;
export const ActionButtonDangerInverted = actionButtonDangerInverted;
export const ModalTitleCustom = modalTitleCustom;
export const DeleteUserMethodModal = deleteUserMethodModal;
export const AddUserMethodModal = addUserMethodModal;
export const ActionButton = actionButton;
export const TextFieldCustom = textFieldCustom;
export const AddUserTextFieldCustom = addUserTextFieldCustom;
export const MenuItemCustom = menuItemCustom;
export const FormControlCustom = formControlCustom;
export const AccountKey = accountKey;
