/* eslint-disable linebreak-style */
import axios from 'axios';
import { adjustmentApiEndPoints } from '../../constants/apiConstants';
import { UseAuthorization } from '../../utils/UseAuthorization';
import * as types from '../actionTypes/AdjustmentsActionTypes';
import { handleUnAuthorizedUser } from './authActionCreators';
import { getTransactionHistory } from './TransactionHistoryActionCreators';

export const saveRefundReasons = (data) => ({
  type: types.REFUND_REASONS,
  data,
});

export const closeConfirmationModal = (dispatch) => {
  dispatch({
    type: types.CLOSE_CONFIRMATION,
  });
};
export const closeAdjustmentsModal = (dispatch) => {
  dispatch({
    type: types.CLOSE_ADJUSTMENTS_CONFIRMATION,
  });
};
export const toggleRequestRefundModal = (dispatch, isOpen) => {
  dispatch({
    type: types.TOGGLE_REQUEST_REFUND_MODAL,
    isOpen,
  });
};

export const creditAdjustmentsRequest = () => ({
  type: types.CREDIT_ADJUSTMENT_SUBMITTED_CONFIRMATION,
});
export const loadRefundReasons = (data) => ({
  type: types.LOADING_ADJUSTMENT_REASONS,
  data,
});

/** Request Adjustment */
export const requestRefund = async (dispatch, data) => {
  dispatch(creditAdjustmentsRequest());
  axios
    .post(`${adjustmentApiEndPoints.requestAdjustment}`, data, await UseAuthorization())
    .then((response) => {
      if (response.data.status === 200) {
        if (data.paymentTransactionId || data.paymentDate) {
          dispatch({
            type: types.REFUND_SUBMITTED,
          });
        } else {
          dispatch({
            type: types.CREDIT_ADJUSTMENTS_SUBMITTED,
          });
        }
        dispatch(getTransactionHistory());
      } else if (response.data.status === 500) {
        dispatch({
          type: types.REFUND_SUBMISSION_FAILED,
        });
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.status === 401) {
        handleUnAuthorizedUser(dispatch);
      }
      dispatch({
        type: types.REFUND_SUBMISSION_FAILED,
      });
      throw error;
    });
};

/** Get all Adjustment reasons */
export const getRefundReasons = async (dispatch) => {
  dispatch(loadRefundReasons(true));
  axios
    .get(`${adjustmentApiEndPoints.getAdjustmentReasons}`, await UseAuthorization())
    .then((response) => {
      if (response.status === 200) {
        dispatch(saveRefundReasons(response.data.data));
      } else if (response.status === 500) {
        dispatch(loadRefundReasons(false));
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.status === 401) {
        handleUnAuthorizedUser(dispatch);
      }
      throw error;
    });
};
