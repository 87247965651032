/* eslint-disable linebreak-style */
import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { NewUserContent, Assistance } from './NewUserContent';
import useClasses from '../styles/SupportClasses';

const NewUser = () => {
  const [steps] = useState(NewUserContent);
  const [assistance] = useState(Assistance);
  const classes = useClasses();
  return (
    <Grid className={classes.container}>
      <Grid className={classes.subSectionHeader}>New User?</Grid>
      <Grid className={classes.moduleContent}>
        <Grid className={classes.row}>
          <Grid className={classes.paddingDiv0}>
            <div className="faqDiv">
              {steps &&
                steps.map((item) => (
                  <div key={item.id}>
                    <div className={`${classes.flexUser} ${classes.faqRow}`}>
                      <span>{`Step${item.id}:`}</span>
                      <span className={`${classes.newUser}`}>{item.value}</span>
                    </div>
                  </div>
                ))}
            </div>
            <div className={`${classes.flexUser} ${classes.assistance}`}>
              {assistance}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default NewUser;
