/* eslint-disable linebreak-style */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import TermsAndConditionsText from './terms-and-conditions-text';
import * as EL from './StyledComponents';
import * as MUI from './MUI_Components';
import useClasses from './Classes';
import * as Actions from '../../redux/actionCreators/UserActionCreators';
import { ComponentLoader } from '../../shared/components/Loader';
import ValidationError from '../../shared/components/ValidationError';

const TermsAndConditions = () => {
  const [agreetoterms, setAgreeToTerms] = useState(false);

  const handleChange = (event) => {
    setAgreeToTerms(event.target.checked);
  };

  const handleContinue = () => {
    Actions.UpdateTermsandConditionsAcceptedStatus(dispatch);
  };

  const dispatch = useDispatch();

  const { isTermsandConditionsUpdateFailed, isTermsAndConditionsLoading } = useSelector(
    (state) => ({
      isTermsandConditionsUpdateFailed:
        state.authReducer.isTermsandConditionsUpdateFailed,
      isTermsAndConditionsLoading: state.authReducer.isTermsAndConditionsLoading,
    })
  );
  const classes = useClasses();
  return (
    <>
      {isTermsAndConditionsLoading ? (
        <ComponentLoader />
      ) : (
        <EL.Wrapper>
          <Grid className={`${classes.module}`}>
            <Grid className={`${classes.container}`}>
              <MUI.TACCard>
                <div>
                  <div className={`${classes.cardContent}`}>
                    <div className={`${classes.headerText}`}>Terms and Conditions</div>
                    <div className={`${classes.scrollbox}`}>
                      <TermsAndConditionsText />
                    </div>
                    <div className={`${classes.checkboxDiv}`}>
                      <MUI.FormControlLabelCustom
                        control={
                          <MUI.AgreeCheckbox
                            checked={agreetoterms}
                            onChange={handleChange}
                            name="checkedB"
                          />
                        }
                        label="By checking this box you acknowledge that you
                        have read and agree to the terms and conditions"
                      />
                    </div>

                    <div className={`${classes.checkboxDiv}`}>
                      {isTermsandConditionsUpdateFailed && (
                        <ValidationError
                          validationErrorData={isTermsandConditionsUpdateFailed}
                        />
                      )}
                    </div>
                    <div className={`${classes.continueDiv}`}>
                      <MUI.ActionButton disabled={!agreetoterms} onClick={handleContinue}>
                        Continue
                      </MUI.ActionButton>
                    </div>
                  </div>
                </div>
              </MUI.TACCard>
            </Grid>
          </Grid>
        </EL.Wrapper>
      )}
    </>
  );
};

export default TermsAndConditions;
