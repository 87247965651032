/* eslint-disable linebreak-style */
import * as types from '../actionTypes/AccountUsersActionTypes';

const accountUsersReducerInitialState = {
  secondaryUsers: [],
  secondaryUser: {
    firstName: '',
    lastName: '',
    emailAddress: '',
    userName: '',
    isDisableModalOpen: false,
  },
  errorMessage: '',
  enableAddUserModal: false,
  isGettingSecondaryUsersLoading: false,
  isDisableUserLoading: false,
  isEnableUserLoading: false,
  isAddScondaryUserLoading: false,
  userCreationFailedError: '',
  userEnablingFailedError: '',
  userDisablingFailedError: '',
  getSecondaryUsersFailedError: '',
  isSaveUserModalOpen: false,
  isUpdatingUserLoading: false,
  userUpdateFailedError: '',
};

const accountUsersReducer = (state = accountUsersReducerInitialState, action) => {
  switch (action.type) {
    case types.CHANGE_SECONDARY_USER_FIELD_VALUE: {
      const { value, id } = action.data && action.data;
      return {
        ...state,
        secondaryUser: {
          ...state.secondaryUser,
          [id]: value,
        },
        userDisablingFailedError: '',
        userEnablingFailedError: '',
      };
    }
    case types.SECONDARY_USERS: {
      return {
        ...state,
        secondaryUsers: action.data,
      };
    }
    case types.SAVE_USER_MODAL: {
      return {
        ...state,
        isSaveUserModalOpen: false,
      };
    }
    case types.USER_CREATED: {
      return {
        ...state,
        enableAddUserModal: false,
        isSaveUserModalOpen: true,
      };
    }
    case types.ERROR_MESSAGE: {
      return {
        ...state,
        errorMessage: action.data,
      };
    }
    case types.ENABLE_USER: {
      return {
        ...state,
        secondaryUsers: state.secondaryUsers.map((item) =>
          item.id === action.data
            ? {
                ...item,
                active: true,
              }
            : item
        ),
      };
    }
    case types.DISABLE_USER: {
      return {
        ...state,
        secondaryUsers: state.secondaryUsers.map((item) =>
          item.id === action.data
            ? {
                ...item,
                active: false,
              }
            : item
        ),
      };
    }
    case types.RENDER_ADD_USER_MODAL: {
      return {
        ...state,
        enableAddUserModal: action.data,
        userCreationFailedError: false,
        errorMessage: '',
        isSaveUserModalOpen: false,
      };
    }
    case types.RESET_ADD_USER_FORM: {
      return {
        ...state,
        secondaryUser: accountUsersReducerInitialState.secondaryUser,
      };
    }
    case types.RESET_ACCOUNT_USERS: {
      return {
        ...state,
        secondaryUser: accountUsersReducerInitialState.secondaryUser,
        enableAddUserModal: false,
        isGettingSecondaryUsersLoading: false,
        isDisableUserLoading: false,
        isEnableUserLoading: false,
        isAddScondaryUserLoading: false,
        userCreationFailedError: false,
        userEnablingFailedError: false,
        userDisablingFailedError: false,
        getSecondaryUsersFailedError: false,
      };
    }
    case types.LOADING_DISABLE_USER: {
      return {
        ...state,
        isDisableUserLoading: action.data,
      };
    }
    case types.LOADING_UPDATING_USER: {
      return {
        ...state,
        isUpdatingUserLoading: action.data,
      };
    }
    case types.LOADING_SECONDARY_USERS: {
      return {
        ...state,
        isGettingSecondaryUsersLoading: action.data,
      };
    }
    case types.LOADING_ENABLE_USER: {
      return {
        ...state,
        isEnableUserLoading: action.data,
      };
    }
    case types.LOADING_ADD_SECONDARY_USER: {
      return {
        ...state,
        isAddScondaryUserLoading: action.data,
      };
    }
    case types.FAILED_CREATING_USER: {
      return {
        ...state,
        userCreationFailedError: action.data,
      };
    }
    case types.FAILED_ENABLING_USER: {
      return {
        ...state,
        userEnablingFailedError: action.data,
      };
    }
    case types.FAILED_UPDATED_USER: {
      return {
        ...state,
        userUpdateFailedError: action.data,
      };
    }
    case types.FAILED_DISABLING_USER: {
      return {
        ...state,
        userDisablingFailedError: action.data,
      };
    }
    case types.FAILED_GETTING_SECONDARYUSERS: {
      return {
        ...state,
        getSecondaryUsersFailedError: action.data,
      };
    }
    default:
      return state;
  }
};

export { accountUsersReducerInitialState, accountUsersReducer };
