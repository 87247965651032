/* eslint-disable linebreak-style */
import React from 'react';
import { Panels, LeftPanel } from '@spectrum-reach/external-theme';
// Internal Sub Components
import ManageAccountCenterPanel from './components/panels/ManageAccountCenterPanel';
import ManageAccountRightPanel from './components/panels/ManageAccountRightPanel';

const UserSettings = () => {
  const leftPanelLinks = [
    {
      name: 'Account Users',
      smoothTo: '#SecondaryUsers',
    },
    {
      name: 'User Profile',
      smoothTo: '#UserProfile',
    },
    {
      name: 'Change Password',
      smoothTo: '#ChangePassword',
    },
  ];
  return (
    <Panels
      title="Manage Account"
      left={<LeftPanel leftPanelLinks={leftPanelLinks} />}
      center={<ManageAccountCenterPanel />}
      right={<ManageAccountRightPanel />}
    />
  );
};

export default UserSettings;
