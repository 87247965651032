/* eslint-disable linebreak-style */
import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import useClasses from './ExtSupportClasses';
import TermsAndConditionsText from '../terms-and-conditions/terms-and-conditions-text';
import * as MUI from '../portal/support/components/styles/MUIComponents';
import ExternalLayout from './ExternalLayout';

const ExternalTermsAndConditions = () => {
  const [enableTermsAndConditions, setEnableTermsAndConditions] = useState();
  const classes = useClasses();
  return (
    <ExternalLayout title="Terms and Conditions">
      <>
        <Grid
          className={`${classes.upperPad} ${classes.TermsAndConditionsBTNdiv} ${classes.buttonDiv}`}
        >
          <span className={`${classes.marleft0}`}>
            {!enableTermsAndConditions ? (
              <MUI.TermsAndConditionsBTN
                onClick={() => {
                  setEnableTermsAndConditions(true);
                }}
              >
                View Terms and Conditions
              </MUI.TermsAndConditionsBTN>
            ) : (
              <MUI.TermsAndConditionsBTN
                onClick={() => {
                  setEnableTermsAndConditions(false);
                }}
              >
                Close
              </MUI.TermsAndConditionsBTN>
            )}
          </span>
        </Grid>

        {enableTermsAndConditions && (
          <Grid className={classes.scrollbox}>
            <TermsAndConditionsText />
          </Grid>
        )}
      </>
    </ExternalLayout>
  );
};
export default ExternalTermsAndConditions;
