/* eslint-disable linebreak-style */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import * as MUI from '../styles/MUI_Components';
import useClasses from '../styles/Classes';
import customStyles from '../styles/CustomStyles';
import DeletePaymentMethod from './DeletePaymentMethod';
import * as Actions from '../../../../../redux/actionCreators/PaymentActionCreators';
import { ComponentLoader } from '../../../../../shared/components/Loader';
import { getSelectedAutoPayMethodId } from '../../../../../redux/selectors/PaymentSelectors';
import PaymentMethodTypes from './PaymentMethodTypes';

const PaymentMethods = (props) => {
  const [launchDeleteConfirm, setLaunchDeleteConfirm] = useState(false);
  const [paymentMethodDetails, setPaymentMethodDetails] = useState({ billing: '' });

  const dispatch = useDispatch();

  const { paymentMethods, defaultPaymentId, paymentMethodLoading } = useSelector(
    (state) => ({
      paymentMethods: state.paymentReducer.paymentMethods,
      showAddPaymentModal: state.paymentReducer.showAddPaymentModal,
      defaultPaymentId: getSelectedAutoPayMethodId(state),
      paymentMethodLoading: state.paymentReducer.paymentMethodLoading,
    })
  );

  useEffect(() => {
    Actions.getPaymentMethods(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteMethod = (val) => {
    setLaunchDeleteConfirm(true);
    setPaymentMethodDetails(_.find(paymentMethods, { paymentTokenId: val }));
  };

  const deleteMethodConfirm = (val) => {
    setLaunchDeleteConfirm(false);
    Actions.DeletePaymentMethodAction(dispatch, val);
  };
  const getDeletePaymentMethodCallback = (val) => {
    if (val === 'cancel') {
      setLaunchDeleteConfirm(false);
      return;
    }

    deleteMethodConfirm(val);
  };

  const classes = useClasses();

  return (
    <Grid className={classes.containerManagePayMethods}>
      <Grid className={classes.subSectionHeader}>Manage Payment Methods</Grid>
      <Grid className={classes.moduleContent}>
        <Grid className={classes.row}>
          <>
            {paymentMethodLoading ? (
              <ComponentLoader />
            ) : (
              <>
                {paymentMethods.map((item) => (
                  <div
                    className={classes.paymentMethodListWrapperActive}
                    key={item.paymentTokenId}
                  >
                    <div className={classes.paymentMethodListDiv}>
                      <span className={classes.paymentMethodListItem}>
                        {item.brand.startsWith('ACH') ? 'Checking' : item.brand}
                      </span>
                      <span className={classes.paymentMethodListItem}>
                        <small style={customStyles.smallCC}>Last 4</small> -{' '}
                        {item.paymentMethodSuffix}
                      </span>
                      <span
                        className={classes.deleteMethod}
                        role="link"
                        tabIndex={0}
                        onKeyPress={() => ''}
                        onClick={() => {
                          deleteMethod(item.paymentTokenId);
                        }}
                      >
                        delete
                      </span>
                    </div>
                    {item.isAutoPayEnabled && (
                      <MUI.EnrolledTag size="small" label="AutoPay Enrolled" />
                    )}
                  </div>
                ))}
              </>
            )}
          </>
        </Grid>
        <>
          <PaymentMethodTypes showCloseBtn={props.showCloseBtn} isRecurringPaymentFlow />
          <div
            className={`${classes.padleft4} ${classes.upperPad} ${classes.buttonDiv}`}
          />
        </>

        <DeletePaymentMethod
          launchDeletePaymentMethod={launchDeleteConfirm}
          selecteddata={paymentMethodDetails}
          deletepaymentmethodCallback={getDeletePaymentMethodCallback}
          defaultPaymentId={defaultPaymentId}
        />
      </Grid>
    </Grid>
  );
};

export default PaymentMethods;
