/* eslint-disable linebreak-style */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { GetUserInfoAction } from '../../redux/actionCreators/UserActionCreators';
import { ComponentLoader } from '../components/Loader';
import useClasses from './AccountInformationClasses';

const AccountInformation = () => {
  const dispatch = useDispatch();

  const { userInfoDetails, isFetchingUserLoading } = useSelector((state) => ({
    userInfoDetails: state.userReducer.userInfoDetails,
    isFetchingUserLoading: state.userReducer.isFetchingUserLoading,
  }));

  useEffect(() => {
    if (Object.keys(userInfoDetails).length === 0) {
      dispatch(GetUserInfoAction('AccountInformation'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useClasses();

  return (
    <>
      {isFetchingUserLoading ? (
        <ComponentLoader />
      ) : (
        <div>
          <Grid className={`${classes.rightUserInfoUL}`}>
            <div className={`${classes.rightUserInfoHeaderLI}`}>Account Information</div>
            <div className={`${classes.rightUserInfoLI}`}>{userInfoDetails.userName}</div>
            <div className={`${classes.rightUserInfoLI}`}>
              {userInfoDetails.firstName} {userInfoDetails.lastName}
            </div>
            <div className={`${classes.rightUserInfoLI}`}>
              AE: {userInfoDetails.accountExecutive}
            </div>
            <div className={`${classes.rightUserInfoLI}`}>
              Company ID : {userInfoDetails.agencyId}
            </div>
            <div className={`${classes.rightUserInfoLI}`}>
              Company Name: {userInfoDetails.companyName}
            </div>
          </Grid>
        </div>
      )}
    </>
  );
};

export default AccountInformation;
