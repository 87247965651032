import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

// TODO: Update theme colors.
const useStyles = makeStyles((theme) => ({
  title: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    margin: 0,
    color: theme.palette.primary,
    width: '225px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(0),
    },
  },
}));

const PageTitle = ({ title }) => {
  const classes = useStyles();

  return <h2 className={classes.title}>{title}</h2>;
};

export default PageTitle;
