/* eslint-disable linebreak-style */
import Button from '@material-ui/core/Button';
import { Dialog, Card } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { device } from '../../shared/components/ResponsiveDevice';
import theme from '../../theme.module.scss';

const actionButton = withStyles(() => ({
  root: {
    color: theme.medBlue,
    border: `1px solid ${theme.medBlue}`,
    padding: '.5rem',
    minWidth: '9rem',
    textTransform: 'capitalize',
    borderRadius: '0px',
    '&:hover': {
      color: theme.white,
      backgroundColor: theme.deepBlue,
    },
  },
  disabled: {
    backgroundColor: '#e4e4e4',
    border: '1px solid #bdbdbd',
  },
}))(Button);

const actionButtonInverted = withStyles(() => ({
  root: {
    backgroundColor: theme.medBlue,
    color: theme.white,
    border: `1px solid ${theme.medBlue}`,
    padding: '.5rem',
    minWidth: '9rem',
    textTransform: 'capitalize',
    borderRadius: '0px',
    '&:hover': {
      color: theme.white,
      backgroundColor: theme.deepBlue,
    },
  },
  disabled: {
    backgroundColor: '#e4e4e4',
    border: '1px solid #bdbdbd',
  },
}))(Button);

const submit = withStyles(() => ({
  root: {
    color: 'white',
    marginTop: '1rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    padding: '1.25rem',
    width: '100%',
    backgroundColor: theme.lightBlue,
    '&:hover': {
      background: theme.deepBlue,
    },
  },
  disabled: {
    backgroundColor: '#e4e4e4',
    border: '1px solid #bdbdbd',
  },
}))(Button);

const textFieldCustom = withStyles({
  root: {
    maxWidth: '225px',
    marginLeft: '0rem',

    '& .MuiInputBase-root': {
      fontFamily: 'Reach-Text-Regular, sans-serif',
      fontSize: '1.0rem',
      color: theme.deepBlue,
      fontWeight: '600',
      height: '2rem',
      padding: '1.5rem',
    },

    '& label.Mui-focused': {
      color: theme.slateGrey,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.deepBlue,
        borderRadius: '0px',
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.slateGrey,
      },
    },

    '& .MuiFormHelperText-root.Mui-error': {
      color: 'red',
      width: '240px',
      marginLeft: '0rem',
    },
  },
})(TextField);

const registrationKeyGuideModal = withStyles(() => ({
  root: {
    '& .MuiDialog-paperScrollPaper': {
      [`@media only screen and ${device.laptop}`]: {
        minWidth: '500px',
        border: `2px solid ${theme.lightBlue}`,
      },
      [`@media only screen and ${device.smartphone}`]: {
        minHeight: '500px',
        minWidth: '340px',
        border: `2px solid ${theme.lightBlue}`,
      },
    },

    '& .MuiDialog-paper': {
      margin: 0,
    },
  },
}))(Dialog);

const cardContainer = withStyles(() => ({
  root: {
    fontFamily: 'Reach-Text-Regular, sans-serif',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '800px',

    '&.MuiPaper-root': {
      background: 'transparent',
    },
    '& .MuiCardContent-root:last-child': {
      paddingBottom: '64px',
    },
    '& .MuiCard-root': {
      background: '#fefeffc7',
      paddingLeft: '10%',
      paddingRight: '10%',

      '@media only screen and (max-width: 600px)': {
        background: '#fefeffc7',
        paddingLeft: '0%',
        paddingRight: '3%',
      },
    },
    '@media only screen and (max-width: 600px)': {
      width: 'auto',
      maxWidth: '85vw',
    },

    '&:focus': {
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.white,
      },
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.deepBlue,
      borderWidth: '2px',
    },
    '& .MuiFormHelperText-root': {
      fontFamily: 'Reach-Text-Regular, sans-serif',
    },
  },
}))(Card);

export const ActionButtonInverted = actionButtonInverted;
export const ActionButton = actionButton;
export const TextFieldCustom = textFieldCustom;
export const Submit = submit;
export const RegistrationKeyGuideModal = registrationKeyGuideModal;
export const CardContainer = cardContainer;
