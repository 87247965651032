/* eslint-disable linebreak-style */
import React from 'react';
import { Box } from '@material-ui/core';
import FAQs from '../frequentlyAskedQuestions/FAQs';
import TermsAndConditions from '../termsAndConditions/TermsAndConditions';
import NewUser from '../NewUser/NewUser';

const AccountCenterPanel = () => (
  <Box md={{ mb: 1 }}>
    <Box mb={3} id="FAQs">
      <FAQs />
    </Box>
    <Box mb={3} id="NewUser">
      <NewUser />
    </Box>
    <Box height="100%" id="TermsAndConditions">
      <TermsAndConditions />
    </Box>
  </Box>
);

export default AccountCenterPanel;
