/* eslint-disable linebreak-style */
/* eslint-disable no-console */
import axios from 'axios';
import * as types from '../actionTypes/AccountUsersActionTypes';
import { AccountUsersApiEndPoints } from '../../constants/apiConstants';
import { UseAuthorization } from '../../utils/UseAuthorization';
import { handleUnAuthorizedUser } from './authActionCreators';
import { GetUserInfoAction } from './UserActionCreators';
import { getErrorMessages } from '../../utils/ErrorHandler';

export const changeSecondaryUserFieldValue = (data) => ({
  type: types.CHANGE_SECONDARY_USER_FIELD_VALUE,
  data,
});
export const resetAddSecondaryUserForm = () => ({
  type: types.RESET_ADD_USER_FORM,
});

export const getSecondaryUsersSuccess = (data) => ({
  type: types.SECONDARY_USERS,
  data,
});

export const enableUserSuccess = (data) => ({
  type: types.ENABLE_USER,
  data,
});
export const updateUserSuccess = (data) => ({
  type: types.UPDATE_USER,
  data,
});
export const disableUserSuccess = (data) => ({
  type: types.DISABLE_USER,
  data,
});

export const renderDisableUserModal = (data) => ({
  type: types.DISABLE_USER_MODAL,
  data,
});
export const renderAddUserModal = (data) => ({
  type: types.RENDER_ADD_USER_MODAL,
  data,
});
export const userCreated = (data) => ({
  type: types.USER_CREATED,
  data,
});
export const userCreationProcessStatus = (data) => ({
  type: types.USER_CREATION_PROCESS_STATUS,
  data,
});
export const loadSecondaryUsers = (data) => ({
  type: types.LOADING_SECONDARY_USERS,
  data,
});
export const loadDisableUser = (data) => ({
  type: types.LOADING_DISABLE_USER,
  data,
});
export const loadEnableUser = (data) => ({
  type: types.LOADING_ENABLE_USER,
  data,
});
export const loadUpdateUser = (data) => ({
  type: types.LOADING_UPDATING_USER,
  data,
});
export const loadAddSecondaryUser = (data) => ({
  type: types.LOADING_ADD_SECONDARY_USER,
  data,
});
export const failedUpdatingUser = (data) => ({
  type: types.FAILED_UPDATED_USER,
  data,
});
export const failedCreatingUser = (data) => ({
  type: types.FAILED_CREATING_USER,
  data,
});
export const failedEnablingUser = (data) => ({
  type: types.FAILED_ENABLING_USER,
  data,
});
export const failedDisablingUser = (data) => ({
  type: types.FAILED_DISABLING_USER,
  data,
});
export const failedGettingSecondaryUsers = (data) => ({
  type: types.FAILED_GETTING_SECONDARYUSERS,
  data,
});
export const resetAccountUsers = () => ({
  type: types.RESET_ACCOUNT_USERS,
});

export const errorMessage = (data) => ({
  type: types.ERROR_MESSAGE,
  data,
});

export const handleSaveUserModal = (data) => ({
  type: types.SAVE_USER_MODAL,
  data,
});
export const enableUser = async (dispatch, userId) => {
  dispatch(loadEnableUser(true));
  axios
    .post(
      `${AccountUsersApiEndPoints.enableUser}?userId=${userId}`,
      {},
      await UseAuthorization()
    )
    .then((response) => {
      dispatch(loadEnableUser(false));
      if (response.data.status === 200 && response.data.isSuccess) {
        dispatch(enableUserSuccess(userId));
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.status === 401) {
        handleUnAuthorizedUser(dispatch);
      } else if (error && error.response && error.response.status === 400) {
        const errorMsg = getErrorMessages(
          error,
          'Failed to enable user. Please contact support.'
        );
        dispatch(failedEnablingUser(errorMsg));
      } else {
        dispatch(failedEnablingUser('Failed to enable user. Please contact support.'));
      }
      dispatch(loadEnableUser(false));
      throw error;
    });
};

export const disableUser = async (dispatch, userId) => {
  dispatch(loadDisableUser(true));
  axios
    .post(
      `${AccountUsersApiEndPoints.disableUser}?userId=${userId}`,
      {},
      await UseAuthorization()
    )
    .then((response) => {
      dispatch(loadDisableUser(false));
      if (response.data.status === 200) {
        dispatch(
          changeSecondaryUserFieldValue({
            value: false,
            id: 'isDisableModalOpen',
          })
        );
        dispatch(disableUserSuccess(userId));
        dispatch(renderDisableUserModal(false));
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.status === 401) {
        handleUnAuthorizedUser(dispatch);
      } else if (error && error.response && error.response.status === 400) {
        const errorMsg = getErrorMessages(
          error,
          'Failed to disable user. Please contact support.'
        );
        dispatch(failedDisablingUser(errorMsg));
      } else {
        dispatch(failedDisablingUser('Failed to disable user. Please contact support.'));
      }
      dispatch(loadDisableUser(false));
      throw error;
    });
};

export const getSecondaryUsers = async (dispatch) => {
  dispatch(loadSecondaryUsers(true));
  axios
    .get(`${AccountUsersApiEndPoints.getSecondaryUsers}`, await UseAuthorization())
    .then((response) => {
      dispatch(loadSecondaryUsers(false));
      if (response.data.status === 200) {
        dispatch(getSecondaryUsersSuccess(response.data.data));
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.status === 401) {
        handleUnAuthorizedUser(dispatch);
      }
      if (error && error.response && error.response.status === 400) {
        const errorMsg = getErrorMessages(
          error,
          'Failed to load secondary users. Please contact support.'
        );
        dispatch(failedGettingSecondaryUsers(errorMsg));
      } else {
        dispatch(
          failedGettingSecondaryUsers(
            'Failed to load secondary users. Please contact support.'
          )
        );
      }
      dispatch(loadSecondaryUsers(false));
      throw error;
    });
};

export const createSecondaryUser = async (dispatch, data) => {
  dispatch(loadAddSecondaryUser(true));
  const userData = {
    firstName: data.firstName,
    lastName: data.lastName,
    userName: data.userName,
    emailAddress: data.emailAddress,
    validationUrl: `${process.env.REACT_APP_DEV_DOMAIN_URL}email-confirmation`,
  };
  axios
    .post(
      `${AccountUsersApiEndPoints.createSecondaryUser}`,
      userData,
      await UseAuthorization()
    )
    .then((response) => {
      dispatch(loadAddSecondaryUser(false));
      if (response.data.status === 200) {
        getSecondaryUsers(dispatch);
        dispatch(userCreated(true));
        dispatch(resetAddSecondaryUserForm());
      } else if (response.data.status === 400) {
        const errorMsg =
          response.data.error ||
          'Failed to create secondary user. Please contact support.';
        dispatch(failedCreatingUser(errorMsg));
      } else {
        dispatch(
          failedCreatingUser('Failed to create secondary user. Please contact support.')
        );
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.status === 401) {
        handleUnAuthorizedUser(dispatch);
      }
      const errorMsg = getErrorMessages(
        error,
        'Failed to create secondary user. Please contact support.'
      );
      dispatch(failedCreatingUser(errorMsg));
      dispatch(loadAddSecondaryUser(false));
      if (error && error.response && error.response.status === 500) {
        const keys = Object.keys(error.response.data.data);
        const isValidKeys = keys.includes('8' || '7');
        if (isValidKeys) {
          const values = Object.values(error.response.data.data);
          const message = values.join(', ');
          dispatch(errorMessage(message));
        }
      }
      throw error;
    });
};

export const updateUser = (data, userName) => async (dispatch) => {
  dispatch(loadUpdateUser(true));
  axios
    .post(
      `${AccountUsersApiEndPoints.updateUser}?userName=${userName}`,
      data,
      await UseAuthorization()
    )
    .then((response) => {
      dispatch(loadUpdateUser(false));
      if (response.data.status === 200 && response.data.isSuccess) {
        dispatch(updateUserSuccess(response.data.data));
        dispatch(GetUserInfoAction());
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.status === 401) {
        handleUnAuthorizedUser(dispatch);
      } else if (error && error.response && error.response.status === 400) {
        const errorMsg = getErrorMessages(
          error,
          'Failed to update user. Please contact support.'
        );
        dispatch(failedUpdatingUser(errorMsg));
      } else {
        dispatch(failedUpdatingUser('Failed to update user. Please contact support.'));
      }
      dispatch(loadUpdateUser(false));
      throw error;
    });
};
