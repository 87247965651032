/* eslint-disable linebreak-style */
import React, { useState } from 'react';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Grid } from '@material-ui/core';
import useClasses from '../styles/SupportClasses';
import { FaqContent } from './FaqContent';

const FAQs = () => {
  const [activeQuestion, setActiveQuestion] = useState({ activeItem: null });
  const [faqContent] = useState(FaqContent);

  const toggleActiveItem = (val) => {
    if (val === activeQuestion.activeItem) {
      setActiveQuestion([]);
    } else {
      setActiveQuestion({ activeItem: val });
    }
  };

  const classes = useClasses();
  return (
    <>
      <Grid className={classes.container}>
        <Grid className={classes.subSectionHeader}>Frequently Asked Questions</Grid>
        <Grid className={classes.moduleContent}>
          <Grid className={classes.row}>
            <Grid className={classes.paddingDiv0}>
              <div className="faqDiv">
                {faqContent.map((item) => (
                  <div key={item.id}>
                    <div
                      className={`${classes.flex} ${classes.faqRow}`}
                      role="link"
                      tabIndex={0}
                      onKeyPress={() => ''}
                      onClick={() => {
                        toggleActiveItem(item.id);
                      }}
                    >
                      <span className={`${classes.faqQuestion}`}>{item.question}</span>
                      <div
                        className={classes.arrowIcon}
                        role="link"
                        tabIndex={0}
                        onKeyPress={() => ''}
                        onClick={() => {
                          toggleActiveItem(item.id);
                        }}
                      >
                        {activeQuestion.activeItem === item.id ? (
                          <KeyboardArrowDownIcon />
                        ) : (
                          <KeyboardArrowRightIcon />
                        )}
                      </div>
                    </div>
                    {activeQuestion.activeItem === item.id ? (
                      <span
                        className={`${classes.faqAnswer}`}
                        dangerouslySetInnerHTML={{ __html: item.answer }}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                ))}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default FAQs;
