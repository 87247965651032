/* eslint-disable linebreak-style */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as MUI from '../styles/MUIComponents';
import useClasses from '../styles/ManageAccountClasses';
import { isValidEmailField } from '../../../../../utils/Validations';
import * as Actions from '../../../../../redux/actionCreators/AccountUsersActionCreators';
import { ComponentLoader } from '../../../../../shared/components/Loader';
import ValidationError from '../../../../../shared/components/ValidationError';

const AddUser = ({ launchAddUserMethod }) => {
  const classes = useClasses();
  const dispatch = useDispatch();

  const {
    secondaryUser,
    isAddScondaryUserLoading,
    userCreationFailedError,
    errorMessage,
  } = useSelector((state) => ({
    secondaryUser: state.accountUsersReducer.secondaryUser,
    isAddScondaryUserLoading: state.accountUsersReducer.isAddScondaryUserLoading,
    userCreationFailedError: state.accountUsersReducer.userCreationFailedError,
    errorMessage: state.accountUsersReducer.errorMessage,
  }));

  const [errorResponse, resetErrorResponse] = useState(false);
  const getValue = (id) => {
    const value = secondaryUser && secondaryUser[id];
    return value;
  };
  const handleChange = (e) => {
    const { value, name } = e.target;
    dispatch(Actions.changeSecondaryUserFieldValue({ value, id: name }));
    resetErrorResponse(false);
  };

  return (
    <MUI.AddUserMethodModal
      open={launchAddUserMethod}
      aria-labelledby="addUser-title"
      aria-describedby="addUser-description"
    >
      {!isAddScondaryUserLoading ? (
        <>
          <div className={classes.modalBody}>
            <div className={classes.modalTitle}> Add User </div>
            <div className={classes.upperPad}>
              <div>
                <div>
                  <span className={classes.adduserInfoText}>
                    To add a new user, complete the form below. Secondary users can makes
                    payments and view accounts that you have access to.
                  </span>
                </div>
              </div>
              <form autoComplete="off">
                <div className={`${classes.adduserForm}`}>
                  <div className={`${classes.upperPad}`}>
                    <MUI.AddUserTextFieldCustom
                      value={getValue('firstName')}
                      inputProps={{
                        style: {
                          padding: '.85rem',
                          textAlign: 'center',
                        },
                      }}
                      label="First Name"
                      variant="outlined"
                      name="firstName"
                      onChange={handleChange}
                    />
                  </div>

                  <div className={`${classes.upperPad}`}>
                    <MUI.AddUserTextFieldCustom
                      value={getValue('lastName')}
                      inputProps={{
                        style: {
                          padding: '.85rem',
                          textAlign: 'center',
                        },
                      }}
                      label="Last Name"
                      variant="outlined"
                      name="lastName"
                      onChange={handleChange}
                    />
                  </div>
                  <div className={`${classes.upperPad}`}>
                    <MUI.AddUserTextFieldCustom
                      value={getValue('userName')}
                      inputProps={{
                        style: {
                          padding: '.85rem',
                          textAlign: 'center',
                        },
                      }}
                      label="User Name"
                      variant="outlined"
                      name="userName"
                      onChange={handleChange}
                    />
                  </div>
                  <div className={`${classes.upperPad}`}>
                    <MUI.AddUserTextFieldCustom
                      value={getValue('emailAddress')}
                      inputProps={{
                        style: {
                          padding: '.85rem',
                          textAlign: 'center',
                        },
                      }}
                      label="Email"
                      error={
                        getValue('emailAddress') &&
                        !isValidEmailField(getValue('emailAddress'))
                      }
                      helperText={
                        getValue('emailAddress') &&
                        !isValidEmailField(getValue('emailAddress')) &&
                        'Please Enter Valid Email'
                      }
                      variant="outlined"
                      name="emailAddress"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </form>
              {userCreationFailedError && errorResponse && (
                <ValidationError
                  validationErrorData={errorMessage || userCreationFailedError}
                />
              )}
              <div className={`${classes.addusermodalActionButtonDiv}`}>
                <MUI.ActionButton
                  onClick={() => {
                    dispatch(Actions.renderAddUserModal(false));
                    dispatch(Actions.resetAddSecondaryUserForm());
                  }}
                >
                  Cancel
                </MUI.ActionButton>
                <span className={`${classes.marleft1}`}>
                  <MUI.ActionButton
                    disabled={
                      !getValue('firstName') ||
                      !getValue('lastName') ||
                      !getValue('userName') ||
                      !isValidEmailField(getValue('emailAddress'))
                    }
                    onClick={() => {
                      Actions.createSecondaryUser(dispatch, secondaryUser);
                      resetErrorResponse(true);
                    }}
                  >
                    Save User
                  </MUI.ActionButton>
                </span>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className={classes.modalBody}>
          <ComponentLoader />
        </div>
      )}
    </MUI.AddUserMethodModal>
  );
};

export default AddUser;
