/* eslint-disable linebreak-style */
/* eslint-disable*/
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from "moment";
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import * as MUI from '../styles/MUI_Components';
import useClasses from '../styles/Classes';
import * as Actions from '../../../../../redux/actionCreators/PaymentActionCreators';
import { ComponentLoader } from '../../../../../shared/components/Loader';
import ValidationError from '../../../../../shared/components/ValidationError';
import ManagePaymentMethod from '../make-a-payment/ManagePaymentsModel';
import RecurringPaymentEnrollmentText from './RecurringPaymentEnrollmentText';
import RecurringPaymentsHistory from './RecurringPaymentsHistory';
import OrderDetails from './OrderDetails';
import RecurringPaymentConfirmationModal from './RecurringPaymentConfirmationModal';
import {
  // hasDateSelectionError,
  getDayOfMonthValidationError,
  getDayOfWeekDateValidation,
} from './Helpers';

const RecurringPayment = () => {
  const dispatch = useDispatch();
  const classes = useClasses();
  const [dayOfMonthError, setDayOfMonthValidation] = useState('');
  const [amountError, setAmountValidation] = useState('');
  const {
    enrollmentData,
    isEnableManagement,
    paymentMethods,
    showManagePaymentModal,
    recurringPaymentTypesData,
    newEnrollmentData,
    recurringPaymentError,
    weekDaysData,
    autoPayLoading,
    showRecurringPaymentConfirmationModal,
    nextPaymentMessage,
  } = useSelector((state) => ({
    enrollmentData: state.paymentReducer.enrollmentData,
    isEnableManagement: state.paymentReducer.isEnableManagement,
    paymentMethods: state.paymentReducer.paymentMethods,
    showManagePaymentModal: state.paymentReducer.showManagePaymentModal,
    newEnrollmentData: state.paymentReducer.newEnrollmentData,
    recurringPaymentError: state.paymentReducer.recurringPaymentError,
    recurringPaymentTypesData: state.paymentReducer.recurringPaymentTypesData,
    weekDaysData: state.paymentReducer.weekDaysData,
    autoPayLoading: state.paymentReducer.autoPayLoading,
    showRecurringPaymentConfirmationModal:
      state.paymentReducer.showRecurringPaymentConfirmationModal,
    nextPaymentMessage: state.paymentReducer.nextPaymentMessage,
  }));

  useEffect(() => {
    Actions.getPaymentMethods(dispatch);
    Actions.fetchRecurringPaymentTypesAction(dispatch);
    Actions.getWeekDaysAction(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    paymentTokenId,
    amount,
    startDate,
    endDate,
    dayOfMonth,
    dayOfWeek,
    orderNumber,
    description,
    recurringPaymentType,
  } = newEnrollmentData;

  useEffect(() => {
    if (startDate && endDate && dayOfMonth) {
      const error = getDayOfMonthValidationError(startDate, endDate, dayOfMonth);
      setDayOfMonthValidation(error);
    }
  }, [startDate, endDate, dayOfMonth]);

  useEffect(() => {
    Actions.IsUserEnrolledAction(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChange = (event) => {
    const { value, name } = event.target;
    if (name === 'recurringPaymentType') {
      setDayOfMonthValidation('');
      dispatch(Actions.resetAutoPayEnrollmentData());
      const selectedType =
        recurringPaymentTypesData &&
        recurringPaymentTypesData.filter((type) => type.status === value);
      dispatch(Actions.setAutoPayEnrollmentData({ id: name, value: selectedType[0] }));
    } else if (name === 'amount') {
      if (parseFloat(value) === 0) {
        setAmountValidation('Amount should be greater than zero');
      } else {
        setAmountValidation('');
      }
      dispatch(Actions.setAutoPayEnrollmentData({ id: name, value }));
    } else {
      dispatch(Actions.setAutoPayEnrollmentData({ id: name, value }));
    }
  };

  const handleAutoPayStartDate = (date) => {
    dispatch(Actions.setAutoPayEnrollmentData({ id: 'startDate', value: date }));
  };

  const handleAutoPayEndDate = (date) => {
    dispatch(Actions.setAutoPayEnrollmentData({ id: 'endDate', value: date }));
  };

  const handleAddNewPayment = () => {
    if (isEnableManagement) {
      dispatch(Actions.setEnableManagement(false));
      dispatch(Actions.resetAutoPayEnrollmentData());
      setDayOfMonthValidation('');
    } else {
      dispatch(Actions.setEnableManagement(true));
      handleAutoPayStartDate(today);
      handleAutoPayEndDate(today);
    }
  };

  const handleDisableAllPayments = () => {
    dispatch(Actions.EndEnrollmentAction());
  };

  const handleSaveChanges = () => {
    const selectedWeek =
      weekDaysData && weekDaysData.filter((type) => type.description === dayOfWeek);
    let updatedEndDate = endDate;
    if(recurringPaymentType?.type === 'Month'){
      updatedEndDate = new Date(moment(endDate).set('date', dayOfMonth))
    }
    const data = {
      paymentTokenId,
      recurringPaymentId: null,
      startDate,
      endDate: updatedEndDate,
      amount: parseFloat(amount),
      dayOfMonth,
      orderNumber,
      description,
      paymentStatus: recurringPaymentType && recurringPaymentType.status,
      paymentType: recurringPaymentType && recurringPaymentType.type,
      dayOfWeek: (selectedWeek && selectedWeek[0] && selectedWeek[0].id) || null,
    };
    Actions.StartEnrollmentAction(dispatch, data);
    setDayOfMonthValidation('');
  };
  const today = new Date();
  return autoPayLoading ? (
    <ComponentLoader />
  ) : (
    <div className={classes.moduleWrapper}>
      <div className={classes.header}>
        <span className={classes.subSectionHeaderScheduled}>Recurring Payments</span>
      </div>

      <div className={classes.moduleContent}>
        <div className={classes.blueRow}>
          <div className={classes.paddingTextRecurringPayment}>
            The recurring payment option allows you to set an amount and frequency for
            your payment to be processed using the payment method of choice.
          </div>
        </div>
        <RecurringPaymentEnrollmentText data={enrollmentData} />
        <div className={`${classes.addRecurringPaymentLink}`}>
          <div
            className={classes.addRecurringPayContainer}
            onClick={handleAddNewPayment}
            role="link"
            tabIndex={0}
            onKeyPress={() => ''}
          >
            <div className={classes.tableInlinePlus}>
              {isEnableManagement ? (
                <RemoveCircleOutlineIcon
                  className={`${classes.tableMoreDetailsActive} ${classes.paddingTop}`}
                  role="link"
                  tabIndex={0}
                  onKeyPress={() => ''}
                />
              ) : (
                <AddCircleOutlineIcon
                  role="link"
                  className={`${classes.paddingTop}`}
                  tabIndex={0}
                  onKeyPress={() => ''}
                  data-testid="expand-history-row"
                />
              )}
            </div>
            <div className={`${classes.martop1} ${classes.marLeft5}`}>
              Add Recurring Payment
            </div>
          </div>
        </div>
        {isEnableManagement && (
          <>
            <div
              className={`${classes.noBottomPad} ${classes.paddingTextRecurringPayment}`}
            >
              <OrderDetails
                paymentMethods={paymentMethods}
                handleChange={handleChange}
                orderNumber={newEnrollmentData.orderNumber}
                description={description}
                amount={amount}
                handleAutoPayStartDate={handleAutoPayStartDate}
                handleAutoPayEndDate={handleAutoPayEndDate}
                startDate={startDate}
                dayOfMonth={dayOfMonth}
                endDate={endDate}
                dayOfMonthError={dayOfMonthError}
                recurringPaymentTypesData={recurringPaymentTypesData}
                weekDaysData={weekDaysData}
                dayOfWeek={dayOfWeek}
                recurringPaymentType={recurringPaymentType}
                paymentTokenId={paymentTokenId}
                amountError={amountError}
              />
              <ValidationError validationErrorData={recurringPaymentError} />

              <div className={`${classes.upperPad} ${classes.buttonSingleDiv2}`}>
                {isEnableManagement && (
                  <span>
                    <MUI.ActionButton
                      onClick={() => {
                        handleSaveChanges();
                      }}
                      disabled={
                        !amount ||
                        !orderNumber ||
                        (!dayOfMonth && !dayOfWeek) ||
                        dayOfMonthError ||
                        (!!getDayOfWeekDateValidation(startDate, endDate) && dayOfWeek) ||
                        amountError ||
                        (recurringPaymentType &&
                          Object.keys(recurringPaymentType).length === 0)
                      }
                    >
                      Submit
                    </MUI.ActionButton>
                  </span>
                )}
              </div>
            </div>
          </>
        )}
        {enrollmentData && enrollmentData.length > 0 && (
          <>
            <RecurringPaymentsHistory />
            <div className={`${classes.addRecurringPaymentLink}`}>
              <div
                className={classes.addRecurringPayContainer}
                onClick={handleDisableAllPayments}
                role="link"
                tabIndex={0}
                onKeyPress={() => ''}
              >
                <CancelOutlinedIcon
                  className={`${classes.martop1}`}
                  role="link"
                  tabIndex={0}
                  onKeyPress={() => ''}
                />
                <div className={`${classes.martop1} ${classes.marLeft5}`}>
                  Disable All Recurring Payments
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <ManagePaymentMethod
        launchManagePaymentMethod={showManagePaymentModal}
        showCloseBtn
        isRecurringPaymentFlow
      />
      {showRecurringPaymentConfirmationModal && (
        <RecurringPaymentConfirmationModal
          launchConfirmationModal={showRecurringPaymentConfirmationModal}
          nextPaymentMessage={nextPaymentMessage}
        />
      )}
    </div>
  );
};

export default RecurringPayment;
