/* eslint-disable linebreak-style */
/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import { useSelector, useDispatch } from 'react-redux';
import { SpectrumReachLogoAlt } from '@spectrum-reach/mui-theme';
import { Footer } from '@spectrum-reach/external-theme';
import { Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import useClasses from '../styles/Classes';
import customStyles from '../styles/CustomStyles';
import * as MUI from '../styles/MUI_Components';
import * as Actions from '../../../../../redux/actionCreators/PaymentActionCreators';
import * as Selectors from '../../../../../redux/selectors/PaymentSelectors';
import { MultipleInvoiceList } from './MultipleInvoiceList';
import { getTotalPaymentValue } from './payment-helpers';

const PaymentConfirmation = () => {
  const classes = useClasses();
  const dispatch = useDispatch();

  const {
    paymentDetails,
    paymentMethods,
    amount,
    oneTimePaymentDetails,
    completedPaymentData,
  } = useSelector((state) => ({
    paymentDetails: Selectors.getPaymentDetailsState(state),
    paymentMethods: state.paymentReducer.paymentMethods,
    amount: state.paymentReducer.enrollmentData.amount,
    oneTimePaymentDetails: state.paymentReducer.oneTimePaymentDetails,
    completedPaymentData: state.paymentReducer.completedPaymentData,
  }));

  const [methodOfPayment, setMethodOfPayment] = useState('');
  useEffect(() => {
    if (paymentDetails && paymentDetails.paymentTokenId) {
      const hasOneTimePaymentDetails = oneTimePaymentDetails.length;

      const payFromPaymentMethods = hasOneTimePaymentDetails
        ? [...oneTimePaymentDetails, ...paymentMethods]
        : paymentMethods;
      const method = payFromPaymentMethods.find(
        (m) => m.paymentTokenId === parseInt(paymentDetails.paymentTokenId, 10)
      );
      setMethodOfPayment(`${method.brand} ${method.paymentMethodSuffix}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const paymentItems = paymentDetails.paymentDetails;
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <div>
      <div style={{ display: 'none' }}>
        <div ref={componentRef} className={classes.printContainer}>
          <Typography
            variant="subtitle1"
            component="h2"
            className={classes.printPageTitle}
          >
            Spectrum Reach Payment Status
          </Typography>
          <div className={classes.printLogo}>
            <div className={classes.printLogoImg}>
              <SpectrumReachLogoAlt width="125" height="85" v1="1200" v2="250" />
            </div>
          </div>
          <div>
            <div>Spectrum Reach</div>
            <div>877-286-7117</div>
            <div>reachbusops@charter.com</div>
          </div>
          <div className={classes.printInfoContainer}>
            <div>
              <div className={classes.paddingDontSeeAcct}>
                <b>Billing Information</b>
              </div>
              <div>
                <div>
                  <b>Agency : </b>
                  {completedPaymentData.agency}
                </div>
                <div>
                  <b>Agency ID : </b>
                  {completedPaymentData.agencyId}
                </div>
                <div>
                  <b>Name : </b>
                  {completedPaymentData.name}
                </div>
                <div>
                  <b>Email : </b>
                  {completedPaymentData.email}
                </div>
                <div>
                  <b>Phone : </b>
                  {completedPaymentData.phone}
                </div>
              </div>
            </div>
            <div>
              <div className={classes.paddingDontSeeAcct}>
                <b>Payment Information</b>
              </div>
              <div>
                <div>
                  <b>Date : </b>
                  {moment(paymentDetails.paymentDate).format('MMM, Do YYYY')}
                </div>
                <div>
                  <b>Transaction ID : </b>
                  {completedPaymentData.transactionId}
                </div>
                <div>
                  <b>Payment Method : </b>
                  {methodOfPayment}
                </div>
                <div>
                  <b>Payment Status : </b>
                  {completedPaymentData.paymentStatus}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.upperPad}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Type</TableCell>
                    <TableCell align="center">ID</TableCell>
                    <TableCell align="center">Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paymentItems &&
                    paymentItems.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell align="center">
                          {row.orderTypeId === 1 || row.orderType === 'Order'
                            ? 'Order'
                            : 'Invoice'}
                        </TableCell>
                        <TableCell align="center">{row.orderId}</TableCell>
                        <TableCell align="center"> {`$ ${row.amount}`}</TableCell>
                      </TableRow>
                    ))}
                  <TableRow>
                    <TableCell colSpan={2} align="right">
                      Total
                    </TableCell>
                    <TableCell align="center">
                      {' '}
                      {paymentDetails.paymentDateType === 'SchedulePayment'
                        ? amount
                        : paymentDetails &&
                          paymentDetails.paymentDetails &&
                          getTotalPaymentValue(
                            paymentDetails && paymentDetails.paymentDetails
                          )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className={classes.printFooter}>
            <Footer />
          </div>
        </div>
      </div>
      <div>
        <div className={`${classes.upperPad} ${classes.multiInvoiceListTable}`}>
          <MultipleInvoiceList multipleInvoiceDetails={paymentItems} />
        </div>
        <div className={`${classes.upperPad}`}>
          Payment Amount:
          <span className={classes.reviewPaymentItem}>
            {paymentDetails.paymentDateType === 'SchedulePayment'
              ? amount
              : getTotalPaymentValue(paymentDetails && paymentDetails.paymentDetails)}
          </span>
        </div>
        <div className={`${classes.upperPad}`}>
          Payment Date:
          <span className={classes.reviewPaymentItem}>
            {moment(paymentDetails.paymentDate).format('MMM, Do YYYY')}
          </span>
        </div>
        <div className={`${classes.upperPad}`}>
          Payment Method:
          <span className={classes.reviewPaymentItem}>{methodOfPayment}</span>
        </div>
      </div>
      <div className={classes.upperPad}>
        <div className={classes.buttonDiv} style={customStyles.nextButtonDiv}>
          <MUI.ActionButtonInvertedDouble
            data-testid="close-payment-confirmation"
            onClick={() => {
              dispatch(Actions.resetPaymentDetails());
            }}
          >
            Close
          </MUI.ActionButtonInvertedDouble>
          <MUI.ActionButtonInvertedDouble
            data-testid="close-payment-confirmation"
            onClick={handlePrint}
          >
            Print confirmation
          </MUI.ActionButtonInvertedDouble>
        </div>
      </div>

      <div className={classes.paymentReviewDisclaimer}>
        Your payment would be processed on{' '}
        {moment(paymentDetails.paymentDate).format('MMM, Do YYYY')}, it may take up to 24
        hours to post to your account
      </div>
    </div>
  );
};

export default PaymentConfirmation;
