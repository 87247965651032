/* eslint-disable linebreak-style */
import { createUseStyles } from 'react-jss';
import theme from '../../theme.module.scss';

const useClasses = createUseStyles({
  rightUserInfoUL: {
    listStyle: 'none',
    paddingLeft: '0rem',
    marginTop: '0rem',
  },

  rightUserInfoHeaderLI: {
    color: `${theme.darkestBlue}`,
    paddingTop: '0rem',
    paddingBottom: '0.5rem',
    fontWeight: '600',
    fontSize: '1.35rem',
  },
  rightUserInfoLI: {
    color: ` ${theme.darkGrey}`,
    paddingTop: '0.15rem',
    paddingBottom: '0.15rem',
    '& :first-child': {
      paddingTop: '0rem',
    },
  },
});

export default useClasses;
