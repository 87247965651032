import React from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { device } from './ResponsiveDevice';
import theme from '../../theme.module.scss';

const useStyles = makeStyles(() => ({
  infoTextFailure: {
    fontFamily: 'inherit',
    letterSpacing: '-0.03rem',
    textAlign: 'center',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    paddingLeft: '2rem',
    paddingRight: '1.5rem',
    lineHeight: '1rem',
    color: `${theme.red}`,
    [`@media only screen and ${device.mobile}`]: {
      paddingLeft: '0rem',
      paddingRight: '0rem',
    },
  },
}));

/** ValidationError Component */
export default function ValidationError(props) {
  const { validationErrorData } = props;
  const classes = useStyles();
  return (
    <Grid>
      {validationErrorData &&
      Array.isArray(validationErrorData) &&
      validationErrorData.length > 0 ? (
        <>
          <Box m={1} bgcolor="background.paper" />
          {validationErrorData.map((data) => (
            <>
              <div className={`${classes.infoTextFailure}`}>{data}</div>
            </>
          ))}
        </>
      ) : (
        validationErrorData !== '' && (
          <>
            {' '}
            <div className={`${classes.infoTextFailure}`}>{validationErrorData}</div>
          </>
        )
      )}
    </Grid>
  );
}
