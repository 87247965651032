import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid #d8dde6',
    transition: 'box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1)',
    willChange: 'box-shadow',
    backgroundColor: '#fff',
    borderRadius: 0,
    outlineColor: 'transparent',
    outlineStyle: 'none',
    height: '100%',
    padding: theme.spacing(2),
    boxSizing: 'border-box',
  },
}));

const StyledPanel = ({ children, item, container, ...other }) => {
  const classes = useStyles();

  return (
    <div className={classes.root} {...other}>
      {children}
    </div>
  );
};

export default StyledPanel;
