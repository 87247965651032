/* eslint-disable linebreak-style */

export const constants = {
  ACCESS_TOKEN: 'accessToken',
  REFRESH_TOKEN: 'refreshToken',
  EXPIRY_TIME: 'expiryTime',
};

/** Save Access and Refresh Token */
export const saveToken = (data) => {
  window.sessionStorage.setItem(constants.ACCESS_TOKEN, data.access_token);
  window.sessionStorage.setItem(constants.REFRESH_TOKEN, data.refresh_token);
  window.sessionStorage.setItem(constants.EXPIRY_TIME, data.expires_in);
};

/** Remove Tokens */
export const removeTokens = () => {
  window.sessionStorage.removeItem(constants.ACCESS_TOKEN);
  window.sessionStorage.removeItem(constants.REFRESH_TOKEN);
  window.sessionStorage.removeItem(constants.EXPIRY_TIME);
};

/** Is user authenticated */
export const isUserAuthenticated = () =>
  window.sessionStorage.getItem(constants.ACCESS_TOKEN);
