/* eslint-disable linebreak-style */
import React, { useState } from 'react';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import useClasses from './ExtSupportClasses';
import { ExternalFaqContent } from './ExternalFaqContent';
import ExternalLayout from './ExternalLayout';

const ExternalSupport = () => {
  const [activeQuestion, setActiveQuestion] = useState({ activeItem: null });
  const [faqContent] = useState(ExternalFaqContent);

  const toggleActiveItem = (val) => {
    if (val === activeQuestion.activeItem) {
      setActiveQuestion([]);
    } else {
      setActiveQuestion({ activeItem: val });
    }
  };
  const classes = useClasses();
  return (
    <ExternalLayout title="Frequently Asked Questions">
      <div className="faqDiv">
        {faqContent.map((item) => (
          <div key={item.id}>
            <div
              className={`${classes.flex} ${classes.faqRow}`}
              role="link"
              tabIndex={0}
              onKeyPress={() => ''}
              onClick={() => {
                toggleActiveItem(item.id);
              }}
            >
              <span className={`${classes.faqQuestion}`}>{item.question}</span>
              <div
                className={classes.arrowIcon}
                role="link"
                tabIndex={0}
                onKeyPress={() => ''}
                onClick={() => {
                  toggleActiveItem(item.id);
                }}
              >
                {activeQuestion.activeItem === item.id ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowRightIcon />
                )}
              </div>
            </div>
            {activeQuestion.activeItem === item.id ? (
              <span
                className={`${classes.faqAnswer}`}
                dangerouslySetInnerHTML={{ __html: item.answer }} // eslint-disable-line react/no-danger
              />
            ) : (
              <></>
            )}
          </div>
        ))}
      </div>
    </ExternalLayout>
  );
};
export default ExternalSupport;
