/* eslint-disable linebreak-style */
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import IE from '../../images/browsers/IE.png';
import * as Icons from './BrowserIcons';

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    padding: '1rem',
    margin: 'auto',
  },
  cardContainer: {
    padding: '1rem',
  },
  headerText: {
    fontSize: '1.5rem',
    textAlign: 'center',
    color: theme.palette.blue.dark,
    fontFamily: 'Reach-Title-Bold, sans-serif',
    marginBottom: '1rem',
  },
  infoText: {
    margin: '2rem',
    textAlign: 'center',
    color: theme.palette.blue.medium,
  },
  browserImg: {
    width: '100px',
    height: '100px',
    padding: '20px',
  },
  browserImgContainer: {
    marginTop: '2rem',
    marginRight: '4rem',
  },
  iEImgContainer: {
    height: '110px',
    margin: 'auto',
    display: 'flex',
  },
  fireFoxStyles: {
    marginLeft: '10px',
    marginBottom: '77px',
  },
}));

const NotCompatiblePage = () => {
  const classes = useStyles();
  return (
    <section>
      <div className={classes.cardWrapper}>
        <Card className={classes.cardContainer}>
          <CardContent>
            <div className={`${classes.headerText}`}>
              Client Portal Not Compatible With Internet Explorer
            </div>
            <img src={IE} className={classes.iEImgContainer} alt="Internet Explorer..." />
            <div className={classes.infoText}>
              Due to the use of new coding technology, this application is not compatible
              with Internet Explorer. Please close out the browser and open the website in
              a different internet browser. Other browser options include Google Chrome,
              Microsoft Edge, Safari, or Firefox.
              <div className={classes.browserImgContainer}>
                <Icons.Chrome width="100px" height="100px" />
                <Icons.Edge width="100px" height="100px" />
                <Icons.Safari width="100px" height="100px" />
                <Icons.Firefox
                  width="20px"
                  height="20px"
                  customClassName={classes.fireFoxStyles}
                />
              </div>
              <div className={classes.infoText}>
                Thank you for your understanding! We apologize for any inconvenience.
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </section>
  );
};
export default NotCompatiblePage;
