/* eslint-disable linebreak-style */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Box } from '@material-ui/core';

// Internal Sub Components
// import ResendEmailConfirmation from '../resendEmailConfirmation/ResendEmailConfirmation';
import MakeAPayment from '../make-a-payment/Payment';
import PaymentMethods from '../managePaymentMethods/PaymentMethods';
import TransactionHistory from '../transaction-history/TransactionHistory';
import CreditAdjustmentRequest from '../creditAdjustments/CreditAdjustmentRequest';
import RecurringPayment from '../autopay/RecurringPayment';
import useClasses from '../styles/Classes';

const ManagePaymentsCenterPanel = () => {
  const classes = useClasses();
  const [showRefundRequest, setShowRefundRequst] = useState(false);
  const cancelRefundRequest = () => {
    setShowRefundRequst(false);
  };
  return (
    // TODO: Replace classNames with MUI default global styles from theme.
    <Box md={{ mb: 1 }} className={`${classes.global} ${classes.accountPage}`}>
      <Box mb={3} id="ManagePaymentMethods">
        <PaymentMethods showCloseBtn={false} />
      </Box>
      <Box mb={3} id="MakePayment">
        <MakeAPayment />
      </Box>
      <Box mb={3} id="Autopay">
        <RecurringPayment />
      </Box>
      <Box id="TransactionHistory">
        <TransactionHistory />
      </Box>
      {showRefundRequest && (
        <CreditAdjustmentRequest
          launchRefundRequestModal={showRefundRequest}
          cancel={cancelRefundRequest}
        />
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  userInfoDetails: state.userReducer.userInfoDetails,
});
export default connect(mapStateToProps, null)(ManagePaymentsCenterPanel);
