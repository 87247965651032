/* eslint-disable linebreak-style */

/** Action Type tp GET User data Request */
export const FETCH_USER_INFO_REQUEST = 'FETCH_USER_INFO_REQUEST';

/** Action Type to GET User data success */
export const FETCH_USER_INFO_SUCCESS = 'FETCH_USER_INFO_SUCCESS';

/** Action Type to GET User data failure */
export const FETCH_USER_INFO_FAILURE = 'FETCH_USER_INFO_FAILURE';

/** Action Type for Terms and Conditions Request */
export const TERMS_AND_CONDITIONS_PENDING = 'TERMS_AND_CONDITIONS_PENDING';

/** Terms and Conditions Accepted Status */
export const TERMS_AND_CONDITIONS_ACCEPTED_STATUS =
  'TERMS_AND_CONDITIONS_ACCEPTED_STATUS';

export const TERMS_AND_CONDITIONS_FAILURE = 'TERMS_AND_CONDITIONS_FAILURE';

export const RESET_USER_DETAILS = 'RESET_USER_DETAILS';

export const USER_DOES_NOT_EXIST = 'USER_DOES_NOT_EXIST';
