/* eslint-disable linebreak-style */
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import PageNotFound from '../modules/page-not-found/PageNotFound';
import Logout from '../modules/logout/Logout';
import Login from '../modules/login/UserLogin';
import ManagePayments from '../modules/portal/managePayments/ManagePayments';
import Support from '../modules/portal/support/Support';
import ManageAccount from '../modules/portal/manageAccount/ManageAccount';
import ForgotPassword from '../modules/forgot-password/ForgotPassword';
import ForgotUsername from '../modules/forgot-username/ForgotUsername';
import ResetPassword from '../modules/reset-password/ResetPassword';
import Confirmation from '../modules/confirmation/Confirmation';
import ExternalTermsAndConditions from '../modules/ext-support/ExternalTermsAndConditions';
import TermsAndConditions from '../modules/terms-and-conditions/TermsAndConditions';
import ExternalSupport from '../modules/ext-support/ExternalSupport';
import NewUser from '../modules/ext-support/NewUser';
import SignUp from '../modules/sign-up/SignUp';
import UserProfile from '../modules/sign-up/UserProfile';
import AuthorizeRoute from './hooks';
import EmailConfirmation from '../modules/email-confirmation/EmailConfirmation';

/** Defining routing paths here */
export const PATHS = {
  LOGIN: '/login',
  SIGNUP: '/signup',
  FORGOT_PASSWORD: '/forgot-password',
  FORGOT_USERNAME: '/forgot-username',
  RESET_PASSWORD: '/reset-password',
  EMAIL_CONFIRMATION: '/email-confirmation',
  DASHBOARD: '/',
  LOGOUT: '/logout',
  SUPPORT: '/support',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  MANAGE_ACCOUNT: '/manage-account',
  CONFIRMATION: '/confirmation',
  EXT_SUPPORT: '/ext-support',
  NEW_USER: '/new-user',
  EXT_TERMS_AND_CONDITIONS: '/ext-terms-and-conditions',
  USER_PROFILE: '/user-profile',
};

const Routes = () => (
  <>
    <Switch>
      <AuthorizeRoute exact path={PATHS.DASHBOARD} component={ManagePayments} />
      <AuthorizeRoute path={PATHS.SUPPORT} component={Support} />
      <AuthorizeRoute exact path={PATHS.MANAGE_ACCOUNT} component={ManageAccount} />
      <AuthorizeRoute exact path={PATHS.USER_PROFILE} component={UserProfile} />
      <Route exact path={PATHS.TERMS_AND_CONDITIONS} component={TermsAndConditions} />
      <Route
        exact
        path={PATHS.EXT_TERMS_AND_CONDITIONS}
        component={ExternalTermsAndConditions}
      />

      <Route exact path={PATHS.LOGIN} component={Login} />
      <Route exact path={PATHS.SIGNUP} component={SignUp} />
      <Route exact path={PATHS.EMAIL_CONFIRMATION} component={EmailConfirmation} />
      <Route exact path={PATHS.FORGOT_PASSWORD} component={ForgotPassword} />
      <Route exact path={PATHS.FORGOT_USERNAME} component={ForgotUsername} />
      <Route exact path={PATHS.RESET_PASSWORD} component={ResetPassword} />
      <Route exact path={PATHS.CONFIRMATION} component={Confirmation} />
      <Route exact path={PATHS.LOGOUT} component={Logout} />
      <Route exact path={PATHS.EXT_SUPPORT} component={ExternalSupport} />
      <Route exact path={PATHS.NEW_USER} component={NewUser} />
      <Route path="*" component={PageNotFound} />
    </Switch>
  </>
);

export default Routes;
