/* eslint-disable linebreak-style */
import React from 'react';
import { useDispatch } from 'react-redux';
import { HashLink } from 'react-router-hash-link';
import { push } from 'connected-react-router';

// Internal Sub Components
import AccountInformation from '../../../../../shared/account-information/AccountInformation';

// Graphics
import StyledPanel from '../../../../../shared/package/styles/StyledPanel';
import AutoHeightBox from '../../../../../shared/package/layout/AutoHeightBox';
import useClasses from '../styles/Classes';

const ManagePaymentsRightPanel = () => {
  const classes = useClasses();
  const dispatch = useDispatch();
  const onHandleClick = () => {
    dispatch(push({ pathname: '/manage-account' }));
  };

  const items = [
    {
      to: '#ChangePassword',
      click: onHandleClick,
      name: 'Change Password',
      path: '/manage-account',
    },
    {
      to: '#SecondaryUsers',
      click: onHandleClick,
      name: 'Account Users',
      path: '/manage-account',
    },
  ];

  return (
    <AutoHeightBox md={{ mb: 1 }}>
      <StyledPanel>
        <AccountInformation />
        <ul className={classes.rightQuickLinksUL}>
          <li className={classes.rightQuickLinksHeaderLI}>Quick Links</li>
          {items &&
            items.map((item) => (
              <li className={classes.rightQuickLinksLI} key={item.name}>
                <HashLink
                  smooth
                  to={item.to}
                  onClick={() => {
                    dispatch(push({ pathname: item.path }));
                  }}
                >
                  {item.name}
                </HashLink>
              </li>
            ))}
        </ul>
      </StyledPanel>
    </AutoHeightBox>
  );
};

export default ManagePaymentsRightPanel;
