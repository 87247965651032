/* eslint-disable linebreak-style */
export const MAKE_PAYMENT_STEP = 'MAKE_PAYMENT_STEP';
export const CHANGE_PAYMENT_FIELD_VALUE = 'CHANGE_PAYMENT_FIELD_VALUE';
export const PAYMENT_METHODS = 'PAYMENT_METHODS';
export const LOADING_PAYMENT_METHODS = 'LOADING_PAYMENT_METHODS';
export const RESET_PAYMENT_DETAILS = 'RESET_PAYMENT_DETAILS';
export const LOADING_PAYMENT_SUBMITTED = 'LOADING_PAYMENT_SUBMITTED';
export const GET_PAYMENT_METHODS_FAILED = 'GET_PAYMENT_METHODS_FAILED';
export const MAKE_PAYMENT_FAILED = 'MAKE_PAYMENT_FAILED';

/** Action Type to Add credit card Request */
export const ADD_CREDIT_CARD_REQUEST = 'ADD_CREDIT_CARD_REQUEST';

/** Action Type to Add credit card success */
export const ADD_CREDIT_CARD_SUCCESS = 'ADD_CREDIT_CARD_SUCCESS';

/** Action Type to Add credit card failure */
export const ADD_CREDIT_CARD_FAILURE = 'ADD_CREDIT_CARD_FAILURE';

/** Action Type to Add bank account Request */
export const ADD_ACH_REQUEST = 'ADD_ACH_REQUEST';

/** Action Type to Add bank account success */
export const ADD_ACH_SUCCESS = 'ADD_ACH_SUCCESS';

/** Action Type to Add bank account failure */
export const ADD_ACH_FAILURE = 'ADD_ACH_FAILURE';

/** Action Type to delete credit card or bank account Request */
export const DELETE_PAYMENT_METHOD_REQUEST = 'DELETE_PAYMENT_METHOD_REQUEST';

/** Action Type to delete credit card or bank account success */
export const DELETE_PAYMENT_METHOD_SUCCESS = 'DELETE_PAYMENT_METHOD_SUCCESS';

/** Action Type to delete credit card or bank account failure */
export const DELETE_PAYMENT_METHOD_FAILURE = 'DELETE_PAYMENT_METHOD_FAILURE';

/** Action Type to change field values */
export const CHANGE_FIELD_VALUE = 'CHANGE_FIELD_VALUE';

/** Action Type to reset field values */
export const RESET_FIELD_VALUES = 'RESET_FIELD_VALUES';

/** Action Type to show/hide add payment modal */
export const TOGGLE_ADD_PAYMENT_MODAL = 'TOGGLE_ADD_PAYMENT_MODAL';

/** Action Type to show/hide manage payment modal */
export const TOGGLE_MANAGE_PAYMENT_MODAL = 'TOGGLE_MANAGE_PAYMENT_MODAL';

/** Action Type to save payment token id */
export const SAVE_PAYMENT_TOKEN_ID = 'SAVE_PAYMENT_TOKEN_ID';

/** Action Type to save one time payment details */
export const SAVE_ONE_TIME_PAYMENT_DETAILS = 'SAVE_ONE_TIME_PAYMENT_DETAILS';

/** Action Type to set save to wallet status */
export const SET_SAVE_TO_WALLET_STATUS = 'SET_SAVE_TO_WALLET_STATUS';

/** Action Type to Autopay Start Enrollment Request */
export const START_ENROLLMENT_REQUEST = 'START_ENROLLMENT_REQUEST';

/** Action Type to Autopay Start Enrollment success */
export const START_ENROLLMENT_SUCCESS = 'START_ENROLLMENT_SUCCESS';

/** Action Type to Autopay Start Enrollment failure */
export const START_ENROLLMENT_FAILURE = 'START_ENROLLMENT_FAILURE';

/** Action Type to Autopay End Enrollment Request */
export const END_ENROLLMENT_REQUEST = 'END_ENROLLMENT_REQUEST';

/** Action Type to Autopay End Enrollment success */
export const END_ENROLLMENT_SUCCESS = 'END_ENROLLMENT_SUCCESS';

/** Action Type to Autopay End Enrollment failure */
export const END_ENROLLMENT_FAILURE = 'END_ENROLLMENT_FAILURE';

/** Action Type to Autopay is Enrolled Request */
export const IS_USER_ENROLLED_IN_AUTOPAY_REQUEST = 'IS_USER_ENROLLED_IN_AUTOPAY_REQUEST';

/** Action Type to Autopay is Enrolled success */
export const IS_USER_ENROLLED_IN_AUTOPAY_SUCCESS = 'IS_USER_ENROLLED_IN_AUTOPAY_SUCCESS';

/** Action Type to Autopay is Enrolled failure */
export const IS_USER_ENROLLED_IN_AUTOPAY_FAILURE = 'IS_USER_ENROLLED_IN_AUTOPAY_FAILURE';

/** Action Type to set autopay enrollment data */
export const SET_AUTO_PAY_ENROLLMENT_DATA = 'SET_AUTO_PAY_ENROLLMENT_DATA';

/** Action Type to reset autopay enrollment data */
export const RESET_AUTO_PAY_ENROLLMENT_DATA = 'RESET_AUTO_PAY_ENROLLMENT_DATA';

export const UPDATE_ENROLLMENT = 'UPDATE_ENROLLMENT';

export const ADD_PAYMENT_METHOD_STEP = 'ADD_PAYMENT_METHOD_STEP';

export const RESET_ERROR = 'RESET_ERROR';

export const ADD_NEW_ROW = 'ADD_NEW_ROW';

export const SET_INVOICE_INFO = 'SET_INVOICE_INFO';
export const MODULE_NAME = 'MODULE_NAME';
export const PAYMENT_METHOD_OPTION = 'PAYMENT_METHOD_OPTION';
export const ADD_METHOD_TYPE = 'ADD_METHOD_TYPE';
export const COMPLETE_PAYMENT_SUCCESS = 'COMPLETE_PAYMENT_SUCCESS';
export const FETCH_RECURRING_PAYMENTS_SUCCESS = 'FETCH_RECURRING_PAYMENTS_SUCCESS';
export const FETCH_RECURRING_PAYMENTS_REQUEST = 'FETCH_RECURRING_PAYMENTS_REQUEST';
export const FETCH_RECURRING_PAYMENTS_FAILURE = 'FETCH_RECURRING_PAYMENTS_FAILURE';
export const FETCH_WEEK_DAYS_REQUEST = 'FETCH_WEEK_DAYS_REQUEST';
export const FETCH_WEEK_DAYS_SUCCESS = 'FETCH_WEEK_DAYS_SUCCESS';
export const FETCH_WEEK_DAYS_FAILURE = 'FETCH_WEEK_DAYS_FAILURE';
export const SET_ENABLE_MANAGEMENT = 'SET_ENABLE_MANAGEMENT';
export const TOGGLE_RECURRING_PAYMENT_CONFIRMATION_MODAL =
  'TOGGLE_RECURRING_PAYMENT_CONFIRMATION_MODAL';
