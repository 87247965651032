/* eslint-disable linebreak-style */
export const FaqContent = () => [
  {
    id: 1,
    question: 'How long does a payment take to post?',
    answer:
      'Payments are processed immediately and posted into our billing system the following day.',
  },
  {
    id: 2,
    question: 'I am not receiving my email receipts. What do I do?',
    answer:
      '1) Check your junk or SPAM folder.<br/>' +
      '2) Make sure no-reply@spectrumreach.com is added to your safer senders list.<br/>' +
      '3) Work with your internal IT support to make sure our emails are not being blocked by security features.<br/>' + // eslint-disable-line max-len
      '4) If those steps fail, contact us. We are constantly evaluating ways to improve our site and experience.<br/>', // eslint-disable-line max-len
  },
  {
    id: 3,
    question:
      'I am not receiving the password reset email to reset my password. What do I do?',
    answer:
      '1) Check your junk or SPAM folder.<br/>' +
      '2) Make sure no-reply@spectrumreach.com is added to your safer senders list.<br/>' +
      '3) Work with your internal IT support to make sure our emails are not being blocked by security features.<br/>' + // eslint-disable-line max-len
      '4) If those steps fail, contact us. We are constantly evaluating ways to improve our site and experience.<br/>', // eslint-disable-line max-len
  },
  {
    id: 4,
    question: 'How secure is the Spectrum Reach Client Portal?',
    answer:
      'Your payment information is always encrypted, and it is never stored on our systems. Credit card data, bank account information, and other sensitive details are stored in a 3rd party, highly secure, and audited payment processor. All of our processes are PCI audited and compliant.', // eslint-disable-line max-len
  },
  {
    id: 5,
    question: "Why can't I view open invoices or see my account balance?",
    answer:
      'We currently do not have the capability to present invoices or account balances in the Client Portal but this is coming in Q4 2023',
  },
];
