/* eslint-disable linebreak-style */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAccessToken } from '../../redux/actionCreators/authActionCreators';
import { isUserAuthenticated, constants } from '../../utils/AuthUtils';

// Gets new Access Token Before token expires
const GetNewAccessToken = () => {
  const dispatch = useDispatch();
  const getTime = () => {
    const getExpirationTime = window.sessionStorage.getItem(constants.EXPIRY_TIME);
    const minutes = Number(getExpirationTime || 3300) / 60;
    const milliSeconds = minutes * 60000;
    return milliSeconds;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (isUserAuthenticated()) {
        dispatch(getAccessToken());
      }
    }, getTime());
    return () => clearInterval(interval);
  }, []);

  return <></>;
};

export default GetNewAccessToken;
