/* eslint-disable linebreak-style */
import { combineReducers } from 'redux';

import { connectRouter } from 'connected-react-router';
import { authReducer, authInitialState } from '../redux/reducers/authReducer';
import { userReducer, userInitialState } from '../redux/reducers/UserReducer';
import {
  adjustmentsReducer,
  adjustmentsReducerInitialState,
} from '../redux/reducers/AdjustmentsReducer';
import {
  paymentReducer,
  paymentReducerInitialState,
} from '../redux/reducers/PaymentReducer';
import {
  transactionHistoryReducer,
  transactionHistoryReducerInitialState,
} from '../redux/reducers/TransactionHistoryReducer';

import {
  accountUsersReducer,
  accountUsersReducerInitialState,
} from '../redux/reducers/AccountUsersReducer';

const initialState = {
  // Initial State To be Defined Here
  authReducer: authInitialState,
  userReducer: userInitialState,
  adjustmentsReducer: adjustmentsReducerInitialState,
  paymentReducer: paymentReducerInitialState,
  transactionHistoryReducer: transactionHistoryReducerInitialState,
  accountUsersReducer: accountUsersReducerInitialState,
};

export const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    authReducer,
    userReducer,
    adjustmentsReducer,
    paymentReducer,
    transactionHistoryReducer,
    accountUsersReducer,
  });

export { initialState };
