/* eslint-disable linebreak-style */
/* eslint-disable no-console  */
import axios from 'axios';
import * as types from '../actionTypes/PaymentActionTypes';
import {
  paymentApiEndPoints,
  cardApiEndPoints,
  autoPayApiEndPoints,
} from '../../constants/apiConstants';
import { UseAuthorization } from '../../utils/UseAuthorization';
import { handleUnAuthorizedUser } from './authActionCreators';
import { getCardBrand, getFullBrandName } from '../../utils/PaymentUtils';
import { getTransactionHistory } from './TransactionHistoryActionCreators';
import { getErrorMessages } from '../../utils/ErrorHandler';

export const makePaymentStep = (data) => ({
  type: types.MAKE_PAYMENT_STEP,
  data,
});
export const resetPaymentDetails = () => ({
  type: types.RESET_PAYMENT_DETAILS,
});

export const changeConfigurationFieldValue = (data) => ({
  type: types.CHANGE_PAYMENT_FIELD_VALUE,
  data,
});
export const addNewRowFunction = (data) => ({
  type: types.ADD_NEW_ROW,
  data,
});

export const setInvoicesInfo = (data) => ({
  type: types.SET_INVOICE_INFO,
  data,
});

export const addMethodType = (data) => ({
  type: types.ADD_METHOD_TYPE,
  data,
});
export const getPaymentMethodsSuccess = (data) => ({
  type: types.PAYMENT_METHODS,
  data,
});

export const loadingPaymentMethods = (data) => ({
  type: types.LOADING_PAYMENT_METHODS,
  data,
});

export const loadingPaymentSubmitted = (data) => ({
  type: types.LOADING_PAYMENT_SUBMITTED,
  data,
});

export const getPaymentMethodsFailed = (data) => ({
  type: types.GET_PAYMENT_METHODS_FAILED,
  data,
});

export const makePaymentFailed = (data) => ({
  type: types.MAKE_PAYMENT_FAILED,
  data,
});
export const addPaymentMethodStepNumber = (data) => ({
  type: types.ADD_PAYMENT_METHOD_STEP,
  data,
});
export const resetError = (data) => ({
  type: types.RESET_ERROR,
  data,
});

export const completePaymentSuccess = (data) => ({
  type: types.COMPLETE_PAYMENT_SUCCESS,
  data,
});

export const getPaymentMethods = async (dispatch) => {
  dispatch(loadingPaymentMethods(true));
  axios
    .get(`${paymentApiEndPoints.getPaymentMethods}`, await UseAuthorization())
    .then((response) => {
      dispatch(loadingPaymentMethods(false));
      if (response.data.status === 200) {
        dispatch(getPaymentMethodsSuccess(response.data.data));
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.status === 401) {
        handleUnAuthorizedUser(dispatch);
      } else if (error && error.response && error.response.status === 400) {
        const errorMsg = getErrorMessages(
          error,
          'Failed to load payment methods. Please contact support.'
        );
        dispatch(getPaymentMethodsFailed(errorMsg));
      } else {
        dispatch(
          getPaymentMethodsFailed(
            'Failed to load payment methods. Please contact support.'
          )
        );
      }
      dispatch(loadingPaymentMethods(false));
      throw error;
    });
};

export const completePayment = async (dispatch, data) => {
  dispatch(loadingPaymentSubmitted(true));
  const apiEndPoint =
    data.paymentDateType === 'PayNow'
      ? `${paymentApiEndPoints.makeImmediatePayment}`
      : `${paymentApiEndPoints.makeScheduledPayment}`;
  axios
    .post(apiEndPoint, data, await UseAuthorization())
    .then((response) => {
      dispatch(loadingPaymentSubmitted(false));
      if (response.data.status === 200) {
        const paymentSuccessData = {
          ...response.data.data,
          paymentStatus: response.data.data.paymentStatus
            ? response.data.data.paymentStatus
            : data.paymentDateType === 'PayNow'
            ? 'Processed'
            : 'Scheduled',
        };
        dispatch(completePaymentSuccess(paymentSuccessData));
        dispatch(getTransactionHistory());
        dispatch(makePaymentStep(5));
      } else {
        dispatch(makePaymentFailed({ isPaymentFailed: false }));
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.status === 401) {
        handleUnAuthorizedUser(dispatch);
      }
      const errorObject = {
        isPaymentFailed: true,
        message:
          error?.response?.data?.error ||
          'Failed to complete the Payment. Please contact support 877-286-7117',
      };
      dispatch(loadingPaymentSubmitted(false));
      dispatch(makePaymentFailed(errorObject));
    });
};

/** Start Enrollment for autopay */
export const enrollAutoPayment = async (dispatch, data) => {
  dispatch(loadingPaymentSubmitted(true));
  axios
    .post(`${autoPayApiEndPoints.startEnrollment}`, data, await UseAuthorization())
    .then((response) => {
      dispatch(loadingPaymentSubmitted(false));
      if (response.data.status === 200 && response.data.isSuccess) {
        dispatch(makePaymentStep(4));
      } else if (response.data.status !== 200) {
        dispatch(makePaymentFailed({ isPaymentFailed: false }));
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.status === 401) {
        handleUnAuthorizedUser(dispatch);
      }
      const errorObject = {
        isPaymentFailed: true,
        message: 'Failed to enroll for autopayment. Please contact support.',
      };
      dispatch(loadingPaymentSubmitted(false));
      dispatch(makePaymentFailed(errorObject));
    });
};

/** Request Action type for add credit card */
export const addCreditCardRequest = () => ({
  type: types.ADD_CREDIT_CARD_REQUEST,
});

/** Success Action type for add credit card */
export const addCreditCardSuccess = (data) => ({
  type: types.ADD_CREDIT_CARD_SUCCESS,
  data,
});

/** Failure Action type for add or update credit card */
export const addCreditCardFailure = (data) => ({
  type: types.ADD_CREDIT_CARD_FAILURE,
  data,
});

/** Request Action type for add bank account */
export const addACHRequest = () => ({
  type: types.ADD_ACH_REQUEST,
});

/** Success Action type for add bank account */
export const addACHSuccess = (data) => ({
  type: types.ADD_ACH_SUCCESS,
  data,
});

/** Failure Action type for add bank account */
export const addACHFailure = (data) => ({
  type: types.ADD_ACH_FAILURE,
  data,
});

/** Request Action type for delete payment method */
export const deletePaymentMethodRequest = () => ({
  type: types.DELETE_PAYMENT_METHOD_REQUEST,
});

/** Success Action type for delete payment method */
export const deletePaymentMethodSuccess = (paymentToken) => ({
  type: types.DELETE_PAYMENT_METHOD_SUCCESS,
  paymentToken,
});

/** Failure Action type for delete payment method */
export const deletePaymentMethodFailure = (data) => ({
  type: types.DELETE_PAYMENT_METHOD_FAILURE,
  data,
});

/** Change Field Values */
export const changeFieldValue = (data) => ({
  type: types.CHANGE_FIELD_VALUE,
  data,
});

/** Reset Field Values */
export const resetFieldValues = () => ({
  type: types.RESET_FIELD_VALUES,
});

const getAccountType = (accountType) => {
  let accountTypeValue = '';
  switch (accountType) {
    case 'Consumer Checking':
      accountTypeValue = 'C';
      break;
    case 'Consumer Savings':
      accountTypeValue = 'S';
      break;
    case 'Commercial Checking':
      accountTypeValue = 'X';
      break;
    default:
      accountTypeValue = 'C';
      break;
  }
  return accountTypeValue;
};

/** Toggle Add Payment Modal */
export const toggleAddPaymentModal = (isOpen) => ({
  type: types.TOGGLE_ADD_PAYMENT_MODAL,
  isOpen,
});

/** Toggle Manage Payment Modal */
export const toggleManagePaymentModal = (isOpen) => ({
  type: types.TOGGLE_MANAGE_PAYMENT_MODAL,
  isOpen,
});

/** Toggle Recurring Payment confirmation Modal */
export const toggleRecurringPaymentConfirmationModal = (isOpen) => ({
  type: types.TOGGLE_RECURRING_PAYMENT_CONFIRMATION_MODAL,
  isOpen,
});

/** Save Payment Token Id */
export const savePaymentTokenId = (id) => ({
  type: types.SAVE_PAYMENT_TOKEN_ID,
  id,
});

/** Save One Time Payment Details */
export const saveOneTimePayDetails = (data) => ({
  type: types.SAVE_ONE_TIME_PAYMENT_DETAILS,
  data,
});

/** Set save to wallet status */
export const setSaveToWalletStatus = (isSave) => ({
  type: types.SET_SAVE_TO_WALLET_STATUS,
  isSave,
});

/** Add Bank Account Action */
export const AddACHAction = () => async (dispatch, getState) => {
  const { paymentAccountDetails, isSaveToWallet } = getState().paymentReducer;
  const accountData = paymentAccountDetails;
  const accountNum = accountData.accountNumber;
  const lastFour = accountNum.slice(accountNum.length - 4);
  const data = {
    customerName: accountData.customerName,
    customerAddress1: accountData.customerAddress1,
    customerAddress2: accountData.customerAddress2,
    customerCity: accountData.customerCity,
    customerState: accountData.customerState,
    customerZIP: accountData.customerZIP,
    customerPhone: accountData.customerPhone,
    customerCountryCode: accountData.customerCountryCode,
    paymentName: '',
    routingNumber: accountData.routingNumber,
    accountNumber: accountNum,
    accountType: getAccountType(accountData.accountType),
    paymentMethodSuffix: lastFour,
  };
  await dispatch(addACHRequest());
  axios
    .post(`${cardApiEndPoints.addACH}`, data, await UseAuthorization())
    .then(async (response) => {
      if (response.data.status === 200) {
        await dispatch(addACHSuccess(response.data.data));
        await getPaymentMethods(dispatch);
        dispatch(addPaymentMethodStepNumber(1));
        dispatch(toggleAddPaymentModal(false));
        dispatch(toggleManagePaymentModal(false));
        if (!isSaveToWallet) {
          dispatch(savePaymentTokenId(response.data.data));
          const oneTimeData = {
            brand: 'ACH',
            paymentTokenId: response.data.data,
            paymentMethodSuffix: data.paymentMethodSuffix,
          };
          dispatch(saveOneTimePayDetails(oneTimeData));
          dispatch(
            changeConfigurationFieldValue({
              value: response.data.data,
              id: 'paymentTokenId',
            })
          );
          dispatch(
            changeConfigurationFieldValue({
              value: new Date(),
              id: 'paymentDate',
            })
          );
          dispatch(
            changeConfigurationFieldValue({
              value: 'PayNow',
              id: 'paymentDateType',
            })
          );
        }
        dispatch(resetFieldValues());
        dispatch(setSaveToWalletStatus(true));
      } else if (response.data.status !== 200) {
        dispatch(addACHFailure());
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.status === 401) {
        handleUnAuthorizedUser(dispatch);
      }
      const errorObject = {
        isAddingAccountFailed: true,
        message:
          error?.response?.data?.error ||
          'An error occurred while adding your account. Please check your account information or contact support 877-286-7117',
      };
      dispatch(addACHFailure(errorObject));
      throw error;
    });
};

/** Add Credit Card Action */
export const AddCreditCardAction =
  (isMakePayment, paymentData) => async (dispatch, getState) => {
    const { paymentAccountDetails, isSaveToWallet } = getState().paymentReducer;
    const accountData = paymentAccountDetails;
    const cardNum = accountData.encryptedCreditCardNumber;
    const lastFour = cardNum.slice(cardNum.length - 4);
    const cardBrand = getCardBrand(accountData.cardNumber);
    const { expYear } = accountData;
    const updatedExpYear = expYear.toString();
    const yearYY = updatedExpYear.slice(updatedExpYear.length - 2);
    const cardExp = `${accountData.expMonth}${yearYY}`;
    const data = {
      customerName: accountData.customerName,
      customerAddress1: accountData.customerAddress1,
      customerAddress2: accountData.customerAddress2,
      customerCity: accountData.customerCity,
      customerState: accountData.customerState,
      customerZIP: accountData.customerZIP,
      customerPhone: accountData.customerPhone,
      customerCountryCode: accountData.customerCountryCode,
      encryptedCreditCardNumber: accountData.encryptedCreditCardNumber,
      paymentMethodSuffix: lastFour,
      cardBrand,
      encryptedIntegrityCheck: accountData.encryptedIntegrityCheck,
      encryptedCardSecurityValue: accountData.encryptedCardSecurityValue,
      expiration: cardExp,
      isOneTimePayment: !isSaveToWallet,
    };
    await dispatch(addCreditCardRequest());
    axios
      .post(`${cardApiEndPoints.addCreditCard}`, data, await UseAuthorization())
      .then(async (response) => {
        if (response.data.status === 200) {
          await dispatch(addCreditCardSuccess(true));
          if (isMakePayment) {
            paymentData.paymentTokenId = response.data.data;
            completePayment(dispatch, paymentData);
            return;
          }
          await getPaymentMethods(dispatch);
          dispatch(addPaymentMethodStepNumber(1));
          dispatch(toggleAddPaymentModal(false));
          dispatch(toggleManagePaymentModal(false));
          if (!isSaveToWallet) {
            dispatch(savePaymentTokenId(response.data.data));
            const oneTimeData = {
              brand: getFullBrandName(accountData.cardNumber),
              paymentTokenId: response.data.data,
              paymentMethodSuffix: data.paymentMethodSuffix,
            };
            dispatch(saveOneTimePayDetails(oneTimeData));
            dispatch(
              changeConfigurationFieldValue({
                value: response.data.data,
                id: 'paymentTokenId',
              })
            );
            dispatch(
              changeConfigurationFieldValue({
                value: new Date(),
                id: 'paymentDate',
              })
            );
            dispatch(
              changeConfigurationFieldValue({
                value: 'PayNow',
                id: 'paymentDateType',
              })
            );
          }
          dispatch(resetFieldValues());
          dispatch(setSaveToWalletStatus(true));
        } else if (response.data.status !== 200) {
          await dispatch(addCreditCardFailure(response.data.data));
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 401) {
          handleUnAuthorizedUser(dispatch);
        }
        const errorObject = {
          isAddingCreditCardFailed: true,
          message:
            error?.response?.data?.error ||
            'An error occurred while adding your card. Please check your card information or contact support 877-286-7117',
        };
        dispatch(addCreditCardFailure(errorObject));
        throw error;
      });
  };

/** Request Action type for is user enrolled in autopay */
export const isUserEnrolledRequest = () => ({
  type: types.IS_USER_ENROLLED_IN_AUTOPAY_REQUEST,
});

/** Success Action type for is user enrolled in autopay */
export const isUserEnrolledSuccess = (data) => ({
  type: types.IS_USER_ENROLLED_IN_AUTOPAY_SUCCESS,
  data,
});

/** Failure Action type for is user enrolled in autopay */
export const isUserEnrolledFailure = (data) => ({
  type: types.IS_USER_ENROLLED_IN_AUTOPAY_FAILURE,
  data,
});

/** Is user Enrolled for autopay */
export const IsUserEnrolledAction = async (dispatch) => {
  dispatch(isUserEnrolledRequest());
  axios
    .get(`${autoPayApiEndPoints.isEnrolled}`, await UseAuthorization())
    .then((response) => {
      if (response.data.status === 200) {
        dispatch(isUserEnrolledSuccess(response.data.data));
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.status === 401) {
        handleUnAuthorizedUser(dispatch);
      } else if (error && error.response && error.response.status === 400) {
        const errorMsg = getErrorMessages(
          error,
          'Failed to load enrollment(s). Please contact support.'
        );
        dispatch(isUserEnrolledFailure(errorMsg));
      } else {
        dispatch(
          isUserEnrolledFailure('Failed to load enrollment(s). Please contact support.')
        );
      }
      throw error;
    });
};

/** Delete Payment method Action */
export const DeletePaymentMethodAction = async (dispatch, paymentToken) => {
  dispatch(deletePaymentMethodRequest());
  axios
    .post(
      `${cardApiEndPoints.deleteCard}?paymentTokenId=${paymentToken}`,
      {},
      await UseAuthorization()
    )
    .then((response) => {
      if (response.data.status === 200 && response.data.isSuccess) {
        dispatch(deletePaymentMethodSuccess(paymentToken));
        IsUserEnrolledAction(dispatch);
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.status === 401) {
        handleUnAuthorizedUser(dispatch);
      } else if (error && error.response && error.response.status === 400) {
        const errorMsg = getErrorMessages(
          error,
          'Failed to delete payment method. Please contact support.'
        );
        dispatch(deletePaymentMethodFailure(errorMsg));
      } else {
        dispatch(
          deletePaymentMethodFailure(
            'Failed to delete payment method. Please contact support.'
          )
        );
      }
      throw error;
    });
};

/** Set AutoPay Enrollment Data */
export const setAutoPayEnrollmentData = (data) => ({
  type: types.SET_AUTO_PAY_ENROLLMENT_DATA,
  data,
});

/** Reset AutoPay Enrollment Data */
export const resetAutoPayEnrollmentData = () => ({
  type: types.RESET_AUTO_PAY_ENROLLMENT_DATA,
});

/** Set enable managment action */
export const setEnableManagement = (data) => ({
  type: types.SET_ENABLE_MANAGEMENT,
  data,
});

/** Action type for autopay edit enrollment data */
export const updateEnrollment = (data) => ({
  type: types.UPDATE_ENROLLMENT,
  data,
});

/** Request Action type for delete payment method */
export const startEnrollmentRequest = () => ({
  type: types.START_ENROLLMENT_REQUEST,
});

/** Success Action type for autopay start enrollment method */
export const startEnrollmentSuccess = (data) => ({
  type: types.START_ENROLLMENT_SUCCESS,
  data,
});

/** Failure Action type for autopay start enrollment method */
export const startEnrollmentFailure = (error) => ({
  type: types.START_ENROLLMENT_FAILURE,
  data: error,
});

/** Start Enrollment for autopay */
export const StartEnrollmentAction = async (dispatch, data) => {
  dispatch(startEnrollmentRequest());
  axios
    .post(`${autoPayApiEndPoints.startEnrollment}`, data, await UseAuthorization())
    .then((response) => {
      if (response.data.status === 200 && response.data.isSuccess) {
        dispatch(startEnrollmentSuccess(response.data.message));
        getPaymentMethods(dispatch);
        IsUserEnrolledAction(dispatch);
        dispatch(setEnableManagement(false));
        dispatch(toggleRecurringPaymentConfirmationModal(true));
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.status === 401) {
        handleUnAuthorizedUser(dispatch);
      } else if (error && error.response && error.response.status === 400) {
        const errorMsg =
          (error && error.response && error.response.data.error) ||
          'Failed to schedule recurring payment. Please contact support.';
        dispatch(startEnrollmentFailure(errorMsg));
      } else {
        // Else case handled here
        dispatch(
          startEnrollmentFailure(
            'Failed to schedule recurring payment. Please contact support.'
          )
        );
      }
      throw error;
    });
};

/** Request Action type for end enrollment for autopay */
export const endEnrollmentRequest = () => ({
  type: types.END_ENROLLMENT_REQUEST,
});

/** Success Action type for end enrollment for autopay */
export const endEnrollmentSuccess = (data) => ({
  type: types.END_ENROLLMENT_SUCCESS,
  data,
});

/** Failure Action type for end enrollment for autopay */
export const endEnrollmentFailure = (data) => ({
  type: types.END_ENROLLMENT_FAILURE,
  data,
});

/** End Enrollment for autopay */
export const EndEnrollmentAction = (id) => async (dispatch) => {
  const data = id
    ? {
        id,
      }
    : {};
  dispatch(endEnrollmentRequest());
  axios
    .post(`${autoPayApiEndPoints.endEnrollment}`, data, await UseAuthorization())
    .then((response) => {
      if (response.data.status === 200) {
        dispatch(endEnrollmentSuccess(response.data.data));
        IsUserEnrolledAction(dispatch);
        getPaymentMethods(dispatch);
      } else if (response.data.status !== 200) {
        dispatch(endEnrollmentFailure());
      }
      dispatch(setEnableManagement(false));
    })
    .catch((error) => {
      if (error && error.response && error.response.status === 401) {
        handleUnAuthorizedUser(dispatch);
      } else if (error && error.response && error.response.status === 400) {
        const errorMsg =
          (error && error.response && error.response.data.error) ||
          'Failed to cancel recurring payment. Please contact support.';
        dispatch(endEnrollmentFailure(errorMsg));
      } else {
        // Else case handled here
        dispatch(
          endEnrollmentFailure(
            'Failed to cancel recurring payment. Please contact support.'
          )
        );
      }
      dispatch(setEnableManagement(false));
      throw error;
    });
};

/** Request Action type for recurring payments */
export const fetchRecurringPaymentsRequest = () => ({
  type: types.FETCH_RECURRING_PAYMENTS_REQUEST,
});

/** Success Action type for recurring payments */
export const fetchRecurringPaymentsSuccess = (data) => ({
  type: types.FETCH_RECURRING_PAYMENTS_SUCCESS,
  data,
});

/** Failure Action type for recurring payments */
export const fetchRecurringPaymentsFailure = (data) => ({
  type: types.FETCH_RECURRING_PAYMENTS_FAILURE,
  data,
});

/** Get Recurring Payment Types */
export const fetchRecurringPaymentTypesAction = async (dispatch) => {
  dispatch(fetchRecurringPaymentsRequest());
  axios
    .get(`${autoPayApiEndPoints.recurringPaymentTypes}`, await UseAuthorization())
    .then((response) => {
      if (response.data.status === 200) {
        dispatch(fetchRecurringPaymentsSuccess(response.data.data));
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.status === 401) {
        handleUnAuthorizedUser(dispatch);
      } else if (error && error.response && error.response.status === 400) {
        const errorMsg =
          (error && error.response && error.response.data.error) ||
          'Failed to load recurring payment(s). Please contact support.';
        dispatch(fetchRecurringPaymentsFailure(errorMsg));
      } else {
        // Else case handled here
        dispatch(
          fetchRecurringPaymentsFailure(
            'Failed to load recurring payment(s). Please contact support.'
          )
        );
      }
      throw error;
    });
};

/** Request Action type for fetching week days for autopay */
export const fetchWeekDaysRequest = () => ({
  type: types.FETCH_WEEK_DAYS_REQUEST,
});

/** Success Action type for fetching week days for autopay */
export const fetchWeekDaysSuccess = (data) => ({
  type: types.FETCH_WEEK_DAYS_SUCCESS,
  data,
});

/** Failure Action type for fetching week days for autopay */
export const fetchWeekDaysFailure = () => ({
  type: types.FETCH_WEEK_DAYS_FAILURE,
});

/** Get Week days for recurring payment */
export const getWeekDaysAction = async (dispatch) => {
  dispatch(fetchWeekDaysRequest());
  axios
    .get(`${autoPayApiEndPoints.getWeekDays}`, await UseAuthorization())
    .then((response) => {
      if (response.data.status === 200) {
        dispatch(fetchWeekDaysSuccess(response.data.data));
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.status === 401) {
        handleUnAuthorizedUser(dispatch);
      } else if (error && error.response && error.response.status === 400) {
        const errorMsg =
          (error && error.response && error.response.data.error) ||
          'Failed to load week days(s). Please contact support.';
        dispatch(fetchWeekDaysFailure(errorMsg));
      } else {
        // Else case handled here
        dispatch(
          fetchWeekDaysFailure('Failed to load week days(s). Please contact support.')
        );
      }
      throw error;
    });
};
