/* eslint-disable linebreak-style */
import theme from '../../../../../theme.module.scss';

const customStyles = {
  iconStyle: {
    position: 'relative',
    top: '6.75px',
  },
  expandIconStyle: {
    position: 'relative',
    top: '6.75px',
    paddingLeft: '.5rem',
  },
  smallCC: {
    color: theme.darkGrey,
  },
  smallSpace: {
    paddingLeft: '.25rem',
  },
  pushRight: {
    marginLeft: 'auto',
    display: 'table',
    paddingTop: '.5rem',
    paddingBottom: '1rem',
  },
  paymentAmountLabel: {
    padding: '.75rem',
  },
  nextButtonDiv: {
    marginLeft: 'auto',
    marginRight: 'auto',
    justifyContent: 'center',
  },
  paymentModuleMaxWidth: {
    maxWidth: '300px',
    margin: 'auto',
  },
};

export default customStyles;
