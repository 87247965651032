/* eslint-disable linebreak-style */
import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import * as MUI from '../styles/MUIComponents';
import useClasses from '../styles/SupportClasses';
import TermsAndConditionsText from '../../../../terms-and-conditions/terms-and-conditions-text';

const TermsAndConditions = () => {
  const [enableTermsAndConditions, setEnableTermsAndConditions] = useState();

  const classes = useClasses();
  return (
    <Grid className={classes.container}>
      <Grid className={classes.subSectionHeader}>Terms and Conditions</Grid>
      <Grid className={classes.moduleContent}>
        <Grid className={classes.row}>
          <Grid className={classes.paddingDiv0}>
            <Grid
              className={`${classes.upperPad} ${classes.TermsAndConditionsBTNdiv} ${classes.buttonDiv}`}
            >
              <span className={`${classes.marleft0}`}>
                {!enableTermsAndConditions ? (
                  <MUI.TermsAndConditionsBTN
                    onClick={() => {
                      setEnableTermsAndConditions(true);
                    }}
                  >
                    View Terms and Conditions
                  </MUI.TermsAndConditionsBTN>
                ) : (
                  <MUI.TermsAndConditionsBTN
                    onClick={() => {
                      setEnableTermsAndConditions(false);
                    }}
                  >
                    Close
                  </MUI.TermsAndConditionsBTN>
                )}
              </span>
            </Grid>

            {enableTermsAndConditions && (
              <Grid className={classes.scrollbox}>
                <TermsAndConditionsText />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TermsAndConditions;
