/* eslint-disable linebreak-style */
import React from 'react';
import AutoHeightBox from '../../../../../shared/package/layout/AutoHeightBox';
import StyledPanel from '../../../../../shared/package/styles/StyledPanel';
import AccountInformation from '../../../../../shared/account-information/AccountInformation';

const UserSettingsRightPanel = () => (
  <AutoHeightBox md={{ mb: 1 }}>
    <StyledPanel>
      <AccountInformation />
    </StyledPanel>
  </AutoHeightBox>
);

export default UserSettingsRightPanel;
