/* eslint-disable linebreak-style */
/** Auth Api End points */
export const authApiEndPoints = {
  login: `${process.env.REACT_APP_IDENTITY_URL}/`,
  postForgotPassword: `${process.env.REACT_APP_IDENTITY_URL}/api/AccountApi/EmailResetPassword`,
  forgotUsername: `${process.env.REACT_APP_IDENTITY_URL}/api/AccountApi/ForgotUsername`,
  resetPassword: `${process.env.REACT_APP_IDENTITY_URL}/api/AccountApi/ResetPassword`,
  createUser: `${process.env.REACT_APP_IDENTITY_URL}/api/AccountApi/CreateUser`,
  changePassword: `${process.env.REACT_APP_IDENTITY_URL}/api/AccountApi/ChangePassword`,
  verifyEmail: `${process.env.REACT_APP_IDENTITY_URL}/api/AccountApi/VerifyEmail`,
  resendEmailConfirmation: `${process.env.REACT_APP_IDENTITY_URL}/api/AccountApi/EmailVerifyEmail`,
};

/** Client portal app api end points */
export const appApiEndPoints = {
  createUser: `${process.env.REACT_APP_API_URL}/User/Create`,
  updateTermsAndConditions: `${process.env.REACT_APP_API_URL}/User/UpdateTermsAndConditions`,
  userInfo: `${process.env.REACT_APP_API_URL}/User/GetUserInformation`,
};

export const adjustmentApiEndPoints = {
  getAdjustmentReasons: `${process.env.REACT_APP_API_URL}/AdjustmentRequest/GetAdjustmentRequestReasons`,
  requestAdjustment: `${process.env.REACT_APP_API_URL}/AdjustmentRequest/CreateAdjustment`,
};

export const cardApiEndPoints = {
  addCreditCard: `${process.env.REACT_APP_API_URL}/AddCreditCard`,
  addACH: `${process.env.REACT_APP_API_URL}/AddACH`,
  updateCreditCard: `${process.env.REACT_APP_API_URL}/UpdateCreditCard`,
  updateACH: `${process.env.REACT_APP_API_URL}/UpdateACH`,
  deleteCard: `${process.env.REACT_APP_API_URL}/DeletePaymentToken`,
};

export const paymentApiEndPoints = {
  getPaymentMethods: `${process.env.REACT_APP_API_URL}/Payment/GetPaymentMethods`,
  makeImmediatePayment: `${process.env.REACT_APP_API_URL}/Payment/MakeImmediatePayment`,
  makeScheduledPayment: `${process.env.REACT_APP_API_URL}/Payment/MakeScheduledPayment`,
};

export const TransactionHistoryApiEndPoints = {
  getTransactionHistory: `${process.env.REACT_APP_API_URL}/Payment/GetPaymentTransactions`,
  cancelScheduledPayment: `${process.env.REACT_APP_API_URL}/Payment/CancelScheduledPayment`,
};

export const autoPayApiEndPoints = {
  startEnrollment: `${process.env.REACT_APP_API_URL}/AutoPay/StartEnrollment`,
  endEnrollment: `${process.env.REACT_APP_API_URL}/AutoPay/EndEnrollment`,
  isEnrolled: `${process.env.REACT_APP_API_URL}/AutoPay/GetEnrollment`,
  recurringPaymentTypes: `${process.env.REACT_APP_API_URL}/AutoPay/GetRecurringPaymentTypes`,
  getWeekDays: `${process.env.REACT_APP_API_URL}/AutoPay/GetWeekDays`,
};

export const AccountUsersApiEndPoints = {
  createSecondaryUser: `${process.env.REACT_APP_API_URL}/User/CreateSecondaryUser`,
  getSecondaryUsers: `${process.env.REACT_APP_API_URL}/User/GetSecondaryUsers`,
  enableUser: `${process.env.REACT_APP_API_URL}/User/EnableUser`,
  disableUser: `${process.env.REACT_APP_API_URL}/User/DisableUser`,
  updateUser: `${process.env.REACT_APP_API_URL}/User/EditUser`,
};
