/* eslint-disable linebreak-style */
import React from 'react';
import { Box } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
}));

const AutoHeightBox = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root} {...props}>
      {children}
    </Box>
  );
};

export default AutoHeightBox;
