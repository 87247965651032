/* eslint-disable linebreak-style */
import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import Card from '@material-ui/core/Card';
import { Grid, Link } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import ResetPasswordForm from './ResetPasswordForm';
import useClasses from './ResetPasswordClasses';

import {
  HeroContainer,
  CardContainer,
  SectionWrapper,
} from '../../shared/components/SharedComponentStyles';

const ResetPassword = () => {
  const classes = useClasses();
  const dispatch = useDispatch();
  return (
    <SectionWrapper>
      <Grid className={`${classes.resetPasswordModule}`}>
        <Grid className={`${classes.resetPasswordWrapper}`}>
          <HeroContainer>
            <CardContainer>
              <Card>
                <CardContent>
                  <Grid className={`${classes.headerText}`}> Password Reset</Grid>
                  <div className={`${classes.linkText}`}>
                    <Link onClick={() => dispatch(push({ pathname: '/login' }))}>
                      <span className={`${classes.linkText}`}>Return to Login</span>
                    </Link>
                  </div>
                  <ResetPasswordForm />
                </CardContent>
              </Card>
            </CardContainer>
          </HeroContainer>
        </Grid>
      </Grid>
    </SectionWrapper>
  );
};

export default ResetPassword;
