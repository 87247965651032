/* eslint-disable linebreak-style */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as MUI from '../styles/MUI_Components';
import useClasses from '../styles/Classes';
import * as Actions from '../../../../../redux/actionCreators/PaymentActionCreators';
import AddPaymentMethod from './AddPaymentMethod';

const PaymentMethodTypes = (props) => {
  const classes = useClasses();
  const dispatch = useDispatch();

  const { showAddPaymentModal } = useSelector((state) => ({
    showAddPaymentModal: state.paymentReducer.showAddPaymentModal,
  }));

  const addMethod = (val) => {
    dispatch(Actions.addMethodType(val));
    dispatch(Actions.toggleAddPaymentModal(false));
    dispatch(Actions.toggleAddPaymentModal(true));
  };

  const getAddPaymentMethodCallback = () => {
    dispatch(Actions.toggleAddPaymentModal(false));
  };

  return (
    <>
      <div className={`${classes.paymentMethodTypesButtonGroup} ${classes.upperPad}`}>
        <MUI.ActionButton
          data-testid="add-new-card"
          onClick={() => {
            addMethod('card');
          }}
        >
          {' '}
          Add Card
        </MUI.ActionButton>

        <MUI.ActionButton
          data-testid="add-new-bank"
          onClick={() => {
            addMethod('account');
          }}
          className={classes.secondButton}
        >
          {' '}
          Add Bank Account
        </MUI.ActionButton>

        {props.showCloseBtn && (
          <MUI.ActionButton
            data-testid="close"
            onClick={() => {
              dispatch(Actions.toggleManagePaymentModal(false));
            }}
            className={classes.secondButton}
          >
            {' '}
            Close
          </MUI.ActionButton>
        )}
      </div>

      <AddPaymentMethod
        module={props.module}
        launchAddPaymentMethod={showAddPaymentModal}
        addpaymentmethodCallback={getAddPaymentMethodCallback}
        isRecurringPaymentFlow={props.isRecurringPaymentFlow}
      />
    </>
  );
};

export default PaymentMethodTypes;
