/* eslint-disable linebreak-style */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

const HtmlTooltip = withStyles((custom) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontFamily: 'Reach-Text-Regular, sans-serif',
    fontSize: custom.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

/** Tooltip component */
const ToolTip = (props) => (
  <HtmlTooltip
    placement="top"
    disableFocusListener
    enterTouchDelay={10}
    title={
      <>
        <Typography color="inherit">{props.toolTipText}</Typography>
      </>
    }
  >
    {props.children}
  </HtmlTooltip>
);

export default ToolTip;
