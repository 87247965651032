/* eslint-disable linebreak-style */
import React from 'react';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { Card, Link, Grid } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import useClasses from './ForgetUsernameClasses';
import {
  HeroContainer,
  CardContainer,
  SectionWrapper,
} from '../../shared/components/SharedComponentStyles';
import ForgotUsernameForm from './ForgotUsernameForm';

const ForgotUsername = () => {
  const classes = useClasses();
  const dispatch = useDispatch();
  return (
    <SectionWrapper>
      <Grid className={`${classes.forgotUsernameModule}`}>
        <Grid className={`${classes.forgotUsernameWrapper}`}>
          <HeroContainer>
            <CardContainer>
              <Card>
                <CardContent>
                  <div className={`${classes.headerText}`}>Forgot Username</div>
                  <div className={`${classes.linkText}`}>
                    {' '}
                    <Link onClick={() => dispatch(push({ pathname: '/login' }))}>
                      <span className={`${classes.linkColor}`}>Return to Login</span>
                    </Link>
                  </div>
                  <ForgotUsernameForm />
                </CardContent>
              </Card>
            </CardContainer>
          </HeroContainer>
        </Grid>
      </Grid>
    </SectionWrapper>
  );
};

export default ForgotUsername;
