import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import IdleTimer from 'react-idle-timer';

import { logoutUser } from '../../redux/actionCreators/authActionCreators';
import { constants as tokenConstants } from '../../utils/AuthUtils';

const LogoutUserOnInactivity = () => {
  const idleTimerRef = useRef(null);
  const dispatch = useDispatch();
  const onIdle = () => {
    const isUserLoggedIn = window.sessionStorage.getItem(tokenConstants.REFRESH_TOKEN);
    if (isUserLoggedIn) {
      logoutUser(dispatch);
    }
  };
  return (
    <div>
      <IdleTimer ref={idleTimerRef} timeout={15 * 60 * 1000} onIdle={onIdle} />
    </div>
  );
};

export default LogoutUserOnInactivity;
