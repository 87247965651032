/* eslint-disable linebreak-style */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import * as MUI from '../styles/MUIComponents';
import useClasses from '../styles/ManageAccountClasses';
import DisableUser from './DisableUser';
import AddUser from './AddUserModal';
import SaveUserModal from './SaveUserModal';
import * as Actions from '../../../../../redux/actionCreators/AccountUsersActionCreators';
import { ComponentLoader } from '../../../../../shared/components/Loader';
import ValidationError from '../../../../../shared/components/ValidationError';

const SecondaryUsers = () => {
  const classes = useClasses();

  const dispatch = useDispatch();

  const {
    secondaryUsers,
    userInfoDetails,
    enableAddUserModal,
    secondaryUser,
    userEnablingFailedError,
    isGettingSecondaryUsersLoading,
    getSecondaryUsersFailedError,
    isSaveUserModalOpen,
  } = useSelector((state) => ({
    secondaryUsers: state.accountUsersReducer.secondaryUsers,
    userInfoDetails: state.userReducer.userInfoDetails,
    enableAddUserModal: state.accountUsersReducer.enableAddUserModal,
    secondaryUser: state.accountUsersReducer.secondaryUser,
    userEnablingFailedError: state.accountUsersReducer.userEnablingFailedError,
    isGettingSecondaryUsersLoading:
      state.accountUsersReducer.isGettingSecondaryUsersLoading,
    getSecondaryUsersFailedError: state.accountUsersReducer.getSecondaryUsersFailedError,
    isSaveUserModalOpen: state.accountUsersReducer.isSaveUserModalOpen,
  }));

  useEffect(() => {
    dispatch(Actions.resetAccountUsers());
    Actions.getSecondaryUsers(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [disableUser, setDisableUser] = useState({});

  const enableUserMethod = (val) => {
    Actions.enableUser(dispatch, val);
  };

  const disableUserMethod = (item) => {
    setDisableUser(item);
    dispatch(
      Actions.changeSecondaryUserFieldValue({
        value: true,
        id: 'isDisableModalOpen',
      })
    );
  };

  const getValue = (id) => {
    const value = secondaryUser && secondaryUser[id];
    return value;
  };

  return (
    <Grid className={classes.container}>
      <Grid className={classes.subSectionHeader}>Account Users</Grid>
      <Grid className={classes.moduleContent}>
        <Grid className={classes.row}>
          <Grid className={classes.paddingDiv0}>
            {isGettingSecondaryUsersLoading ? (
              <ComponentLoader />
            ) : (
              <>
                {' '}
                {userInfoDetails && userInfoDetails.userType === 1 ? (
                  <div>{`${userInfoDetails.firstName} ${userInfoDetails.lastName}`}</div>
                ) : (
                  <div>{userInfoDetails.parentUserName}</div>
                )}
                <div>
                  <span>Owner</span>
                  <span>Primary Role</span>
                </div>
                <>
                  <div className={`${classes.upperPad} userlist`}>
                    {secondaryUsers &&
                      secondaryUsers.map((item) => (
                        <div key={item.id} className={`${classes.deleteMethodRow}`}>
                          <span>{item.userName}</span>
                          {userInfoDetails &&
                            userInfoDetails.userType === 1 &&
                            (item.active ? (
                              <span
                                className={classes.disableMethod}
                                role="link"
                                tabIndex={0}
                                onKeyPress={() => ''}
                                onClick={() => {
                                  disableUserMethod(item);
                                }}
                              >
                                disable
                              </span>
                            ) : (
                              <span
                                className={classes.enableMethod}
                                role="link"
                                tabIndex={0}
                                onKeyPress={() => ''}
                                onClick={() => {
                                  enableUserMethod(item.id);
                                }}
                              >
                                enable
                              </span>
                            ))}
                        </div>
                      ))}
                  </div>
                </>
                {userEnablingFailedError && (
                  <ValidationError validationErrorData={userEnablingFailedError} />
                )}
                {getSecondaryUsersFailedError && (
                  <ValidationError validationErrorData={getSecondaryUsersFailedError} />
                )}
                <>
                  {userInfoDetails && userInfoDetails.userType === 1 && (
                    <div className={`${classes.upperPad} ${classes.updateContactBTNDiv}`}>
                      <Grid className={`${classes.buttonDiv}`}>
                        <span>
                          <MUI.ActionButton
                            onClick={() => {
                              dispatch(Actions.renderAddUserModal(true));
                            }}
                          >
                            Add User
                          </MUI.ActionButton>
                        </span>
                      </Grid>
                    </div>
                  )}
                </>
                <AddUser launchAddUserMethod={enableAddUserModal} />
                <SaveUserModal launchSaveUserModal={isSaveUserModalOpen} />
                <DisableUser
                  launchDeleteUserMethod={getValue('isDisableModalOpen')}
                  selecteddata={disableUser}
                />
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SecondaryUsers;
