/* eslint-disable linebreak-style */
import React, { useEffect } from 'react';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { Header, Footer } from '@spectrum-reach/external-theme';
import { isUserAuthenticated, removeTokens } from '../../utils/AuthUtils';
import { GetUserInfoAction } from '../../redux/actionCreators/UserActionCreators';
import { loadingSignIn } from '../../redux/actionCreators/authActionCreators';

const useClasses = createUseStyles({
  PageWrapper: {
    boxSizing: 'border - box',
    height: '100 %',
    padding: '0rem',
    width: '100 %',
  },

  Container: {
    margin: '0auto',
    position: 'relative',
    boxSizing: 'content - box',
    minHeight: '100vh',
  },
});

const Layout = (props) => {
  const dispatch = useDispatch();
  const classes = useClasses();

  useEffect(() => {
    if (isUserAuthenticated()) {
      if (Object.keys(userInfoDetails).length === 0) {
        dispatch(GetUserInfoAction());
      }
    }
  }, []);

  const { userInfoDetails, isFetchingUserLoading, isLoadingSignIn } = useSelector(
    (state) => ({
      routerLocation: state.router.location,
      userInfoDetails: state.userReducer.userInfoDetails,
      isFetchingUserLoading: state.userReducer.isFetchingUserLoading,
      isLoadingSignIn: state.authReducer.isLoadingSignIn,
    })
  );

  const isTermsAndConditionsAccepted =
    userInfoDetails &&
    userInfoDetails.acceptedTermsAndConditions &&
    userInfoDetails.acceptedTermsAndConditions;

  const handleClick = ({ name }) => {
    if (isUserLoggedIn()) {
      switch (name) {
        case 'Support':
          dispatch(push({ pathname: '/ext-support' }));
          break;
        case 'New User?':
          dispatch(push({ pathname: '/new-user' }));
          break;
        case 'Terms & Conditions':
          dispatch(push({ pathname: '/ext-terms-and-conditions' }));
          break;
        default:
      }
    } else {
      dispatch(push({ pathname: '/support' }));
    }
  };

  const isUserLoggedIn = () =>
    !isUserAuthenticated() || Object.keys(userInfoDetails).length === 0;

  const currentWebsiteLinks = [
    {
      name: 'Support',
      onFooterClick: handleClick,
    },
    {
      name: 'Terms & Conditions',
      onFooterClick: handleClick,
    },
  ];

  const externalWebsiteLinks = [
    {
      name: 'Your Privacy Rights',
      link: 'https://www.spectrum.com/policies/your-privacy-rights',
    },
    {
      name: 'California Consumer Privacy Rights',
      link: 'https://www.spectrum.com/policies/california',
    },
    {
      name: 'Do Not Sell or Share My Personal Information/Opt-Out of Targeted Advertising',
      link: 'https://spectrum.com/policies/your-privacy-rights-opt-out',
    },
    {
      name: 'California Consumer Limit the Use of My Sensitive Personal Information',
      link: 'https://www.spectrum.com/policies/your-privacy-rights-opt-out',
    },
  ];

  const handleClickHeaderItem = ({ path }) => {
    dispatch(push({ pathname: path }));
  };
  const onClickLogout = async () => {
    removeTokens();
    await dispatch(push({ pathname: '/logout' }));
    localStorage.removeItem('termsAndConditionsStatus');
    window.location.reload();
  };

  const headerItemsBeforeLogin = [
    {
      path: '/support',
      name: 'Support',
      menuAction: handleClick,
    },
    {
      path: '/new-user',
      name: 'New User?',
      menuAction: handleClick,
    },
  ];

  const headerItems = [
    {
      name: 'Manage Payments',
      menuAction: handleClickHeaderItem,
      path: '/',
    },
    {
      path: '/manage-account',
      name: 'Manage Account',
      menuAction: handleClickHeaderItem,
    },
    {
      path: '/support',
      name: 'Support',
      menuAction: handleClick,
    },
    {
      path: '/Logout',
      name: 'Logout',
      showOnDesktop: false,
      menuAction: onClickLogout,
    },
  ];

  let activeNavSet = false;
  headerItems.forEach((item) => {
    const url = window.location.href;
    let pathSection = url.substr(window.location.href.lastIndexOf('/'));
    if (pathSection.indexOf('#') > -1) {
      pathSection = pathSection.substr(0, pathSection.indexOf('#'));
    }
    if (pathSection === item.path) {
      item.activeNav = true;
      activeNavSet = true;
    }
  });
  if (!activeNavSet) {
    headerItems[0].activeNav = true;
  }

  const onHeaderLogoClick = () => {
    dispatch(push({ pathname: '/' }));
    dispatch(loadingSignIn(false));
  };

  const onFooterLogoClick = () => {
    dispatch(push({ pathname: '/' }));
  };

  return (
    <div className={classes.PageWrapper}>
      <div className={classes.Container}>
        <Header
          headerItems={headerItems}
          headerItemsBeforeLogin={props.isIE ? [] : headerItemsBeforeLogin}
          isUserAuthenticated={isUserAuthenticated()}
          isTermsAndConditionsAccepted={isTermsAndConditionsAccepted}
          isLoadingSignIn={isLoadingSignIn}
          userInfoDetails={userInfoDetails}
          onHeaderLogoClick={props.isIE ? null : onHeaderLogoClick}
          isFetchingUserLoading={isFetchingUserLoading}
          onClickLogout={onClickLogout}
        />
        {window.location.pathname === '/login' ? (
          props.children
        ) : (
          <div>{props.children}</div>
        )}
        <Footer
          currentWebsiteLinks={props.isIE ? [] : currentWebsiteLinks}
          externalWebsiteLinks={externalWebsiteLinks}
          onFooterLogoClick={props.isIE ? null : onFooterLogoClick}
        />
      </div>
    </div>
  );
};

export default Layout;
