import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

import theme from '../../../theme.module.scss';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: theme.ashGrey,
  },
}));

const PageBackground = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
};

export default PageBackground;
