/* eslint-disable linebreak-style */
import * as types from '../actionTypes/AdjustmentsActionTypes';

const adjustmentsReducerInitialState = {
  isConfirmationModalOpen: false,
  refundReasons: {},
  isAdjustmentsConfirmationModalOpen: false,
  isRefundSubmissionFailed: false,
  isCreditAdjustmentSubmissionLoading: false,
  isAdjustmentReasonsLoading: false,
  isOpenRefundRequestModal: false,
};

/** User Reducer */
const adjustmentsReducer = (state = adjustmentsReducerInitialState, action) => {
  switch (action.type) {
    case types.CREDIT_ADJUSTMENT_SUBMITTED_CONFIRMATION:
      return {
        ...state,
        isCreditAdjustmentSubmissionLoading: true,
      };
    case types.LOADING_ADJUSTMENT_REASONS:
      return {
        ...state,
        isAdjustmentReasonsLoading: action.data,
      };
    case types.REFUND_SUBMITTED:
      return {
        ...state,
        isConfirmationModalOpen: true,
        isCreditAdjustmentSubmissionLoading: false,
      };
    case types.CLOSE_CONFIRMATION:
      return {
        ...state,
        isConfirmationModalOpen: false,
        isOpenRefundRequestModal: false,
        isRefundSubmissionFailed: false,
      };
    case types.REFUND_REASONS:
      return {
        ...state,
        refundReasons: action.data,
        isAdjustmentReasonsLoading: false,
      };
    case types.CREDIT_ADJUSTMENTS_SUBMITTED:
      return {
        ...state,
        isAdjustmentsConfirmationModalOpen: true,
        isCreditAdjustmentSubmissionLoading: false,
      };
    case types.CLOSE_ADJUSTMENTS_CONFIRMATION:
      return {
        ...state,
        isAdjustmentsConfirmationModalOpen: false,
        isRefundSubmissionFailed: false,
      };
    case types.REFUND_SUBMISSION_FAILED:
      return {
        ...state,
        isRefundSubmissionFailed: true,
        isCreditAdjustmentSubmissionLoading: false,
      };
    case types.TOGGLE_REQUEST_REFUND_MODAL:
      return {
        ...state,
        isOpenRefundRequestModal: action.isOpen,
        isRefundSubmissionFailed: false,
      };
    default:
      return state;
  }
};

export { adjustmentsReducerInitialState, adjustmentsReducer };
