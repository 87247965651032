/* eslint-disable linebreak-style */
import React from 'react';
import { useDispatch } from 'react-redux';
import { HashLink } from 'react-router-hash-link';
import * as MUI from '../styles/MUI_Components';
import useClasses from '../styles/Classes';
import * as Actions from '../../../../../redux/actionCreators/PaymentActionCreators';

const RecurringPaymentConfirmationModal = ({
  launchConfirmationModal,
  nextPaymentMessage,
}) => {
  const classes = useClasses();
  const dispatch = useDispatch();

  return (
    <MUI.RefundRequestModal
      open={launchConfirmationModal}
      aria-labelledby="add-refundRequest-title"
      aria-describedby="add-refundRequest-description"
    >
      <div className={`${classes.modalBody} ${classes.global}`}>
        <div className={classes.martop2}>
          <div className={`${classes.recurringRequestSubmitted}`}>
            Recurring Payment Enabled Successfully
          </div>
          <div className={`${classes.deepBlue} ${classes.upperPad} ${classes.marleft1}`}>
            {nextPaymentMessage}.&nbsp;
            <HashLink
              smooth
              to="#MakePayment"
              onClick={() => {
                dispatch(Actions.toggleRecurringPaymentConfirmationModal(false));
              }}
            >
              Do you wish to make an additional one-time payment for the current month?
            </HashLink>
          </div>

          <div
            className={`${classes.requestRefundModalButtons} 
                      ${classes.martop2} ${classes.buttonDiv} ${classes.paymentTypeCenter}`}
          >
            <MUI.ActionButton
              className={`${classes.smallButtonText}`}
              onClick={() => {
                dispatch(Actions.toggleRecurringPaymentConfirmationModal(false));
              }}
            >
              Close
            </MUI.ActionButton>
          </div>
        </div>
      </div>
    </MUI.RefundRequestModal>
  );
};

export default RecurringPaymentConfirmationModal;
