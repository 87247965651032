/* eslint-disable linebreak-style */
export const ENABLE_USER = 'ENABLE_USER';
export const DISABLE_USER = 'DISABLE_USER';
export const SECONDARY_USERS = 'SECONDARY_USERS';
export const DISABLE_USER_MODAL = 'DISABLE_USER_MODAL';
export const CHANGE_SECONDARY_USER_FIELD_VALUE = 'CHANGE_SECONDARY_USER_FIELD_VALUE';
export const RESET_ADD_USER_FORM = 'RESET_ADD_USER_FORM';
export const RENDER_ADD_USER_MODAL = 'RENDER_ADD_USER_MODAL';
export const USER_CREATED = 'USER_CREATED';
export const USER_CREATION_PROCESS_STATUS = 'USER_CREATION_PROCESS_STATUS';
export const LOADING_SECONDARY_USERS = 'LOADING_SECONDARY_USERS';
export const LOADING_UPDATING_USER = 'LOADING_UPDATING_USER';
export const LOADING_DISABLE_USER = 'LOADING_DISABLE_USER';
export const LOADING_ENABLE_USER = 'LOADING_ENABLE_USER';
export const LOADING_ADD_SECONDARY_USER = 'LOADING_ADD_SECONDARY_USER';
export const FAILED_UPDATED_USER = 'FAILED_UPDATED_USER';
export const FAILED_CREATING_USER = 'FAILED_CREATING_USER';
export const FAILED_ENABLING_USER = 'FAILED_ENABLING_USER';
export const FAILED_DISABLING_USER = 'FAILED_DISABLING_USER';
export const FAILED_GETTING_SECONDARYUSERS = 'FAILED_GETTING_SECONDARYUSERS';
export const RESET_ACCOUNT_USERS = 'RESET_ACCOUNT_USERS';
export const ERROR_MESSAGE = 'ERROR_MESSAGE';
export const SAVE_USER_MODAL = 'SAVE_USER_MODAL';
export const UPDATE_USER = 'UPDATE_USER';
