/* eslint-disable linebreak-style */
import React from 'react';
import { Card, Link, Grid } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import {
  HeroContainer,
  CardContainer,
  SectionWrapper,
} from '../../shared/components/SharedComponentStyles';
import useClasses from './PageNotfoundClasses';

const PageNotFound = () => {
  const classes = useClasses();

  return (
    <SectionWrapper>
      <Grid className={`${classes.pageNotFoundModule}`}>
        <Grid className={`${classes.pageNotFoundWrapper}`}>
          <HeroContainer>
            <CardContainer>
              <Card>
                <CardContent>
                  <div className={`${classes.headerText}`}>Page Not Found</div>
                  <div className={`${classes.infoText}`}>
                    The page you are looking for does not exist, please check your URL
                  </div>

                  <div className={`${classes.linkText}`}>
                    {' '}
                    <Link
                      onClick={() => {
                        window.location.href = '/';
                      }}
                    >
                      <span className={`${classes.linkColor}`}>Return to Login</span>
                    </Link>
                  </div>
                </CardContent>
              </Card>
            </CardContainer>
          </HeroContainer>
        </Grid>
      </Grid>
    </SectionWrapper>
  );
};

export default PageNotFound;
