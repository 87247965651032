/* eslint-disable linebreak-style */
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { rootReducer } from './rootReducer';
import safeThunk from './middleware/safeThunk';

export const history = createBrowserHistory();

export default function Store(initialState) {
  const enhancers = [];
  if (
    process.env.REACT_APP_ENV === 'Development' ||
    process.env.REACT_APP_ENV === 'Local'
  ) {
    // add support for Redux dev tools
    const devToolsExtension = window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
  }

  return createStore(
    rootReducer(history),
    initialState,
    compose(applyMiddleware(thunk, safeThunk, routerMiddleware(history)), ...enhancers)
  );
}
