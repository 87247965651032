/* eslint-disable linebreak-style */
import React from 'react';
import styled from 'styled-components';

const Section = ({ className, children }) => (
  <section className={className}>{children}</section>
);

const wrapper = styled(Section)`
  margin: 0 auto;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 0rem;
  position: relative;
  z-index: 0 !important;
`;

export const Wrapper = wrapper;
