/* eslint-disable linebreak-style */
import { createUseStyles } from 'react-jss';
import theme from '../../theme.module.scss';
import { device } from '../../shared/components/ResponsiveDevice';

const useClasses = createUseStyles({
  confirmationModule: {
    zIndex: '0',
    position: 'relative',
  },

  confirmationWrapper: {
    margin: ' 0 auto',
  },

  linkText: {
    color: `${theme.medBlue}`,
    fontFamily: 'Reach-Title-Bold, sans-serif',
    letterSpacing: '-0.08rem',
    textAlign: 'center',
    paddingTop: '1rem',
    fontSize: '1rem',

    '&:hover': {
      cursor: 'pointer',
      color: `${theme.deepBlue}`,
    },
  },

  headerText: {
    color: `${theme.deepBlue}`,
    fontFamily: 'Reach-Title-Bold, sans-serif',
    fontSize: '1.5rem',
    lineHeight: '2rem',
    letterSpacing: '-0.1rem',
    textAlign: 'center',
    paddingTop: '1rem',
  },

  infoText: {
    color: 'inherit',
    fontFamily: 'inherit',
    letterSpacing: '-0.03rem',
    textAlign: 'left',
    padding: '2rem 1.5rem 1rem 2rem',
    lineHeight: '1rem',
    fontSize: '1rem',

    [`@media only screen and ${device.mobile}`]: {
      paddingLeft: '0',
      paddingRight: '0',
    },
  },

  infoTextFailure: {
    color: `${theme.darkRed}`,
    fontFamily: 'inherit',
    letterSpacing: '-0.03rem',
    textAlign: 'left',
    padding: '2rem 1.5rem 1rem 2rem',
    lineHeight: '1rem',
    fontSize: '1rem',

    [`@media only screen and ${device.mobile}`]: {
      paddingLeft: '0',
      paddingRight: '0',
    },
  },
  linkColor: {
    color: `${theme.medBlue}`,
  },
});

export default useClasses;
