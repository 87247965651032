/* eslint-disable linebreak-style */
import React from 'react';
import { useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import * as MUI from '../styles/MUIComponents';
import useClasses from '../styles/ManageAccountClasses';
import * as Actions from '../../../../../redux/actionCreators/AccountUsersActionCreators';

const SaveUserModal = ({ launchSaveUserModal }) => {
  const classes = useClasses();
  const dispatch = useDispatch();
  return (
    <MUI.DeleteUserMethodModal open={launchSaveUserModal}>
      <>
        {' '}
        <div className={classes.modalBody}>
          <div className={classes.modalTitle}> User Created</div>
          <div className={classes.upperPad}>
            <div className={`${classes.deepBlue} ${classes.paddingBottom1}`}>
              Confirmation email sent. A registration email has been sent to the newly
              added user.
            </div>
            <span>
              <Grid
                className={`${classes.modalButtonsLeftPad} ${classes.buttonDiv} ${classes.upperPad}`}
              >
                <MUI.ActionButton
                  onClick={() => {
                    dispatch(Actions.renderAddUserModal(true));
                  }}
                >
                  {' '}
                  Create User
                </MUI.ActionButton>
                <span className={classes.marleft1}>
                  <MUI.ActionButton
                    onClick={() => {
                      dispatch(Actions.handleSaveUserModal(false));
                    }}
                  >
                    Cancel
                  </MUI.ActionButton>
                </span>
              </Grid>
            </span>
          </div>
        </div>
      </>
    </MUI.DeleteUserMethodModal>
  );
};
export default SaveUserModal;
